const Dashboards = {
  home: "dashboards.home",
  overview: "dashboards.overview",
  welcomeBack: "dashboards.welcomeBack",
  hiUser: "dashboards.hiUser",
  inProgress: "dashboards.inProgress",
  notStarted: "dashboards.notStarted",
  overdue: "dashboards.overdue",
  completed: "dashboards.completed",
  tasksAssignedToMe: "dashboards.tasksAssignedToMe",
  tasksOpenByStatus: "dashboards.tasksOpenByStatus",
  tasksNext7Days: "dashboards.tasksNext7Days",
  prospectsFunnel: "dashboards.prospectsFunnel",
  tenantStats: "dashboards.tenantStats",
  prospectsStats: "dashboards.prospectsStats",
  rentalStats: "dashboards.rentalStats",
  bankingStats: "dashboards.bankingStats",
  recentTenantRequests: "dashboards.recentTenantRequests",
  internalTask: "dashboards.internalTask",
  tenantRequests: "dashboards.tenantRequests",
  ownerRequests: "dashboards.ownerRequests",
  workRequests: "dashboards.workRequests",
  future: "dashboards.future",
  current: "dashboards.current",
  past: "dashboards.past",
  new: "dashboards.new",
  contact: "dashboards.contact",
  showing: "dashboards.showing",
  rentalApplication: "dashboards.rentalApplication",
  setupTenantScreening: "dashboards.setupTenantScreening",
  reportReadyToRequest: "dashboards.reportReadyToRequest",
  connectYourBankAccounts: "dashboards.connectYourBankAccounts",
  lease: "dashboards.lease",
  totalX: "dashboards.totalX",
  totalNumberOfProspects: "dashboards.totalNumberOfProspects",
  totalNumberOfVacantUnits: "dashboards.totalNumberOfVacantUnits",
  totalOwedByYou: "dashboards.totalOwedByYou",
  totalOwedToYou: "dashboards.totalOwedToYou",
  asOfToday: "dashboards.asOfToday",
  next90Days: "dashboards.next90Days",
  next120Days: "dashboards.next120Days",
  visitOurHelpCenter: "dashboards.visitOurHelpCenter",
  topFiveSourcesLastThirtyDays: "dashboards.topFiveSourcesLastThirtyDays",
  topFiveSourcesByVacancies: "dashboards.topFiveSourcesByVacancies",
  referAFriendAndEarnMoney: "dashboards.referAFriendAndEarnMoney",
  refresh: "dashboards.refresh",
  setupOnlinePayments: "dashboards.setupOnlinePayments",
  last30Days: "dashboards.last30Days",
  customizeTenantPortal: "dashboards.customizeTenantPortal",
  scheduleDemo: "dashboards.scheduleDemo",
  moveOuts: "dashboards.moveOuts",
  moveIns: "dashboards.moveIns",
  moveOutsNext7Days: "dashboards.moveOutsNext7Days",
  moveInsNext7Days: "dashboards.moveInsNext7Days",
  expiredLeases: "dashboards.expiredLeases",
  overdueTasks: "dashboards.overdueTasks",
  unassignedTenantRequests: "dashboards.unassignedTenantRequests",
  mon: "dashboards.mon",
  tue: "dashboards.tue",
  wed: "dashboards.wed",
  thu: "dashboards.thu",
  fri: "dashboards.fri",
  sat: "dashboards.sat",
  open: "dashboards.open",
  unassigned: "dashboards.unassigned",
  newLast30Days: "dashboards.newLast30Days",

  thingsToDo: "dashboards.thingsToDo",
  suggested: "dashboards.suggested",
  prospectsByProperty: "dashboards.prospectsByProperty",
  prospectsByLeadSource: "dashboards.prospectsByLeadSource",
  occupancyRate: "dashboards.occupancyRate",
  vacanciesByProperty: "dashboards.vacanciesByProperty",
  draftLeasesFunnel: "dashboards.draftLeasesFunnel",
  leaseRenewalsFunnel: "dashboards.leaseRenewalsFunnel",
  rentalApplicationFunnel: "dashboards.rentalApplicationFunnel",
  recentPaymentsReceived: "dashboards.recentPaymentsReceived",
  leasesWithOutstandingBalances: "dashboards.leasesWithOutstandingBalances",
  accountsReceivable: "dashboards.accountsReceivable",
  accountsPayable: "dashboards.accountsPayable",
  pnl: "dashboards.pnl",
  properties: "dashboards.properties",
  units: "dashboards.units",
  sqft: "dashboards.sqft",
  vacant: "dashboards.vacant",
  occupied: "dashboards.occupied",
  leasingActivityNext7Days: "dashboards.leasingActivityNext7Days",
  notSent: "dashboards.notSent",
  sent: "dashboards.sent",
  viewed: "dashboards.viewed",
  partiallySigned: "dashboards.partiallySigned",
  fullyExecuted: "dashboards.fullyExecuted",
  undecided: "dashboards.undecided",
  approved: "dashboards.approved",
  approvedWithConditions: "dashboards.approvedWithConditions",
  rejected: "dashboards.rejected",
  bankBalance: "dashboards.bankBalance",
  doorloopBalance: "dashboards.doorloopBalance",
  invited: "dashboards.invited",
  active: "dashboards.active",
  renewalOffer: "dashboards.renewalOffer",
  draftLease: "dashboards.draftLease",
  notRenewing: "dashboards.notRenewing",
  customizeDashboard: "dashboards.customizeDashboard",
  removeWidget: "dashboards.removeWidget",
  widgets: "dashboards.widgets",
  quickTip: "dashboards.quickTip",
  dndTip: "dashboards.dndTip",
  bankConnectFirstHeader: "dashboards.bankConnectFirstHeader",
  bankConnectSecondHeader: "dashboards.bankConnectSecondHeader",
  bankConnectSubtext: "dashboards.bankConnectSubtext",
  bankConnectButtonText: "dashboards.bankConnectButtonText",
  bankDetails: "dashboards.bankDetails",
  accountBalance: "dashboards.accountBalance",
  status: "dashboards.status",
  activeAccount: "dashboards.activeAccount",
  updatingAccount: "dashboards.updatingAccount",
  onlinePaymentsFirstHeader: "dashboards.onlinePaymentsFirstHeader",
  onlinePaymentsSecondHeader: "dashboards.onlinePaymentsSecondHeader",
  onlinePaymentsSubtext: "dashboards.onlinePaymentsSubtext",
  onlinePaymentsButtonText: "dashboards.onlinePaymentsButtonText",
  scheduleDemoFirstHeader: "dashboards.scheduleDemoFirstHeader",
  scheduleDemoSecondHeader: "dashboards.scheduleDemoSecondHeader",
  scheduleDemoSubtext: "dashboards.scheduleDemoSubtext",
  scheduleDemoButtonText: "dashboards.scheduleDemoButtonText",
  rentalAppFirstHeader: "dashboards.rentalAppFirstHeader",
  rentalAppSecondHeader: "dashboards.rentalAppSecondHeader",
  rentalAppSubtext: "dashboards.rentalAppSubtext",
  rentalAppButtonText: "dashboards.rentalAppButtonText",
  rentalAppSecondaryButtonText: "dashboards.rentalAppSecondaryButtonText",
  noData: "dashboards.noData",
  noDataSubtext: "dashboards.noDataSubtext",
  networkError: "dashboards.networkError",
  networkErrorSubtext: "dashboards.networkErrorSubtext",
  unassignedTasks: "dashboards.unassignedTasks",
  draftsLeases: "dashboards.draftsLeases",
  unassignedProspects: "dashboards.unassignedProspects",
  pendingRentalApplications: "dashboards.pendingRentalApplications",
  rentalApplicationsPendingReports: "dashboards.rentalApplicationsPendingReports",
  rentalApplicationsPendingDecision: "dashboards.rentalApplicationsPendingDecision",
  rentalApplicationUnitsOpenForApplication: "dashboards.rentalApplicationUnitsOpenForApplication",
  rentalApplicationsSubmittedApplications: "dashboards.rentalApplicationsSubmittedApplications",
  rentalApplicationsFailedApplications: "dashboards.rentalApplicationsFailedApplications",
  pastDueLeases: "dashboards.pastDueLeases",
  pastDueBills: "dashboards.pastDueBills",
  transactionsPendingReview: "dashboards.transactionsPendingReview",
  xUnits: "dashboards.xUnits",
  lastNumberOfMonthsToDate: "dashboards.lastNumberOfMonthsToDate",
  revenue: "dashboards.revenue",
  expenses: "dashboards.expenses",
  netIncome: "dashboards.netIncome"
};
export default Dashboards;
