"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseSettingsDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const lateFeePolicy_1 = require("./shared/lateFeePolicy");
const paymentAllocationsSettings_dto_1 = require("./shared/paymentAllocationsSettings.dto");
const recurringTransactionsSettings_dto_1 = require("./shared/recurringTransactionsSettings.dto");
const rentersInsuranceSettings_dto_1 = require("./shared/rentersInsuranceSettings.dto");
const tenantPortalAccess_dto_1 = require("./shared/tenantPortal/tenantPortalAccess.dto");
const portalRequestSettings_dto_1 = require("./shared/portalRequestSettings.dto");
const convenienceFeesPolicy_dto_1 = require("./shared/convenienceFeesPolicy.dto");
const returnedPaymentFeesSettings_dto_1 = require("./shared/returnedPaymentFeesSettings.dto");
class LeaseSettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LeaseSettingsDto = LeaseSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenantPortalAccess_dto_1.TenantPortalAccessDto),
    __metadata("design:type", tenantPortalAccess_dto_1.TenantPortalAccessDto)
], LeaseSettingsDto.prototype, "tenantPortalAccess", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => portalRequestSettings_dto_1.PortalRequestSettingsDto),
    __metadata("design:type", portalRequestSettings_dto_1.PortalRequestSettingsDto)
], LeaseSettingsDto.prototype, "tenantPortalRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => lateFeePolicy_1.LateFeesSettingsDto),
    __metadata("design:type", lateFeePolicy_1.LateFeesSettingsDto)
], LeaseSettingsDto.prototype, "lateFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto),
    __metadata("design:type", convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto)
], LeaseSettingsDto.prototype, "convenienceFeesPolicy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto),
    __metadata("design:type", returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto)
], LeaseSettingsDto.prototype, "returnedPaymentFeesSettings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentersInsuranceSettings_dto_1.RentersInsuranceSettingsDto),
    __metadata("design:type", rentersInsuranceSettings_dto_1.RentersInsuranceSettingsDto)
], LeaseSettingsDto.prototype, "rentersInsurance", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => paymentAllocationsSettings_dto_1.PaymentAllocationsSettingsDto),
    __metadata("design:type", paymentAllocationsSettings_dto_1.PaymentAllocationsSettingsDto)
], LeaseSettingsDto.prototype, "paymentAllocations", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => recurringTransactionsSettings_dto_1.RecurringTransactionsSettingsDto),
    __metadata("design:type", recurringTransactionsSettings_dto_1.RecurringTransactionsSettingsDto)
], LeaseSettingsDto.prototype, "recurringTransactions", void 0);
