import { AppLayoutContext } from "@/contexts/appLayoutContext";
import { useContextSelector } from "use-context-selector";
import { AppLayouts } from "./index";

const LayoutRenderer = ({ children }) => {
  const currentLayout = useContextSelector(AppLayoutContext, (context) => context.currentLayout);

  const SelectedLayout = AppLayouts?.[currentLayout] || AppLayouts.SideBarLayout;

  return <SelectedLayout>{children}</SelectedLayout>;
};

export default LayoutRenderer;
