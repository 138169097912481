"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationV2SpecificAmenityTypeEnum = void 0;
var SyndicationV2SpecificAmenityTypeEnum;
(function (SyndicationV2SpecificAmenityTypeEnum) {
    SyndicationV2SpecificAmenityTypeEnum["ADDITIONAL_STORAGE"] = "AdditionalStorage";
    SyndicationV2SpecificAmenityTypeEnum["AIR_CONDITIONER"] = "AirConditioner";
    SyndicationV2SpecificAmenityTypeEnum["ALARM"] = "Alarm";
    SyndicationV2SpecificAmenityTypeEnum["BALCONY"] = "Balcony";
    SyndicationV2SpecificAmenityTypeEnum["CABLE"] = "Cable";
    SyndicationV2SpecificAmenityTypeEnum["CARPET"] = "Carpet";
    SyndicationV2SpecificAmenityTypeEnum["CARPORT"] = "Carport";
    SyndicationV2SpecificAmenityTypeEnum["CEILING_FAN"] = "CeilingFan";
    SyndicationV2SpecificAmenityTypeEnum["CONTROLLED_ACCESS"] = "ControlledAccess";
    SyndicationV2SpecificAmenityTypeEnum["COURTYARD"] = "Courtyard";
    SyndicationV2SpecificAmenityTypeEnum["DISHWASHER"] = "DishWasher";
    SyndicationV2SpecificAmenityTypeEnum["DISPOSAL"] = "Disposal";
    SyndicationV2SpecificAmenityTypeEnum["DOUBLE_SINK_VANITY"] = "DoubleSinkVanity";
    SyndicationV2SpecificAmenityTypeEnum["DRYER"] = "Dryer";
    SyndicationV2SpecificAmenityTypeEnum["FIREPLACE"] = "Fireplace";
    SyndicationV2SpecificAmenityTypeEnum["FRAMED_MIRRORS"] = "FramedMirrors";
    SyndicationV2SpecificAmenityTypeEnum["FURNISHED"] = "Furnished";
    SyndicationV2SpecificAmenityTypeEnum["GARAGE"] = "Garage";
    SyndicationV2SpecificAmenityTypeEnum["HANDRAILS"] = "Handrails";
    SyndicationV2SpecificAmenityTypeEnum["HARDWOOD_FLOORING"] = "HardwoodFlooring";
    SyndicationV2SpecificAmenityTypeEnum["HARD_SURFACE_COUNTER_TOPS"] = "HardSurfaceCounterTops";
    SyndicationV2SpecificAmenityTypeEnum["HEAT"] = "Heat";
    SyndicationV2SpecificAmenityTypeEnum["INDIVIDUAL_CLIMATE_CONTROL"] = "IndividualClimateControl";
    SyndicationV2SpecificAmenityTypeEnum["ISLAND_KITCHEN"] = "IslandKitchen";
    SyndicationV2SpecificAmenityTypeEnum["LAMINATE_COUNTER_TOPS"] = "LaminateCounterTops";
    SyndicationV2SpecificAmenityTypeEnum["VINYL_FLOORING"] = "VinylFlooring";
    SyndicationV2SpecificAmenityTypeEnum["LARGE_CLOSETS"] = "LargeClosets";
    SyndicationV2SpecificAmenityTypeEnum["LINEN_CLOSET"] = "LinenCloset";
    SyndicationV2SpecificAmenityTypeEnum["MICROWAVE"] = "Microwave";
    SyndicationV2SpecificAmenityTypeEnum["PANTRY"] = "Pantry";
    SyndicationV2SpecificAmenityTypeEnum["PATIO"] = "Patio";
    SyndicationV2SpecificAmenityTypeEnum["PRIVATE_BALCONY"] = "PrivateBalcony";
    SyndicationV2SpecificAmenityTypeEnum["PRIVATE_PATIO"] = "PrivatePatio";
    SyndicationV2SpecificAmenityTypeEnum["RANGE"] = "Range";
    SyndicationV2SpecificAmenityTypeEnum["REFRIGERATOR"] = "Refrigerator";
    SyndicationV2SpecificAmenityTypeEnum["SATELLITE"] = "Satellite";
    SyndicationV2SpecificAmenityTypeEnum["SKYLIGHT"] = "Skylight";
    SyndicationV2SpecificAmenityTypeEnum["TILE_FLOORING"] = "TileFlooring";
    SyndicationV2SpecificAmenityTypeEnum["VAULTED_CEILING"] = "VaultedCeiling";
    SyndicationV2SpecificAmenityTypeEnum["VIEW"] = "View";
    SyndicationV2SpecificAmenityTypeEnum["WASHER"] = "Washer";
    SyndicationV2SpecificAmenityTypeEnum["WHEEL_CHAIR"] = "WheelChair";
    SyndicationV2SpecificAmenityTypeEnum["WD_HOOKUP"] = "WD_Hookup";
    SyndicationV2SpecificAmenityTypeEnum["WINDOW_COVERINGS"] = "WindowCoverings";
    SyndicationV2SpecificAmenityTypeEnum["OTHER"] = "Other";
})(SyndicationV2SpecificAmenityTypeEnum || (exports.SyndicationV2SpecificAmenityTypeEnum = SyndicationV2SpecificAmenityTypeEnum = {}));
