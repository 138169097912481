"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionReportItem = void 0;
class TransactionReportItem {
    constructor(params) {
        this.date = params.date;
        this.beginningBalance = params.beginningBalance;
        this.id = params.id;
        this.account = params.account;
        this.type = params.type;
        this.amount = params.amount;
        this.credit = params.credit;
        this.debit = params.debit;
        this.reference = params.reference;
        this.memo = params.memo;
        this.runningBalance = params.runningBalance;
        this.property = params.property;
        this.propertyName = params.propertyName;
        this.unit = params.unit;
        this.unitName = params.unitName;
        this.lease = params.lease;
        this.vendor = params.vendor;
        this.owner = params.owner;
        this.tenant = params.tenant;
        this.managementCompany = params.managementCompany;
        this.nameType = params.nameType;
        this.name = params.name;
        this.fullName = params.fullName;
        this.paymentMethod = params.paymentMethod;
        this.notes = params.notes;
        this.cleared = params.cleared;
        this.reconciled = params.reconciled;
        this.journalEntryId = params.journalEntryId;
        this.accountFullName = params.accountFullName;
        this.tenantName = params.tenantName;
        this.ownerName = params.ownerName;
        this.leaseName = params.leaseName;
        this.vendorName = params.vendorName;
        this.orderIndex = params.orderIndex;
    }
}
exports.TransactionReportItem = TransactionReportItem;
