import type { ViewComponentProps } from "DLUI/view/view";

interface SideBarStyleProps {
  layoutStyle: ViewComponentProps;
  outerContentStyle: ViewComponentProps;
  innerContentStyle: ViewComponentProps;
  sideBarStyle: ViewComponentProps;
  topBarStyle: ViewComponentProps;
}

export const getSideBarResponsiveStyle = (
  isTabletOrMobile: boolean,
  isOnlyTablet: boolean,
  isSideMenuIsCollapsed: boolean
): SideBarStyleProps => {
  const responsiveStyle = {
    layoutStyle: {
      height: "100%",
      overflow: "hidden",
      minHeight: "initial"
    },
    outerContentStyle: {
      flexDirection: "row",
      noWrap: true
    },
    innerContentStyle: {
      height: "100%",
      flexDirection: "row"
    },
    sideBarStyle: {
      marginLeft: 0
    }
  } as SideBarStyleProps;

  if (isTabletOrMobile) {
    responsiveStyle.layoutStyle.height = "auto";
    responsiveStyle.layoutStyle.minHeight = "100vh";
    responsiveStyle.outerContentStyle.flexDirection = "column";
    responsiveStyle.outerContentStyle.noWrap = false;
    responsiveStyle.innerContentStyle.flexDirection = "column";
  }

  if (isOnlyTablet) {
    responsiveStyle.innerContentStyle.flexDirection = "row";
  }

  return responsiveStyle;
};
