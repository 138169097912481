"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginAndPasswordSettingsDisplayDto = exports.LoginAndPasswordSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const isStrongPassword_validator_1 = require("../common/validators/isStrongPassword.validator");
const common_1 = require("../common");
const loginMfaType_1 = require("../login/loginMfaType");
class LoginAndPasswordSettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LoginAndPasswordSettingsDto = LoginAndPasswordSettingsDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], LoginAndPasswordSettingsDto.prototype, "loginEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isStrongPassword_validator_1.IsComplexPassword)(),
    __metadata("design:type", String)
], LoginAndPasswordSettingsDto.prototype, "newPassword", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LoginAndPasswordSettingsDto.prototype, "allowTechnicianAccess", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LoginAndPasswordSettingsDto.prototype, "isMfaRequired", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(loginMfaType_1.LoginMfaType),
    __metadata("design:type", String)
], LoginAndPasswordSettingsDto.prototype, "mfaType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], LoginAndPasswordSettingsDto.prototype, "currentPassword", void 0);
class LoginAndPasswordSettingsDisplayDto extends LoginAndPasswordSettingsDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.LoginAndPasswordSettingsDisplayDto = LoginAndPasswordSettingsDisplayDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsEqualTo)("newPassword", {
        message: "Password confirmation does not match your new password."
    }),
    __metadata("design:type", String)
], LoginAndPasswordSettingsDisplayDto.prototype, "newPasswordConfirmation", void 0);
