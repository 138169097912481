import type { DoorLoopLeadDto } from "@doorloop/dto";
import _ from "lodash";
import { calculateLeadScore } from "screens/demo/leadScoreCalculator";
import type {
  CalendlyCallResponse,
  CalendlyPayload,
  DLMCCookie,
  HubSpotObject,
  NeverbounceResponse
} from "screens/demo/types";
import { QueryParams } from "utils/queryParams";

const hubspotLeadCreationURL = `https://api.hsforms.com/submissions/v3/integration/submit/39674306/12cc5240-d11a-49aa-b4d9-0f63b72eced5`;

const fetchWithTimeout = async (url: RequestInfo, options?: RequestInit, timeout = 5000): Promise<Response> => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal
    });
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    window.analytics.track("lead_fetch_error", {
      error
    });
    throw error;
  } finally {
    clearTimeout(id);
  }
};

const nativePosthogTracking = (
  name: string,
  properties?: Record<string, string | boolean>,
  $set?: Record<string, string>
) => {
  window.posthog?.capture?.(name, {
    ...properties,
    $set
  });
};

const convertToCalendlyBody = (leadDto: DoorLoopLeadDto, cookieData: Partial<DLMCCookie>): CalendlyPayload => {
  const [firstname, ...lastNameParts] = leadDto.name?.split(" ") || [];
  return {
    email: leadDto.email!,
    phone: leadDto.phone!,
    firstname,
    lastname: lastNameParts.join(" "),
    expected_number_of_units__c: leadDto.numberOfUnits!,
    custom_lead_score: calculateLeadScore(leadDto, cookieData),
    user_selected_occupation: leadDto.selectedJobTitle!,
    are_you_a_tenant__attorney__or_accountant_: leadDto.jobTitle!,
    primary_portfolio__type: leadDto.mainPropertyType!,
    customer_reported_timeline__c: leadDto.whenPlanToUseSoftware!,
    manage_properties_us_canada: leadDto.doYouManagePropertiesInTheUSOrCanada!,
    utm_source: cookieData.utmSource || "",
    utm_campaign: cookieData.utmCampaign || "",
    utm_content: cookieData.utmContent || "",
    utm_source___last_touch: cookieData.utmSourceLast || "",
    utm_campaign___last_touch: cookieData.utmCampaignLast || "",
    utm_content___last_touch: cookieData.utmContentLast || "",
    has_filled_out_hubspot_demo_form: true,
    ip_address_country__c: leadDto.country || "",
    leadsource: leadDto.leadSource!,
    blocked_from_demos: "",
    hs_context: "",
    url_params: {
      hide_gdpr_banner: 1,
      hide_event_type_details: 1
    },
    form_labels: {
      email: "Email",
      user_selected_occupation: "What is your role?",
      primary_portfolio__type: "What is your main property type?",
      customer_reported_timeline__c: "When do you plan on using the software?",
      custom_lead_score: "custom lead score",
      utm_source: "utm source",
      utm_source___last_touch: "utm source   last touch",
      utm_campaign: "utm campaign",
      utm_campaign___last_touch: "utm campaign   last touch",
      utm_content___last_touch: "utm content   last touch",
      utm_content: "utm content",
      has_filled_out_hubspot_demo_form: "has filled out hubspot demo form",
      ip_address_country__c: "ip address country  c",
      manage_properties_us_canada: "Do you manage properties in the US or Canada?",
      leadsource: "leadsource",
      blocked_from_demos: "blocked from demos",
      hs_context: "hs context"
    }
  };
};
const getCalendlyURL = async (leadDto: DoorLoopLeadDto, cookieData: DLMCCookie): Promise<CalendlyCallResponse> => {
  try {
    const res = await fetch(
      "https://calendly.com/api/form_builder/forms/8fcad175-5671-475f-8c42-1e8d4cba5e1f/submissions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(convertToCalendlyBody(leadDto, cookieData))
      }
    );
    const data = await res.json();
    return data;
  } catch (e) {
    // Fallback for unknown failures
    return {
      result_type: "event_type_id",
      result_url: "https://calendly.com/doorloop/demo"
    };
  }
};

const getHubspotCookie = (): string | null => {
  const name = "hubspotutk";
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
};
const getLeadSource = () => {
  const leadSource = QueryParams.get("leadsource");
  return leadSource ? decodeURIComponent(leadSource) : "Scheduled Demo";
};
const createHubSpotObject = (dto: DoorLoopLeadDto, cookieData: DLMCCookie): HubSpotObject => {
  const {
    ipAddress,
    country,
    isUsingVPN,
    email,
    name,
    phone,
    numberOfUnits,
    selectedJobTitle,
    whenPlanToUseSoftware,
    mainPropertyType,
    jobTitle,
    doYouManagePropertiesInTheUSOrCanada
  } = dto;
  const data: HubSpotObject = {
    fields: [],
    context: {
      hutk: getHubspotCookie(),
      pageUri: window.location.href,
      pageName: document.title,
      ipAddress: dto.ipAddress || ""
    }
  };
  const push = (name: string, value: string | undefined) => {
    value &&
      data.fields.push({
        name,
        value
      });
  };

  push("ip_address__c", ipAddress);
  push("ip_address_country__c", country);
  push("using_vpn_", isUsingVPN ? "Yes" : "No");
  push("phone", phone);
  push("expected_number_of_units__c", numberOfUnits?.toString());

  if (name) {
    const [firstName, ...lastNameParts] = name.split(" ");
    const lastName = lastNameParts.join(" ");
    push("full_name", name);
    push("firstname", firstName);
    push("lastname", lastName);
  }

  push("primary_portfolio__type", mainPropertyType);
  push("user_selected_occupation", selectedJobTitle);
  push("customer_reported_timeline__c", whenPlanToUseSoftware);
  push("are_you_a_tenant__attorney__or_accountant_", jobTitle);
  push("manage_properties_us_canada", doYouManagePropertiesInTheUSOrCanada);

  push("email", email || "");
  push("leadsource", getLeadSource());
  push("has_filled_out_hubspot_demo_form", "true");

  if (cookieData) {
    push("utm_campaign", cookieData.utmCampaign);
    push("utm_campaign___all_touches", cookieData.utmCampaignsAll);
    push("utm_campaign___last_touch", cookieData.utmCampaignLast);

    push("utm_content", cookieData.utmContent);
    push("utm_content___all_touches", cookieData.utmContentsAll);
    push("utm_content___last_touch", cookieData.utmContentLast);

    push("utm_medium", cookieData.utmMedium);
    push("utm_medium___all_touches", cookieData.utmMediumsAll);
    push("utm_medium___last_touch", cookieData.utmMediumLast);

    push("utm_source", cookieData.utmSource);
    push("utm_source___last_touch", cookieData.utmSourceLast);
    push("utm_sources___all", cookieData.utmSourcesAll);

    push("utm_term", cookieData.utmTerm);
    push("utm_term___all_touches", cookieData.utmTermsAll);
    push("utm_term___last_touch", cookieData.utmTermLast);

    push("adwords_gclid", cookieData.adwordsGclid);
    push("msclkid", cookieData.msClkid);
    push("li_fat_id", cookieData.linkedinId);

    push("adgroup_id", cookieData.adgroupID);
    push("keyword_id", cookieData.keywordID);
    push("campaign_id", cookieData.campaignID);

    push("referrer", cookieData.referrer);
    push("first_website_visit_at", cookieData.firstWebsiteVisitAt);
    push("landing_page", cookieData.landingPage);
  }

  push("custom_lead_score", calculateLeadScore(dto, cookieData).toString());

  return data;
};
const reportToHubSpot = (dto: DoorLoopLeadDto, cookieData: DLMCCookie, hubspotFormId: string) => {
  fetch(`https://api.hsforms.com/submissions/v3/integration/submit/39674306/${hubspotFormId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(createHubSpotObject(dto, cookieData))
  });
};

const makeEmailedTracker = (email: string) => (event: string) => nativePosthogTracking(event, undefined, { email });
const validateEmail = _.memoize(
  async (
    email: string
  ): Promise<{
    isValid: boolean;
    result: string;
  }> => {
    const track = makeEmailedTracker(email);
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      track("lead_email_invalid");
      return {
        isValid: false,
        result: "invalid_email"
      };
    }

    const validationUrl = `https://validate-email-endpoint.vercel.app/api/validate-email?email=${encodeURIComponent(email)}`;
    try {
      const response = await fetchWithTimeout(
        validationUrl,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" }
        },
        10_000
      );
      const data: NeverbounceResponse = await response.json();

      if (response.status === 429) {
        track("lead_rate_limited");
        return {
          isValid: false,
          result: "too_many_requests"
        };
      }
      if (data.result === "invalid" || data.result === "disposable") {
        track("lead_email_invalid");
        return {
          isValid: false,
          result: "invalid_email"
        };
      }
      track("lead_valid_email");
      return {
        isValid: ["valid", "catchall", "unknown"].includes(data.result),
        result: data.result
      };
    } catch (error) {
      track("lead_email_error");
      return {
        isValid: false,
        result: "invalid_email"
      };
    }
  }
);

const createEmailOnlyLead = async (dto: DoorLoopLeadDto, cookieData: DLMCCookie): Promise<string | void> => {
  const email = dto.email || "";
  const track = makeEmailedTracker(email);
  const { isValid, result: validationResult } = await validateEmail(email);
  if (!isValid) {
    return validationResult;
  }
  const data = createHubSpotObject(dto, cookieData);
  data.fields.push({
    name: "neverbouncevalidationresult",
    value: validationResult
  });

  try {
    track("lead_attempted_form_api_call");
    const response = await fetch(hubspotLeadCreationURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      console.error(new Error(`HTTP error! status: ${response.status}`));
    }
    nativePosthogTracking(
      "lead_created",
      {
        lead_created_location: window.location.pathname,
        lead_created_pms_post: false
      },
      { email }
    );
  } catch (error: unknown) {
    track("lead_created_error");
    return error as string;
  }
};

export const demoFormUtils = {
  getCalendlyURL,
  validateEmail,
  hubspot: {
    createEmailOnlyLead,
    reportToHubSpot
  },
  track: nativePosthogTracking
};
