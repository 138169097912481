import {
  MarketingAbcIcon,
  MarketingCbsIcon,
  MarketingFoxNewsIcon,
  MarketingNbsIcon,
  MarketingUsaIcon
} from "assets/icons";
import { Icon } from "DLUI/icon/icon";
import SeparationLine from "DLUI/separatorView/separationLine";
import View from "DLUI/view/view";
import React from "react";
import Text from "DLUI/text/text";
import AppStrings from "locale/keys";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

/**
 * DLUI Copyright - generates the platform copyright text
 */

const MarketingViewSeenOn: React.FC = () => {
  const { isMobile } = useResponsiveHelper();

  return (
    <View marginTop={"auto"}>
      <SeparationLine width={"100%"} height={1} />
      <View
        width={"100%"}
        flex={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
        paddingLeft={40}
        paddingRight={40}
        marginBottom={20}
        marginTop={20}
      >
        <Text
          fontSize={14}
          color={isMobile ? "white" : "secondary-gray"}
          value={AppStrings.Common.AsSeenOn}
          textTransform={"uppercase"}
        />
        <View>
          <View
            marginTop={20}
            justifyContent={"space-around"}
            flexDirection={"row"}
            height={40}
            alignItems={"flex-start"}
            paddingRight={isMobile ? 0 : 40}
            paddingLeft={isMobile ? 0 : 40}
          >
            <Icon width={40} height={40} Source={MarketingAbcIcon} />
            <Icon width={40} height={40} Source={MarketingCbsIcon} />
            <Icon width={40} height={40} Source={MarketingFoxNewsIcon} />
            <Icon width={40} height={40} Source={MarketingNbsIcon} />
            <Icon width={40} height={40} Source={MarketingUsaIcon} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default MarketingViewSeenOn;
