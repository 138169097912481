import React from "react";

import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";

import type { DLButtonUIProps } from "./buttonUI";
import ButtonUI from "./buttonUI";

interface ButtonRestrictedAccessWrappersProps {
  clearance?: AnyPermissionClearance;
  excludedDevices?: any[];
}

export type DLButtonProps = ButtonRestrictedAccessWrappersProps & DLButtonUIProps;

const DLButton: React.FC<DLButtonProps> = React.forwardRef(({ clearance, excludedDevices, ...propsUI }, ref) => (
  <ButtonUI ref={ref} {...propsUI} />
));
DLButton.displayName = "DLButton";
export default DLButton;
