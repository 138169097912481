export default {
  screen: {
    assigned: "Assigned",
    prospect: "Prospect",
    addNewProspect: "New Prospect",
    prospects: "Prospects",
    searchPlaceHolder: "Search prospects",
    unassigned: "Unassigned",
    noOpenTasks: "No Open Tasks",
    deleteConfirmationText: "Are you sure you want to delete this prospect?",
    deleteLoadingText: "Deleting prospect",
    deleteProspectSuccessText: "Prospect was deleted successfully",
    emptydataViewInstructionsText: "No Prospects Found",
    newApplication: "New Application",
    sendRentalApplication: "Send Rental Application",
    viewRentalApplication: "View Rental Application",
    viewRentalApplications: "View Rental Applications",
    createRentalApplication: "Rental Application",
    createRentalApplicationTooltip: "Please add contact details to the applicant",
    lookingFor: "Looking For",
    minBeds: "Min Beds",
    minBaths: "Min Baths",
    maxRent: "Max Rent",
    helpPanel: {
      overview: "Prospects Overview",
      description: "Manage prospects and find better tenants faster.",
      createProspect: "Creating Prospects",
      trackProspects: "Learn more about tracking Prospects",
      screenProspects: "How do I use DoorLoop’s rental applications to screen prospects?"
    }
  },
  prospectStatus: {
    applicationSent: "Application Sent",
    applicationSubmitted: "Application Submitted",
    applicationInProgress: "Application In Progress",
    leaseOfferMade: "Lease Offer Made",
    leaseSentForSignature: "Lease Sent",
    new: "New",
    showingCompleted: "Showing Completed",
    showingScheduled: "Showing Scheduled",
    contactAttempted: "Contact Attempted",
    contactMade: "Contact Made",
    closedLost: "Closed Lost",
    closedMoveIn: "Closed Move-In",
    leaseOfferAccepted: "Lease Offer Accepted"
  },
  newProspect: {
    loadingText: "Creating prospect ...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "Prospect created successfully",
    marketingProfile: "Marketing Profile",
    editProspect: "Edit Prospect",
    leadStatus: "Lead Status",
    leadSource: "Lead Source",
    leadMedium: "Lead Medium",
    creditScore: "Credit Score",
    monthlyIncome: "Monthly Income",
    searchCriteria: "Search Criteria",
    minBedrooms: "Min Bedrooms",
    minBathrooms: "Min Bathrooms",
    maxRent: "Max Rent",
    moveIn: "Move-in",
    nextStep: "Next Step",
    addAnotherProperty: "Add Another Property",
    expectedMoveInDate: "Desired Move-in",
    moveInDate: "Desired Move-in",
    interests: "Interests",
    pets: "Pets",
    vehicles: "Vehicles",
    dependents: "Dependents"
  },
  prospectDetails: {},
  screening: {
    toolNameLabel: "Screening tool name",
    unscreenedWarning: "Attention, unscreened prospect",
    notScreenedYetTitle: "This Prospect Was Not Screened With DoorLoop Yet",
    notScreenedYetTitlePlural: "These Prospects Were Not Screened With DoorLoop Yet",
    notScreenedYetPlural: "These Prospects Were Not Screened With DoorLoop Yet",
    notScreenedYetLong: "Looks like this Prospect hasn't been screened with DoorLoop's TransUnion powered tool",
    notScreenedYetDescription:
      "Want to make sure you're getting a reliable tenant? Consider running a background check. If you've done it elsewhere, just give us a heads up below.",
    whichScreeningTool: "Which screening tool did you use for this prospect?",
    whichScreeningToolPlural: "Which screening tool did you use for these prospects?",
    startScreening: "Start Screening",
    alreadyScreened: "Already Screened",
    markAsScreened: "Mark as Screened",
    markedAsScreen: "Marked as Screened",
    markingAsScreened: "Marking as Screened",
    notApplicable: "Not Applicable",
    screeningStatus: "Screening Status",
    notScreened: "Not Screened",
    screenedByDoorLoop: "Screened by DoorLoop",
    screenedByOther: "Screened by 3rd Party"
  }
};
