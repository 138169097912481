import type { Theme } from "@material-ui/core/styles/createTheme";
import { makeStyles } from "@material-ui/styles";

import type { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../enums";

type ClassKey = `${DLButtonVariantsEnum}_${DLButtonColorsEnum}`;

const useStylesContainer = makeStyles<Theme, {}, ClassKey | "root" | DLButtonSizesEnum>((theme) => {
  return {
    root: {
      "&:disabled": {
        color: `${theme.newPalette.text.disabled} !important`
      }
    },
    large: {
      height: 44,
      padding: 14
    },
    medium: {
      height: 40,
      padding: 10
    },
    small: {
      height: 30,
      padding: 8
    },
    contained: {
      "&:disabled": {
        backgroundColor: `${theme.newPalette.action.disabledBackground} !important`
      }
    },
    contained_primary: {
      color: theme.newPalette.primary.contrastText,
      backgroundColor: theme.newPalette.primary.main,

      "&:hover": {
        backgroundColor: theme.newPalette.primary.dark
      }
    },
    contained_secondary: {
      color: theme.newPalette.secondary.contrastText,
      backgroundColor: theme.newPalette.secondary.main,

      "&:hover": {
        backgroundColor: theme.newPalette.secondary.dark
      }
    },
    contained_neutral: {
      color: theme.newPalette.neutral.contrastText,
      backgroundColor: theme.newPalette.neutral.main,

      "&:hover": {
        backgroundColor: theme.newPalette.neutral.dark
      }
    },
    contained_error: {
      color: theme.newPalette.error.contrastText,
      backgroundColor: theme.newPalette.error.main,

      "&:hover": {
        backgroundColor: theme.newPalette.error.dark
      }
    },
    contained_warning: {
      color: theme.newPalette.error.contrastText,
      backgroundColor: theme.newPalette.warning.main,

      "&:hover": {
        backgroundColor: theme.newPalette.warning.dark
      }
    },
    outlined: {
      "&:disabled": {
        borderColor: theme.newPalette.action.disabledBackground
      }
    },
    outlined_primary: {
      color: theme.newPalette.primary.main,
      border: `1px solid ${theme.newPalette.primary.main}`,

      "&:hover": {
        backgroundColor: theme.newPalette.primary.light,
        border: `1px solid ${theme.newPalette.primary.dark}`
      }
    },
    outlined_secondary: {
      color: theme.newPalette.secondary.main,
      border: `1px solid ${theme.newPalette.secondary.main}`,

      "&:hover": {
        backgroundColor: theme.newPalette.secondary.light,
        border: `1px solid ${theme.newPalette.secondary.dark}`
      }
    },
    outlined_neutral: {
      color: theme.newPalette.neutral.contrastText,
      border: `1px solid ${theme.newPalette.neutral.contrastText}`,

      "&:hover": {
        backgroundColor: theme.newPalette.neutral.light
      }
    },
    outlined_error: {
      color: theme.newPalette.error.main,
      border: `1px solid ${theme.newPalette.error.main}`,

      "&:hover": {
        backgroundColor: theme.newPalette.error.light,
        border: `1px solid ${theme.newPalette.error.dark}`
      }
    },
    outlined_warning: {
      color: theme.newPalette.warning.main,
      border: `1px solid ${theme.newPalette.warning.main}`,

      "&:hover": {
        backgroundColor: theme.newPalette.warning.light,
        border: `1px solid ${theme.newPalette.warning.dark}`
      }
    },
    text_primary: {
      color: theme.newPalette.primary.main,

      // Apply hover effect only if the device is not touch-enabled.
      // See https://stackoverflow.com/q/40532204/4106263.
      "&:hover": {
        "@media not (hover: none)": {
          backgroundColor: theme.newPalette.neutral.light
        }
      }
    },
    text_secondary: {
      color: theme.newPalette.secondary.main,

      "&:hover": {
        "@media not (hover: none)": {
          backgroundColor: theme.newPalette.neutral.light
        }
      }
    },
    text_neutral: {
      color: theme.newPalette.neutral.contrastText,

      "&:hover": {
        "@media not (hover: none)": {
          backgroundColor: theme.newPalette.neutral.dark
        }
      }
    },
    text_error: {
      color: theme.newPalette.error.main,

      "&:hover": {
        "@media not (hover: none)": {
          backgroundColor: theme.newPalette.neutral.dark
        }
      }
    },
    text_warning: {
      color: theme.newPalette.warning.main,

      "&:hover": {
        "@media not (hover: none)": {
          backgroundColor: theme.newPalette.neutral.dark
        }
      }
    }
  };
});

const useStylesText = makeStyles<Theme, {}, "color" | DLButtonSizesEnum>((theme) => {
  return {
    large: {
      lineHeight: 17,
      fontSize: 14,
      letterSpacing: -0.3
    },
    medium: {
      lineHeight: 17,
      fontSize: 14,
      letterSpacing: -0.3
    },
    small: {
      lineHeight: 14,
      fontSize: 12,
      letterSpacing: -0.4
    },
    color: {
      color: "inherit !important"
    }
  };
});

const useStylesIcon = makeStyles<Theme, {}, ClassKey | DLButtonSizesEnum>((theme) => {
  return {
    large: {
      height: "17px !important",
      width: "17px !important"
    },
    medium: {
      height: "17px !important",
      width: "17px !important"
    },
    small: {
      height: "14px !important",
      width: "14px !important"
    },
    contained_primary: {
      color: theme.newPalette.primary.contrastText,

      "& path": {
        fill: `${theme.newPalette.primary.contrastText} !important`
      }
    },
    contained_secondary: {
      color: theme.newPalette.secondary.contrastText,

      "& path": {
        fill: `${theme.newPalette.secondary.contrastText} !important`
      }
    },
    contained_neutral: {
      color: theme.newPalette.neutral.contrastText,

      "& path": {
        fill: `${theme.newPalette.neutral.contrastText} !important`
      }
    },
    contained_error: {
      color: theme.newPalette.error.contrastText,

      "& path": {
        fill: `${theme.newPalette.error.contrastText} !important`
      }
    },
    contained_warning: {
      color: theme.newPalette.warning.contrastText,

      "& path": {
        fill: `${theme.newPalette.warning.contrastText} !important`
      }
    },
    outlined_primary: {
      color: theme.newPalette.primary.main,

      "& path": {
        fill: `${theme.newPalette.primary.main} !important`
      }
    },
    outlined_secondary: {
      color: theme.newPalette.secondary.main,

      "& path": {
        fill: `${theme.newPalette.secondary.main} !important`
      }
    },
    outlined_neutral: {
      color: theme.newPalette.neutral.contrastText,

      "& path": {
        fill: `${theme.newPalette.neutral.contrastText} !important`
      }
    },
    outlined_error: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.error.main} !important`
      }
    },
    outlined_warning: {
      color: theme.newPalette.warning.main,

      "& path": {
        fill: `${theme.newPalette.warning.main} !important`
      }
    },
    text_primary: {
      color: theme.newPalette.primary.main,

      "& path": {
        fill: `${theme.newPalette.primary.main} !important`
      }
    },
    text_secondary: {
      color: theme.newPalette.secondary.main,

      "& path": {
        fill: `${theme.newPalette.secondary.main} !important`
      }
    },
    text_neutral: {
      color: theme.newPalette.neutral.contrastText,

      "& path": {
        fill: `${theme.newPalette.neutral.contrastText} !important`
      }
    },
    text_error: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.error.main} !important`
      }
    },
    text_warning: {
      color: theme.newPalette.warning.main,

      "& path": {
        fill: `${theme.newPalette.warning.main} !important`
      }
    }
  };
});

const useStylesLoadingIcon = makeStyles<Theme, {}, "root">((theme) => {
  return {
    root: {
      color: theme.newPalette.action.disabled,

      "& path": {
        fill: `${theme.newPalette.action.disabled} !important`
      }
    }
  };
});

export { useStylesIcon, useStylesText, useStylesContainer, useStylesLoadingIcon };
