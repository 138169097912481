import type { PropsWithChildren } from "react";
import { useState } from "react";
import View from "DLUI/view/view";
import { useKeyboard } from "../../hooks/useKeyboard";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useResponsiveHelper } from "../../contexts/responsiveContext";
import { useAnalyticsService } from "../../hooks/useAnalyticsService";
import { getSideBarResponsiveStyle } from "./layoutHelpers";
import { sideMenuCollapsingAtom } from "components/layouts/layoutAtom";
import { useAtom } from "jotai";

const SideBarLayout = ({ children }: PropsWithChildren<unknown>) => {
  const loginData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [showCreateNewMenu, setShowCreateNewMenu] = useState<boolean>(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { isTabletOrMobile, isOnlyTablet } = useResponsiveHelper();
  const [isGlobalSearchInActiveAnimation, setIsGlobalSearchInActiveAnimation] = useState(false);
  const { dispatchAnalytics } = useAnalyticsService();
  const [isSideMenuIsCollapsed] = useAtom(sideMenuCollapsingAtom);
  const { layoutStyle, sideBarStyle, topBarStyle, outerContentStyle, innerContentStyle } = getSideBarResponsiveStyle(
    isTabletOrMobile,
    isOnlyTablet,
    isSideMenuIsCollapsed
  );

  const closeCreateNew = () => {
    setShowCreateNewMenu(false);
  };

  const toggleCreateNew = () => {
    setShowCreateNewMenu(!showCreateNewMenu);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  useKeyboard(
    "Escape",
    () => {
      closeCreateNew();
    },
    []
  );

  return (
    <View
      className="sidebarLayout"
      height={"100%"}
      width={"100%"}
      flexDirection={"row"}
      noWrap
      overflow={"hidden"}
      {...layoutStyle}
    >
      <div className="fixed h-full w-full md:static md:h-dvh">
        <View flexDirection={"row"} height={"100%"} className={"outer-layout-screen-wrapper"} {...outerContentStyle}>
          {loginData && (
            <View
              autoWidth
              height={"100%"}
              className="sidebar"
              flexDirection={"row"}
              {...sideBarStyle}
              style={{ zIndex: 9999 }}
            ></View>
          )}
          <View flex={1} className={"inner-layout-screen-wrapper"} height={"100%"} {...innerContentStyle}>
            {children}
          </View>
        </View>
      </div>
    </View>
  );
};

export default SideBarLayout;
