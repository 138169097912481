export default {
  errors: {
    title: "Please fix the following errors to syndicate your listing:",
    unitPhotos: "Add photos to the unit.",
    propertyPhotos: "Add photos to the property.",
    unitDescription: "Add unit description.",
    propertyDescription: "Add property description.",
    unitAmenities: "Add amenities to the unit.",
    propertyAmenities: "Add amenities to the property.",
    unitBedsRooms: "Add the number of Beds in the unit.",
    unitBathsRooms: "Add the number of Baths in the unit."
  },
  forMoreInformation: "For more info regarding listing on partner sites <CustomLink>click here</CustomLink>.",
  zillowSyndication:
    "<bold>Note:</bold> Zillow syndication requires a direct relationship in order to set up your DoorLoop feed. For more info regarding listing on partner sites and troubleshooting <CustomLink>click here</CustomLink>.",
  realEstateAndHomes: "Real Estate and Homes",
  noListingsFound: "We have no available listings at the moment.",
  confirmCloseContactForm: "Are you sure you want to close the dialog?\nYour changes will be lost.",
  pageTitle: "Listings",
  howCanWeHelp: "How can we help?",
  generalInformationSettingsDescription: "Update your property address, name and description.",
  alreadySubmittedContactForm: "You have already submitted a message for this listing.",
  thankYou: "Thank you!",
  sorry: "Sorry!",
  noActiveListing: "No Active Listings",
  getInTouch: "Get in touch with {{companyName}} for available properties.",
  yourPropertyManager: "your property manager",
  submissionSucceeded: "We have received your submission!",
  submissionFailed: "We were unable to submit your request.",
  photosSettingsDescription: "Upload photos for this property",
  classAndTypeSettingsDescription: "Let us know which property",
  amenitiesSettingsDescription: "Specify which amenities are included in this property",
  petPolicySettingsDescription: "Update the pet policy for this property",
  leaseTerms: "Lease Terms",
  deposit: "Deposit",
  askingRent: "Asking Rent",
  listingContact: "Listing Contact",
  dateAvailable: "Date Available",
  listingDetails: "Listing Details",
  listingPartners: "Listing Partners",
  thisUnitIsActive: "This unit is active",
  unitDetails: "Unit Details",
  petsNotAllowed: "Pets Not Allowed",
  petsAllowed: "Pets Allowed",
  petRestrictionsLabel: "If you have any specific pet restrictions, please add them here...",
  smallDogs: "Small Dogs",
  dogs: "Large Dogs",
  cats: "Cats",
  selectWhichPetsLabel: "Please select which pets are allowed in this property",
  petsDetails: "Pets Details",
  petsPolicyDescription: "Set your pet policy for this property.",
  petsPolicy: "Pet Policy",
  partnerWebsitesListing: "Partner Websites Listing",
  unitListingActiveDescription: "List this unit for rent on partner websites",
  activateListing: "Activate to sync your listing with our partner websites",
  propertyNoAmenitiesDescription: "Add all the amenities included in your property.",
  propertyNoOwnersDescription: "Add the owner(s) of the property here.",
  unitNoAmenitiesDescription: "Add all the amenities included in your unit.",
  unitAmenitiesInfoDescription: "Please select which amenities are included in this unit.",
  propertyAmenitiesInfoDescription: "Please select which amenities are included in this property.",
  generalPropertyInfoDescription: "This information will be used in your online listings.",
  generalUnitInfoDescription: "General information about your unit. Will be used in the listing as well.",
  photosDescription: "Upload, rearrange, or delete photos that appear in your online listings.",
  currentStatus: "Current Status",
  futureStatus: "Future Status",
  moveOutInXDays: "Move-out in {{x}} days",
  moveInInXDays: "Move-in in {{x}} days",
  notRented: "Not Rented",
  rented: "Rented",
  photos: "Photos",
  noPhotos: "No Photos",
  uploadPhotosLabel: "We recommend high quality professional photos to make your listing stand out!",
  addNewPhotos: "Add New Photos",
  noAmenities: "No Amenities",
  addAmenities: "Add Amenities",
  amenities: "Amenities",
  unitAmenities: "Unit Amenities",
  propertyAmenities: "Property Amenities",
  propertyDetails: "Property Details",
  featuresAndAmenities: "Features and Amenities",
  bedBathSqftAbbreviated: "{{bed}} bed • {{bath}} bath • {{sqft}} sqft",
  monthSuffixAbbreviated: "/Month",
  noOwners: "No Owners",
  addOwners: "Add Owners",
  description: "Description",
  unitDescription: "Unit Description",
  propertyDescription: "Property Description",
  listingStatus: "Listing Status",
  listed: "Listed",
  listing: "Listing",
  useExactMatch: "Use exact match",
  saveAndClose: "Save and Close",
  priceRange: "Price Range",
  listingSyndication: "Listing Syndication",
  partnerListingSites: "Partner Listing Sites",
  reduceVacancy:
    "Reduce vacancy and attract the perfect tenants by listing your unit on our partner sites. Toggle on to enable the listing for your <CustomLink>selected partners</CustomLink>.",
  companySiteListing: "Company Site Listing",
  listedOnDoorloopSites: "Listed on Doorloop sites",
  listAndMarketYourVacancies: "List and market your vacancies",
  listingSyncOptions: "Listing Sync Options",
  chooseSyndicationsDesc: "Enable integration with DoorLoop partner listing sites",
  chooseSyndicationsTitle: "Partner Listing Sites",
  advancedIntegrations: "Advanced Integrations",
  listYourUnitsOnAnyThirdPartySite: "List your units on any third-party site",
  howToAdvertiseYourUnitsOnline: "How to advertise your units online?",
  selectTheSitesToAdvertiseOnBelow: "Select the sites to advertise on below.",
  goToEachUnitsSettings: "Go to each unit's settings you want to list, and activate the listing.",
  learnMore: "Learn more",
  paidListingPartners: "Paid Listing Partners",
  freeListingPartners: "Free Listing Partners",
  copyId: "Copy ID",
  toListOnZillow: "To list on Zillow, a paid subscription might be needed.",
  setupRequirement: "Setup Requirement:",
  toSetupYourAccount: "To set up your account, Contact Zillow at ",
  provideCompanyId: " and provide them with your DoorLoop Company ID:",
  learnMoreSyndication: "Learn more about creating online listings",
  syndicationDescription: "Advertise your vacant units with our listing partners",
  listingDoesntAcceptOnlineApplications: "This listing currently doesn't accept online applications",
  notListed: "Not Listed",
  available: "Available",
  availableNow: "Available Now",
  asking: "Asking:",
  updateUnitInformation: "Update Unit Information",
  updatePropertyInformation: "Update Property Information",
  other: "Other",
  zillow: "Zillow Sites (Zillow, HotPads, Trulia)",
  zillowSites: "(Zillow, HotPads, Trulia)",
  zillowMayContact: "Zillow may contact you about additional purchases and packages.",
  dwellsy: "Dwellsy",
  rentalSource: "RentalSource",
  zumper: "Zumper",
  apartmentsCom: "Apartments.com",
  apartmentsComBullet1: "Unit names must not contain marketing terms.",
  ApartmentsComBullet2: "Property descriptions should not include any hyperlinks.",
  ApartmentsComBullet3:
    "For properties with 5+ units, direct communication with Apartments.com is required, and advertising may incur a fee.",
  embedYourListings: "Embed Your Listings",
  embedTheLinkOnThirdPartySites: "Embed the link on third-party sites or your own website to show your listings.",
  additionalFeesMayApply: "Additional fees may apply",
  squareFeet: "Square feet",
  monthlyRent: "Monthly Rent",
  numberBedroomsAbbreviated: "{{number}} bd.",
  numberBathroomsAbbreviated: "{{number}} ba.",
  searchTextFieldPlaceholder: "Search by name, address, city, state or zip code",
  // Property Amenities
  bedrooms: "Bedrooms",
  bedroomsCount: "{{count}} Bedrooms",
  bathrooms: "Bathrooms",
  bathroomsCount: "{{count}} Bathrooms",
  beds: "Beds",
  baths: "Baths",
  type: "Type",
  price: "Price",
  property: "Property",
  unit: "Unit",
  availability24Hours: "Availability 24 Hours",
  accentWalls: "Accent Walls",
  basketballCourt: "Basketball Court",
  bilingual: "Bilingual",
  boatDocks: "Boat Docks",
  businessCenter: "Business Center",
  carWashArea: "Car Wash Area",
  childCare: "Child Care",
  clubDiscount: "Club Discount",
  clubHouse: "Club House",
  conferenceRoom: "Conference Room",
  concierge: "Concierge",
  coverPark: "Cover Park",
  doorAttendant: "Door Attendant",
  fitnessCenter: "Fitness Center",
  elevator: "Elevator",
  freeWeights: "Free Weights",
  furnishedAvailable: "Furnished Available",
  gamingStations: "Gaming Stations",
  garage_Attached: "Garage - Attached",
  garage_Detached: "Garage - Detached",
  garage_SameLevelParking: "Garage - Same Level Parking",
  gate: "Gate",
  groceryService: "Grocery Service",
  groupExercise: "Group Exercise",
  guestRoom: "Guest Room",
  highSpeed: "High Speed",
  housekeeping: "Housekeeping",
  houseSitting: "House Sitting",
  joggingWalkingTrails: "Jogging/Walking Trails",
  lakeFront: "Lake Front",
  lakeAccess: "Lake Access",
  laundry: "Laundry",
  library: "Library",
  mealService: "Meal Service",
  mediaRoom: "Media Room",
  multiUseRoom: "Multi-Use Room",
  nightPatrol: "Night Patrol",
  onSiteMaintenance: "On Site Maintenance",
  onSiteManagement: "On Site Management",
  packageReceiving: "Package Receiving",
  perDiemAccepted: "Per Diem Accepted",
  pool: "Pool",
  playGround: "Play Ground",
  racquetball: "Racquetball",
  recRoom: "Rec Room",
  recycling: "Recycling",
  sauna: "Sauna",
  shortTermLease: "Short Term Lease",
  smokeFree: "Smoke Free",
  spa: "Spa",
  storageSpace: "Storage Space",
  sundeck: "Sundeck",
  tennisCourt: "Tennis Court",
  transportation: "Transportation",
  tVLounge: "TV Lounge",
  valetTrash: "Valet Trash",
  vintage: "Vintage",
  volleyballCourt: "Volleyball Court",
  wirelessInternet: "Wireless Internet",
  // Unit Amenities
  additionalStorage: "Additional Storage",
  airConditioner: "Air Conditioner",
  alarm: "Alarm",
  balcony: "Balcony",
  cable: "Cable",
  carpet: "Carpet",
  carport: "Carport",
  ceilingFan: "Ceiling Fan",
  controlledAccess: "Controlled Access",
  courtyard: "Courtyard",
  dishwasher: "Dishwasher",
  disposal: "Disposal",
  doubleSinkVanity: "Double Sink Vanity",
  dryer: "Dryer",
  fireplace: "Fireplace",
  framedMirrors: "Framed Mirrors",
  furnished: "Furnished",
  garage: "Garage",
  handrails: "Handrails",
  hardwoodFlooring: "Hardwood Flooring",
  hardSurfaceCounterTops: "Hard Surface Counter Tops",
  heat: "Heat",
  individualClimateControl: "Individual Climate Control",
  islandKitchen: "Island Kitchen",
  laminateCounterTops: "Laminate Counter Tops",
  vinylFlooring: "Vinyl Flooring",
  largeClosets: "Large Closets",
  linenCloset: "Linen Closet",
  microwave: "Microwave",
  pantry: "Pantry",
  patio: "Patio",
  privateBalcony: "Private Balcony",
  privatePatio: "Private Patio",
  range: "Range",
  refrigerator: "Refrigerator",
  satellite: "Satellite",
  skylight: "Skylight",
  tileFlooring: "Tile Flooring",
  vaultedCeiling: "Vaulted Ceiling",
  view: "View",
  washer: "Washer",
  wheelChair: "Wheel Chair",
  wD_Hookup: "WD Hookup",
  windowCoverings: "Window Coverings",
  rentalListingsURL: "Listing Link:",
  companyId: "DoorLoop Company ID:",
  paid: "Paid",
  free: "Free",
  autoSyncEnabled: "Auto Sync Enabled",
  requestInformation: "Request Information",
  applyOnline: "Apply Online",
  otherInstructionDescription:
    "Your personal Rental Listings URL can be used to sync with any third party by providing them with your rental listings url.",
  reachApartmentsCom: "Reach out to Apartments.com at",
  reachApartmentsComDescription: "to setup your account and provide them with your rental listings url.",
  reachAZillow:
    "Zillow requires a direct relationship in order to set up your DoorLoop feed. Please reach out to them at the email address below and provide them with your DoorLoop Company ID.",
  reachAZillowDescription: "to setup your account and provide them with your DoorLoop Company ID."
};
