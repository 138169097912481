"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupIntentDto = void 0;
// https://stripe.com/docs/api/setup_intents/object
class SetupIntentDto {
    constructor(props) {
        Object.assign(this, props);
    }
}
exports.SetupIntentDto = SetupIntentDto;
