export type ViewBackgroundColor =
  | "dark"
  | "light"
  | "white"
  | "secondary-gray"
  | "dialog-dark-background"
  | "pink"
  | "blue"
  | "blue-light"
  | "secondary-blue"
  | "blue-lighter"
  | "secondary-gray-light"
  | "secondary-gray-hover"
  | "yellow"
  | "yellow-light"
  | "transparent"
  | "black"
  | "light-green"
  | "lighter-green"
  | "lightest-green"
  | "light-purple"
  | "green"
  | "error-red"
  | "reminder-blue";

export const ViewBackgroundColorMapping: Record<ViewBackgroundColor, string> = {
  "blue-light": "blueLightBackground",
  "dialog-dark-background": "dialogDarkOpacity",
  "blue-lighter": "blueLighterBackground",
  "secondary-blue": "secondaryBlueBackground",
  "error-red": "errorRedBackground",
  "light-green": "lightGreenBackground",
  "lighter-green": "lighterGreenBackground",
  "lightest-green": "lightestGreenBackground",
  "reminder-blue": "reminderBackground",
  "secondary-gray-hover": "secondaryGrayHoverBackground",
  "secondary-gray-light": "secondaryGrayLightBackground",
  "secondary-gray": "secondaryGrayBackground",
  "yellow-light": "yellowLightBackground",
  "light-purple": "purpleBackground",
  black: "blackBackground",
  blue: "blueBackground",
  dark: "darkBackground",
  green: "greenBackground",
  light: "lightBackground",
  pink: "pingBackground",
  transparent: "transparent",
  white: "whiteBackground",
  yellow: "yellowBackground"
};

export const ViewHoverColorMapping: Record<ViewBackgroundColor, string> = {
  "blue-light": "blueLightBackgroundHover",
  "dialog-dark-background": "dialogDarkOpacityHover",
  "blue-lighter": "blueLighterBackgroundHover",
  "secondary-blue": "secondaryBlueBackground",
  "error-red": "errorRedBackgroundHover",
  "light-green": "lightGreenBackgroundHover",
  "lighter-green": "lighterGreenBackgroundHover",
  "lightest-green": "lightestGreenBackgroundHover",
  "reminder-blue": "reminderBackgroundHover",
  "secondary-gray-hover": "secondaryGrayHoverBackgroundHover",
  "secondary-gray-light": "secondaryGrayLightBackgroundHover",
  "secondary-gray": "secondaryGrayBackgroundHover",
  "yellow-light": "yellowLightBackgroundHover",
  "light-purple": "purpleBackgroundHover",
  black: "blackBackgroundHover",
  blue: "blueBackgroundHover",
  dark: "darkBackgroundHover",
  green: "greenBackgroundHover",
  light: "lightBackgroundHover",
  pink: "pingBackgroundHover",
  transparent: "transparent",
  white: "whiteBackgroundHover",
  yellow: "yellowBackgroundHover"
};
