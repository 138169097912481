import type { ClientSuperProperties, LoginResponseDto } from "@doorloop/dto";
import { getPlanNameByPriceId, LoginResponseType, SegmentEventTypes } from "@doorloop/dto";
import moment from "moment";
import Cohere from "cohere-js";
import Cookies from "universal-cookie";
import { getCurrentDevicePlatform, getCurrentDevicePlatformType } from "common/native/utils";
import { capitalize } from "lodash";
import { ANALYTICS_EVENT_SOURCE } from "@doorloop/dto";
import { generalUtils } from "@doorloop/utils";
import { Routes } from "components/appRouter";

declare global {
  interface Window {
    Intercom?: any;
    dataLayer?: any;
    wootric?: any;
    uetq?: any;
    gtag?: any;
    posthog?: any;
    fbq?: any;
    vgo?: any;
    profitwell?: any;
  }
}

interface IdentifyAdditionalProperties {
  googleAnalyticsAdditionalProperties?: {
    userType: LoginResponseType;
    platformName: "OwnerPortal";
  };
}

export interface TrackFilterAnalyticsArgs {
  userType: LoginResponseType;
  filterType: string;
  filterValue: string;
  screenName: string;
}

export interface AnalyticsTrackMethodProps {
  trackEventInIntercom?: boolean;
}

export type AnalyticsSettingsSource = "General Settings" | "Property Settings" | "Lease Settings";

export enum PosthogFeatureFlagKeysEnum {
  ALLOW_RECORDING_SESSIONS = "record-20-percent-of-users-sessions",
  TENANT_AUTO_PAY_PAYMENT_FLOW = "autoPayPaymentFlow",
  TENANT_PAYMENT_WIZARD_BUTTONS_POSITION = "tenantPaymentProcessButtonsPosition"
}

const objectIdRegex = /[0-9a-fA-F]{24}/;

const FORCE_RECORDING_PATHS: string[] = [Routes.DEMO];

class AnalyticsService {
  cookies = new Cookies();

  TRACK_PAGEVIEWS = true;
  AUTO_CAPTURE = true;

  didIdentify = { cohere: false, wootric: false, intercom: false, ga: false, posthog: false, profitwell: false };
  isAutomationUser = false;

  constructor() {
    //https://developers.google.com/tag-platform/devguides/datalayer#gtag.js
    window.uetq = window.uetq || [];
  }

  startSessionRecording = () => {
    if (window.posthog && this.TRACK_PAGEVIEWS) {
      // posthog session screen recording
      window.posthog.onFeatureFlags(() => {
        const forceRecordingSession = FORCE_RECORDING_PATHS.some((x) => window.location.href.includes(x));
        if (
          window.posthog.isFeatureEnabled(PosthogFeatureFlagKeysEnum.ALLOW_RECORDING_SESSIONS) ||
          forceRecordingSession
        ) {
          window.posthog.startSessionRecording();
        }
      });
    }
  };

  page = () => {
    if (window.Intercom) {
      window.Intercom("update", {
        last_request_at: parseInt(String(new Date().getTime() / 1000))
      });
    }
    if (window.posthog && this.TRACK_PAGEVIEWS) {
      const pageName = this.splitUrl();
      window.posthog.capture("$pageview", { pageName });
    }
  };

  logout = () => {
    window.Intercom && window.Intercom("shutdown");
    window.posthog && window.posthog.reset();
    for (const key of Object.keys(this.didIdentify)) {
      this.didIdentify[key] = false;
    }
  };

  /**
   * @deprecated use useAnalyticsService instead
   * https://www.notion.so/Analytics-PostHog-Events-58ccd0135132473385f95141de8e2bcd
   */
  track = (name: SegmentEventTypes | string, properties?: any, options?: AnalyticsTrackMethodProps) => {
    try {
      if (name) {
        name = name.toLowerCase().split(" ").join("_");
      }
      if (window.gtag) {
        window.gtag("event", name, properties);
      }
      if (window.posthog) {
        window.posthog.capture(name, properties);
      }
      if (window.Intercom && options?.trackEventInIntercom && !this.isAutomationUser) {
        window.Intercom("trackEvent", name, properties);
      }
      if (window.fbq) {
        window.fbq("trackCustom", name, properties);
      }
      if (window.uetq) {
        window.uetq.push("event", name, {
          event_category: "track_event",
          event_label: name
        });
      }
    } catch (e) {
      console.error("Error tracking event", e);
    }
  };

  identifyLead(email?: string, name?: string, phone?: string, numberOfUnits?: number, sfLeadId?: string) {
    try {
      if (window.gtag) {
        const gaClientId = this.getGaClientId();

        window.gtag("set", "user_properties", {
          numberOfUnits,
          gaClientId
        });
      }
      if (window.posthog && sfLeadId) {
        window.posthog.identify(
          sfLeadId,
          {
            name,
            email,
            phone,
            numberOfUnits
          },
          { created_at: moment().toDate() }
        );
      }
    } catch (e) {
      console.error("Error identifying lead");
    }
  }

  isMobile = () => {
    let isMobile = false; //initiate as false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
    return isMobile;
  };

  identifyCohere = (loginResponse: LoginResponseDto) => {
    try {
      if (!this.didIdentify.cohere) {
        Cohere.identify(
          loginResponse.id, // Required: can be any unique ID
          {
            displayName:
              loginResponse.type +
              " | " +
              loginResponse.firstName +
              " " +
              loginResponse.lastName +
              " | " +
              loginResponse.currentDbTenant?.companyName,
            email: loginResponse.loginEmail,
            companyId: loginResponse.currentDbTenant?.id,
            company: loginResponse.currentDbTenant?.companyName,
            type: loginResponse.type
          }
        );
        this.didIdentify.cohere = true;
      }
    } catch (e) {
      console.error("Error identifying Cohere");
    }
  };

  identifyIntercom = (loginResponse: LoginResponseDto) => {
    try {
      if (window.Intercom && !this.didIdentify.intercom) {
        window.Intercom("boot", {
          app_id: "njbci459",
          user_hash: loginResponse.intercom_hash,
          user_id: loginResponse.id,
          name: loginResponse.firstName + " " + loginResponse.lastName,
          email: loginResponse.loginEmail,
          type: loginResponse.type,
          created_at: moment(loginResponse.createdAt).unix(),
          company: {
            id: loginResponse.currentDbTenant.id,
            ...loginResponse.currentDbTenant,
            name: loginResponse.currentDbTenant.companyName,
            plan: loginResponse.currentDbTenant.subscriptionPlan?.price_id,
            plan_units: loginResponse.currentDbTenant.subscriptionPlan?.price_quantity,
            created_at: moment(loginResponse.currentDbTenant.createdAt).unix(),
            trial_ends_at: moment(loginResponse.currentDbTenant.subscriptionPlan?.trialEndsAt).unix()
          },
          leaseId: loginResponse?.currentLease?.id
        });
        this.didIdentify.intercom = true;
        this.isAutomationUser = Boolean(loginResponse.loginEmail?.toLowerCase().includes("automation"));

        if (this.isMobile()) {
          window.Intercom("hide");
        }
      }
    } catch (e) {
      console.error("Error identifying Intercom");
    }
  };

  identifyWootric = (loginResponse: LoginResponseDto) => {
    try {
      if (
        window.wootric &&
        !this.didIdentify.wootric &&
        loginResponse.type === LoginResponseType.USER &&
        !this.isMobile()
      ) {
        //@ts-ignore

        if (window.location.search.indexOf("wootric") > -1) {
          //@ts-ignore
          window.wootric_survey_immediately = true; // Shows survey immediately for testing purposes.
        }
        //@ts-ignore
        window.wootricSettings = {
          email: loginResponse.loginEmail,
          external_id: loginResponse.id,
          created_at: moment(loginResponse.createdAt).unix(),
          account_token: "NPS-302b0698",
          properties: {
            name: loginResponse.name,
            firstName: loginResponse.firstName,
            lastName: loginResponse.lastName,
            priceQuantity: loginResponse.currentDbTenant?.subscriptionPlan?.price_quantity
          }
        };
        window.wootric("run");
        this.didIdentify.wootric = true;
      }
    } catch (e) {
      console.info("Wootric blocked");
    }
  };

  private getGaClientId() {
    const gaClientId = this.cookies.get<string>("_ga");
    if (gaClientId && gaClientId?.length > 6) {
      return gaClientId.substring(6, gaClientId.length);
    }
    return gaClientId;
  }

  identifyGoogleAnalytics = (loginResponse: LoginResponseDto, additionalProperties = {}) => {
    try {
      if (window.gtag && !this.didIdentify.ga) {
        const gaClientId = this.getGaClientId();
        //https://support.google.com/analytics/answer/9213390?hl=en#zippy=%2Cin-this-article
        window.gtag("config", "G-CSHBWMC49K", { user_id: loginResponse.id });
        window.gtag("set", "user_properties", {
          user_id: loginResponse.id,
          gaClientId,
          userId: loginResponse.id,
          dbTenantId: loginResponse.currentDbTenant.id,
          userType: loginResponse.type,
          ...additionalProperties
        });

        this.didIdentify.ga = true;
      }
    } catch (e) {
      console.info("Google analytics blocked");
    }
  };

  identifyProfitWell = (loginResponse: LoginResponseDto) => {
    try {
      if (window.profitwell && !this.didIdentify.profitwell && loginResponse.currentDbTenant?.customerId) {
        window.profitwell("start", { user_id: loginResponse.currentDbTenant.customerId });
        this.didIdentify.profitwell = true;
      }
    } catch (e) {
      console.info("Profitwell analytics blocked");
    }
  };

  identifyPosthog = (loginResponse: LoginResponseDto) => {
    try {
      if (window.posthog && !this.didIdentify.posthog) {
        window.posthog.register({
          env: process.env.NODE_ENV,
          userAgent: navigator.userAgent,
          platform: getCurrentDevicePlatform(),
          platformType: getCurrentDevicePlatformType(),
          type: loginResponse.type,
          dbTenant: loginResponse.currentDbTenant?.id,
          event_source: ANALYTICS_EVENT_SOURCE,
          isHOAUser: Boolean(loginResponse.currentDbTenant.isHOAUser),
          numOfUnits: loginResponse.currentDbTenant.subscriptionPlan?.price_quantity,
          numOfActiveUnits: loginResponse.currentDbTenant.subscriptionPlan?.numOfActiveUnits,
          planType: getPlanNameByPriceId(loginResponse.currentDbTenant.subscriptionPlan?.price_id),
          region: loginResponse.currentDbTenant.language,
          isTestUser: generalUtils.isTestUser(loginResponse.loginEmail || "")
        } as ClientSuperProperties);
        window.posthog.identify(
          loginResponse.id,
          {
            name: loginResponse.name,
            email: loginResponse.loginEmail,
            type: loginResponse.type
          },
          { created_at: moment(loginResponse.createdAt).toDate() }
        );

        window.posthog.group?.("company", "id:" + loginResponse.currentDbTenant.id, {
          ...loginResponse.currentDbTenant,
          name: loginResponse.currentDbTenant.companyName,
          plan: loginResponse.currentDbTenant.subscriptionPlan?.price_id,
          plan_units: loginResponse.currentDbTenant.subscriptionPlan?.price_quantity,
          created_at: moment(loginResponse.currentDbTenant.createdAt).toDate(),
          trial_ends_at: moment(loginResponse.currentDbTenant.subscriptionPlan?.trialEndsAt).toDate()
        });

        this.didIdentify.posthog = true;
      }
    } catch (e: any) {
      console.log(e);
      console.info("Posthog analytics blocked: ", e.message || e || "");
    }
  };

  identify = (loginResponse: LoginResponseDto, additionalProperties: IdentifyAdditionalProperties = {}) => {
    try {
      if (loginResponse?.currentDbTenant && loginResponse?.id) {
        this.identifyCohere(loginResponse);
        this.identifyWootric(loginResponse);
        this.identifyIntercom(loginResponse);
        this.identifyGoogleAnalytics(loginResponse, additionalProperties.googleAnalyticsAdditionalProperties);
        this.identifyPosthog(loginResponse);
        this.identifyProfitWell(loginResponse);
      }
    } catch (e) {
      console.log("Error identifying user");
    }
  };

  splitUrl = (options?: { transformNameForAnalytics: boolean }): string => {
    const splitUrl = location.pathname.replace(objectIdRegex, "").split("/");

    let pageName: string;

    if (splitUrl.length > 2) {
      pageName = `${splitUrl[1]} - (${splitUrl[splitUrl.length - 1]})`;
    } else {
      pageName = splitUrl[1];
    }

    if (options && options.transformNameForAnalytics) {
      return splitUrl
        .filter(Boolean)
        .map((s) => capitalize(s).replace("-", ""))
        .join("_");
    }

    return pageName;
  };

  trackFilter = ({ userType, filterType, filterValue, screenName }: TrackFilterAnalyticsArgs): void => {
    this.track(`${screenName}_${SegmentEventTypes.FILTER_APPLIED}`, {
      platformLocation: this.splitUrl({ transformNameForAnalytics: true }),
      userType,
      filterType,
      filterValue
    });
  };

  triggerAbTestingExperiment = <A = "existing", B = "new">(
    featureFlagKey: PosthogFeatureFlagKeysEnum
  ): A | B | null => {
    if (window?.posthog?.isFeatureEnabled(featureFlagKey)) {
      return window?.posthog?.getFeatureFlagPayload(featureFlagKey) as A | B;
    }

    return null;
  };
}

export const analyticsService = new AnalyticsService();
