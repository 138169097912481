import type { LoginResponseDto, LoginThemeDto } from "@doorloop/dto";
import type { AuthAction, LocaleData } from "./actions";
import * as Types from "./types";

export interface IAuthState {
  isAuthenticated: boolean;
  currentLoginResponse?: LoginResponseDto;
  loginResponses?: LoginResponseDto[];
  localeData: LocaleData;
  theme?: LoginThemeDto;
}

const authReducer = (
  state: IAuthState = {
    isAuthenticated: false,
    localeData: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySymbol: "$",
      percentageSymbol: "%",
      locale: "en-US",
      dateFormat: "MM/DD/YYYY",
      currencySymbolPosition: "prefix",
      percentSymbolPosition: "prefix"
    }
  },
  action: AuthAction
): IAuthState => {
  let currentLoginResponse = state.currentLoginResponse;
  switch (action.type) {
    case Types.LOGIN_SUCCESS: {
      const { currentLoginResponse, loginResponses } = action.authResponse;
      return {
        ...state,
        isAuthenticated: true,
        currentLoginResponse,
        loginResponses
      };
    }

    case Types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        currentLoginResponse: undefined
      };

    case Types.UPDATE_SUBSCRIPTION_DATA: {
      const nextState = {
        ...state
      };

      if (nextState.currentLoginResponse?.currentDbTenant?.subscriptionPlan) {
        nextState.currentLoginResponse.currentDbTenant.subscriptionPlan = action.subscription;
      }

      return nextState;
    }

    case Types.UPDATE_LOGIN_THEME:
      return {
        ...state,
        theme: action.theme
      };

    case Types.UPDATE_LOCALE_DATA:
      if (currentLoginResponse && currentLoginResponse.currentDbTenant) {
        currentLoginResponse.currentDbTenant.language = action.localeData.locale;
        currentLoginResponse.currentDbTenant.currency = action.localeData.currency;
      }

      return {
        ...state,
        isAuthenticated: state.isAuthenticated,
        localeData: action.localeData,
        currentLoginResponse
      };

    case Types.UPDATE_USER_INFORMATION:
      currentLoginResponse = state.currentLoginResponse;
      if (currentLoginResponse) {
        currentLoginResponse.firstName = action.personalInfoSettings.firstName;
        currentLoginResponse.lastName = action.personalInfoSettings.lastName;
        currentLoginResponse.pictureUrl = action.personalInfoSettings.pictureUrl;
      }
      return {
        ...state,
        isAuthenticated: state.isAuthenticated,
        currentLoginResponse
      };
    case Types.UPDATE_COMPANY_START_DATE:
      currentLoginResponse = state.currentLoginResponse;
      if (currentLoginResponse && action.startDate) {
        currentLoginResponse.currentDbTenant.companyStartDate = action.startDate;
      }

      return {
        ...state,
        currentLoginResponse
      };

    case Types.UPDATE_ONBOARDING_PROGRESS:
      currentLoginResponse = state.currentLoginResponse;
      if (currentLoginResponse?.currentDbTenant.onBoarding) {
        // new state with updated onboarding progress
        return {
          ...state,
          currentLoginResponse: {
            ...currentLoginResponse,
            currentDbTenant: {
              ...currentLoginResponse.currentDbTenant,
              onBoarding: action.onBoardingDto
            }
          }
        };
      }
      break;
    case Types.UPDATE_SHOW_ONBOARDING_ON_LOGIN:
      currentLoginResponse = state.currentLoginResponse;
      if (currentLoginResponse && currentLoginResponse.currentDbTenant.onBoarding) {
        return {
          ...state,
          currentLoginResponse: {
            ...currentLoginResponse,
            showOnBoardingOnLogin: action.showOnBoardingOnLogin
          }
        };
      }
      break;
    case Types.UPDATE_TOTAL_UNREAD_NOTIFICATIONS:
      if (state.currentLoginResponse) {
        return {
          ...state,
          currentLoginResponse: {
            ...state.currentLoginResponse,
            totalUnreadNotifications: action.totalUnread
          }
        };
      }
      break;
    case Types.UPDATE_IS_FEATURE_ENABLED:
      if (state.currentLoginResponse) {
        return {
          ...state,
          currentLoginResponse: {
            ...state.currentLoginResponse,
            currentDbTenant: {
              ...state.currentLoginResponse.currentDbTenant,
              userFeatures: {
                ...state.currentLoginResponse.currentDbTenant.userFeatures,
                [action.featureName]: action.isEnabled
              }
            }
          }
        };
      }
      break;
    case Types.UPDATE_PAYMENTS_ANALYTICS_DATA:
      if (state.currentLoginResponse) {
        return {
          ...state,
          currentLoginResponse: {
            ...state.currentLoginResponse,
            paymentsAnalyticsData: action.paymentsAnalyticsData
          }
        };
      }
      break;
  }
  return state;
};
export default authReducer;
