export const TermsOfServiceUrl = "https://www.doorloop.com/legal/terms";
export const TenantPortalTermsOfServiceUrl = "https://www.doorloop.com/legal/tenant-portal";
export const TransunionTermsOfServiceUrl = "https://www.doorloop.com/legal/transunion";
export const PrivacyPolicyUrl = "https://www.doorloop.com/legal/privacy";
export const GettingStartedUrl = "https://support.doorloop.com/en/articles/6301613-doorloop-getting-started";
export const ScheduleDemoUrl = "https://calendly.com/doorloop/demo";
export const HelpCenterUrl = "https://support.doorloop.com";
export const HelpCenterTenantUrl = " https://intercom.help/tenant";
export const ApplicantTermsOfUse = "http://www.doorloop.com/legal/applicants";
export const RentalApplicationBannerLearnMore =
  "https://support.doorloop.com/en/articles/8494476-set-up-rental-applications";

export const CheckOnlineStatusReceivePayment =
  "https://support.doorloop.com/en/articles/8174404-check-the-status-of-online-payments-with-the-received-payments-page#h_5d566495a4";

export const FormAndTemplatesUrl = "https://www.doorloop.com/forms";
export const RapidRentUrl = "https://www.doorloop.com/legal/rapidrent";
export const OwnerPortalHelpCenterUrl =
  "https://intercom.help/owner-portal/en/collections/5939971-owners-portal-tutorials";
