import type { KeyboardEventHandler } from "react";
import type React from "react";
import { useEffect } from "react";

export type KeyboardKeys =
  | "Enter"
  | "Escape"
  | "Tab"
  | "ArrowUp"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "f"
  | "k"
  | "p";

interface CombinationControlConfig {
  isCtrlCombination?: boolean;
  avoidWithShift?: boolean;
}

export const useKeyboard = (
  key: KeyboardKeys,
  handler: KeyboardEventHandler,
  deps: React.DependencyList = [],
  combinationControlConfig?: CombinationControlConfig
) => {
  const { isCtrlCombination, avoidWithShift } = combinationControlConfig || {};

  useEffect(() => {
    const eventHandler = (e) => {
      const { key: eventKey, metaKey, ctrlKey } = e;
      if (key === eventKey) {
        if (isCtrlCombination) {
          if (ctrlKey || metaKey) {
            e.preventDefault();
            handler(e);
          }
        } else if (avoidWithShift) {
          if (!e.shiftKey) {
            handler(e);
          }
        } else {
          handler(e);
        }
      }
    };
    window.document.addEventListener("keydown", eventHandler);
    return () => window.document.removeEventListener("keydown", eventHandler);
  }, deps);
};
