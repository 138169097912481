"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhenPlanToUseSoftware = exports.MainPropertyTypeEnum = exports.HOAWelcomePropertyTypeClient = exports.WelcomePropertyTypeClient = exports.WelcomePropertyType = void 0;
var WelcomePropertyType;
(function (WelcomePropertyType) {
    WelcomePropertyType["RESIDENTIAL"] = "Residential";
    WelcomePropertyType["COMMERCIAL"] = "Commercial";
    WelcomePropertyType["INDUSTRIAL"] = "Industrial";
    WelcomePropertyType["AFFORDABLE"] = "Affordable Housing";
    WelcomePropertyType["STUDENT"] = "Student Housing";
    WelcomePropertyType["ASSOCIATIONS"] = "Community Associations";
})(WelcomePropertyType || (exports.WelcomePropertyType = WelcomePropertyType = {}));
var WelcomePropertyTypeClient;
(function (WelcomePropertyTypeClient) {
    WelcomePropertyTypeClient["RESIDENTIAL"] = "Residential";
    WelcomePropertyTypeClient["COMMERCIAL"] = "Commercial";
    WelcomePropertyTypeClient["INDUSTRIAL"] = "Industrial";
    WelcomePropertyTypeClient["AFFORDABLE"] = "Affordable Housing";
    WelcomePropertyTypeClient["STUDENT"] = "Student Housing";
})(WelcomePropertyTypeClient || (exports.WelcomePropertyTypeClient = WelcomePropertyTypeClient = {}));
var HOAWelcomePropertyTypeClient;
(function (HOAWelcomePropertyTypeClient) {
    HOAWelcomePropertyTypeClient["RESIDENTIAL"] = "Residential";
    HOAWelcomePropertyTypeClient["COMMERCIAL"] = "Commercial";
})(HOAWelcomePropertyTypeClient || (exports.HOAWelcomePropertyTypeClient = HOAWelcomePropertyTypeClient = {}));
var MainPropertyTypeEnum;
(function (MainPropertyTypeEnum) {
    MainPropertyTypeEnum["AFFORDABLE_HOUSING"] = "Affordable Housing";
    MainPropertyTypeEnum["OTHER"] = "Other";
    MainPropertyTypeEnum["COMMUNITY_ASSOCIATIONS"] = "Community Associations";
    MainPropertyTypeEnum["COMMERCIAL"] = "Commercial";
    MainPropertyTypeEnum["RESIDENTIAL"] = "Residential";
    MainPropertyTypeEnum["SHORT_TERM_RENTALS"] = "Short term rentals";
    MainPropertyTypeEnum["STORAGE"] = "Storage";
    MainPropertyTypeEnum["RESIDENTIAL_COMMERCIAL"] = "Both";
})(MainPropertyTypeEnum || (exports.MainPropertyTypeEnum = MainPropertyTypeEnum = {}));
var WhenPlanToUseSoftware;
(function (WhenPlanToUseSoftware) {
    WhenPlanToUseSoftware["ASAP"] = "ASAP";
    WhenPlanToUseSoftware["ONE_TO_THREE_MONTHS"] = "1-3 Months";
    WhenPlanToUseSoftware["THREE_TO_SIX_MONTHS"] = "3-6 Months";
    WhenPlanToUseSoftware["SIX_MONTHS_PLUS"] = "6 Months +";
    WhenPlanToUseSoftware["JUST_RESEARCHING"] = "Just Researching";
})(WhenPlanToUseSoftware || (exports.WhenPlanToUseSoftware = WhenPlanToUseSoftware = {}));
