import { atom } from "jotai";

const DRAWER_PREF_STORAGE_KEY = "Drawer-Preferences";

interface DrawerPrefProps {
  isCollapsed?: boolean;
}

const getDrawerPreferencesFromStorage = (): DrawerPrefProps =>
  JSON.parse(localStorage.getItem(DRAWER_PREF_STORAGE_KEY) || "{}");

export const notificationsLayoutVisibilityAtom = atom<boolean>(false);
export const sideMenuCollapsingAtom = atom<boolean>(Boolean(getDrawerPreferencesFromStorage()?.isCollapsed));
export const desktopTopNavigationBarBackgroundColorAtom = atom<string>("#F5F6FA");
