"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerResponseErrorCode = void 0;
var ServerResponseErrorCode;
(function (ServerResponseErrorCode) {
    ServerResponseErrorCode["TOTP_REQUIRED_EMAIL"] = "TOTP_REQUIRED_EMAIL";
    ServerResponseErrorCode["TOTP_REQUIRED_SMS"] = "TOTP_REQUIRED_SMS";
    ServerResponseErrorCode["EXPIRED_PORTAL_INVITATION"] = "EXPIRED_PORTAL_INVITATION";
    ServerResponseErrorCode["ACCEPT_INVITE_EMAIL_ADDRESS_REQUIRED"] = "ACCEPT_INVITE_EMAIL_ADDRESS_REQUIRED";
    ServerResponseErrorCode["JOURNAL_ENTRY_RECONCILED_UPDATE_NOT_ALLOWED"] = "JOURNAL_ENTRY_RECONCILED_UPDATE_NOT_ALLOWED";
    ServerResponseErrorCode["TRANSUNION_ERROR"] = "TRANSUNION_ERROR";
    ServerResponseErrorCode["PLAID_ITEM_NOT_FOUND"] = "ITEM_NOT_FOUND";
    ServerResponseErrorCode["ITEM_LOGIN_REQUIRED"] = "ITEM_LOGIN_REQUIRED";
    ServerResponseErrorCode["PLAID_INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    ServerResponseErrorCode["TRANSACTION_LIMIT"] = "TRANSACTION_LIMIT";
    ServerResponseErrorCode["PRODUCT_NOT_READY"] = "PRODUCT_NOT_READY";
    ServerResponseErrorCode["GENERAL_PLAID_TRANSACTION_ERROR"] = "GENERAL_PLAID_TRANSACTION_ERROR";
    ServerResponseErrorCode["USER_PERMISSION_REVOKED"] = "USER_PERMISSION_REVOKED";
    ServerResponseErrorCode["OPERATION_SKIPPED"] = "OPERATION_SKIPPED";
    ServerResponseErrorCode["PASSWORD_RESET_REQUIRED"] = "PASSWORD_RESET_REQUIRED";
    ServerResponseErrorCode["CONVERSATION_PHONE_NUMBER_IS_DISABLED"] = "CONVERSATION_PHONE_NUMBER_IS_DISABLED";
    ServerResponseErrorCode["COMMUNICATION_NO_CREDITS"] = "COMMUNICATION_NO_CREDITS";
    ServerResponseErrorCode["VALIDATION_FAILED"] = "VALIDATION_FAILED";
    ServerResponseErrorCode["VPN_ERROR"] = "VPN_ERROR";
    ServerResponseErrorCode["RENTAL_APPLICATION_REPORTS_RESTRICTION"] = "RENTAL_APPLICATION_REPORTS_RESTRICTION";
})(ServerResponseErrorCode || (exports.ServerResponseErrorCode = ServerResponseErrorCode = {}));
