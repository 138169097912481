export default {
  generalSettings: {
    tagName: "Tag Name",
    settings: "Settings",
    generalSettings: "General Settings",
    personal: "Personal",
    company: "Company",
    relatedLists: "Related Lists",
    propertySettings: "Property Settings",
    personalInformationTitle: "Personal Information",
    personalInformationSubTitle: "Change your name, picture, phone, email, & mailing address.",
    loginAndPasswordTitle: "Login & Password",
    loginAndPasswordSubTitle: "Change your email or password, and allow technician access.",
    loginAndPasswordSettingsTitle: "Login & Password Settings",
    simulation: "Simulation",
    subtotal: "Subtotal",
    customAllocations: {
      customAllocationsTitle: "Custom Allocations",
      customAllocationsSubTitle: "Add custom allocation presets to use for lease charges and credits",
      propertyNameCustomAllocations: "{{propertyName}} Custom Allocations",
      deleteAllocation: "Delete Allocation",
      helpPanel: {
        description: "Add custom allocation presets to use for lease charges and credits",
        learnMore: "Learn more about custom allocations"
      }
    },
    companyStartDate: {
      companyStartDateTitle: "Company Start Date",
      companyStartDateSubTitle: "Your company start date is when DoorLoop should start charging your tenants rent",
      subtitle: "This is the date to start charging your existing tenants and:",
      tenantInvite: "Invite your existing tenants to the tenant portal",
      rentReminder: "Send rent reminders to existing tenants",
      doorloopStartDate: "DoorLoop Start Date",
      infoText:
        "We recommend using the 1st of the following month of when you signed up to DoorLoop, but no less than 14 days from the date you sign up."
    },
    companyInformationTitle: "Company Information",
    convenienceFees: {
      settingsTitle: "Convenience Fees",
      settingsDialogSubTitle:
        "A convenience fee is an extra amount that is added to any transaction made by your tenants when using ACH as a payment method.",
      settingsDialogBenefitsTitle: "Set up Convenience Fees to:",
      settingsDialogBenefits1: "Create an additional revenue stream",
      settingsDialogBenefits2: "Reduce the likelihood of late payments",
      settingsDialogBenefits3: "Exclusive to premium users",
      inputGroupLabel: "Add a convenience fee to rent payments",
      inputAmountLabel: "Select the amount",
      inputMethodLabel: "Select your Revenue Account",
      useCompanyDefaultConvenienceFees: "Use company default convenience fee structure",
      usePropertyDefaultConvenienceFees: "Use property default convenience fee structure",
      specifyConvenienceFeesForProperty: "Specify convenience fee for this property",
      specifyConvenienceFeesForLease: "Specify a convenience fee structure for this lease",
      helpText: "Learn more about convenience fees",
      defaultAccountError:
        "Failed retrieving convenience fees default account,\n please set it up first in 'default accounts' settings",
      warningText:
        "Please note that any changes made to convenience fees will only apply to future payments and will not apply to any active recurring payments."
    },
    returnedPaymentFees: {
      settingsTitle: "Returned Payment Fee",
      settingsDialogSubTitle:
        "This fee is automatically applied when a payment is returned due to insufficient bank funds or a disputed credit card charge.",
      helpText: "Learn more about returned payment fees",
      settingsDialogBenefitsTitle: "Manage Returned Payments With Ease!",
      settingsDialogBenefits1:
        "If a tenant payment is returned for insufficient funds, the ${{fee}} bank fee will be paid from your account.",
      settingsDialogBenefits2: "You can charge tenants to help offset this cost.",
      settingsDialogBenefits3:
        "Set your fee amount, but be sure to follow local laws—some states allow higher fees, while others cap it below $25.",
      inputGroupLabel: "Add a Returned Payment Fee",
      inputAmountLabel: "Fee Amount",
      inputAmountPlaceHolder: "Set Amount",
      inputAccountLabel: "Account",
      inputAccountPlaceHolder: "Choose Account",
      warningText: "Ensure you comply with all laws for the maximum fee you can charge for returned payments.",
      hideFeaturesForProperty: "Use company default returned payment fees for this property",
      specifyFeaturesForProperty: "Specify returned payment fees for this property",
      hideFeaturesForLease: "Use property default returned payment fees for this lease",
      specifyFeaturesForLease: "Specify returned payment fees for this lease",
      defaultAccountError:
        "Failed retrieving returned payment fees default account,\n please set it up first in 'default accounts' settings"
    },
    onlinePaymentFees: {
      settingsTitle: "Fees Settings",
      loadingText: "Saving fees settings...",
      errorText: "Something went wrong. Please try again later or contact us.",
      successText: "Fees settings saved successfully",
      returnedPaymentFees: "Returned Payment Fees",
      lateFees: "Late Fees",
      convenienceFees: "Convenience Fees",
      confirmationDialog: {
        title: "Proceed without setting a fee?",
        description: "No fee is currently assigned for returned payments. Do you want to continue without setting one?"
      }
    },
    paymentAllocation: {
      paymentAllocationSettingsTitle: "Payment Allocation",
      paymentAllocationSettingsDescription:
        "Edit how payments are automatically received, either by custom priority or date.",
      paymentAllocationsSettingsDialogSubTitle:
        "Edit how payments are automatically received, either by custom priority or date.",
      paymentAllocationsSettingsDialogHowToAllocate: "How should payments be allocated to charges?",
      allocateBy: "Allocate By",
      accountsReOrderDescription:
        "Set the payment priority of your accounts. Accounts listed are prioritized first in order.\n" +
        "Those not shown will be prioritized from oldest to newest. ",
      categoriesReOrderDescription:
        "Set the payment priority of your categories. Categories listed are prioritized first in order.\n" +
        "Those not shown will be prioritized from oldest to newest. ",
      accounts: "Accounts",
      categories: "Categories",
      addAnAccount: "Add an account",
      addAccount: "Add Account",
      addACategory: "Add a category",
      helpText: "Learn more about payment allocation",
      noAccountsSelectedForAllocation: "Please select at least 1 account to continue"
    },
    companyInformationSubTitle: "Change your company name, logo, address, login URL, and account owner.",
    regionAndCurrencyTitle: "Region & Currency",
    regionAndCurrencySubTitle: "Change your country, date formats, and currency settings.",
    defaultAccountsTitle: "Default Accounts",
    defaultAccountsSubTitle: "Change your default bank accounts and chart of accounts.",
    ownerPortalTitle: "Owner Portal",
    usersTitle: "Users",
    usersSubTitle: "Add or edit users.",
    userRolesTitle: "User Roles",
    userRolesSubTitle: "Allow or block access to features of DoorLoop for each user.",
    quickbooksIntegrationTitle: "Quickbooks Sync",
    quickbooksIntegrationSubTitle: "Sync DoorLoop and Quickbooks.",
    listingsTitle: "Listing Partners",
    listingsSubTitle: "Post your vacancies online.",
    automaticBackupsTitle: "Automatic Backups",
    automaticBackupsSubTitle: "Change your personal information, such as your name and profile picture.",
    communicationCenterTitle: "Communication Center",
    communicationCenterSubTitle: "Edit communication center settings",
    apiKeys: {
      apiKeysTitle: "Zapier & API Keys",
      apiKeysSubTitle: "Generate keys to integrate with DoorLoop",
      apiKeys: "API Keys",
      apiKeysNameTextError: "Api key name mandatory and can be 100 characters maximum.",
      apiKeysDialogPanelHelpArticle1: "Learn more about Zapier",
      apiKeysDialogPanelVideoTutorial: "Learn how to connect to Zapier",
      apiKeysGetAllTextError: "Getting Api Keys failed",
      apiKeysCreateTextError: "Creating Api Key failed",
      apiKeysDeleteTextError: "Update Api Key failed",
      apiKeysUpdateTextError: "Remove Api Key failed",
      helpPanel: {
        description: "This Line can contain assistive text, help, page description, guidelines and more. (Optional)",
        learnMore: "Learn more about Zapier",
        videoTutorial: "Learn how to connect to Zapier"
      }
    },
    propertyGroupsTitle: "Portfolios",
    propertyGroupsSubTitle: "Bundle multiple properties together when running reports or other features.",
    noteCategoriesTitle: "Note Tags",
    noteCategoriesSubTitle: "Add tags to organize and sort your notes.",
    fileCategoriesTitle: "File Tags",
    fileCategoriesSubTitle: "Add tags to organize and sort your files.",
    taskCategoriesTitle: "Task Categories",
    taskCategoriesSubTitle: "Add categories to organize and sort your tasks.",
    vendorClassificationsTitle: "Vendor Classifications",
    vendorClassificationsSubTitle: "Add Classifications to organize and sort your vendors.",
    leadSourcesTitle: "Lead Sources",
    leadSourcesSubTitle: "Keep track of where your leads, prospective tenants, and owners came from.",
    updatingUserInformation: "Updating user information",
    loadingUserInformation: "Loading user information",
    failedToLoadUserInformation: "Failed to load user information",
    updatingUserInformationSuccessText: "User information was updated",
    technicianAccessEnabldUntil: "DoorLoop will have access to your account until",
    technicianAccessEnabled: "Tech access enabled",
    expiresOn: "Expires on:",
    addressAndType: "Address & Type",
    companyInfo: {
      companyInfo: "Company Info",
      generalInfo: "General Info",
      addCompanyLogo: "Add Your Company Logo",
      recommendedSize: "Recommended size: 300x100",
      primaryEmailAddress: "Primary Email Address",
      primaryPhoneNumber: "Primary Phone Number",
      companyWebsite: "Company Website",
      updatingCompanyInformation: "Updating company information",
      loadingCompanyInformation: "Loading company information",
      failedToLoadCompanyInformation: "Failed to load company information",
      updatingCompanyInformationSuccessText: "company information was updated"
    },
    rentalApplications: {
      sectionRequired: "Set this section as a requirement to submit the application",
      generalInstructions: "General Instructions",
      companyLink: "Company Link",
      copyLinkDescription:
        "Share your {{linkType}} application link with your prospects to help them get started with the application process for the available units.",

      propertyLink: "Property Link",
      generalInstructionsSectionDescription:
        "Any specific instructions for the application? These will be shown on the first page of the application.",
      generalInstructionsExample:
        "i.e. Please fill out all the information, if you have any questions, contact us at 555-123-4556",
      aboutYou: "About You",
      applicantInfo: "Applicant Info",
      residential: "Residential",
      vehicle: "Vehicle",
      createQuestions: "Create Questions",
      manageFee: "Manage Fee",
      aboutYouRentalApplicationDescription: "",
      manageFeeTitle: "Applications & Screening Settings",
      aboutYouSectionDescription:
        "Any specific instructions for the application? These will be shown on the first page of the application.",

      pets: "Pets",
      petsRentalApplicationDescription: "",
      askAboutPets: "Ask the applicant about pets",
      petsSectionDescription: "Do you want to collect information about their pets?",
      vehicleInformation: "Vehicle Information",
      vehicleSectionDescription: "Do you want to collect information about their vehicles?",
      askAboutVehicles: "Ask the applicant about vehicles",
      dependants: "Dependants",
      dependantsSectionDescription: "Do you want to collect information about their dependants?",
      askAboutDependants: "Ask the applicant about dependants",
      emergencyContacts: "Emergency Contacts",
      emergencyContactsSectionDescription: "Do you want to collect information about their emergency contacts?",
      askAboutEmergencyContacts: "Ask the applicant about emergency contacts",
      employment: "Employment",
      employmentSectionDescription: "Do you want to specify any additional instructions about their employment?",
      askAboutEmployment: "Ask the applicant about employment",
      additionalIncome: "Additional Income & Assets",
      additionalIncomeSectionDescription: "Do you want to collect information about their additional income?",
      askAboutAdditionalIncome: "Ask the applicant about additional income",
      residentialHistory: "Residential History",
      residentialHistorySectionDescription:
        "Do you want to specify any additional instructions about their residential history?",
      additionalInformationSectionDescription: "Do you want to ask them more questions?",
      filesAndDocumentsToggleLabel: "Ask the applicants to upload files",
      additionalAttachments: "Additional Attachments",
      limitedToOneFilePerAttachment: "Limited to one file per attachment",
      filesAndDocumentsSectionDescription:
        "You can ask applicants to upload files such as a photo of their driver license, bank statements and more.",
      askAboutResidentialHistory: "Ask the applicant about residential history",
      askForAdditionalInformation: "Ask the applicant for additional information",
      additionalInformation: "Additional Information",
      filesAndDocuments: "Files & Documents",
      requestDocuments: "Request Documents",
      notifications: "Notifications",
      applicationLink: "Application Link",
      notificationsDescription:
        "DoorLoop can automatically notify you by email when a new rental application is submitted",
      notifyUsersWhenSubmitted: "Notify users when an application is submitted",
      termsAndConditions: "Terms & Conditions",
      termsAndConditionsDescription:
        "You can add additional terms and conditions for submitting a rental application. Each applicant will be required to accept these terms and conditions before submitting their application.",
      requireTermsAndConditions: "Require each applicant to accept additional terms and conditions",
      acceptedByLabel: "Accepted By (Full Name)",
      acceptedBy: "Accepted By",
      acceptedAt: "Accepted At",
      acceptTermsAndConditions: "I accept the above terms and conditions"
    },
    doorLoopURL: {
      doorLoopURL: "DoorLoop URL",
      doorLoopURLDescription: "You can modify your DoorLoop URL to match your company brand.",
      tenantPortalURLDescription: "You can modify your Tenant portal URL to match your company brand.",
      ownerPortalURLDescription: "You can modify your Owner portal URL to match your company brand.",
      subdomain: "subdomain",
      usersLoginURL: "Users Login URL",
      clientPortalURL: "Tenant Portal URL",
      ownerPortalURL: "Owner Portal URL"
    },
    accountOwner: {
      accountOwnerDescription:
        "The user selected below is the owner of this DoorLoop account. They will always have full access to all the data and features on this account.",
      accountOwnerModify: "* Only the current account owner can change the owner of the account."
    },
    regionAndCurrency: {
      region: "Region",
      dateFormats: "Date Formats",
      currency: "Currency",
      numberFormats: "Number Formats",
      loadingRegionAndCurrencyData: "Loading region and currency data",
      LoadingRegionAndCurrencyDataSuccessText: "Loading completed",
      updatingRegionAndCurrencyData: "Updating region and currency data",
      updateRegionAndCurrencySuccessText: "region and currency data updated successfully"
    },
    defaultAccounts: {
      convenienceFeeIncome: "Convenience Fee Income",
      merchantReturnedPaymentFee: "Returned Payment Fee",
      operatingAccountChangedNotificationTitle: "Important information about changing your operating account",
      operatingAccountChangedNotificationText:
        "If you are changing your operating account after previously receiving payments through the Tenant Portal, please ensure that all tenants delete and then recreate scheduled and automatic payments to ensure they are routed to the new bank account.",
      dialogDescription:
        "These accounts will be used by default for certain financial transactions that take place in DoorLoop.",
      bank: "Bank",
      operatingBankAccount: "Operating Bank Account",
      trustBankAccount: "Trust Bank Account",
      income: "Income",
      rentalIncome: "Rental Income",
      applicationFee: "Application Fee",
      liability: "Liability",
      securityDeposit: "Security Deposit",
      expense: "Expense",
      managementFees: "Management Fees",
      equity: "Equity",
      ownerContribution: "Owner Contribution",
      ownerDistribution: "Owner Distribution",
      selectAccount: "Select Account",
      selectCategory: "Select Category",
      loadingDefaultAccountsData: "Loading default accounts data",
      loadingDefaultAccountsDataSuccessText: "Loading completed",
      updatingDefaultAccountsData: "Updating default accounts data",
      updateDefaultAccountsSuccessText: "Default accounts data updated successfully"
    },
    taskCategories: {
      dialogDescription: "Link your tasks with task categories, run reports, and keep track of your tasks.",
      loadingTaskCategories: "Loading task categories",
      loadingTaskCategoriesDataSuccessText: "Loading task categories completed"
    },
    noteCategories: {
      dialogDescription: "Link your notes with note categories, run reports, and keep track of your notes.",
      loadingNoteCategories: "Loading note categories",
      loadingNoteCategoriesDataSuccessText: "Loading note categories completed"
    },
    fileCategories: {
      dialogDescription: "Link your files with file categories, run reports, and keep track of your files.",
      loadingFileCategories: "Loading file categories",
      loadingFileCategoriesDataSuccessText: "Loading file categories completed"
    },
    vendorClassifications: {
      dialogDescription: "Link your vendors with vendor classifications, run reports, and keep track of your vendors.",
      loadingVendorClassifications: "Loading vendor classifications",
      loadingVendorClassificationsDataSuccessText: "Loading vendor classifications completed"
    },
    leadSource: {
      dialogDescription: "Link your leads with lead source, run reports, and keep track of your leads.",
      loadingLeadSources: "Loading lead sources",
      loadingVendorCategoriesDataSuccessText: "Loading lead sources completed"
    },
    propertyGroups: {
      dialogDescription: "Link your properties with portfolios, run reports, and keep track of your properties.",
      loadingPropertyGroups: "Loading portfolios",
      loadingPropertyGroupsDataSuccessText: "Loading portfolios completed",
      portfolioName: "Portfolio Name",
      propertiesNames: "Properties Names"
    },
    feesMobileForm: {
      addFee: "Add Fee",
      saveFeeChanges: "Save Changes"
    },
    managementFees: {
      managementFees: "Management Fees",
      managementPayoutAccounts: "Management Payout Accounts",
      managementPayoutCategories: "Management Payout Categories",
      createManagementFeeBills: "Create Management Fee Bills",
      propertiesWithManagementFees: "Properties with Billable Management Fees",
      managementFeesDescription: "Calculate and generate bills automatically for your properties",
      managementFeesDialogDescription:
        "DoorLoop automatically calculates and generates your management fee bills. Fill in the details below to get started.",
      whichVendorToPay:
        "Choose the person or entity that will receive the management fee payments (usually your own property management company).",
      chargeBasedPaymentsAccrual: "Add a fee based on charges posted (Accrual Basis)",
      chargeBasedPaymentsCash: "Add a fee based on payments received (Cash Basis)",
      incomeAccount: "Income Account",
      baseCategory: "Base Category",
      payoutAccount: "Payout Account",
      payoutCategory: "Payout Category",
      fee: "Fee",
      expenseCategory: "Expense Category",
      addAccount: "Add Account",
      addAnotherCategory: "Add another category",
      addAnotherFee: "Add Another Fee",
      flatFeeCharge: "Flat fee",
      flatFeeChargeDescription: "Add a flat fee to each management fee bill",
      minimumFee: "Minimum fee",
      minimumFeeDescription:
        "Add a Minimum Fee to ensure a minimum payment is received, regardless of the fees calculated above.",
      payoutManagementAccounts: "Payout accounts",
      payoutManagementCategories: "Payout categories",
      payoutManagementAccountsDescription:
        "Management payout accounts will create a management payout bill for the total amount in each of the accounts below, clearing it from the P&L and cash flow statement for the period.",
      payoutManagementCategoriesDescription:
        "Management payout categories will create a management payout bill for the total amount in each of the categories below, clearing it from the P&L and cash flow statement for the period.",
      collectManagementFeesForProperty: "Enable management fee for this property",
      specifyManagementFeeStructureForProperty: "Specify management fee structure for this property",
      useCompanyDefaultManagementFees: "Use company default management fee structure",
      specifyManagementFeesForProperty: "Enable management fee for this property",
      setupStructure: "Set Up Your Management Fee Structure",
      addManagementFee: "Add management fee",
      editManagementFee: "Edit management fee",
      addFee: "Add Fee",
      configureManagementFees: "Configure Management Fees",
      setManagementFeesDescription:
        "Customize your management fees to fit your needs. We will automatically calculate them and create the bills for you.",
      setMinimumFee: "Set a Minimum Fee",
      selectFeeType: "Fee Type",
      managementFeesScreenDescription:
        "Please check the box next to each property you would like to generate management fee bills for,",
      managementFeesScreenDescription2: "You can then review the bills created, and pay them via the pay bill wizard.",
      billsCreatedSuccessfully: "{{numOfBills}} Bills Created Successfully",
      managementFeeBills: "Management Fee Bills:",
      managementAccountsPayout: "Management Accounts Payout:",
      totalBillsCreatedAmount: "{{numOfBills}} for a total of",
      viewBills: "View Bills",
      accrualDescription: "Percentage of charges posted (Accrual Basis)",
      cashDescription: "Percentage of payments received (Cash Basis)",
      flatFeeDescription: "Flat fee",
      selectFeePercent: "Select the fee percentage and a base account where the fee will be charged",
      selectExpenseAccount: "Select an expense account where the fee will be transferred",
      enterFlatFee: "Enter flat fee amount",
      rules: {
        cash: "Cash",
        accrual: "Accrual",
        cashBasis: "Cash Basis",
        accrualBasis: "Accrual Basis",
        flat: "Flat"
      },
      defaults: {
        type: "type",
        fee: "Fee"
      },
      setManagementPayoutCategories: "Set Up Payout Categories",
      setManagementPayoutAccountsDescription:
        "Payout Categories designate which payouts are retained entirely by your business. Once paid, these amounts are excluded from the period's Profit & Loss and Cash Flow Statement.",
      selectIncomeAccount: "Select income account",
      sampleCalculator: {
        editInputs: "Edit Inputs",
        intro: `Preview your fee structure according to your setup. Click "Edit Inputs" to change the sample values.`,
        payoutAccounts: "Payout Accounts:",
        expenseAccount: "Expense account",
        totalFeesSample: "Total",
        visibleToYou: "Visible only to you"
      }
    },
    communicationCenter: {
      smsSettings: "SMS Settings",
      smsSettingsDescription: "To start sending and receiving SMS messages, simply turn on the toggle switch.",
      smsSettingsEnablePhoneNumberLabel: "Send and receive SMS messages.",
      smsSettingsPhoneNumberDescription: "This is your Doorloop phone number",
      smsSettingsPhoneNumberCopy: "Phone number has been copied",
      messagesSettingsNotifyAutomatically: "Automatically notify the user.",
      messagesSettingsTenantsTitle: "Tenants Messages Settings",
      messagesSettingsTenantsAssignToUsersLabel: "Automatically assign users when a tenant message is received.",
      messagesSettingsOwnersTitle: "Owners Messages Settings",
      messagesSettingsOwnersAssignToUsersLabel: "Automatically assign users when a owner message is received.",
      messagesSettingsVendorsTitle: "Vendors Messages Settings",
      messagesSettingsVendorsAssignToUsersLabel: "Automatically assign users when a vendor message is received.",
      messagesSettingsProspectsTitle: "Prospects Messages Settings",
      messagesSettingsProspectsAssignToUsersLabel: "Automatically assign users when a prospect message is received."
    }
  }
};
