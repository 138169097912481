import React from "react";
import { View } from "DLUI/view";
import * as _ from "lodash";

interface WizardStepsIndicatorProps {
  numOfSteps: number;
  currentStep: number;
  size?: number;
}

export const WizardStepsIndicator: React.FC<WizardStepsIndicatorProps> = ({
  numOfSteps,
  currentStep,
  size = 16
}: WizardStepsIndicatorProps) => (
  <div>
    {_.times(numOfSteps, (i) => (
      <View
        key={i}
        height={size}
        width={size}
        style={{
          margin: "4px",
          display: "inline-block"
        }}
        borderRadius={100}
        backgroundColor={i === currentStep ? "pink" : "secondary-gray"}
      />
    ))}
  </div>
);
