"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationFloorplan = exports.SyndicationRoomType = void 0;
const syndicationIdentifiable_1 = require("./syndicationIdentifiable");
const class_validator_1 = require("class-validator");
const syndicationCommon_1 = require("./syndicationCommon");
const syndicationDepositType_1 = require("./syndicationDepositType");
const syndicationFee_1 = require("./syndicationFee");
const syndicationSpecificAmenityType_1 = require("./syndicationSpecificAmenityType");
const class_transformer_1 = require("class-transformer");
const syndicationFileType_1 = require("./syndicationFileType");
class SyndicationRoomType {
}
exports.SyndicationRoomType = SyndicationRoomType;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationRoomType.prototype, "_RoomType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], SyndicationRoomType.prototype, "Count", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], SyndicationRoomType.prototype, "Comment", void 0);
class SyndicationFloorplan extends syndicationIdentifiable_1.SyndicationIdentifiable {
}
exports.SyndicationFloorplan = SyndicationFloorplan;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], SyndicationFloorplan.prototype, "FloorplanType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], SyndicationFloorplan.prototype, "Name", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], SyndicationFloorplan.prototype, "UnitCount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], SyndicationFloorplan.prototype, "Comment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], SyndicationFloorplan.prototype, "UnitsAvailable", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], SyndicationFloorplan.prototype, "FloorCount", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], SyndicationFloorplan.prototype, "TotalRoomCount", void 0);
__decorate([
    (0, class_validator_1.ArrayNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], SyndicationFloorplan.prototype, "Room", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationCommon_1.SyndicationRangeGroup),
    __metadata("design:type", syndicationCommon_1.SyndicationRangeGroup)
], SyndicationFloorplan.prototype, "SquareFeet", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], SyndicationFloorplan.prototype, "DisplayedUnitsAvailable", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationCommon_1.SyndicationRangeGroup),
    __metadata("design:type", syndicationCommon_1.SyndicationRangeGroup)
], SyndicationFloorplan.prototype, "MarketRent", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationCommon_1.SyndicationRangeGroup),
    __metadata("design:type", syndicationCommon_1.SyndicationRangeGroup)
], SyndicationFloorplan.prototype, "EffectiveRent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationCommon_1.SyndicationRangeGroup),
    __metadata("design:type", syndicationCommon_1.SyndicationRangeGroup)
], SyndicationFloorplan.prototype, "LeaseTerm", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationDepositType_1.SyndicationDepositType),
    __metadata("design:type", syndicationDepositType_1.SyndicationDepositType)
], SyndicationFloorplan.prototype, "Deposit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationFee_1.SyndicationFee),
    __metadata("design:type", syndicationFee_1.SyndicationFee)
], SyndicationFloorplan.prototype, "Fee", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationSpecificAmenityType_1.SyndicationSpecificAmenityType),
    __metadata("design:type", Array)
], SyndicationFloorplan.prototype, "Amenity", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationFileType_1.SyndicationFileType),
    __metadata("design:type", Array)
], SyndicationFloorplan.prototype, "File", void 0);
