// Types Definition
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const FORGOT_PASSWORD_REQUEST_BEGIN = "FORGOT_PASSWORD_REQUEST_BEGIN";
export const FORGOT_PASSWORD_REQUEST_COMPLETE = "FORGOT_PASSWORD_REQUEST_COMPLETE";

export const UPDATE_LOCALE_DATA = "UPDATE_LOCALE_DATA";
export const UPDATE_SUBSCRIPTION_DATA = "UPDATE_SUBSCRIPTION_DATA";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const UPDATE_COMPANY_START_DATE = "UPDATE_COMPANY_START_DATE";

export const UPDATE_LOGIN_THEME = "UPDATE_LOGIN_THEME";

export const UPDATE_TENANT_PORTAL_LEASE = "UPDATE_TENANT_PORTAL_LEASE";
export const UPDATE_TENANT_PORTAL_DB_TENANT = "UPDATE_TENANT_PORTAL_DB_TENANT";

export const UPDATE_ONBOARDING_PROGRESS = "UPDATE_ONBOARDING_PROGRESS";

export const UPDATE_SHOW_ONBOARDING_ON_LOGIN = "UPDATE_SHOW_ONBOARDING_ON_LOGIN";

export const UPDATE_TOTAL_UNREAD_NOTIFICATIONS = "TOTAL_UNREAD_NOTIFICATIONS";
export const UPDATE_MERCHANT_ACCOUNT_DATA = "UPDATE_MERCHANT_ACCOUNT_DATA";

export const UPDATE_IS_FEATURE_ENABLED = "UPDATE_IS_FEATURE_ENABLED";

export const UPDATE_PAYMENTS_ANALYTICS_DATA = "UPDATE_PAYMENTS_ANALYTICS_DATA";
