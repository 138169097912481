"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runSafeFn = runSafeFn;
function runSafeFn(fn, defaultValue) {
    try {
        return fn();
    }
    catch (err) {
        console.error("Failed to run function", err);
        return defaultValue;
    }
}
