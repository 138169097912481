import type { History, LocationDescriptorObject, LocationState } from "history";
import { createBrowserHistory } from "history";
import { store } from ".";

class DLUI_History {
  history: History<any>;
  private lastRoute?: string;

  constructor() {
    this.history = createBrowserHistory();
  }

  get state() {
    return this.history.location.state;
  }

  push = (path: string | LocationDescriptorObject<any>, state?: LocationState) => {
    this.lastRoute = window.location.pathname;
    if (typeof path === "string") {
      this.history.push(path, state);
    } else {
      this.history.push(path);
    }
  };

  pushSteps = (pathSteps: string[]) => {
    this.lastRoute = window.location.pathname;
    this.history.push(pathSteps.join(""));
  };

  go = (n: number) => {
    this.history.go(n);
  };

  goBack = () => {
    if (this.lastRoute) {
      this.history.goBack();
    } else {
      const loginResponse = store.getState().auth.currentLoginResponse;
      if (loginResponse) {
        const redirectPath = "";
        this.history.replace(redirectPath || "/");
      } else {
        this.history.replace("/");
      }
    }
  };

  replace = (path: string | LocationDescriptorObject<any>, state?: any) => {
    if (typeof path === "string") {
      this.history.replace(path, state);
    } else {
      this.history.replace(path);
    }
  };

  replaceSearch = (search: string) => {
    this.history.replace({
      search
    });
  };
}

export const history = new DLUI_History();
