export default {
  accountInfo: {
    number: `Bank Account Number`,
    routing: "Bank Routing Number",
    nextCheck: "Next Check Number",
    someChecksMessage: `Some checks come with your routing, account and check numbers already printed on the checks. If not,
toggle this switch on and DoorLoop will include this information when printing checks.`,
    youCanPrintMessage: `You can print checks via DoorLoop for transactions such as expenses, owner distributions and bill payments.`,
    alsoInclude: `Also include routing, account and check numbers when printing on the check`,
    learnMore: `Learn more`,
    bankName: "Bank Name",
    accountOwnerName: "Account Owner Name",
    bankAccountContactInfo: "Bank Account Contact Info",
    payToTheOrderOf: "PAY TO THE ORDER OF",
    dollars: "DOLLARS",
    authorizedSignature: "AUTHORIZED SIGNATURE"
  },
  chartOfAccounts: {
    helpPanel: {
      description: "Use these accounts to categorize and record your transactions.",
      learnMoreChartOfAccounts: "Understanding your chart of accounts",
      setUpCharts: "How do I set up my chart of accounts?",
      changeDefault: "How do I change my default accounts?"
    },
    downloadExcel: "Download Excel",
    companyDefaultAccount: "Company Default Account",
    searchAccounts: "Search Accounts",
    defaultAccountFor: "Default Account For",
    runReport: "Run Report",
    makeInactive: "Make Inactive",
    viewRegister: "View Register",
    newAccount: "New Account",
    editAccount: "Edit Account",
    accountInfo: "Account Info",
    accountName: "Account Name",
    subAccountDescription: "This is a sub-account",
    parentAccount: "Parent Account",
    activeAccount: "Active Account",
    openingBalances: "Opening Balances",
    openingBalancesTitle: "When do you want to start tracking your finances for this account?",
    openingBalancesDescription:
      "Your accounting start date is very important, because this is the day you begin tracking transactions for this bank account in DoorLoop. " +
      "Everything before this date is summarized in the opening balance you set for this bank account.",
    accountingStartDate: "Accounting Start Date",
    accountBalanceDescriptionPart1: "What was your total account balance on",
    accountBalanceDescriptionPart2: "one day before your accounting start date?",
    balanceByPropertyTitle: "Great! Now we need to know the balance by property",
    balanceByPropertyDescription:
      "Please add the balance per property below. The total per property must be equal to the total Account Balance. This should include the total amount for each property, including deposits and reserves.",
    balanceOn: "Balance on",
    remainingBalance: "Remaining Balance",
    leaseOptions: "Lease Options",
    leaseChargeItem: "Use this account for Lease Charges",
    leaseChargeItemDescription:
      "This will allow you to select this account when creating a new lease charge, credit, or refund.",
    leaseDeposits: "Use this account for Lease Deposits",
    leaseDepositsDescription: "This will allow you to select this account when creating a new lease deposit charge.",
    leaseStatementMemo: "Set a default lease statement memo",
    leaseStatementMemoDescription:
      "This will allow you to set a default memo on the lease statement. It will help explain your charges (i.e., Rent, Parking, Cleaning Fee, etc.)",
    defaultLeaseMemo: "Default Lease Memo",
    doorLoopPayments: "DoorLoop Payments",
    invalidOpeningBalanceByProperty: "Remaining balance must be zero",
    loadingText: "Creating account ...",
    errorText: "Something went wrong, please try again later or contact us.",
    successText: "Account created successfully",
    openingBalancesExists: "You already entered your opening balances for this account.",
    openingBalancesExistsDescription: "if you would like to make any adjustments.",
    cantEditSystemAccountTitle: "System accounts cannot be changed",
    cantEditSystemAccountsubTitle: "Please select a different account or create a new one"
  },
  accountClass: {
    asset: "Asset",
    liability: "Liability",
    equity: "Equity",
    revenue: "Revenue",
    expense: "Expense"
  },
  companyName: "Company Name",
  ein: "EIN",
  merchantAccountNotFound: "Merchant account not found",
  stripeNoActionRequiredTitle: "Application already submitted",
  stripeNoActionRequiredContent:
    "This application cannot be accessed because it has already been submitted. If you have any questions or concerns, please contact our customer success team for more information.",
  stripeApplyNowDialogTitle: "Receive Rent Payments",
  stripeActionRequired: "Action Required",
  stripeUpdateInformation: "Update Information",
  stripeVerifyInformation: "Verify Information",
  stripe4digitsValidationError: "No match to last 4 digits",
  stripeApplyNowContentI:
    "We are excited to announce that DoorLoop has partnered with Stripe, which will provide a safer, simpler and more intuitive way to receive payments online.",
  stripeApplyNowContentII: "To get started, please complete the Stripe application form.",
  //stripeApplyNowContentIII: Ori: "Was the text here changed on purpose?"
  //  "For security purposes, the owner of this bank account will be required to provide a US-government issued ID and submit a self-captured photo to verify their identity.",
  stripeApplyNowContentIII:
    "This application must be submitted by an executive, senior manager, or someone else with significant responsibility for controlling and managing your business.",
  stripeApplyNowContentIV:
    "After submitting the application, you will be charged a ${{stripeApplicationFee}} application fee.",
  stripeApplyNowContentV:
    "All applications are subject to underwriting, risk team approval, and the acceptance of the DL rapid rent agreement.",
  stripeApplyNowContentCountryAndCurrency:
    "By continuing, you acknowledge that your business location is <bold>{{country}}</bold> and your preferred currency is <bold>{{currency}}</bold>. Once you submit your application, these account details <bold>cannot be updated.</bold>",
  stripeActionRequiredNeedAssistance: "Need assistance?",
  stripeActionRequiredSendUsAMessage: "Send us a message",
  stripeActionRequiredEINBulletI: "The EIN we have on file for your company did not match IRS records.",
  stripeActionRequiredEINBulletIIPartI: "Please make sure the company name and EIN",
  stripeActionRequiredEINBulletIIPartII: "match exactly",
  stripeActionRequiredEINBulletIIPartIII:
    "what’s shown on the company’s SS-4 confirmation letter or Letter 147c, including capitalization and punctuation.",
  stripeActionRequiredEINBulletIII:
    "If the information fails to validate, you can also send us your SS-4 letter from the IRS instead.",
  stripeActionRequiredBankAccountContentI:
    "We are excited to announce that DoorLoop has partnered with Stripe, which will provide a safer, simpler and more intuitive way to receive payments online.",
  stripeActionRequiredBankAccountContentII:
    "In order to ensure a smooth transition, we require you to update your information with Stripe.",
  stripeActionRequiredBankAccountContentIII:
    "We need to verify your bank account number. Please enter the full account number below.",
  stripeActionRequiredOtherPayrixContentI:
    "We are excited to announce that DoorLoop has partnered with Stripe, which will provide a safer, simpler and more intuitive way to receive payments online.",
  stripeActionRequiredOtherPayrixContentII:
    "To ensure a smooth transition, we need you to verify your information in order to prevent any disruption in service.",
  stripeActionRequiredOtherStripeContentI:
    "Our payment partner, Stripe, has notified us that they require additional information from you in order to continue processing online payments.",
  stripeActionRequiredOtherStripeContentII:
    "Please update your information or contact our customer success team for further assistance.",
  stripeActionRequiredOtherBulletI:
    "{{firstName}} {{lastName}} is currently listed as the primary representative for this merchant account.",
  stripeActionRequiredOtherBulletII:
    "For security reasons, Stripe may ask you to verify {{firstName}}’s date of birth and last 4 digits of the SSN.",
  stripeUpdateMerchantAccount:
    "In order to update your account information, we will redirect you to our payment processing partner Stripe.",
  identityVerification: "Identity Verification",
  identityVerificationDescription:
    "In order to start accepting online payments we require the identity of the business owner to be verified.",
  ownerListDescription: "We need to verify the identity of the following owner:",
  driversLicense: "Drivers License",
  pleaseNote: "Please Note:",
  sendLinkMessage:
    "This must be completed by the business owner listed below. If you are not the owner, you will be able to send them a text message with a link complete the verification process.",
  verifyIdentity: "Verify Identity",
  identityVerificationSubmitted: "Identity Verification Submitted",
  identityVerificationSubmittedDescription:
    "We are processing your request and let you know as soon as its been verified",
  identityVerificationRequired: "Identity Verification Required",
  identityVerificationFailed: "Identity Verification Failed",
  identityVerificationFailedDescription:
    "Your identity verification has failed. We will need additional documentation in order to approve your merchant account.",
  pleaseContactCustomerSupport: "Please contact customer support",
  createNewAccountTitleOption: "Create New Account",
  createNewAccountOption: "Create Account",
  adjustOpeningBalance: "Adjust Opening Balance",
  adjustOpeningBalanceDescription:
    "Please delete your past reconciliation data before updating your opening balance or add a general entry to adjust your opening balance",
  payoutBankAccount: "Payout Bank Account",
  maxPaymentLimit: "Max Payment Limit",
  maxMonthlyTotal: "Max Monthly Total",
  maxMonthlyPaymentPerLease: "Max Monthly Payment per Lease",
  maxMonthlyPaymentPerUnit: "Max Monthly Payment per Unit",
  maximumReceivableForLease: "Maximum receivable for lease",
  maximumReceivableForCard: "Maximum receivable for card",
  monthlyLimitsBreakdown: "Monthly Limits Breakdown ",
  monthlyUsage: "Monthly Usage %",
  downloadChromeModal: {
    title: "Download Google Chrome for the Best Check Printing Experience",
    description:
      "Looks like you’re using another browser. Chrome’s infrastructure supports the full extent of our check printing capabilities.",
    feature1: "Configure print settings for custom stationary.",
    feature2: "Save print settings for all future.",
    feature3: "Seamlessly print future checks in just a few clicks.",
    downloadChrome: "Download Chrome",
    maybeLater: "Maybe Later"
  },
  merchantAccountFeesDisclaimer: {
    name: "Merchant Account Fees Disclaimer",
    title: "Help cover your costs with Returned Payment Fees"
  },
  viewAllCategories: "View All Categories",
  hideAdditionalCategories: "Hide Additional Categories"
};
