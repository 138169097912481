import { withFormikDevtools } from "formik-devtools-extension";
import { useEffect, useRef } from "react";
import type { BaseDto } from "@doorloop/dto";
import type { FormikProps } from "formik";

// Chrome Extension -
// https://chromewebstore.google.com/detail/formik-devtools/dadeefbkfcpaeacnafgceahcpjlfmmjj?hl=en&pli=1
export function useFormikTools<T extends FormikProps<BaseDto>>(formik?: T) {
  const ref = useRef<T>();

  const init = (formikRef: T) => {
    if (process.env.NODE_ENV === "development") {
      withFormikDevtools(formikRef);
    }

    ref.current = formikRef;
  };

  useEffect(() => {
    if (!formik) {
      return;
    }

    init(formik);
  }, [formik]);

  return {
    ref,
    initFormik: init
  };
}
