"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantWithRentalApplicationsDto = exports.ContactDetailsDto = exports.DisplayTenantDto = exports.TenantDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const common_1 = require("../common");
const prospectInfo_dto_1 = require("./prospectInfo.dto");
const screeningService_enum_1 = require("./screeningService.enum");
class TenantDto extends common_1.PersonDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantDto = TenantDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.DependantDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "dependants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.PetDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "pets", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.VehicleDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "vehicles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.EmergencyContactDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "emergencyContacts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], TenantDto.prototype, "useUnitAddressAsPrimaryAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => prospectInfo_dto_1.ProspectInfoDto),
    __metadata("design:type", prospectInfo_dto_1.ProspectInfoDto)
], TenantDto.prototype, "prospectInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => common_1.PortalInfoDto),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", common_1.PortalInfoDto)
], TenantDto.prototype, "portalInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(common_1.isPersonPhoneOrEmailArrayNotEmpty, ["phones"]),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.EmailDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "emails", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(common_1.isPersonPhoneOrEmailArrayNotEmpty, ["emails"]),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.PhoneDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "phones", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TenantDto.prototype, "stripeCustomerId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], TenantDto.prototype, "acceptedOnTOS", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(screeningService_enum_1.ScreeningService),
    __metadata("design:type", Object)
], TenantDto.prototype, "screeningService", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => dto.screeningService === screeningService_enum_1.ScreeningService.OTHER),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TenantDto.prototype, "otherScreeningService", void 0);
class DisplayTenantDto extends TenantDto {
    constructor() {
        super();
        this.selected = false;
    }
}
exports.DisplayTenantDto = DisplayTenantDto;
class ContactDetailsDto {
}
exports.ContactDetailsDto = ContactDetailsDto;
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(common_1.isPersonPhoneOrEmailArrayNotEmpty, ["phones"]),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.EmailDto),
    __metadata("design:type", Array)
], ContactDetailsDto.prototype, "emails", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(common_1.isPersonPhoneOrEmailArrayNotEmpty, ["emails"]),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.PhoneDto),
    __metadata("design:type", Array)
], ContactDetailsDto.prototype, "phones", void 0);
class TenantWithRentalApplicationsDto extends TenantDto {
}
exports.TenantWithRentalApplicationsDto = TenantWithRentalApplicationsDto;
