"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiResultUtils = void 0;
const apiResult_1 = require("../apiResult");
class ApiResultUtils {
    isApiResult(obj) {
        return obj instanceof apiResult_1.ApiResult;
    }
}
exports.apiResultUtils = new ApiResultUtils();
