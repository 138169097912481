"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllPlaidTransactionsQuery = exports.PLAID_TRANSACTIONS_SORT_BY = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../../getAllBaseRequest.query");
const plaidTransactionStatus_enum_1 = require("./plaidTransactionStatus.enum");
const common_1 = require("../../common");
exports.PLAID_TRANSACTIONS_SORT_BY = {
    date: "date"
};
class GetAllPlaidTransactionsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllPlaidTransactionsQuery = GetAllPlaidTransactionsQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_plaidItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_plaidAccount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "auto_match_transaction", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(plaidTransactionStatus_enum_1.PlaidTransactionStatus),
    __metadata("design:type", String)
], GetAllPlaidTransactionsQuery.prototype, "filter_status", void 0);
