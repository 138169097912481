"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherJobTitles = exports.JobTitlesMarketing = exports.JobTitles = void 0;
var JobTitles;
(function (JobTitles) {
    JobTitles["PROPERTY_MANAGER"] = "Property Manager";
    JobTitles["COMPANY_OWNER"] = "Company Owner";
    JobTitles["PROPERTY_OWNER"] = "Property Owner";
    JobTitles["ACCOUNTANT"] = "Accountant";
    JobTitles["HOA_MEMBER_OR_COMMUNITY_MANAGER"] = "HOA Member or Community Manager";
    JobTitles["OTHER"] = "Other";
})(JobTitles || (exports.JobTitles = JobTitles = {}));
var JobTitlesMarketing;
(function (JobTitlesMarketing) {
    JobTitlesMarketing["PROPERTY_OWNER"] = "Property Owner";
    JobTitlesMarketing["PROPERTY_MANAGER"] = "Property Manager";
    JobTitlesMarketing["LANDLORD"] = "Landlord";
    JobTitlesMarketing["MANAGEMENT_COMPANY_OWNER"] = "Management Company Owner";
    JobTitlesMarketing["HOA_MEMBER_OR_MANAGER"] = "HOA Member or Manager";
    JobTitlesMarketing["OTHER"] = "Other";
})(JobTitlesMarketing || (exports.JobTitlesMarketing = JobTitlesMarketing = {}));
var OtherJobTitles;
(function (OtherJobTitles) {
    OtherJobTitles["TENANT"] = "Tenant";
    OtherJobTitles["ACCOUNTANT"] = "Accountant";
    OtherJobTitles["ATTORNEY"] = "Attorney";
    OtherJobTitles["OTHER"] = "Other";
})(OtherJobTitles || (exports.OtherJobTitles = OtherJobTitles = {}));
