import { Redirect, Route, Router } from "react-router-dom";
import { AppLayoutContextProvider } from "@/contexts/appLayoutContext";
import LayoutRenderer from "../layouts/layoutRenderer";
import { history } from "@/store/history";
import Routes from "./routes";
import DemoRouter from "./demoRouter";

// https://v5.reactrouter.com/
const AppRouter = () => (
  <Router history={history.history}>
    <AppLayoutContextProvider>
      <LayoutRenderer>
        <Redirect from="/" to={Routes.DEMO_ADDITIONAL_INFO} />
        <Route path={Routes.DEMO} render={DemoRouter} />
      </LayoutRenderer>
    </AppLayoutContextProvider>
  </Router>
);

export default AppRouter;
