"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTransactionsListReportQuery = void 0;
const class_validator_1 = require("class-validator");
const paymentMethod_enum_1 = require("../../journalEntries/paymentMethod.enum");
const leasePaymentDepositStatus_enum_1 = require("../../journalEntries/leasePayments/leasePaymentDepositStatus.enum");
const common_1 = require("../../common");
const journalEntryType_enum_1 = require("../../journalEntries/journalEntryType.enum");
const transactionsListReportGroupBy_1 = require("./transactionsListReportGroupBy");
class GetTransactionsListReportQuery {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetTransactionsListReportQuery = GetTransactionsListReportQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(journalEntryType_enum_1.JournalEntryType),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", Object)
], GetTransactionsListReportQuery.prototype, "filter_paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_dueDate_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_dueDate_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_reference", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_batch", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_vendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(leasePaymentDepositStatus_enum_1.DepositStatus),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_depositStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetTransactionsListReportQuery.prototype, "filter_deleted", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.EPayPaymentStatus),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_ePayStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "filter_propertyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetTransactionsListReportQuery.prototype, "filter_usePagination", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(transactionsListReportGroupBy_1.TransactionsListReportGroupBy),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetTransactionsListReportQuery.prototype, "groupBy", void 0);
