export default {
  loginResponseType: {
    hoaAccount: "HOA",
    rentalsAccount: "Rentals",
    tenantPortal: "Tenant Portal",
    ownerPortal: "Owner Portal"
  },
  for: "for",
  runningFrom: "running from",
  confirmAndViewReport: "Confirm & View Report",
  localRegulationCompliance: "Local regulation compliance",
  localRegulationComplianceDescription:
    '{{countyName}} County - {{reportName}} Report\n In this county, the {{reportName}} Report can only be viewed after you\'ve reviewed all other criteria and determined the applicant meets your standards. If the applicant qualifies based on the other criteria, click "Confirm & View Report" to access the {{reportName}} Report.',
  youNeedToCompleteTheSet: "Warning!\nYou need to complete the set up of this account to receive payments",
  setUpAccount: "Set Up Account",
  applyTheCompanysDefault: "Apply the company's default Rental Application fee settings to this property",
  defineCustomRentalApplicationFee: "Define custom Rental Application fee settings for this property.",
  apply: "Apply",
  noApplicationIdError: "No Application Id",
  requiredAction: "Required Action",
  connect: "Connect",
  requestPaymentFromApplicant: "Request payment from applicant",
  doorloopsScreeningFee: "DoorLoop’s screening fee $49.90 ",
  expiredRentalApplication: "Rental Application Expired",
  theApplicationForThisApplicationHasExpired:
    "The applicant's information is no longer valid.\n The rental application for this applicant has expired, and the information provided is outdated. \n To ensure accurate screening results, please request a new application.",
  requestNewApplication: "Request New Application",
  haveTheApplicantCoverTheirScreeningCosts: "Have the applicant cover their screening costs.",
  payForTheSelectedReportsYourself: "Pay for the selected reports yourself.",
  backgroundCheckPaymentPage: "Background Check Payment Page",
  secureYourRental: "Secure Your Rental: Complete Your Screening Payment",
  afterPaymentWellConduct:
    "After payment, we'll conduct a comprehensive background check, covering criminal history, credit score, and eviction history. This helps your property manager ensure you're the perfect fit for the property.",
  letsMakeYourDreamAReality: "Let's make your dream home a reality!",
  screeningReportsForApplication: "Screening reports for application {{applicationId}}",
  generateASharableLinkForMyBackgroundCheck: "Generate a shareable link for my background check",
  showReport: "Show Report >",
  screeningReports: "Screening Reports",
  firstLastNameReference: "{{firstName}} {{lastName}} Application #{{reference}}",
  theHighestRatedPropertyManagementSoftware: "The Highest-Rated Property Management Software",
  allInOneSoftware: "All-in-one software that is easy, secure, and reliable.",
  getOrganizedByKeepingTrack: "Get organized by keeping track of every lease and file in one place.",
  makeMoreMoneyWithAutomated: "Make more money with automated rent & fee collection using any payment method.",
  dynamicCommunications: "Dynamic communications center for smooth operations",
  requestADemo: "Request a Demo",
  reportsWillBeSentToTheAboveEmail: "The reports will be sent to the above email.",
  getASharableCopyOfYourScreeningReport: "Get a Shareable Copy of Your Screening Report!",
  submittedAt: "Submitted at: {{date}}",
  iWillCoverScreeningMyself: "I will cover screening myself",
  waitingForTheApplicantToPay: "Waiting for the applicant to pay",
  paymentSuccessful: "Payment Successful",
  confirmationNumberObject: "Confirmation Number: {{confirmationNumber}}",
  youCanNowCloseTheWindow: "You Can Now Close The Window",
  weWillNotifyYouWhenTheApplicantHasPaid: "We will notify you once the applicant has paid",
  cancelPaymentRequest: "Cancel Payment Request",
  sendReminderToTheApplicant: "Send Reminder To The Applicant",
  cancelApplicantPayRequest: "Cancel applicant pay request",
  thePaymentLinkWillNotBeValid:
    "The payment link will not be valid after canceling the request, but you will still be able to pay or send a new payment request.",
  openInApp: "Open in the DoorLoop app",
  couldNotLoadDataError: "Could not load the requested data",
  sendPaymentRequest: "Send Payment Request",
  sendPaymentRequestLowerCase: "Send payment request",
  weWillSendAnEmailToTheApplicant:
    "We will send an email to the applicant, containing a payment link for the TransUnion screening reports.",
  new: "New",
  used: "used",
  spent: "Spent",
  received: "Received",
  remaining: "Remaining",
  sendPortalInvitation: "Send portal invitation",
  globalSearchPlaceholder: "Search anything {{ searchShortcut }}",
  reset: "Reset",
  profile: "Profile",
  xSelected: "{{ x }} Selected",
  months: {
    full: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December"
    },
    partial: {
      january: "Jan",
      february: "Feb",
      march: "Mar",
      april: "Apr",
      may: "May",
      june: "Jun",
      july: "Jul",
      august: "Aug",
      september: "Sep",
      october: "Oct",
      november: "Nov",
      december: "Dec"
    }
  },
  quarters: {
    q1: "Q1",
    q2: "Q2",
    q3: "Q3",
    q4: "Q4"
  },
  createdByOn: "Created by {{who}} on {{date}} at {{time}}",
  updatedByOn: "Updated by {{who}} on {{date}} at {{time}}",
  system: "System",
  loadingRentalApplicationGroupFailed: "Failed to load rental application group",
  saveAndClose: "Save and Close",
  duplicate: "Duplicate",
  duplicated: "Duplicated",
  widget: "Widget",
  tenantPageWidgets: {
    titles: {
      balanceDue: "Balance Due",
      invalidEmails: "Invalid Emails",
      missingContactInfo: "Missing Contact Info",
      notSignedToThePortal: "Didn't Sign Into The Portal"
    },
    tooltips: {
      balanceDue: "Click to see all tenants with outstanding balances",
      invalidEmails: "Click to see all users with invalid emails",
      missingContactInfo: "Click to filter out all the tenants with missing contact information.",
      notSignedToThePortal: "Click to filter out all the tenants who didn’t sign into the tenant portal."
    },
    tenants: "Tenants",
    seeTenants: "View Tenants"
  },
  everyApplicantWillBeCharged:
    "This fee will be charged to all applicants unless adjusted at the property or unit level.",
  addressAutoCompleteError: "Address auto completion failed.",
  searchingAddress: "Searching Address ...",
  noCountriesFound: "No Countries Found",
  identityNumber: "Identity Number",
  identityNumberUS: "SSN",
  identityNumberCA: "SIN",
  payee: "Payee",
  filter: "Filter",
  paymentReminderSent: "Payment reminder sent",
  portalInvitationSent: "Portal invitation sent",
  downloadFailed: "Download Failed",
  downloadSucceeded: "Download Succeeded",
  downloadDepositSlip: "Download Deposit Slip",
  drawerExpand: "Expand",
  drawerCollapse: "Collapse",
  saveYourRentalApplicationBeforeSaving: "Save your application before closing",
  savingItAllowsYouToResume: "Saving it now allows you to resume your application at a more convenient time.",
  bulkUpload: "Bulk Upload",
  current: "Current",
  past: "Past",
  future: "Future",
  addContactInfo: "Add contact info",
  invalidEmailStatusTooltip: "Invalid email address",
  reviseEmailAddress: "Revise email address",
  allSetForNow: "All set for now",
  createNewLease: "Create New Lease",
  sendRentReminder: "Send rent reminder",
  sendTenantPortalInvitation: "Send tenant portal invitation",
  tenantLoginToPortal: "Tenant log in to portal",
  tenantSetupPaymentMethod: "Tenant set up payment method",
  availableOnlyOnDesktop: "Available only on desktop",
  screeningStatus: "Screening Status",
  recordAsLeasePayment: "Record As Lease Payment",
  confirmAndPay: "Confirm & Pay",
  feeAmount: "(${{amount}} Fee)",
  noFeeBrackets: "(No Fee)",
  unknown: "Unknown",
  propertyAddressNotValid: "Property address not valid",
  deductScreeningCostText: "Include screening costs in the fee by default. ",
  missingQueryParamsErrorText: "Missing required query params",
  manageFee: "Manage Fee",
  internalNotes: "Internal Notes (Private)",
  importantLegalNote: "Important Legal Notice:",
  reasons: "Reasons",
  addInternalNotesHere: "These notes will not be visible to the applicant",
  provideAReason: "Specify your reasons for the decision",
  sendAutomatedEmail: "Send automated response email to the applicant with my decision.",
  lastDecisionEmailSend: "{{decisionStatus}} email sent at {{date}}",
  approved: "Approved",
  rejected: "Rejected",
  rentalApplicationEnums: {
    paymentOptions: {
      applicantBoldStart: "All-in-One Solution",
      applicantEnd: `No merchant account needed; applicants pay {{currencySymbol}}{{screeningFeeAmount}} for reports.`,
      userBoldStart: "Application-Only Plan",
      userEnd: "Free for applicants; you cover costs upon reports request.",
      customBoldStart: "Custom Fee Plan",
      customEnd: "Set your fee and choose when to request screening reports.",
      noFee: "No Fee",
      noFeeEnd: "The applicant will not be charged",
      customYourFeeBoldStart: "Customize Fee",
      customYourFeeEnd: "Adjust the fee to cover expenses or generate extra income",
      applicationOnlyPlanStart: "Application-Only Plan"
    }
  },
  noSetupNeeded: "No additional setup or merchant account required",
  applicantsWillPay: "Applicants pay $49.90 for their background check",
  chooseWhenToRequest: "Choose when to request TransUnion screening reports for an applicant",
  getTheScreeningReportsInstantly: "Access comprehensive criminal, eviction, and credit reports from TransUnion",
  freeForApplicants: "Free and unlimited application form submissions",
  screeningUponPostFeePayment: "Screening not included; reports available for a fee upon request",
  setACustomFeeToGenerate: "Set a custom fee to generate revenue and cover or offset platform costs.",
  useTheFeeAsAFilter:
    "Use the fee as a filter to identify serious applicants who are genuinely interested in your property.",
  selectTenantsBasedOnHowSuitableTheyAreForYourProperty:
    "Select tenants based on how suitable they are for your property.",
  merchantAccountNeeded: "Merchant account required",
  pleaseAttachAnyFilesSupportingYourDecision: "Please attach any file supporting your decision",
  setYourPreferredFee: "Set custom fees to generate revenue and choose to cover or offset screening costs",
  feeSettings: "Fee Charged to Applicants",
  safeguardYourInvestmentWithDoorLoop: "Safeguard Your Investments with DoorLoop",
  receivingApplications: "Receiving Applications",
  openUnitForRentalApplication: "Open for Applications",
  closeUnitForRentalApplication: "Close for Applications",
  rentalApplicationMissingMerchantAccountError: "Rental application misconfigured\nPlease contact the landlord",
  rentalApplicationListingTitle:
    "Control unit presentation and application availability - allow applicants to submit rental applications for the unit.",
  rentalApplicationListingDescription: "Application Submission",
  listOnYourCompanySite: "List on your company site",
  maximumVisibilityAndAttractIdeal:
    "Maximize visibility and attract ideal renters by listing your commercial office on sites designed with DoorLoop's custom listing module. Toggle on to enable this feature for your DoorLoop-customized site.",
  listThisUnitOnMyCompanySite: "List This Unit On My Company Site",
  commercialListing: "Commercial Listing",
  rentalApplicationListingHandlerText: "Show Unit & Accept Applications",
  rentalApplicationAvoidLossesAndHeadaches:
    "Avoid losses and headaches. Manage applications and conduct in-depth screenings through TransUnion credit, criminal and eviction reports with DoorLoop",
  rentalApplicationMadeEasyWithDoorLoop: "Rental Application Made Easy with DoorLoop",
  rentalApplicationLearnMoreAboutDLScreeningProcess: "Learn more about DoorLoop's screening process",
  inviteTenantsAndProspectsToApply: "Invite Tenants and Prospects to Apply",
  rentalApplicationStreamlinedProcess:
    "We've streamlined the rental application process, consolidating everything into one convenient dashboard.",
  rentalApplicationEffortlessly:
    "Effortlessly choose prospects for screening and access their background check reports with ease.",
  criminalHistory: "Criminal History",
  summarizedCreditRisk: "Summarized Credit Risk",
  evictionRecord: "Eviction Record",
  browseReportSamples: "Browse Report Samples",
  open: "Open",
  closed: "Closed",
  opened: "Opened",
  fillRequiredFields: "Please fill required fields",
  rentalApplicationCouldNotHandleOpenForApplicationError: "Could not update unit application status",
  openForApplication: "Open for applications",
  closeForApplication: "Close for applications",
  applicationWasAlready: "Application was already {{status}}",
  rentalApplicationMandatorySettingsSection:
    "This section cannot be taken out and must be included in the rental application for screening purposes.",
  rentalApplicationPropertyLevelCustomizeTitle: "You can customize the rental Application Fees for this property",
  rentalApplicationSettingsEnabledFieldTextRequired: "Please enter a text for the enabled field",
  rentalApplicationSettingsSectionFieldsRequired: "Please enable at least one of the fields, or hide this section",
  rentalApplicationCustomFeeMerchantAccountNote:
    "Please setup your merchant account first if you want to set a custom application fee.",
  rentalApplicationManageApplication: "Manage Application Fees",
  rentalApplicationManageApplicationDescription:
    "Choose your ideal settings and fee plan with flexible solutions for you and your applicants.",
  creditCardFee: "Credit card fee: ${{feeAmount}}",
  totalChargeForApplicant: "Total cost for applicant ",
  totalChargeForApplicantCustomFee: "Total cost for applicant - custom application fee",
  rentalApplicationCustomChargeDescription: "Every application is charged this fee - Unless there's an override",
  verifyLocalFeeRegulations: "Check local rental application fee regulations to stay compliant.",
  applicationFeeIsNotApplicable: "Application fee is not applicable or available in your country",
  propertyAccountBankSettings: "See the property bank accounts list.",
  applicationFeeApplyMerchantAccount:
    "To keep things running smoothly, ensure each property has its own merchant account.",
  transunionNoteBasedOnPlan:
    "Note that the Transunion fee is based on your subscription plan. ({{planName}}: ${{planPrice}}).",
  byUsingRentalApp: "By using the rental application, ",
  screeningReportsAutomaticRequest: "When included in the application cost, reports are accessible without request.",
  transunionReportDescription: "Gain valuable insights and unlock a new income opportunity with TransUnion Reports ",
  downloadPrintable: "Download Printable Version",
  downloadPDF: "Download PDF",
  sendReportViaEmail: "Send Report via Email",
  downloadReport: "Download Report",
  saveBeforeDownloading: "Make sure to save changes before downloading",
  mandatory: "Mandatory",
  noDataFound: "No Data Found",
  verifyCompanyAddress: "Company Address Verification",
  missingCompanyAddressDescription:
    "To begin using the rental application feature, update your company address and ensure all information is accurate. Keep in mind that addresses outside the US are not applicable.",
  verifyCompanyAddressButton: "Verify Company Address",
  verifyPropertyAddress: "Property Address Verification",
  missingPropertyAddressDescription:
    "To begin using the rental application feature, update your property address and ensure all information is accurate. Keep in mind that addresses outside the US are not applicable.",
  verifyPropertyAddressButton: "Verify Property Address",
  notAvailableAbbreviated: "N/A",
  reportsWillBeRequested: "Reports will be requested once the application is submitted",
  reportsWillNotBeRequested: "Reports will not be requested when application is submitted",
  applicationResent: "The application was sent.",
  bankTransferAch: "Tenants can pay with bank transfer (ACH)",
  paginationStatus: "Showing {{currentItems}} of {{totalItems}} items",
  paymentMethodLearMoreContent: "Set which payment method will be available for your tenants in the tenants portal.",
  lessee: "Lessee",
  applicant: "Applicant",
  invalidPhoneNumber: "Please enter a valid phone number",
  selectAll: "Select All",
  invalidEmail: "Please enter a valid email address",
  attention: "Attention",
  addEmail: "Add Email Address",
  addPhone: "Add Phone Number",
  properties: "Properties",
  searchProperties: "Search Properties",
  createdBy: "Created By",
  createdAt: "Created At",
  updatedBy: "Updated By",
  updatedAt: "Updated At",
  account: "Account",
  restrictedOnMobile: "We are working on making this page mobile friendly. Please use the desktop version for now.",
  restrictedOnMobileSubscription: "Please use the desktop version to subscribe.",
  restrictedOnMobileHeader: "Page not available",
  restrictedOnMobileSubscriptionHeader: "Subscription Not Available",
  platformName: "doorloop",
  copyright: "Copyright © DoorLoop, Inc.",
  companyName: "DoorLoop",
  dismiss: "Dismiss",
  discard: "Discard",
  keepEditing: "Keep editing",
  retry: "Retry",
  unit: "Unit",
  help: "Help",
  units: "Units",
  title: "Title",
  note: "Note",
  description: "Description",
  createdOrEditedBy: "Created / Edited By",
  uploadedOrEditedBy: "Uploaded / Edited By",
  xResultFound: "{{x}} result found",
  xResultsFound: "{{x}} results found",
  noMatchesFound: "No Matches Found",
  noResultsFoundDescription: "Nothing found. Please check your filters and try again.",
  noResultsFoundSubText: "Please check your filters and try again.",
  noResultsFoundMainText: "Nothing Found",
  inputNewSearchAndTryAgain: "Please input a new search and try again.",
  expandOptionsLinkText: "Show more options",
  noResultsFound: "Nothing found",
  noResultsYet: "There are no results. Press the add button to get started.",
  addNew: "Add New",
  send: "Send",
  sendNow: "Send Now",
  makeActive: "Make Active",
  view: "View",
  viewRequest: "View Request",
  deleteRequest: "Delete Request",
  businessTitle: "Business Title",
  inactiveConfirmation: "Make Inactive Confirmation",
  activeConfirmation: "Make Active Confirmation",
  activate: "Activate",
  save: "Save",
  setUpFee: "Set Up Fee",
  exportStatement: "Export Statement",
  downloadInvoice: "Download Invoice",
  sendReceipt: "Send Receipt",
  downloadReceipt: "Download Receipt",
  cancel: "Cancel",
  openNewTab: "Open new tab",
  edit: "Edit",
  resume: "Resume",
  delete: "Delete",
  reverse: "Reverse Payment",
  close: "Close",
  back: "Back",
  submit: "Submit",
  next: "Next",
  supportedFileTypesSuffix: "no larger than {{size}} MB.",
  noFilesFound: "No Files Found",
  thisFolderIsEmpty: "This Folder is Empty",
  operationInprogress: "Operation In Progress",
  deleteConfirmation: "Delete Confirmation",
  cancelConfirmation: "Cancel Confirmation",
  voidConfirmation: "Void Confirmation",
  error: "Error",
  networkErrorTitle: "Error",
  credentialsError: "Invalid email or password. Please try again.",
  networkErrorSubTitle: "Can't connect. Please check your internet connection and try again.",
  generalError: "Something went wrong. Please try again later or contact us.",
  notSupportedFileError: "File {{fileName}} is not supported.",
  multipleNotSupportedFileError: "Please upload only 1 file.",
  fileAlreadyExistError: "The file {{fileName}} could not be uploaded. Only one file can be uploaded at a time.",
  multipleNotSupportedTypesError: "Unsupported files were added. Please review the files and try again.",
  operationCompleted: "Operation completed",
  noMoreResults: "No More Results",
  textNotificationsWillUseCommunicationCredit:
    "Each notification sent via text message will use one communication credit.",
  loading: "Loading",
  loadingSlow: "This is taking longer than expected. Please wait a few more seconds.",
  uploadLoadingText: "Uploading files, please wait.",
  operationFailed: "Failed to load the request, please try again",
  operationSucceeded: "Loading completed",
  enabled: "Enabled",
  disabled: "Disabled",
  required: "Required",
  ifRented: "If Rented",
  field: "Field",
  notRequired: "Not Required",
  createNew: "Create New",
  goBack: "Go Back",
  splitValues: "-- Split Values --",
  yes: "Yes",
  no: "No",
  none: "None",
  custom: "Custom",
  viewAllOptions: "View Settings",
  clickHere: "Click here",
  learnMore: "Learn more",
  tenantPortalSettingsSections: {
    allowTenantToAccessTheTenantPortal: "Allow tenants to access the tenant portal",
    next: "Next",
    continue: "Continue",
    saveAndClose: "Save & Close",
    tenantPortalSettings: "Tenant Portal Settings",
    permissions: "Permissions",
    contactInfo: "Contact Info",
    portalCustomization: "Customization",
    portalInvites: "Portal Invites",
    instructions: "Add instructions for tenant requests.",
    tenantCanPayWithCreditCard: "Tenants can pay with credit card",
    addInstructions:
      "Add instructions for tenant requests. It will be displayed when new requests are created at the tenant portal.",
    customization: {
      title: "Customize",
      logo: {
        upload: "Upload Your Logo",
        size: "Recommended size: 300x100px",
        fallback: "No logo yet? Your company name will be displayed instead."
      },
      background: {
        upload: "Upload Background Image",
        size: "Recommended size: 1920x1080"
      },
      color: {
        choose: "Choose Theme Color"
      },
      url: {
        edit: "Edit Portal URL",
        suffix: ".app.doorloop.com"
      },
      preview: "Preview"
    },
    phone: "Phone",
    email: "Email",
    phoneNumberAllCapitalized: "Company Phone Number",
    showHideToggle: "Show/hide to tenant",
    doorloopPhoneNumber: "DoorLoop Phone Number",
    companyEmail: "Company Email",
    companyAddress: "Company Address"
  },
  getShareableLink: "Get Shareable Link",
  toLearnMore: "to learn more",
  comingSoon: "Coming Soon",
  period: "Period",
  search: "Search",
  logOut: "Log Out",
  notSet: "Not Set",
  personalSettings: "Profile",
  changeYourPassword: "Security",
  editSubscription: "Edit Subscription",
  gettingStarted: "Getting Started",
  knowledgeBase: "Knowledge Base",
  formsAndTemplates: "Forms & Templates",
  printPreparingMessage: "We are preparing your document for printing. Please wait...",
  show: "Show",
  hide: "Hide",
  scheduleDemo: "Schedule Demo",
  scheduleADemo: "Schedule a demo",
  assignee: "Assignee",
  yourTrialExpiresIn: "Your trial expires in",
  yourTrialExpired: "Your trial expired",
  chooseAPlan: "Choose a Plan",
  allocate: "Allocate",
  continue: "Continue",
  oneUnit: "1 Unit",
  xUnits: "{{x}} Units",
  zeroBalance: "Zero Balance",
  days: "days",
  day: "day",
  scheduleDemoAdditionalinfoDocumentTitle: "Schedule Demo - Additional Info",
  askUsAnything: "Ask us anything",
  doorloopUniversity: "DoorLoop University",
  average: "Average",
  acceptTOS:
    "By clicking here, I confirm that I have read and agree to DoorLoop's <0>{{tosLink}}</0> and <1>{{privacyPolicyLink}}.</1>",
  mustAcceptTOS: "You must accept the terms of service before proceeding.",
  bySigningUp: "By signing up, I accept the DoorLoop",
  termsOfService: "Terms of Service",
  rapidRent: "Rapid Rent",
  rapidRentTermsOfService:
    "By clicking here, I confirm that I have read and agree to terms and conditions of <0>{{tosLink}}</0>.",
  privacyPolicy: "Privacy Policy",
  onlinePaymentFees: "Fees Settings",
  returnedPaymentFees: "Returned Payment Fees",
  returnedPaymentFee: "For bank payments, the returned payment fee is a minimum of ${{amount}} for insufficient funds.",
  zeroReturnedPaymentFee: "Fees may apply in case of insufficient funds.",
  byMakingPayment: "By making a payment, you agree to the",
  terms: "Terms",
  privacy: "Privacy",
  cookie: "Cookie",
  policies: "policies",
  xItemNameSelected: "{{x}} {{itemName}} Selected",
  printChecks: {
    alert: {
      title: "We’re sorry, but printing checks currently isn't supported in your region/currency.",
      subTitle: "Want us to add support for your country?"
    },
    helpPanel: {
      learnMorePrintChecks: "How to print checks",
      reprintChecks: "How do I reprint checks I already printed?",
      description: "Print checks to pay your vendors & owners. You can also give tenants back their deposits.",
      dialogTitle: "Printing Checks"
    },
    preview: {
      header: "Checks Preview",
      mode: "Print Mode",
      dialog: {
        action: "Done",
        reminder: {
          header: "reminder",
          body: "Don’t forget to sign your checks!"
        },
        question: "Did you download the PDF and print the checks?",
        header: "Printed checks review",
        done: "Yes, all is good!",
        notDone: "No, keep the checks.",
        checkNumber: "First Check Number",
        error: "Something went wrong while proccessing your printed checks",
        firstCheckValidation: "Please Enter the number of your first check"
      }
    },
    setup: {
      menuItems: {
        step1: "Print a Test",
        step2: "Make Adjustments"
      },
      action: "Save",
      cancel: "Cancel",
      sample: {
        loadPaper: {
          header: "Insert Paper",
          subHeader: "Insert a regular white paper in your printer"
        },
        printSample: {
          header: "Print",
          subHeader:
            "After clicking Save, click “Print a Test“ to see how it looks. In the Print window, click “More Settings“ and set the Scale to “Default” or “Actual Size”. Set the Margins to “Default”. Uncheck the boxes for “Headers and footers“ and “Background graphics“. Do not print on both sides"
        },
        printAlign: {
          header: "Adjust",
          subHeader:
            "Put the test page you just printed on top of a real check. Hold it up to the light to see if it lines up. If adjustments are needed, click the “Make Adjustments“ page on the left."
        },
        header: "Select a Check",
        subHeader: "Which check are you using?",
        voucher: "Voucher (1 Per Page)",
        standard: "Standard (3 Per Page)",
        description:
          "We recommend printing a test using a regular sheet of white paper to see if the text lines up so you don’t waste any real checks."
      },
      reader: {
        description: {
          part1: `Any time you print on a preprinted form, it can be tricky to get the text in the right spot. `,
          part2: `Using Adobe Reader for printing checks gives the most consistent printing, with much fewer adjustments needed.`,
          part3: ` Don’t worry, you'll only need to do this once. `
        },
        download: {
          header: "Download and Install",
          subHeader: `Download the latest version of the Reader. It’s free.
          Already using Reader? Make sure you’re using the latest version.`
        },
        default: {
          header: "Set as Default",
          subHeader: "Set Reader as the default viewer for PDFs for you browser.",
          needHelp: "Need Help?"
        }
      },
      adjust: {
        description: {
          part1:
            "Printing with Reader? In the preview, click the printer icon and set these options in the Paper Size & Handling section:",
          bullet1: "Actual size",
          bullet2: " Choose paper source by PDF page size",
          bullet3: "Auto portrait/landscape",
          part2: `This is where you make adjustments to correctly align your printer with your checks`
        },
        settings: {
          header: "Adjustment Settings",
          text: `You can only make adjustments for the height. Enter a number usually from 1-100. The higher the number, the more the check will move down the page`,
          gridPreset: "Move the check down or up the page",
          horizontal: "Horizontal Position",
          vertical: "Height Adjustment",
          checkInnerVertical: "Check inner space adjustment",
          printTest: "Print a New Test"
        }
      }
    },
    balance: "Account Balance",
    checksSelected: "Checks Selected",
    selectedTotal: "Selected Total",
    actionPanel: {
      print: "Print",
      save: "Save",
      action: "Preview Checks",
      cancel: "Cancel",
      setup: "Print Setup",
      order: "Order Checks"
    },
    noSelection: {
      title: "Please select an account",
      text: "Please select the account with your checks"
    },
    emptyscreen: {
      title: "There are no checks in the print queue for the selected account",
      text: "There are no checks in the print queue for the selected account",
      button: "Add Check"
    },
    lexicalAmount: "{{amount}} and {{fraction}}/100 ****",
    removeFromQueue: "Remove from Queue"
  },
  subscription: {
    onboardingDiscountDescription: "50% Off Onboarding",
    saveUpTo: "SAVE UP TO 20%",
    maxUnitsMessage: "Max 160 units",
    updateCreditCard: "Update Credit Card",
    updateCard: "Update Card",
    plans: {
      basic: "Starter",
      standard: "Pro",
      pro: "Premium"
    },
    settings: {
      header: "Subscription",
      text: "Change or update your subscription plan."
    },
    unitsError: {
      text1: "you currently have",
      text2: "active units, please enter a number larger than"
    },
    errorMessage: "We haven't been able to retrieve your subscription.",
    loadingMessage: "loading your subscription data...",
    title: "Subscription",
    subTitle: "Subscribe to a plan",
    freeUntil: "50% OFF 2 MONTHS",
    headerDescriptionText: "Get started with a low one-time onboarding fee and enjoy your first 2 months at 50% off.",
    unitCountHeader: "Enter your unit count",
    unitCountSubHeader: "How many units / doors do you manage in your portfolio?",
    startFreeHeader: "Select a plan",
    startFreeSubHeader: "Choose a plan that works best for you.",
    generalInfo: `You will not get charged until January 01, 2022.
    You can cancel anytime before or after from this page. `,
    payPerMonth: "Per Month (Billed Monthly)",
    payPerYear: "Per Month (Billed Annually)",
    startPaying: "Start Paying in",
    faqHeader: "FAQ",
    currentPlan: "Current Plan",
    comparePlans: "Compare Plans",
    view: {
      subTitle: "Subscription Details",
      payment: {
        error: {
          generalError: "There was a problem while updating your credit card",
          header: "YOUR PAYMENT DID NOT GO THROUGH",
          body: "Your credit card was declined, please update your payment information to avoid service interruption"
        },
        expiry: "Expiry Date",
        updateCard: "Update Payment Method",
        header: "Payment Method",
        last: "Last Payment",
        next: "Next Payment",
        on: "on"
      },
      details: {
        plan: "Plan",
        planPrice: "Plan Price",
        activeUnits: "Active Units",
        planUnits: "Plan Units",
        updatePlan: "Update Plan"
      }
    },
    checkout: {
      errors: {
        success: "Your subscription was created successfully",
        loading: "Your subscription is being processed... please wait a few moments",
        failed: "An unexpected error has occurred while processing your subscription"
      },
      footer: {
        acceptpayment: "Accepted Payments Methods",
        moneyback: "Money Back Guarantee",
        secure: "SSL Secure Payment",
        secureinfo: "Your information is protected by 256-bit SSL encryption.",
        moneybackinfo: "Try DoorLoop risk-free with a 30 day money-back guarantee (excluding the onboarding fee)."
      },
      summary: {
        header: "SUMMARY",
        permonth: "mo",
        peryear: "year",
        onboarding: "One Time Onboarding",
        dueOnMonthly: "Due Monthly on",
        dueOnAnually: "Due Annually on",
        dueToday: "Due Today",
        proRataCredit: "Pro-Rata Credit",
        creditCardError: "Credit Card Number is missing or invalid",
        create: "Subscribe",
        update: "Update Subscription",
        safeAndSecure: "Safe & Secure Payment",
        moneyBack: "30 Day Money Back Guarantee",
        discountApplied: "This promotion has been applied.",
        discountAppliedAmount: "The amount due already reflects this promotion.",
        agreetoterms: {
          by: "By subscribing, you agree to the",
          and: "and",
          policies: "policies",
          terms: "Terms",
          privacy: "Privacy"
        }
      },
      details: {
        yourPlan: "YOUR PLAN",
        plan: "Plan",
        billed: "Billed",
        change: "Change",
        units: "Units"
      },
      coupon: {
        apply: "Apply Coupon",
        header: "PROMO COUPON",
        error: {
          message: "This coupon is expired or invalid for the selected plan",
          header: "INVALID PROMO COUPON"
        }
      },
      payment: {
        button: "Add Credit Card",
        header: "PAYMENT METHOD",
        subHeader: "Card Details",
        saveForLater: "Save this payment information for future use."
      },
      title: "Subscription",
      subTitle: "Checkout"
    },
    button: {
      getStarted: "Get Started",
      update: "Update Plan",
      upgrade: "Upgrade Plan",
      downgrade: "Downgrade Plan"
    },
    faq: {
      questions: {
        whatIsDoorloop: "What is DoorLoop?",
        whatProperty: "What properties can I manage?",
        whoFor: "Who is DoorLoop for?",
        mostPopular: "What are the most popular features?",
        isEasy: "We are not computer savvy, is it easy to use?",
        fromPhone: "Can I use DoorLoop from my phone?",
        whatProcess: "What is the process to get started?",
        moreQuestions: "I have more questions!",
        moreQuestionsAlt: "I have more questions.",
        upgradePlan: "How do I upgrade my plan?",
        downgradePlan: "How do I downgrade my plan?",
        moreUsers: "How do I add more users?",
        moreUnits: "How do I add more units?",
        prevPayments: "How do I view my previous payments and invoices?",
        exportInfo: "How do I export my information?",
        accountOnHold: "Can I put my account on hold?",
        ifCancel: "If I cancel, will I lose my information?",
        cancelPlan: "How do I cancel my plan?"
      },
      answers: {
        upgradePlan: {
          text: `Click the "Update Plan" button on the top of this page and click the "Upgrade Plan" button for the new plan you would like to use. `
        },
        downgradePlan: {
          text1: `Contact customer support for further assistance`,
          text2: `Please keep in mind, you will not be able to downgrade if you used certain features not available on that plan (like user access roles, bank account reconciliation, or the API for example).`
        },
        moreUsers: {
          text: `Click the "Create New" button on the left and select "User". You are able to add as many users as you like for no additional charge.`
        },
        moreQuestionsAlt: {
          text: `If you have any more questions, please contact us via the chat bubble on the bottom right.`
        },
        prevPayments: {
          text: `Right above the FAQs on this page, you will be able to see your next payment and your last payment. To see all your previous payments, please check your email for the monthly email we send you with your payment details.`
        },
        exportInfo: {
          text: `Click on "Reports" on the left, select any Report, and click the Excel or Print icon on the top right to export it.`
        },
        moreUnits: {
          text: `Click the "Update Plan" button on the top of this page and enter the total number of units you would like on the top right. Click the "Update Plan" button under the plan you are on.`
        },
        ifCancel: {
          text: `
          You will not be able to view any information once your account is canceled, so we highly recommend exporting your data first. If you reactivate your account within 1 year (or longer), you will be able to view all historical data.
          `
        },
        cancelPlan: {
          text: `We are sad to see you go! If you would like to cancel your plan, please reach out to your Account Executive or contact us via the chat bubble on the bottom right.`
        },
        accountOnHold: {
          text: `To put your account on hold, you will have to cancel your account and then reactivate it later. Your data may not remain in the software forever, so it’s important to export your information just in case. If you have any promotions, they will also not reapply when you reactivate. If you need any help, please contact us via the chat bubble on the bottom right.`
        },
        whatIsDoorloop: {
          text1: `DoorLoop is the easiest property management software that helps manage and grow your business. Managing someone else’s portfolio? DoorLoop has you covered too.`,
          text2: `Our goal is to make your life easier so you get more done in less time, increase occupancy and profitability while decreasing turnover and workload. Soon you’ll have the time and energy to focus on what matters most.`
        },
        useFromPhone: {
          text1: `Yes, DoorLoop is 100% mobile friendly and responsive to every device. Simply open your regular browser on your phone (Safari, Chrome, etc…) and login to DoorLoop. You can save a bookmark to your home screen for even faster access.`,
          text2: `A dedicated mobile app for iPhone and Android devices is coming soon.`,
          text3: `Now you can work and access all of your properties remotely no matter where you are in the world.`
        },
        whoIsDoorloopFor: {
          text1: `DoorLoop is built for property managers, management companies, owners, landlords, investors, tenants or anyone managing any property worldwide.`,
          text2: `Most of our members are frustrated with how they currently manage their business. They want to be more organized, generate custom reports with ease, and increase profits without increasing their expenses.`,
          text3: `With unlimited customization and flexibility, DoorLoop is perfect for anyone just getting started with 1 property, or large companies managing thousands.`
        },
        isEasy: {
          text1: `Our world-class designers built the most beautiful and user friendly software imaginable. If you can use a mobile phone, you can use DoorLoop. `,
          text2: `Anyone, regardless of age or computer experience, can use DoorLoop. In fact, most of our members don’t even ask for training, but we still recommend 1 hour of free basic training.`,
          text3: ` We promise, by the end of week one, you and your team will know exactly how to manage all of your tenants, properties, units, leases, renewals, and even work orders and vendors. We know new software can be scary, but we’ll be there holding your hand every step of the way.`
        },
        whatProperty: {
          text1: "If it has a door, you can manage it with DoorLoop!",
          text2: "Manage any combination of properties and spaces, for example:",
          list: {
            l1: {
              header: "Residential properties: ",
              content: "Single Family, Multi-Family, Condo, Townhouse, Apartment"
            },
            l2: {
              header: "Commercial properties: ",
              content: "Industrial, Office, Retail, Shopping Center, Storage, Parking, Co-Working, Parking Spaces"
            },
            l3: {
              header: "Student Housing"
            },
            l4: {
              header: "Mobile Homes"
            },
            l5: {
              header: "Affordable & Workforce Housing"
            },
            l6: {
              header: "Community Associations & HOAs"
            }
          }
        },
        whatFeatures: {
          text1: "With DoorLoop, you can:",

          list: {
            l1: {
              content: "List and market your units online to increase occupancy "
            },
            l2: {
              content: "Screen tenants and run background checks "
            },
            l3: {
              content: "Offer paperless online applications to prospects"
            },
            l4: {
              content: "Automatically fill and send lease agreements with eSignatures"
            },
            l5: {
              content: "Offer renters insurance"
            },
            l6: {
              content:
                "Collect rent online by ACH (eCheck, wire transfer), debit card, credit card, cash with DoorLoop’s RapidRent"
            },
            l7: {
              content: "Offer a tenant portal with maintenance requests and communications "
            },
            l8: {
              content: "Offer an owner’s portal with custom reports "
            },
            l9: {
              content: "Manage your vendors and work orders"
            },
            l10: {
              content: "Send automated renewal agreements to reduce turnover"
            },
            l11: {
              content: "Manage your bookkeeping and accounting to remove duplicate data entry in QuickBooks"
            },
            l12: {
              content: "Accounts Receivable, Accounts Payable and many more financials"
            },
            l13: {
              content: "Run unlimited custom reports in seconds"
            },
            l14: {
              content: "And much more"
            }
          },
          link: {
            text1: "Visit our",
            href: "Features ",
            text2: "page to learn more."
          }
        },
        whatIsTheProcess: {
          text1: "After years of trial and error, we came up with the Perfect Portfolio Plan:",

          list: {
            l1: {
              link: {
                text1: "Step 1:",
                href: "Schedule a demo ",
                text2: "or sign up for your free trial."
              }
            },
            l2: {
              content:
                "Step 2: If you’re using another software, import all your data into DoorLoop with our dedicated migration team."
            },
            l3: {
              content: "Step 3: Complete a 1-hour basic training."
            },
            l4: {
              content:
                "Step 4: Add all your properties, units, tenants, and lease expirations. Email your tenants the link to their new tenant portal for payments, maintenance requests, and messages. Enable the owner portal for financial reports."
            }
          },
          link: {
            text1: "It's really is that simple. ",
            href: "Click here ",
            text2: "to schedule your demo and get started."
          }
        },
        moreQuestions: {
          link1: {
            text1: "Please",
            href: "click here ",
            text2: "to send us a message, and we promise to respond within 24 hours guaranteed."
          },
          link2: {
            text1: "You can also",
            href: "click here ",
            text2: "to schedule a private virtual demo."
          }
        }
      }
    }
  },
  validation: {
    required: "The field is required",
    invalid: "This value is not valid",
    failed: "Validation failed"
  },
  allLeases: "All Leases",
  pageNotFound: {
    header: "Page not found",
    subHeader: "It appears the page you've been looking for could not be found",
    errorHeader: "Something went wrong",
    errorSubHeader: "Please try again or contact us.",
    buttonText: "Go to homepage"
  },
  balance: "Balance",
  tenantPortalStatus: "Tenant Portal Status",
  action: "Action",
  filterOptions: {
    tenantPortalStatus: "Tenant Portal Status",
    budgetType: "Budget Type",
    accountName: "Account Name",
    name: "Name",
    group: "Group",
    balance: "Balance",
    property: "Property",
    unit: "Unit",
    lease: "Lease",
    listed: "Listed",
    class: "Class",
    availabilityForApplications: "Availability for Applications",
    type: "Type",
    status: "Status",
    leaseStatus: "Lease Status",
    userRole: "User Role",
    owner: "Owner",
    occupied: "Occupied",
    inEviction: "In Eviction",
    active: "Active",
    portfolio: "Portfolio",
    paymentMethod: "Payment Method",
    filterBy: "Filter By:",
    deletedItems: "Deleted Items",
    reportTypeAll: "Details & Summary",
    reportTypeDetailed: "Detailed",
    reportTypeSummary: "Summary",
    insurance: {
      insuranceRequired: "Insurance Required",
      insuranceProvided: "Insurance Provided",
      insuranceStatus: "Insurance Status",
      noInsurance: "No Insurance",
      expired: "Expired",
      active: "Active",
      future: "Future"
    },
    actionRequired: "Action Required",
    transactionType: "Transaction Type",
    issued: "Issued",
    unprinted: "Unprinted"
  },
  listHeader: {
    currentBalance: "Current Balance",
    balance: "Balance",
    date: "Date",
    dueDate: "Due Date",
    depositAndPaymentDate: "Deposit/Payment Date",
    category: "Category",
    runningBalance: "Running Balance",
    tenant: "Tenant",
    vendor: "Vendor",
    owner: "Owner",
    receivedFrom: "Received From",
    memo: "Memo",
    description: "Description",
    notes: "Notes",
    total: "Total",
    totalCredit: "Total Credit",
    totalDebit: "Total Debit",
    actions: "Actions",
    reference: "Reference",
    ePayStatus: "ePay Status",
    status: "Status",
    amount: "Amount",
    subject: "Subject",
    createdDate: "Created Date",
    property: "Property"
  },
  dropzone: {
    instructionsTextPart1: "Drag files or",
    instructionsTextPart2: "to upload",
    instructionsDragActiveText: "Drop the files here",
    failedToUpload: "Failed to upload",
    failedToDelete: "Failed to delete",
    errorInstructionsText: "Press the retry button to try again",
    attachments: "Attachments",
    downloadFile: "Download File",
    editFileInfo: "Edit Info",
    fileInfo: "File Info",
    uploadFile: "Upload File",
    uploadLogo: "Upload Logo",
    uploadImage: "Upload Image",
    download: "Download",
    deleteFile: "Delete File",
    deletingFile: "Deleting File",
    editFile: "Edit File",
    fileName: "File Name",
    updatingFile: "Updating files",
    loadingFile: "Loading files",
    fileDescription: "File description",
    tags: "Tags",
    deleteFileConfirmationText: "Are you sure you want to delete the file?",
    deleteFileLoadingText: "Deleting file",
    deleteFileSuccessText: "File was deleted successfully",
    failedToLoadFiles: "Field to load Files",
    addfile: "Add file",
    reloadFiles: "Reload files",
    uploadingFile: "Uploading file",
    sharedAllFilesWithTenants: "Share files with the tenants."
  },
  settingsText: "Settings",
  settings: {
    lateFees: {
      feeCurrency: "Fee ($)",
      feePercent: "Fee (%)",
      chargeTime: "When should we charge this late fee?",
      daysAfterRentDue: "Days after rent is due",
      chargeFrequency: "How often should we charge this fee?",
      lateFeePolicyFrequency: "How should we calculate this fee?",
      lateFeesAccount: "Which income account should the late fees go into?",
      lateFeePolicyFeeTypeLabel: "Fee Type",
      chargeAmount: "How much should we charge?",
      balancePercentage: "Percentage of balance",
      lateFeeIncome: "Late Fee Income",
      lateFee: "Late Fee",
      account: "Account",
      setMaxMonthlyLateFeesAmount: "Maximum monthly late fees",
      setMaxDailyLateFeesAmount: "Maximum daily late fees",
      setMinBalanceToApplyLateFeeAmount: "Minimum balance to apply late fee",
      lateFeesOptions: "Late Fees Options",
      minimumBalance: "Minimum Balance",
      frequnecy: {
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly"
      }
    }
  },
  enums: {
    PropertyType: {
      RESIDENTIAL_SINGLE_FAMILY: "Residential Single Family",
      RESIDENTIAL_MULTI_FAMILY: "Residential Multi Family",
      RESIDENTIAL_CONDO: "Residential Condo",
      RESIDENTIAL_TOWNHOME: "Residential Townhome",
      RESIDENTIAL_OTHER: "Residential Other",
      COMMERCIAL_OTHER: "Commercial Other",
      COMMERCIAL_INDUSTRIAL: "Commercial Industrial",
      COMMERCIAL_OFFICE: "Commercial Office",
      COMMERCIAL_RETAIL: "Commercial Retail",
      COMMERCIAL_SHOPPING_CENTER: "Commercial Shopping Center",
      COMMERCIAL_STORAGE: "Commercial Storage",
      COMMERCIAL_PARKING: "Commercial Parking",
      COMMERCIAL_CO_WORKING: "Commercial Co-Working"
    },
    ConversationsSort: {
      NEWEST: "Newest",
      OLDEST: "Oldest"
    },
    communicationLinkedToType: {
      USER: "USER",
      TENANT: "TENANT",
      OWNER: "OWNER",
      VENDOR: "VENDOR",
      SYSTEM: "SYSTEM"
    },
    purposes: {
      FINANCIAL_MANAGEMENT: "Accounting, reporting, and financial management",
      AUTOMATE_RENT_COLLECTION: "Automate rent collection",
      MARKETING: "Marketing and leasing my properties",
      MAINTENANCE: "Streamline maintenance & vendor requests",
      IMPROVE_COMMUNICATION: "Improve communications with stakeholders",
      STREAMLINE_MY_WORKFLOW: "Streamline my workflow and grow my business",
      OTHER: "Other"
    },
    jobTitles: {
      PROPERTY_MANAGER: "Property Manager",
      COMPANY_OWNER: "Property Manager & Company Owner",
      PROPERTY_OWNER: "Property Owner (self-managed)",
      ACCOUNTANT: "Accountant",
      HOA_MEMBER_OR_COMMUNITY_MANAGER: "HOA Member or Community Manager",
      OTHER: "Other"
    },
    jobTitlesMarketing: {
      PROPERTY_OWNER: "I own the properties",
      PROPERTY_MANAGER: "I manage the properties",
      LANDLORD: "I own and manage the properties",
      MANAGEMENT_COMPANY_OWNER: "I own or run a property management company",
      HOA_MEMBER_OR_MANAGER: "I work with a community association",
      OTHER: "Other"
    },
    otherJobTitles: {
      TENANT: "Tenant",
      ACCOUNTANT: "Accountant",
      ATTORNEY: "Attorney",
      OTHER: "Other"
    },
    mainPropertyTypes: {
      OTHER: "Other",
      AFFORDABLE_HOUSING: "Affordable Housing",
      COMMERCIAL: "Commercial",
      COMMUNITY_ASSOCIATIONS: "Community Associations",
      RESIDENTIAL: "Residential",
      SHORT_TERM_RENTALS: "Short Term Rentals",
      STORAGE: "Storage",
      RESIDENTIAL_COMMERCIAL: "Residential & Commercial"
    },
    whenPlanToUseSoftware: {
      ASAP: "ASAP",
      ONE_TO_THREE_MONTHS: "1-3 Months",
      THREE_TO_SIX_MONTHS: "3-6 Months",
      SIX_MONTHS_PLUS: "6 Months +",
      JUST_RESEARCHING: "Just Researching"
    },
    yesNoEnum: {
      YES: "Yes",
      NO: "No"
    },
    managementSoftware: {
      APPFOLIO: "AppFolio",
      ARTHUR_ONLINE: "Arthur Online",
      AVAIL: "Avail",
      BUILDINGSTACK: "BuildingStack",
      BUILDIUM: "Buildium",
      COZY: "Cozy",
      ENTRATA: "Entrata",
      MICROSOFT_EXCEL_WORD: "Microsoft Excel/Word",
      GOOGLE_SHEETS_DOCS: "Google Sheets/Docs",
      HEMLANE: "Hemlane",
      INNAGO: "Innago",
      LANDLORD123: "Landlord123",
      LANDLORDSTUDIO: "LandlordStudio",
      MANAGECASA: "ManageCasa",
      MANUAL_PEN_PAPER: "Manual - Pen & Paper",
      MRI: "MRI",
      ONESITE: "Onesite",
      OTHER: "Other",
      PAYHOA: "PayHOA",
      PROPERTYBOSS: "PropertyBoss",
      PROPERTY_MATRIX: "Property Matrix",
      PROPERTYWARE: "PropertyWare",
      QUICKBOOKS: "QuickBooks",
      REAL_PAGE: "Real Page",
      RE_LEASED: "Re-Leased",
      RENT_MANAGER: "Rent Manager",
      RENTREDI: "RentRedi",
      RENTTEC_DIRECT: "RentTec Direct",
      RENTVINE: "RentVine",
      RESMAN: "ResMan",
      SIMPLIFYEM: "SimplifyEM",
      SITELINK: "SiteLink",
      STESSA: "Stessa",
      TENANT_CLOUD: "Tenant Cloud",
      TURBOTENANT: "TurboTenant",
      YARDI_BREEZE: "Yardi Breeze",
      YARDI_VOYAGER: "Yardi Voyager",
      ZILLOW: "Zillow"
    },
    positions: {
      PRESIDENT: "President",
      VICE_PRESIDENT: "Vice President",
      SECRETARY: "Secretary",
      TREASURY: "Treasury",
      BOARD_MEMBER: "Board Member"
    },
    rentReminderOverdueNotificationsFrequency: {
      ONCE: "Once",
      DAILY: "Daily",
      EVERY_3_DAYS: "Every 3 days",
      WEEKLY: "Weekly"
    },
    rounding: {
      NEAREST_WHOLE_NUMBER: "Nearest Whole Number",
      NEAREST_TENTH: "Nearest Tenth",
      NEAREST_HUNDREDTH: "Nearest Hundredth"
    },
    portalStatus: {
      INVITED: "Invited",
      ACTIVE: "Active",
      INACTIVE: "Inactive"
    },
    lateFeePolicyFeeType: {
      FIXED_AMOUNT: "Flat fee ($)",
      PERCENTAGE_OF_OUTSTANDING_BALANCE: "Percentage (%)",
      PERCENTAGE_OF_SPECIFIC_ITEMS_OUTSTANDING_BALANCE: "Percentage of specific items from outstanding balance",
      PERCENTAGE_OF_RECURRING_RENT_CHARGES: "Percentage of recurring rent charges"
    },
    lateFeeMaximumFeeType: {
      MONTHLY: "per Month",
      DAILY: "per Day"
    },
    communicationType: {
      EMAIL: "Email",
      TEXT: "SMS",
      PUSH: "Push Notifications"
    },
    lateFeePolicyFrequency: {
      ONCE: "Once",
      DAILY: "Daily",
      WEEKLY: "Weekly",
      MONTHLY: "Monthly"
    },
    lateFeeSettingsAccountsToConsider: {
      ALL_ACCOUNTS: "All Accounts",
      SPECIFIC_ACCOUNTS: "Specific Accounts",
      ALL_ACCOUNTS_EXCLUDING_LATE_FEES: "All Accounts (excluding late fees)"
    },
    taskPriority: {
      LOW: "Low",
      MEDIUM: "Medium",
      HIGH: "High"
    },
    taskStatus: {
      NOT_STARTED: "Not Started",
      IN_PROGRESS: "In Progress",
      COMPLETED: "Completed",
      RECEIVED: "Received",
      ARCHIVED: "Archived"
    },
    tenantRequestType: {
      GENERAL: "General",
      MAINTENANCE: "Maintenance",
      OTHER: "Other"
    },
    tenantRequestMaintenanceCategory: {
      APPLIANCES: "Appliances",
      DOORS_AND_LOCKS: "Doors and Locks",
      ELECTRONICS_AND_LIGHTING: "Electronics and Lighting",
      EXTERIORS: "Exteriors",
      FLOORING: "Flooring",
      HEATING_AND_COOLING: "Heating and Cooling",
      PLUMBING_AND_BATHROOMS: "Plumbing and Bathrooms",
      SAFETY_EQUIPMENT: "Safety Equipment",
      PREVENTATIVE_MAINTENANCE: "Preventative Maintenance",
      PEST_MANAGEMENT: "Pest Management",
      RESIDENT_EXPERIENCE: "Resident Experience",
      HVAC: "HVAC",
      ARCHITECTURAL: "Architectural",
      OTHER: "Other"
    },
    depositStatus: {
      DEPOSITED: "Deposited",
      UNDEPOSITED: "Undeposited"
    },
    rentalApplicationDecisionStatus: {
      NOT_STARTED: "Not Started",
      UNDECIDED: "No Decision",
      APPROVED: "Approved",
      REJECTED: "Rejected",
      IGNORED: "Ignored"
    },
    rentalApplicationStatus: {
      SENT: "Sent",
      NOT_SENT: "Not Sent",
      SENDING_FAILED: "Sending Failed",
      SUBMITTED: "Submitted"
    },
    screeningRequestReportsStatus: {
      IDENTITY_VERIFICATION_PENDING: "Identity Verification Pending",
      READY_FOR_REPORT_REQUEST: "Ready for Report Request",
      REPORTS_DELIVERY_IN_PROGRESS: "Reports Delivery in Progress",
      REPORTS_DELIVERY_FAILED: "Reports Delivery Failed"
    },
    rentalApplicationReportStatus: {
      PENDING_REPORT: "Pending Report",
      IDENTITY_VERIFICATION_FAILED: "Identity Verification Failed",
      REPORT_AVAILABLE: "Report Available"
    },
    rentalApplicationOverviewDecisionStatus: {
      NOT_STARTED: "Not Started",
      UNDECIDED: "Pending Decision",
      APPROVED: "Approved",
      REJECTED: "Rejected",
      IGNORED: "Ignored"
    },
    recurringTransactionFrequency: {
      Daily: "Daily",
      Weekly: "Weekly",
      Every2Weeks: "Every 2 Weeks",
      Monthly: "Monthly",
      Every2Months: "Every 2 Months",
      Quarterly: "Quarterly",
      Every6Months: "Every 6 Months",
      Annually: "Annually",
      Annual: "Annual",
      OneTime: "One-time",
      Every7DaysLowercase: "every 7 days",
      Every14DaysLowercase: "every 14 days"
    },
    prospectStatus: {
      NEW: "New",
      CONTACT_ATTEMPTED: "Contact Attempted",
      CONTACT_MADE: "Contact Made",
      SHOWING_SCHEDULED: "Showing Scheduled",
      SHOWING_COMPLETED: "Showing Completed",
      APPLICATION_SENT: "Application Sent",
      APPLICATION_SUBMITTED: "Application Submitted",
      APPLICATION_IN_PROGRESS: "Application In Progress",
      LEASE_OFFER_MADE: "Lease Offer Made",
      LEASE_OFFER_ACCEPTED: "Lease Offer Accepted",
      LEASE_SENT: "Lease Sent",
      CLOSED_MOVED_IN: "Closed Move-In",
      CLOSED_LOST: "Closed Lost"
    },
    journalEntryType: {
      beginningBalance: "Beginning Balance",
      leaseCharge: "Charge",
      leasePayment: "Payment",
      leaseRefund: "Refund",
      leaseCredit: "Credit",
      vendorBill: "Bill",
      vendorBillPayment: "Bill Payment",
      vendorCredit: "Vendor Credit",
      expense: "Expense",
      deposit: "Deposit",
      generalEntry: "Journal Entry",
      lineItem: "Line Item",
      transfer: "Transfer",
      ownerContribution: "Owner Contribution",
      ownerDistribution: "Owner Distribution",
      applicationFee: "Application Fee",
      leaseReversedPayment: "Reversed Payment",
      appliedCredit: "Applied Credit"
    },
    linkedResourceType: {
      leaseCharge: "Lease Charge",
      leasePayment: "Lease Payment",
      leaseReversedPayment: "Lease Reversed Payment",
      leaseRefund: "Lease Refund",
      leaseCredit: "Lease Credit",
      expense: "Expense",
      vendorBill: "Vendor Bill",
      vendorBillPayment: "Vendor Bill Payment",
      deposit: "Deposit",
      generalEntry: "General Entry",
      reconciliation: "Reconciliation",
      transfer: "Transfer",
      managementFee: "Management Fee",
      ownerContribution: "Owner Contribution",
      ownerDistribution: "Owner Distribution",
      vendorCredit: "Vendor Credit",
      tenant: "Tenant",
      prospect: "Prospect",
      property: "Property",
      unit: "Unit",
      owner: "Owner",
      file: "File",
      lease: "Lease",
      leaseDraft: "Lease Draft",
      vendor: "Vendor",
      user: "User",
      leaseRenewalOffer: "Lease Renewal Offer",
      note: "Note",
      recurringTransaction: "Recurring Transaction",
      recurringTransactionRent: "Recurring Transaction Rent",
      recurringTransactionCharge: "Recurring Transaction Charge",
      recurringTransactionCredit: "Recurring Transaction Credit",
      recurringTransactionPayment: "Recurring Transaction Payment",
      recurringTransactionVendorBill: "Recurring Transaction Vendor Bill",
      recurringTransactionExpense: "Recurring Transaction Expense",
      recurringTransactionTask: "Recurring Transaction Task",
      task: "Task",
      rentalApplication: "Rental Application",
      insurancePolicy: "Insurance Policy",
      announcement: "Announcement",
      communication: "Communication",
      conversation: "Conversation",
      eSignatureRequest: "Electronic Signature Request",
      eSignatureTemplate: "Electronic Signature Template"
    },
    paymentMethod: {
      CASH: "Cash",
      CHECK: "Check",
      OTHER: "Other",
      EPAY: "ePay",
      CREDIT_CARD: "Credit Card",
      CASHIERS_CHECK: "Cashier's Check",
      MONEY_ORDER: "Money Order",
      EFT: "EFT",
      DEBIT_CARD: "Debit Card",
      WESTERN_UNION_CASH: "CashPayments"
    },
    ePayPaymentMethod: {
      CARD: "Card",
      ECHECK: "eCheck",
      WESTERN_UNION_CASH: "CashPayments"
    },
    receivedFrom: {
      VENDOR: "Vendor",
      TENANT: "Tenant",
      OWNER: "Owner"
    },
    leaseDraftType: {
      NEW_LEASE: "New Lease",
      RENEWAL_LEASE: "Renewal Lease"
    },
    propertyTypes: {
      Residential: "Residential",
      Commercial: "Commercial",
      Industrial: "Industrial",
      "Affordable Housing": "Affordable Housing",
      "Student Housing": "Student Housing",
      "Community Associations": "Community Associations"
    },
    accountClass: {
      ASSET: "Asset",
      LIABILITY: "Liability",
      EQUITY: "Equity",
      REVENUE: "Revenue",
      EXPENSE: "Expense"
    },
    accountType: {
      ASSET_ACCOUNTS_RECEIVABLE: "Asset > Accounts Receivable",
      ASSET_OTHER_CURRENT_ASSETS: "Asset > Other Current Assets",
      ASSET_BANK: "Asset > Bank",
      ASSET_FIXED_ASSETS: "Asset > Fixed Assets",
      ASSET_OTHER_ASSETS: "Asset > Other Asset",
      LIABILITY_ACCOUNTS_PAYABLE: "Liability > Accounts Payable",
      LIABILITY_CREDIT_CARD: "Liability > Credit Card ",
      LIABILITY_OTHER_CURRENT_LIABILIY: "Liability > Other Current Liability",
      LIABILITY_LONG_TERM_LIABILITY: "Liability > Long Term Liability",
      EQUITY_EQUITY: "Equity",
      REVENUE_INCOME: "Revenue > Income",
      REVENUE_OTHER_INCOME: "Revenue > Other Income",
      EXPENSE_EXPENSE: "Expense",
      EXPENSE_OTHER_EXPENSE: "Expense > Other Expense",
      EXPENSE_COGS: "Expense > Cogs"
    },
    bankAccountType: {
      8: "Checking",
      9: "Saving",
      10: "Business Checking",
      11: "Business Saving"
    },

    leaseTermType: {
      Fixed: "Fixed",
      AtWill: "Month-to-Month"
    },
    defaultAccountType: {
      ACCOUNTS_RECEIVABLE: "Accounts Receivable",
      ACCOUNTS_PAYABLE: "Accounts Payable",
      UNAPPLIED_LEASE_PAYMENTS_INCOME: "Unapplied Lease Payments Income ",
      UNAPPLIED_BILL_PAYMENTS_EXPENSE: "Unapplied Bill payments Expense",
      UNDEPOSITED_FUNDS: "Undeposited Funds",
      LATE_FEES: "Late Fees",
      MANAGEMENT_FEES: "Management Fees",
      RENTAL_INCOME: "Rental Income",
      SECURITY_DEPOSIT: "Security Deposits",
      APPLICATION_FEE: "Application Fees",
      OPERATING_ACCOUNT: "Operating Account",
      TRUST_ACCOUNT: "Trust Account",
      OWNER_CONTRIBUTION: "Owner Contributions",
      OWNER_DISTRIBUTION: "Owner Distributions",
      OPENING_BALANCE: "Opening Balance",
      MERCHANT_RESERVES: "Merchant Reserves",
      CLEARING_ACCOUNT: "Clearing Account",
      MERCHANT_PLATFORM_FEES: "Merchant Platform Fees",
      MERCHANT_RETURNED_PAYMENT: "Merchant Returned Payment",
      APPLICATION_FEE_EXPENSE: "Application Fee Expense",
      CONVENIENCE_FEE: "Convenience Fee"
    },
    businessType: {
      0: "Sole Proprietor",
      1: "Corporation",
      2: "LLC",
      3: "Partnership",
      5: "Non Profit",
      6: "Government"
    },
    rentalApplicationAdditionalQuestionType: {
      YES_NO: "Yes/No",
      YES_NO_FREE_TEXT_IF_YES: "Yes/No And Text If Yes",
      FREE_TEXT: "Free Text"
    },
    gender: {
      MALE: "Male",
      FEMALE: "Female",
      PREFER_NOT_TO_SAY: "Prefer not to say"
    },
    rentalApplicationJobType: {
      EMPLOYED: "Employed",
      SELF_EMPLOYED: "Self Employed",
      UNEMPLOYED: "Unemployed",
      STUDENT: "Student"
    },
    paymentAllocationAllocateByEnum: {
      OLDEST_CHARGE_FIRST: "Oldest charge first",
      ALLOCATE_MANUALLY: "Allocate manually"
    },
    maritalStatus: {
      SINGLE_NEVER_MARRIED: "Single never married",
      MARRIED_OR_DOMESTIC_PARTNERSHIP: "Married/Domestic partnership",
      DIVORCED: "Divorced",
      WIDOWED: "Widowed",
      SEPARATED: "Separated"
    },
    eSignatureSignerType: {
      TENANT: "Tenant",
      PROSPECT: "Prospect",
      VENDOR: "Vendor",
      OWNER: "Owner",
      USER: "User"
    },
    eSignatureRequestStatus: {
      DRAFT: "Draft",
      OUT_FOR_SIGNATURE: "Out for signature",
      DECLINED: "Declined",
      COMPLETED: "Completed",
      CANCELED: "Canceled"
    },
    taskType: {
      INTERNAL_TASK: "Task",
      TENANT_REQUEST: "Tenant Request",
      OWNER_REQUEST: "Owner Request",
      WORK_ORDER: "Work Order"
    },
    workOrderPermissionToEnter: {
      NOT_APPLICABLE: "Not Set",
      YES: "Yes",
      NO: "No"
    },
    allocationStrategy: {
      MANUAL: "Allocate Manually",
      EVEN: "Allocate Evenly",
      BY_SIZE: "Allocate by Size"
    },
    allocationStrategyWithCustom: {
      MANUAL: "Allocate Manually",
      EVEN: "Allocate Evenly",
      BY_SIZE: "Allocate by Size",
      CUSTOM: "Use a Custom Allocation"
    },
    insurancePolicyProvider: {
      OTHER: "Other",
      LEMONADE: "Lemonade",
      SURE: "Sure"
    },
    marketingCampaign: {
      GoogleSearch: "Google Search",
      YouTube: "YouTube",
      Instagram: "Instagram",
      TikTok: "TikTok",
      Facebook: "Facebook",
      Webinar: "Webinar",
      Podcast: "Podcast",
      LinkedIn: "LinkedIn",
      Reddit: "Reddit",
      Quora: "Quora",
      Referral: "Referral",
      Other: "Other"
    }
  },
  createNewMenu: {
    tasksAndMaintenance: "Tasks & Maintenance",
    user: "User",
    lease: "Lease",
    tenantTransactions: "Tenant Transactions",
    vendorTransactions: "Vendor Transactions",
    ownerTransactions: "Owner Transactions",
    otherTransactions: "Other Transactions",
    ownerContribution: "Owner Contribution",
    ownerDistribution: "Owner Distribution",
    journalEntry: "Journal Entry",
    bankTransfer: "Bank Transfer",
    bankDeposit: "Bank Deposit",
    printChecks: "Print Checks",
    billsAndExpenses: "Bills & Expenses",
    chargesAndPayment: "Charges & Payment",
    check: "Check"
  },
  globalSearch: {
    searchTitle: "Global search",
    searchEverywhere: "Search...",
    searching: "Searching ... ",
    searchInstructions: "Search for properties, units, leases, people, tasks, & more",
    noResultsFoundDescription: "Nothing found. Please search again."
  },
  notificationsPanel: {
    emailConfirm: {
      confirmMessage: "Please confirm your email address",
      resendEmail: "Resend Email"
    }
  },
  filterPanelDatePicker: {
    from: "From",
    to: "To",
    custom: "Custom",
    today: "Today",
    thisWeek: "This Week",
    thisWeekToDate: "This Week To Date",
    thisMonth: "This Month",
    thisMonthToDate: "This Month To Date",
    thisQuarter: "This Quarter",
    thisQuarterToDate: "This Quarter To Date",
    thisYear: "This Year",
    thisYearToDate: "This Year To Date",
    thisYearTolastMonth: "This Year To Last Month",
    yesterday: "Yesterday",
    lastWeek: "Last Week",
    lastWeekToDate: "Last Week To Date",
    lastMonth: "Last Month",
    lastMonthToDate: "Last Month To Date",
    lastQuarter: "Last Quarter",
    lastQuarterToDate: "Last Quarter To Date",
    lastYear: "Last Year",
    last7Days: "Last 7 days",
    last30Days: "Last 30 days",
    last60Days: "Last 60 days",
    last90Days: "Last 90 days",
    last120Days: "Last 120 days",
    last180Days: "Last 180 days",
    next7Days: "Next 7 days",
    next30Days: "Next 30 days",
    next60Days: "Next 60 days",
    next90Days: "Next 90 days",
    next120Days: "Next 120 days",
    allTime: "All Time"
  },
  recurrenceHints: {
    onTheRecurringDayOfEachMonth: "on the {{recurringDay}} of each month",
    paymentDayMayDiffer: "Payment day may differ depending on the length of the month."
  },
  missingInfo: "Missing contact info",
  inviteToPortal: "Send Portal Invitation",
  tenantPortalMissingInfoTooltip: "In order to send an invite please fill out the tenant email & mobile number",
  ownerPortalMissingInfoTooltip: "In order to send an invite please fill out the owner email or mobile number",
  bankAccount: "Bank Account",
  onlinePayments: "Online Payments",
  resumeApplication: "Resume Application",
  applyNow: "Apply Now",
  verifyIdentity: "Verify Identity",
  acceptingOnlinePayments: "Accepting Online Payments",
  applicationDeclined: "Application Declined",
  applicationSubmitted: "Application Pending Review",
  accountClosed: "Account Closed",
  undepositedFunds: "Undeposited Funds",
  paymentsInProcess: "Payments in Process",
  ePayInTransit: "ePay in transit",
  manualEntries: "Manual entries",
  doorLoopBalance: "DoorLoop Balance",
  makeInactiveAccountConfirmationText: "Are you sure you want to make the account inactive?",
  makeActiveAccountConfirmationText: "Are you sure you want to activate the account?",
  bankAccountsEmptyDataViewInstructionsText: "No accounts Found",
  onlinePaymentsNotSupportedTitle:
    "We’re sorry, but online payments aren’t currently supported in your region/currency.",
  onlinePaymentsNotSupportedSubTitle: "Want us to add support for your country?",
  sendUsMessage: "Send us a message",
  beforeYouBegin: "Before you begin",
  beforeYouBeginDescription: "",
  aboutTheBusiness: "About the Business",
  aboutTheBusinessDescription: "This information will also appear on your tenants credit card statements",
  aboutTheOwners: "About the owners",
  aboutTheOwnersDescription:
    "Due to regulatory guidelines, we are required to collect information on anyone who has significant ownership of your business (25% or more). If there are no owners with at least 25% ownership, use the information for an individual who is authorized to enter the business into financial agreements.",
  payoutInfo: "Payout Info",
  summary: "Summary",
  requirement: "Requirement",
  merchantAccountRequirements:
    "This application must be submitted by an executive, senior manager or someone who otherwise has significant responsibility for the control and management of your business. If that’s not you, please ask the right person to fill out this form.",
  requirementInstructions: "In this application you will be asked to provide:",
  businessTax: "The Business Tax ID or EIN number",
  socialSecurityAndDriverLicense:
    "The social security and driver license numbers for anyone that owns 25% or more of this business. These are necessary because the USA PATRIOT Act of 2001 requires that financial institutions verify the identity of anyone opening an account.",
  routingAndAccountNumber: "The routing and account numbers for the bank account where you want us to send the money",
  newMerchantAccount: "Merchant Account",
  payWithRapidrent: "Get paid faster with RapidRent by DoorLoop",
  payWithRapidrentDescription: "No more bounced checks or bank fees. Collect more rent by credit cards or ACH.",
  generalBusinessInformation: "General Business Information",
  generalBusinessExplainer:
    "All information provided must match the records filed with the IRS and your State Secretary’s office in order to be approved." +
    " If you file as a sole proprietor, use your SSN in place of an EIN and use your full legal name in place of a Business Name.",
  businessType: "Business Type",
  businessName: "Business Name",
  einPlaceholder: "Tax ID Number / EIN",
  yearEstablished: "Date Established",
  doingBusinessAs: "Doing Business As (This will show on your tenant’s credit card statement)",
  businessPhone: "Business Phone",
  customerServicePhone: "Customer Service Phone",
  website: "Website",
  addressInformation: "Address information",
  addressInformationDescriptionForBusiness:
    "Please enter the physical business address that matches your tax return. P.O boxes are not accepted.",
  addressInformationDescriptionForOwner:
    "Please enter the owner's physical address as it appears on their driver's license.",
  additionalInformation: "Additional Information",
  additionalInformationDescription:
    "Please enter the estimated average transaction amount you expect to receive from each individual tenant, each month. Underwriting will approve you for a maximum transaction amount, likely up to $10,000. " +
    "If you need to receive payments over this amount, you may need to provide us with more documentation.",
  currentlyAcceptingCreditCards: "Currently Accepting Rent Payments Online",
  thisBusinessIsPubliclyTraded: "This business is publicly traded",
  annualVolume: "Total Expected Online Payments Per Year",
  averageTicket: "Average Transaction Amount",
  noWebsiteHelpText:
    "If you do not have a website, you can enter any of your current online listings, your LinkedIn profile, or any other social media account.",
  businessOwners: "Business Owners",
  primaryBusinessRepresentative: "Primary Business Representative",
  generalInformation: "General Information",
  ssn: "SSN",
  ssnOrItin: "SSN or ITIN",
  itin: "ITIN",
  socialIdNumber: "Social Id Number",
  driversLicenseNumber: "Drivers License Number",
  driverLicenseState: "Driver License State",
  useBusinessAddress: "Use Business Address",
  ownershipInformation: "Ownership Information",
  significantReponsibility: "Owns 25% or more of this company",
  authorizedEnter: "Authorized to enter the business into a commercial relationship",
  ownershipPercent: "Ownership Percent",
  payoutInfoSubTitle: "Tell us where you would like us to send your money.",
  important: "Important",
  endLeaseWarning:
    "Please note, once the lease is ended, there is no way to reactivate it." +
    " The move-out date you list here will become the new end date of this lease and portal access will end for all tenants on this lease.",
  payoutWarningText:
    "Please confirm that this is the correct information, otherwise your money may be sent to the wrong account!",
  accountDetails: "Account Details",
  accountName: "Account Name",
  accountClass: "Account Class",
  accountType: "Account Type",
  bankRoutingNumber: "Bank Routing Number",
  bankRoutingNumberDescription: "Your bank account has to be a checking account.",
  bankAccountNumber: "Bank Account Number",
  confirmBankAccountNumber: "Confirm Bank Account Number",
  confirmBankAccountNumberDescription:
    "Carefully review this information as this is where we are going to send your payouts.",
  summaryDescription: "You are about to submit this merchant application for underwriting review",
  rapidRentTermsAndConditions:
    "Please review the following carefully, all data entered will be verified against federal and public records:",
  rapidRentVerifyDays: "It may take up to 5 business days for underwriting to review your application.",
  rapidRentReview: "Make sure all information entered is correct. Any incorrect information will delay your approval.",
  rapidRentCharge: "Your credit card on file will be charged ${{chargeAmount}} for this application fee.",
  acceptRapidRentTerms: "I Accept the RapidRent Terms and Conditions",
  autoPrefillInformation: "Auto Prefill Information",
  autoPrefillInformationDescription:
    "It looks like you have already submitted an application for a merchant account. We can make it easier for you and pre-fill this application with information. Would you like us to do that?",
  newBusinessEntity: "No, This application is for a different business entity",
  existingBusinessEntity:
    "Yes, This application is for the same business entity, Please prefill the information for me",
  application: "Application",
  applicationReference: "Application {{reference}}",
  applicationSelectionValidationText: "Please select an application",
  ownersSelectionValidationText: "You need to add all the partners that owns at least 25% of the business",
  creatingMerchantApplication: "Creating merchant application",
  termsAndConditionsValidationMessage: "Please accept terms and conditions",
  transunionAmountError: "The application fee must be at least {{amount}} to cover costs. ",
  businessInfoValidationMessage:
    "Your business info or Address are invalid - make sure your state is a valid U.S state",
  noRecurringTransactionsFound: "No Recurring Transactions Found",
  noRecurringRentFound: "No Recurring Rent Found",

  portalContactInfo: "Portal Contact Info",
  tenantPortalContactInfoDescription: "This information will be shown to your tenants on the tenant portal.",
  ownerPortalContactInfoDescription: "You can customize the owner portal contact info",
  hideTenantPortalContactInfo: "Display default company information",
  hideOwnerPortalContactInfo: "Do not show company contact info in the owner portal",
  hidePortalContactInfoForProperty: "Use company default portal contact info for this property",
  specifyPortalContactInfoForProperty: "Customize portal contact info for this property",

  hideInvitesForProperty: "Use company default portal invites info for this property",
  specifyInvitesForProperty: "Specify portal invites info for this property",

  hidePortalUrlForProperty: "Use company default portal URL for this property",
  specifyPortalUrlForProperty: "Specify portal URL for this property",

  hideTenantRequestSettingsForProperty: "Use company default tenant request settings for this property",
  specifyTenantRequestSettingsForProperty: "Specify tenant request settings for this property",

  hideThemeForProperty: "Use company default portal theme for this property",
  specifyThemeForProperty: "Specify portal theme for this property",

  hideFeaturesForProperty: "Use company default portal features for this property",
  specifyFeaturesForProperty: "Specify portal features for this property",

  hideFeaturesForLease: "Use property default portal features for this lease",
  specifyFeaturesForLease: "Specify portal features for this lease",

  specifyTenantPortalContactInfo: "Customize contact info displayed in the portal",
  specifyOwnerPortalContactInfo: "Specify company contact info for the owner portal",

  themeSettings: "Theme Settings",
  countryAddress: "Country",
  companyAddress: "Company address",
  tenantPortalThemeSettingsDescription:
    "Personalize the appearance of your tenant portal and even customize the URL to match your company’s brand.",
  tenantPortalPreview: {
    addressPrefix: "https://",
    addressSuffix: ".app.doorloop.com/tenant-portal..."
  },
  ownerPortalThemeSettingsDescription: "You can customize the look and feel of your owner portal",
  logo: "Logo",
  colorTheme: "Color Theme",
  backgroundAndCoverImage: "Background / Cover Image",
  pressToAddCoverImage: "Press to Add Background / Cover Image",
  coverImageRecommendedSize: "Recommended size: 1920x1080",
  portalInvites: "Portal Invites",
  tenantsPortalSettingsDescription: "Set permissions and features for your tenants in the tenant portal.",
  ownersPortalFeaturesDescription: "Set features and permissions for your owner portal",
  permissions: "Permissions",
  tenantPortalFeaturesAccess: "Grant tenants access to the tenant portal",
  ownerPortalFeaturesAccess: "Allow owners to access the owner portal",
  enableOnlinePayments: "Enable online payments",
  tenantInsurance: "Tenant Insurance",
  allowPartialPayments: "Allow partial payment",
  leaseLedger: "Lease Ledger",
  viewLeaseLedger: "Tenants can view Lease Ledger",
  uploadProofOfInsurance: "Upload Insurance",
  requireInsuranceDocuments: "Tenants can upload Insurance Policy",
  purchaseRentersInsurance: "Purchase renters insurance",
  requests: "Requests",
  accessToRequests: "Access to Submitted Requests",
  paymentPreferences: "Payment Preferences",
  requestsAndCommunication: "Requests and Communication",
  tenantPortalRequestsSettingsDescription:
    "Increase interaction with your tenants by allowing them to submit their requests online",
  submitNewOnlineRequests: "Submit new online requests",
  allowTenantOnlineRequests: "Allow tenants to submit online requests",
  updateOpenRequests: "Update open requests",
  viewOpenRequests: "View open requests",
  viewClosedRequests: "View closed requests",
  tenantPortalInvitesDescription: "You can customize the look and feel of the email invites sent to your tenants",
  ownerPortalInvitesDescription: "You can customize the look and feel of the email invites sent to your owners",
  portalInviteTitle: "Title",
  portalInviteSubTitle: "Subtitle",
  emailBody: "Email Body",
  portalUrl: "Portal URL",
  emailTemplate: "Email Template",
  urlStructure: "URL Structure",
  portalAccess: "Portal Access",
  tenantPortalAccess: "Invite this tenant to the Tenant Portal",
  ownerPortalAccess: "Invite this owner to the Owner Portal",
  newPortalInvite: "New Portal Invite",
  newPortalInviteDescription:
    "Once you click “Save”, they will receive an email and a text message to access their portal.",
  sentAt: "Sent at",
  lastSent: "Last sent:",
  invitationSent: "Invitation Sent",
  sendingInvitation: "Sending Invitation",
  tenantPortalInvitedDescription: "We sent the user an invitation to join the tenants portal.",
  tenantPortalAccessActive: "Tenant Portal Active",
  tenantPortalAccessActiveDescription: "The tenant successfully logged into the portal.",
  ownerPortalInvitedDescription: "We sent the user an invitation to join the owners portal.",
  ownerPortalAccessActive: "Owner Portal Active",
  ownerPortalAccessActiveDescription: "The owner successfully logged into the portal.",
  doorloopDemo: "REQUEST A DEMO",
  doorloopDemoDescription: "Schedule a demo in 36 seconds",
  doorloopDemoAdditionalInfoDescription: "See if DoorLoop is the right fit!",
  doorloopDemoLetsScheduleYourPersonalizedDemo: "Let's Schedule Your Personalized Demo",
  asSeenOn: "As Seen On",
  home: "Home",
  details: "Details",
  myLease: "My Lease Details",
  leaseDetails: "Lease Details",
  welcome: "Welcome",
  amountDue: "Amount Due",
  outstandingBalance: "Outstanding Balance Due",
  availableBalance: "Your Available Credit",
  creditAvailable: "Credit Available",
  noBalance: "No Outstanding Balance",
  autoPay: "Auto-Pay",
  singlePay: "Single Payment",
  makeAPayment: "Make a Payment",
  setupAutoPay: "Setup Auto-Pay",
  addAnotherAutoPay: "Add New Auto-Pay",
  insuranceIsRequired: "Insurance is required",
  doYouHaveInsurance: "Do you have insurance?",
  noInsurance: "No Insurance",
  iHaveAnActivePolicy: "I Have an Active Policy",
  insuranceIsRequiredDescription: "Looks like you don't have any insurance for this lease",
  updateInsuranceDetails: "Update Your Insurance Details",
  noActiveAutoPay: "No Active Auto-Pays",
  noActiveAutoPayDescription: "Auto-Pay is not setup for this lease",
  getInsurance: "Get Insurance",
  noActiveInsurance: "Get Insured",
  lemonadeRentersInsurance: "Lemonade Renters Insurance",
  noActiveLemonadeInsuranceDescription:
    "Lemonade offers renters insurance starting at just $5 a month to safeguard your most valuable items.",
  noActiveSureInsurance: "Sure Renters Insurance",
  noActiveSureInsuranceDescription: "Insurance policies starting at $5/mo",
  noActiveInsuranceDescription: "Insurance policies starting at $5/mo",
  noActiveRequests: "No Active Requests",
  noActiveRequestsDescription: "Communicate matters easily",
  addNewRequest: "New Request",
  createNewRequest: "Create New Request",
  paymentsHistory: "Transactions List",
  downloadAccountStatement: "Account Statement",
  noPaymentHistoryFound: "No payments history found",
  noPayments: "No Payments",
  noPaymentsDescription: "No payments have been made yet",
  howCanWeHelp: "How can we help?",
  requestsScreenSubTitle: "Let us know what we can do for you...",
  leaseAddress: "Lease Address",
  billingAndPayment: "Billing and Payment",
  lastPayment: "Last Payment",
  autoPayInstructions: "Follow the instructions below to set up auto-pay for your lease",
  payWithAutoPay: "Pay with Auto-Pay",
  payWithAutoPayDescription: "Create a recurring payment",
  whyUseAutoPay: "Why Use Auto-Pay?",
  singlePayment: "One-Time Payment",
  singlePayDescription: "Make a single payment of any amount now or in the future",
  nameOnCard: "Name on Card",
  cardNumber: "Account Number",
  exDate: "Exp. Date",
  cvv: "CVV",
  newCreditCard: "New Credit Card",
  newPaymentMethod: "New Payment Method",
  paymentOptions: "Payment Options",
  change: "Change",
  amountToPay: "Payment Amount",
  rentPaymentDue: "Rent Payment Due",
  paymentMethod: "Payment Method",
  paymentMethods: "Payment Methods",
  creditCardPlatformFee: "Credit Card Platform Fee",
  bankAccountPlatformFee: "Bank Account Platform Fee",
  savePaymentInformation: "Save this payment information for future use.",
  paymentOptionsDescription:
    "Your card will be charged {{recurrenceText}}, with the first charge occurring on {{paymentDate}}. {{additionalInfo}}",
  ach: "ACH",
  card: "Card",
  eCheck: "e-Check",
  addCard: "Add Card",
  addECheck: "Add e-Check",
  oneTimePayment: "One-time",
  recurringPayment: "Recurring Payment",
  paymentSummary: "Payment Summary",
  confirmPaymentTitle: "Finalize Payment",
  confirmPaymentSubTitle: "Press the Pay Now button to confirm",
  appliedTowardsRent: "Payment Amount",
  platformFee: "Platform Fee",
  platformFeeWithPercentageFee: "Platform Fee ({{percentageFee}}%)",
  minimum: "Minimum",
  switchToECheck: "Switch to e-Check",
  noFee: "No Fee",
  newBankAccount: "New Bank Account",
  switchToCreditCard: "Switch to Credit Card",
  addBankAccount: "Add Bank Account",
  paymentOptionsAmountDescription: "How much would you like to pay?",
  paymentOptionsPaymentFrequency: "How often do you want to make this payment?",
  paymentOptionsPaymentDate: "When would you like to start paying?",
  paymentOptionsFrequencyDescription: "How frequently would you like to make this payment?",
  setupPayemntOptionsDescription: "Setup Your Payment Options:",
  numberOfPayments: "Number of Payments",
  invalidPaymentMethod: "You must select payment method",
  update: "Update",
  existingPaymentMethod: "My Existing Payment Method",
  yourLastPaymentWillBeOn: "Your last payment will be on",
  selectPaymentMethod: "Select Payment Method",
  schedulePayment: "Schedule Payment",
  monthlyAutoPay: "Monthly Auto-Pay",
  weeklyAutoPay: "Weekly Auto-Pay",
  every2WeeksAutoPay: "Every 2 Weeks Auto-Pay",
  creditCardAutoPay: "Credit Card Auto-Pay",
  eCheckAutoPay: "e-Check Auto-Pay",
  nextPayment: "Next Payment:",
  convenienceFee: "Convenience Fee",
  convenienceFees: "Convenience Fees",
  cancelAutoPay: "Cancel Auto-Pay",
  editAutoPay: "Edit Auto-Pay",
  scheduledPayment: "Scheduled Payment",
  autoPayDeleteConfirmationText: "Are you sure you want to delete the Auto-Pay?",
  autoPayDeleteLoadingText: "Deleting Auto-Pay",
  autoPayDeleteSuccessText: "Auto-Pay was deleted successfully",
  autoPayCancelConfirmationText: "Are you sure you want to cancel the Auto-Pay?",
  autoPayCancelLoadingText: "Cancelling Auto-Pay",
  autoPayCancelSuccessText: "Auto-Pay was cancelled successfully",
  autoPayIsActive: "Auto-Pay is Active",
  autoPayBenefit1: "Save time and effort on bill payments",
  autoPayBenefit2: "Ensures paying on time and avoid late fees",
  paymentAutoPayBenefit1: "Save time and effort on making payments",
  paymentAutoPayBenefit2: "Always pay on time and avoid late fees",
  activeCount: "{{count}} Active",
  activeAutoPayDescriptionForThisLease: "Auto-Pay for this lease",
  activeRequestDescriptionYouHave: "You have",
  activeRequestDescriptionForThisLease: "Requests for this lease",
  whyCreateRequests: "Why Create Requests?",
  requestsBenefit1: "Speed up maintenance requests.",
  requestsBenefit2: "Keep all your rental activity in one organized place.",
  paymentSuccessfully: "Your payment was received successfully.",
  confirmationNumber: "Confirmation number: ",
  totalCharged: "Total Charged",
  done: "Done",
  paymentInstructions: "Payment Instructions",
  paymentInstructionsSettingsDescription: "Specify instructions for tenants on how they should pay their rent.",
  paymentMethodIdPrefix: {
    creditCard: "xxxxxxxx-{{number}}",
    cashPayment: "Cash {{number}}",
    mtcn: "MTCN {{token}}"
  },
  documentTitle: "DoorLoop - ",
  newRequest: "New Request",
  editRequest: "Edit Request",
  viewOpenRequest: "View Open Request",
  requestSubjectDescription: "What is this request about?",
  requestSubjectDescriptionViewOnly: "Request Subject",
  requestDescriptionText: "Please provide a detailed description of your request:",
  requestDescriptionTextViewOnly: "Request Details",
  requestDescriptionTextNoDescription: "We received your request and will send you an update as soon as possible.",
  filesAndDocuments: "Files and Documents",
  filesDescription: "Any photos / files related to this request?",
  accessToProperty: "Access to property",
  accessToAssociation: "Access to association",
  noAccessToProperty: "Not Permitted",
  requestAccessNeeded: "Enable the toggle to authorize access to your property",
  requestAccessNeededFromTenant: "Enable the toggle to permit entering the property on behalf of the tenant",
  requestAccessNeededFromOwner: "Enable the toggle to permit entering the association on behalf of the owner",
  accessGranted: "Access Granted",
  submitRequest: "Submit Request",
  requestDeleteConfirmationText: "Are you sure you want to delete the Request?",
  insurancePolicyDeleteConfirmationText: "Are you sure you want to delete the insurance policy?",
  insurancePolicyDeleteConfirmationTextDisclaimer:
    "For complete removal, you must also delete it from your insurance company's records.",
  requestDeleteLoadingText: "Deleting Request",
  insurancePolicyDeleteLoadingText: "Deleting Policy",
  requestDeleteSuccessText: "Request was deleted successfully",
  insurancePolicyDeleteSuccessText: "Insurance policy was deleted successfully",
  defaultProperty: "Default Property",
  defaultPropertyDescription:
    "DoorLoop automatically handles the reconciliation process for bank deposits related to this account. Which property should be the default property for these transactions? Don’t worry, you can still use this bank account to receive payments for any property you wish.",
  securityConfirmation: "Security Confirmation",
  deleteTransactionConfirmationText: "Are you sure you want to delete the transaction?",
  deletingTransaction: "Deleting transaction",
  transactionDeleted: "Transaction Deleted",
  vendorDeleted: "Vendor Deleted",
  prospectDeleted: "Prospect Deleted",
  ownerDeleted: "Owner Deleted",
  insurancePolicy: "Insurance Policy",
  insurancePolicies: "Insurance Policies",
  budgets: "Budgets",
  insuranceProviderDescription: "Who are you insured with?",
  providerName: "Provider Name",
  liabilityCoverageDescription: "How much are you insured for?",
  liabilityCoverage: "Liability Coverage",
  insurancePeriodDescription: "What is the insurance period?",
  proofOfInsurance: "Proof of Insurance",
  proofOfInsuranceDescription: "Please upload your proof of insurance below",
  activePolicy: "Active Policy",
  inactivePolicy: "Inactive Policy",
  insuranceActive: "You Are Insured",
  viewInsurancePolicy: "View Insurance Policy",
  lastSeen: "Last seen",
  loginEmail: "Login Email",
  sendInvite: "Send Invite",
  bankVerificationStatusPart1: "Bank verification ",
  bankVerificationStatusPart2: " status: ",
  bankVerificationRequestedDate: "Verification requested Date: ",
  bankVerificationTestExpectedAt: "Expected test deposit at: ",
  couldNotGetTenantPaymentTokens: "Could not get tenant payment methods",
  viewOnlyRecurringPaymentTitle: "this recurring payment cannot be modified",
  viewOnlyRecurringPaymentTitleDescription: "Only the tenant can make changes to this recurring payment.",
  returnedPaymentChargesNegativeLines: "This deposit has negative line items for fees incurred by returned payments",
  viewOnlyPaymentTitle: "This payment cannot be modified because it was submitted through the tenant portal",
  viewOnlyPaymentDescription: "This is an electronic payment. Only the payment allocations can be modified.",
  transactionId: "Transaction Id",
  accountInactiveMessage: "Your account is inactive. Please contact your property manager for more information.",
  rentRemindersTitle: "Rent & Payment Notifications",
  rentRemindersTitleDescription: "Send email and text notifications to your tenants.",
  useCompanyDefaultRentReminders: "Use company default notifications",
  specifyRentRemindersForProperty: "Specify notifications for this property",
  rentRemindersDialogInstructions:
    "You can set different payment notifications for each property as needed, or use your company default settings.",
  paymentInstructionsDialogInstructions:
    "You can set different payment instructions for each property as needed, or use your company default settings.",
  rentChargeNotifications: "Rent Posted",
  rentReminders: "Rent Reminder",
  upcomingRent: "Upcoming Rent",
  rentChargeNotificationsDescription: "Send a notification to remind the tenant about an upcoming rent",
  postRecurringChargesBeforeDueDate: "Post charges to the account before the due date",
  rentChargeTextMessageNotificationsDescription: "Send a text to remind the tenant about an upcoming charge",
  rentChargeDoNotSendReminderIfTenantAlreadyPaid: "Do not send a notification if the tenant has already paid",
  rentNotificationSendEmailWhenRentIsCharged: "Send a notification to the tenant when rent is posted",
  rentNotificationSendTextMessageWhenRentIsCharged: "Send a text to the tenant when rent is charged",
  rentNotificationSendEmailWhenRentIsChargedAttachStatement: "Attach an account statement to the email",
  rentNotificationSendEmailWhenRentIsChargedAttachInvoice: "Attach an invoice to the email",
  rentNotificationSendEmailWhenRentIsChargedAttachCashPaymentSlip: "Include cash payment details",
  daysBeforeRentIsDue: "Days before rent is due",
  paymentReceivedNotifications: "Payment Received",
  paymentReceivedNotifyByEmail: "Send a notification to the tenant when they make an online payment",
  paymentReceivedNotifyUsersByEmail: "Send a notification to DoorLoop users when a tenant makes an online payment",
  selectUsers: "Select users",
  selectUser: "Select user",
  paymentReversedNotifications: "Payment Returned",
  textMessages: "Text Messages",
  paymentReversedNotifyByEmail: "Send a notification to the tenant when an online payment is returned",
  doNotSentNotificationsAsTextMessages: "Enable SMS for late fees",
  doNotSentNotificationsAsTextMessagesRentReminders: "Send text message notifications to tenants",
  sendCashPaymentDetailsUponMoveIn: "Send cash payment details upon move in",
  cashPaymentMethodNotSelected: "Cash payment method is currently not selected.",
  paymentReversedNotifyByTextMessage: "Send a text to the tenant when a payment is returned",
  paymentReversedNotifyUsersByEmail: "Send a notification to DoorLoop users when an online payment is returned",
  useCompanyDefaultPaymentInstructions: "Use company default payment instructions",
  specifyPaymentInstructionsForProperty: "Specify payment Instructions for this property",
  paymentInstructionsLabel: "For example, send payment to Management Company...",
  tenantRequestsSettingItemTitle: "Tenant Requests",
  tenantRequestsSettingItemDescription: "Get notified and automatically assign new tenant requests.",
  ownerRequestsSettingItemTitle: "Owner Requests",
  ownerRequestsSettingItemDescription: "Get notified and automatically assign new owner requests.",
  tenantPortalNotificationsDialogInstructions:
    "You can set different notifications for each property as needed, or use your company default settings.",
  useCompanyDefaultTenantPortalNotifications: "Use company default tenant portal notifications",
  specifyTenantPortalNotificationsForProperty: "Specify notifications settings for this property",
  tenantRequestsSettings: "Tenant Requests Settings",
  ownerRequestsSettings: "Owner Requests Settings",
  notifyTenantOnReceived: "Automatically notify the tenant once a tenant request is received",
  notifyOwnerOnReceived: "Automatically notify the owner once an owner request is received",
  notifyAssignedOnReceived: "Automatically notify assigned users when a tenant request is received",
  automaticallyAssignUsers: "Automatically assign users when a tenant request is received",
  ownerRequestNotifyAssignedOnReceived: "Automatically notify assigned users when an owner request is received",
  ownerRequestAutomaticallyAssignUsers: "Automatically assign users when an owner request is received",
  resendInvitation: "Resend Invitation",
  creditDate: "Credit Date",
  leaseLateFeesSwitchLabel: "Automatic Late Fees",
  selectAccount: "Select Account",
  selectAccounts: "Select Accounts",
  paymentHasBeenReversed: "This payment was returned",
  on: "On",
  paymentReversedDescription: "To make changes to this payment, you must cancel the reversal first",
  paymentHasBeenDeposited: "This payment has already been deposited",
  depositedPaymentDescription: "To modify this payment, please unlink it from the deposit first.",
  viewDeposit: "View Deposit",
  additionalQuestions: "Additional Questions",
  answerType: "Answer Type",
  addQuestion: "Add Question",
  question: "Question",
  additionalAttachments: "Additional Attachments",
  attachmentIsRequired: "Please add this attachment",
  addAttachment: "Add Attachment",
  addAnotherFile: "Add Another File",
  addFile: "Add File",
  requiredToSubmit: "Required to submit",
  markAsMandatory: "Mark As Mandatory",
  attachmentName: "Attachment Name",
  instructions: "Instructions",
  hideSectionForProperty: "Use company default settings for this property",
  specifySettingsForProperty: "Specify settings for this property",
  fullName: "Full Name",
  freeTrialOfferDescription: "Want to get a free trial instead?",
  startUsingTheSoftware: "Start using the software right away.",
  noThanks: "No Thanks",
  rentalApplicationSettings: "Application Settings",
  rentalApplicationSettingsDescription: "Allow applicants to submit rental applications for this property",
  merchantAccountRequired: "Merchant Account Required",
  setPropertyMerchantAccount: "Set merchant account for this property",
  merchantAccountMissing: "To pay using a merchant account please",
  missingMerchantAccount: "This property does not have an active merchant account",
  missingMerchantAccountDescription:
    "Merchant account is required in order to charge applicants a fee and request credit, criminal and eviction reports from TransUnion",
  applyForMerchantAccount: "Apply for a Merchant Account",
  propertyLevelApplications: "Property Level Applications",
  allowYourProspectToApply:
    "Allow your prospects to apply for the entire property without choosing a specific property",
  chargeApplicantsApplicationFee: "Charge applicants an application fee",
  applicationFeeAmountDescription: "How much would you like to charge for an application fee?",
  applicationFeeAmountLabel: "Total Application Fee ($)",
  applicationFeeAmountError: "Application fee must be at least ${{amount}}",
  requirePayRentalApplicationFeeOnline: "Require the applicant to pay the rental application fee online",
  onlinePaymentCreditCardFee: "Online Payment Credit Card Fee:",
  transunionReport: "Transunion Report",
  moreInformationRequired: "More Information Required",
  applicationFeeSummary: "Summary",
  onlineCreditCardProcesingLabel: "Online credit card processing cost",
  finalCost: "Final Cost",
  yourProfit: "Your Revenue",
  requestTransunionReport: "Request Credit, Criminal and Eviction History reports from Transunion",
  transunionReportsCost: "Transunion reports ",
  iAccept: "I accept the",
  and: "and",
  termsAndConditions: "Terms and Conditions.",
  transunionInvalidLocale: "Reports are not available for your location / language. Contact us for more information",
  searchApplications: "Search applications",
  rentalApplicationsEmptyDataViewInstructionsText: "No Rental Applications Found",
  rentalApplication: "Rental Application",
  invalidAddress: "Invalid Address",
  identityNotVerified: "Identity Not Verified",
  selectProspect: "Select Prospect",
  propertyRentalApplicationsNotSet: "Property Not Set Up for Rental Applications",
  propertyRentalApplicationsNotSetDescription:
    "Please review your rental application settings or select a different property",
  reviewPropertyRentalApplicationSettings: "Review Property Rental Application",
  automaticallyUpdateProspect: "Automatically update the prospect info with data entered on the application",
  pendingVerification: "Identity Unverified",
  reportsAvailable: "Reports Available",
  readyForReportsRequest: "Ready for Reports Request",
  reportExpired: "Report Expired",
  expectedMoveIn: "Expected Move In",
  relatedApplications: "Related Applications",
  join: "Join",
  decision: "Decision",
  decisionNotes: "Decision Notes",
  decisionUpdatedBy: "Decision Updated By",
  decisionUpdatedAt: "Decision Updated At",
  updateDecision: "Update Decision",
  rentalApplicationDeleteConfirmationText: "Are you sure you want to delete this rental application?",
  rentalApplicationDeleteLoadingText: "Deleting Rental Application...",
  rentalApplicationDeleteSuccessText: "The rental application was deleted successfully",
  rentalApplicationSendRentalApplication: "Send Application",
  rentalApplicationSendFailure: "Can’t send application, missing fields - please update contact info",
  rentalApplicationReminderSendFailure: "Can’t send reminder",
  unitCurrentlyListedError:
    "This unit is currently listed as active. To prevent prospects from applying, please proceed by removing the unit from the listing.",
  rentalApplicationSent: "Application Sent",
  postChargeReminderSend: "Reminder sent",
  rentalApplicationReminderSent: "Reminder Sent",
  rentalApplicationStatus: "Application Status",
  rentalApplicationReportStatus: "Report Status",
  decisionStatusDialogDescription: "",
  decisionStatusDialogWarningText:
    "If you reject a tenant’s application, you must provide an Adverse Action Letter. DoorLoop does not draft or send this letter for you, but you can notify the tenant with an automated response email. Consult an attorney to ensure legal compliance.",
  decisionStatusDialogWarningTitleText: "Please Note",
  desiredMoveInDate: "What’s your desired move in date?",
  applyAsTenant: "Apply as a tenant",
  applyAsCoSigner: "Apply as a co-signer",
  applyingAlone: "I’m applying alone",
  applyingWidthCoApplicants: "I have co-applicants",
  applyingAsTenantOrCoSigner: "Are you applying as a tenant or a co-signer?",
  whoAreYouMovingInWith: "Who are you moving in with",
  shareApplication: "Share Application",
  shareApplicationDescription:
    "You can share the link below with your co-applicants or add their information below and we will send them an invite on your behalf:",
  publicLink: "Public Link",
  inviteCoApplicants: "Invite Co-applicants",
  publicLinkDescription: "Anyone with that link can view and edit",
  copyLink: "Copy Link",
  textCopied: "Text Copied",
  identification: "Identification",
  gender: "Gender",
  bioLabel: "Tell us why you’re awesome!",
  oneOrMorePets: "Yes, I have one or more pets",
  noPets: "I don’t have any pets",
  petInformation: "Pet Information",
  dependentInformation: "Dependent Information",
  petName: "Pet Name",
  petType: "Pet Type",
  petBreed: "Breed",
  petColor: "Color",
  petAge: "Age",
  petWeight: "Weight (lbs)",
  serviceAnimal: "Service Animal",
  spayedOrNeutered: "Spayed/neutered",
  myPetIsSpayedOrNeutered: "My pet is spayed/neutered",
  myPetIsServiceAnimal: "My pet is a service animal",
  addAnotherPet: "Add another pet",
  oneOrMoreVehicles: "Yes, I have one or more vehicles",
  noVehicles: "I don’t have any vehicles",
  vehicles: "Vehicles",
  vehicleMake: "Make",
  vehicleModel: "Model",
  vehicleYear: "Year",
  vehicleColor: "Color",
  licensePlate: "License Plate",
  addAnotherVehicle: "Add another vehicle",
  doYouHaveAnyPets: "Do you have any pets?",
  doYouHaveAnyVehicles: "Do you have any vehicles?",
  doYouHaveAnyDependants: "Do you have any dependants?",
  oneOrMoredependants: "Yes, I have dependants",
  noDependants: "No, I don’t have dependants",
  addAnotherDependent: "Add another dependent",
  dependantInformation: "Dependant information",
  employmentInformation: "Employment Information",
  titleOrPosition: "Title/Position",
  jobType: "Job Type",
  annualIncome: "Annual Income",
  currentlyWorkHere: "I currently work here",
  supervisorInformation: "Supervisor Information",
  supervisorName: "Supervisor Name",
  supervisorEmail: "Supervisor Email",
  supervisorPhone: "Supervisor Phone",
  incomeInformation: "Income Information",
  source: "Source",
  previousAddress: "Previous Address",
  yourCurrentAddress: "Current Address",
  ownProperty: "I own this property",
  rentProperty: "I rent this property",
  monthlyPayment: "Monthly Payment",
  landlordOrCompanyName: "Landlord / Company name",
  contactPhone: "Contact Phone",
  contactEmail: "Contact Email",
  contactPerson: "Contact Person",
  contactName: "Contact Name",
  currentlyLiveHere: "I currently live here",
  reasonForLeaving: "Reason for leaving",
  maritalStatus: "Marital Status",
  reviewAndPayment: "Review and Payment",
  creditCard: "Credit Card",
  creditCardOrDebitCard: "Credit Card / Debit Card",
  cashPayments: {
    title: "Cash Payments (Western Union)",
    warnings: {
      amountLimit:
        "Note: Tenant's balance exceeds <Limit/>. This sum cannot be paid in a single cash payment transaction per Western Union regulations. Enable your tenant to make payments, by opening partial payments.",
      amountLimitWithPartialPayments:
        "Note: Tenant's balance exceeds <Limit/>. This sum cannot be paid in a single cash payment transaction per Western Union regulations. Be aware that your tenant will need to split the payment."
    },
    overviewList: {
      formerTenantTooltip: "This tenant was removed from the lease on {{date}}",
      formerTenant: "(Former Tenant)",
      copyDetails: "Copy CashPayments Details",
      downloadPdf: "Download CashPayments Slip",
      amount: "<strong>Amount</strong>: <value/>",
      accountNumber: "<strong>Account Number</strong>: {{value}}",
      billerName: "<strong>Biller Name</strong>: {{value}}"
    },
    tenantPortal: {
      dialogTitle: "CashPayments Slip",
      dialogSubtitle: "Share the information below with the payee for  cash bill payment at authorized locations.",
      yourPaymentInformation: "Your Payment Information",
      lookForQuickCollectServiceLocations: "Look for Quick Collect service locations",
      utilizeTheQuickCollect:
        "Utilize the Quick Collect service available  at Western Union, 7-eleven stores, Rite Aid, and other participating locations.",
      findTheNearestLocation: "Find the nearest location",
      partialPaymentAvailable: "Partial payment available",
      partialPaymentUnavailable: "Partial payment unavailable",
      serviceFee: "<fee/> service fee per payment*",
      instructions: {
        title: "Store Instructions",
        one: "1.  Enter Biller name & Account number.",
        two: "2.  Enter the total amount as shown above.",
        three: "3.  Collect the cash from the customer.",
        four: "4.  Provide the customer with a receipt."
      },
      paymentDetails: {
        amount: "Amount",
        accountNumber: "Account Number",
        billerName: "Biller Name"
      }
    }
  },
  confirmYourIdentity: "confirm your identity",
  examQuestionsDescription: "",
  examAnswerValidationText: "Please answer all the questions",
  submitExamFailed: "Some or all of your answers were incorrect. Please click retry to try again",
  viewReports: "View Reports",
  unFilledApplication: "This application hasn’t been filled out yet.",
  unFilledApplicationDescription: "You can send the link below or send a reminder to the applicant to fill it out.",
  unFilledRentalApplication: "Attract Prospects with Rental Applications!",
  sendRentalApplicationEmptyDescription:
    "Send out your rental application, and ensure the best-quality tenants for every property!",
  unFilledRentalApplicationDescription:
    "Copy the link below, send out your rental application, and ensure the best-quality tenants for every property!",
  phoneNumber: "Phone Number",
  rentalApplicationIsNotConfigured: "Visit the rental application settings to fix errors and enable the feature",
  rentalApplicationUnitIsNotOpenForListingTooltip:
    "Rental applications for this unit are currently closed. To proceed, please modify the setup for this specific unit.",
  rentalApplicationSubmitted: "Rental application submitted!",
  rentalApplicationInProgressTooltip: "Rental application is in progress!",
  rentalApplicationApplicantAddressIsNotValidText:
    "The details of the applicant’s residential history addresses could not be validated. Please request a local or USPS-verified address.",
  rentalApplicationApplicantIdentityIsNotValidText:
    "The details provided by the applicant to TransUnion could not be validated. Please advise them to get in touch with TransUnion's support.",
  totalIncome: "Total Income",
  spayed: "Spayed",
  notSpayed: "Not Spayed",
  residence: "Residence",
  own: "Own",
  rent: "Rent",
  delinquency: "Delinquency",
  moveOut: "Move-out",
  moveInMoveOut: "Move-in / Move-out",
  present: "Present",
  year: "Year",
  reportsWereNotRequested: "Reports were not requested",
  rentalApplicationApplicantAddressNotValidDescription:
    "To receive screening reports, kindly request the applicant to provide a local or USPS-verified address.",
  rentalApplicationApplicantAddressNotValidTitle:
    "Applicant's identity verification was unsuccessful due to incomplete or unrecognized residential history addresses",
  reportsWereNotRequestedDescription:
    "Credit, Criminal and Eviction history reports were not requested for this rental application. Please review your rental application settings if you wish to turn them on for future applications",
  openRentalApplicationSettings: "Open Rental Application Settings",
  unableToVerifyIdentity: "Unable to verify identity",

  unableToVerifyIdentityDescription:
    "Transunion was unable to verify the applicant’s identity. Please have them call Transunion customer support at (866) 775-0961 for assistance with completing their screening over the phone. \nIf needed, you can send them the link below to resubmit their application at no additional cost.",
  reportsCouldNotVerified: "TransUnion was unable to verify the applicant's identity",
  reportsCouldNotVerifiedDescription:
    "The prospect's application was submitted, but the details provided to TransUnion could not be validated. Kindly instruct the applicant to review their information and reach out to TransUnion's support at 1-(866) 775-0961 for assistance. Once they've reviewed their details, please request them to resubmit the application.",
  onceTheyReviewRequestSubmit: "Once they've reviewed their details, please request them to resubmit the application.",
  reportsDeliveryFailed: "Reports delivery failed",
  reportsDeliveryFailedDescription:
    "Transunion failed to generate reports for this applicant. \nThis usually happens if the applicant entered invalid information in their application. You can send them the link below to resubmit their application at no additional cost.",
  unableToVerifyIdentityDescriptionForProspect:
    "We are unable to verify your identity online. Please call Transunion customer support at (866) 775-0961 for assistance with completing your screening over the phone. Following this, if you have entered incorrect information and need to update your information, please reach out to your property manager for a link to update your application.",
  identityVerified: "Identity Verified",
  identityVerifiedDescription:
    "The applicant has confirmed their identity, please click the button below to request Transunion to generate Credit, Eviction and Criminal reports for this applicant.",
  requestReports: "Request Reports",
  recommendedWithIcon: "Recommended ⚡️",
  identityVerifiedSuccessfully: "Identity Verified Successfully",
  canRequestScreeningReports: "You can now request screening reports:\n credit, eviction and criminal record.",
  chooseAProspectWhoWillReceiveTheApplication: "Choose the prospect who will receive the application.",
  cardInformation: "{{brand}} {{last4digits}}",
  reportsGeneratedInProgress: "Reports Generation in Progress",
  accessReportsAfterPayment: "To access the reports please complete the payment.",
  transunionReportFee: "TransUnion Reports Fee",
  transunionScreeningReports: "TransUnion screening reports",
  reportsProcessedIn2Days: "Generating new reports may take up to two days.",
  payAndRequestReports: "Pay & Request Reports",
  reportsGeneratedInProgressDescription:
    "Credit, Criminal, and Eviction reports are being prepared and are typically ready within 72 hours. To ensure the highest quality, some records may require courthouse verification, which could extend this timeframe. Check back here periodically for updates.",
  reportExpiredOn: "Report Expired on",
  reportExpiredOnDescriptiOn:
    "As per the Transunion Terms of Service, DoorLoop is not permitted to save a copy of these reports in order to protect the applicant’s privacy,",
  criminalReport: "Criminal Report",
  criminalReportDescription: "Applicant's criminal record report",
  creditReport: "Credit Report",
  creditReportDescription: "Applicant's credit history report",
  evictionReport: "Eviction Related Proceedings",
  evictionReportDescription: "Applicant's eviction history report",
  expiresIn: "Expires in",
  expiresAt: "Expires at",
  applicationHasBeenSubmitted: "Application has been submitted",
  applicationHasBeenSubmittedDescription: "Your rental application has been sent successfully.",
  viewCompanyInformation: "View Company Information",
  viewRentalApplicationSettings: "View Rental Applications Settings",
  landlordInformation: "Landlord Information",
  deletePolicy: "Delete Policy",
  report: "Report",
  cleared: "Cleared",
  showBeginningBalance: "Beginning Balance",
  notCleared: "Not Cleared",
  connectNow: "Connect to Bank",
  bankConnectDialogHeaderDescription: "You are making a connection for the following account:",
  bankConnectRepairConnection: "You are repairing the connection for the following account:",
  bankConnectRefreshTransactions: "Refresh for transaction updates",
  youHave: "You have",
  refresh: "Refresh",
  manual: "Manual",
  connectYourAccounts: "Connect your accounts",
  connectYourAccountsDescription:
    "Connecting your bank allows DoorLoop to securely pull transactions from your\n" +
    "bank or credit card accounts to help you with reconciliation.",
  connectAccount: "Connect account",
  connectAnotherAccount: "Connect another account",
  asOf: "As of",
  updating: "Updating...",
  repairing: "Repairing...",
  reauthenticate: "Re-authenticate",
  review: "Review",
  disconnectBankAccount: "Disconnect Bank",
  unreconciledTransactions: "Unreconciled Transactions",
  reconnect: "Reconnect",
  repairConnection: "Repair Connection",
  confirmRepairConnectionPart1: "Doorloop is about to reconnect with",
  confirmRepairConnectionPart2:
    "Your username and password will have to be entered again, but don't worry, no information will be lost.",
  syncError: "Sync Error",
  bankConnectionNeedsRepair: "The connection has been damaged, please repair.",
  bankConnectionNeedsReauthentication: "The connection has expired, please re-authenticate.",
  checkListScreenHeaderDescription: "Select the boxes on the left to print each check",
  balanceUpdated: "Balance updated",
  lastUpdated: "Last Updated:",
  matchedTransaction: "Matched Transaction",
  recordNewExpense: "Record as New Expense",
  recordNewDeposit: "Record as New Deposit",
  recordNewTransfer: "Record as New Transfer",
  matchExistingTransactionExplainer:
    "Use this link to match this bank transaction to one or more DoorLoop transactions",
  matchExistingTransaction: "Match to an Existing Transaction",
  newExpenseTotal: "New Expense Total",
  newDepositTotal: "New Deposit Total",
  difference: "Difference",
  exclude: "Exclude",
  excludeTooltip: {
    header: "Exclude Transaction",
    description: "Click this to exclude transactions you do not want to account for in DoorLoop."
  },
  excluded: "Excluded",
  matchExpenseValidationMessage: "Transaction amount must be equal to the expense amount",
  matchDepositValidationMessage: "Transaction amount must be equal to the deposit amount",
  categorized: "Categorized",
  undo: "Undo",
  match: "Match",
  matchTransactions: "Match Transactions",
  matchSummary: "Match Summary",
  bankTransaction: "Bank Transaction",
  matchedTransactions: "Matched Transactions",
  matchedTransactionsValidationMessage:
    "Attention: The bank transaction and  the selected transactions difference needs to be $0",
  pending: "Pending",
  expired: "Expired",
  failedToUploadAttachments: "Failed to upload attachments",
  openingBalanceRequired: "Opening Balance Required",
  openingBalanceRequiredDescription: "Please set the account opening balance before enabling BankConnect",
  openingBalanceDateChangeInfo:
    "We are presenting transactions from your opening balance date: {{date}}. To change this date, adjust your <0>{{openingBalance}}</0>.",
  setOpeningBalance: "Set Opening Balance",
  openingBalance: "Opening Balance",
  quickbooksSyncToggleTitle: "Sync data from DoorLoop to Quickbooks",
  quickbooksSyncToggleDescription:
    "Once enabled, you will be redirected to Quickbooks to authorize DoorLoop to access your account.",
  syncStartDate: "Sync start date",
  syncStartDateDescription:
    "If you wish to limit which transactions get pushed to Quickbooks, you can enter the transaction start date.",
  pleaseNote: "Please Note:",
  weRecommendYou: "We recommend you ",
  readOurKnowledgeBaseArticle: "read our knowledge base article.",
  learnQuickbooksSync:
    "Now that your QuickBooks Integration is active, we recommend reading our knowledge base article to learn how DoorLoop sync your information.",
  beforeTurningOnIntegration:
    "Before activating your QuickBooks Integration, we recommend reading our knowledge base article to ensure you sync your account correctly.",
  quickbooksIntegrationEnabled: "Quickbooks integration is enabled",
  quickbooksIntegrationEnabledDescription: "It may take up to 24 hours for your DoorLoop data to appear in Quickbooks.",
  confirm: "Confirm",
  reminders: "Reminders",
  needsReview: "Needs Review",
  autoMatched: "Auto-Matched",
  deletePaymentTokenConfirmationText: "Are you sure you want to delete this payment method?",
  activeAutoPay: "Active Auto-Pay Detected",
  makeAnotherPaymentQuestion: "You already have an active Auto-Pay. Do you want to proceed with an extra payment?",
  makeAnotherAutoPayQuestion: "Would you like to setup another Auto-Pay?",
  activeAutoPayDescription: "Please review your auto-pay details before making another payment",
  makeAnotherPayment: "Make Another Payment",
  setupAnotherAutoPay: "Add New Auto-Pay",
  viewAutoPayDetails: "View Auto-Pay Details",
  viewAutoPaySettings: "View Current Settings",
  yourNextRecurringChargeOf: "Your next recurring charge of",
  isDuOn: "is due on",
  dueOn: "are due on <date />",
  viewPolicy: "View Policy",
  editPolicy: "Edit Policy",
  dragFilesOrClickHereToUpload: "Drag files or click here to upload",
  select: "Select",
  cardBenefit1: "Pay easily with <strong>Apple Pay</strong> or <strong>Google Pay</strong>",
  cardBenefit2: "Avoid high interest rates by paying with debit card",
  cardBenefit3: "Earn credit card points, cashback and more",
  cardBenefit4: "No returned payment fees for insufficient funds",
  bankBenefit1: "Maintain sufficient funds in your account.",
  bankBenefit2: "Insufficient funds will trigger a $25 returned payment fee.",
  bankInsufficientFunds: "Insufficient funds will trigger a ${{amount}} returned payment fee.",
  payWithCard: "Pay with Card",
  payWithBank: "Pay with Bank (ACH)",
  payWithCash: "Pay with Cash",
  paymentMethodStep: {
    stripeDisclaimer: {
      title: "Secure Payments with SSL Encryption",
      description: "Guaranteeing the protection of your financial and personal information in every transaction.",
      poweredBy: "Powered By"
    },
    bankAccountLearnMoreDialog: {
      learnMore: "Learn More",
      title: "Choose how to connect your bank account",
      description: {
        part1:
          "DoorLoop, in partnership with Stripe, the world's leading payment provider, offers you two secure options to connect your bank account:",
        part2: "Choose between automatically syncing your account or manually inputting your details."
      }
    },
    creditCardBanner: {
      title: "Benefits of Paying Rent with Your Credit/Debit Card:",
      points: {
        point1: "Pay easily with <strong>Apple Pay</strong> or <strong>Google Pay</strong>",
        point2: "Avoid high interest rates by paying with a debit card",
        point3: "Earn credit card points, cashback and more",
        point4: "No returned payment fees for insufficient funds"
      }
    }
  },
  transactionDate: "Transaction Date:",
  amountMustBeGreaterThanZero: "Amount must be greater than zero",
  chargeLateFees: "Charge late fees",
  tenantRequestSettingsInstructionsDescription:
    "You can add additional instructions for your tenants that will be displayed when new requests are created from the tenant portal",
  tenantRequestSettingsInstructions: "Tenant Request Instructions",
  ownerRequestSettingsInstructions: "Owner Request Instructions",
  ownerRequestSettingsInstructionsDescription:
    "You can add additional instructions for your owners that will be displayed when new requests are created from the owner portal",
  ownerReportsInstructionsDescription:
    "Choose which reports owners can access. Reports are still limited by the properties and objects this owner has access to even if they have access to a report",
  activityFeed: {
    activityFeed: "Activity Feed",
    createdByPersonType: "{{userName}} ({{personType}}) created this {{resourceType}}",
    createdBy: "{{userName}} created this {{resourceType}}",
    updateBy: "{{userName}} updated this {{resourceType}}",
    deletedBy: "{{userName}} deleted this {{resourceType}}",
    request: "Request",
    viewAll: "View All",
    you: "You",
    propertyManager: "Property Manager",
    visibleToTenant: "Tenant Notified",
    deletedField: "Deleted {{fieldName}}",
    changedFromTo: "Changed {{fieldName}} from {{olvValue}} to {{newValue}}",
    changedTo: "Changed {{fieldName}} to {{newValue}}",
    createdBySystem: "System created this {{resourceType}}"
  },
  timeZone: "Time Zone",
  timeZoneDescription: "Please select your timezone below:",
  recurring: {
    oneTimeTask: "One Time Task",
    recurringTask: "Recurring Task",
    generalEntry: "Journal Entry",
    oneTimeGeneralEntry: "One Time Journal Entry",
    recurringGeneralEntry: "Recurring Journal Entry",
    recurringTasks: "Recurring Tasks",
    oneTimeWorkOrder: "One Time Work Order",
    recurringWorkOrder: "Recurring Work Order",
    recurringWorkOrders: "Recurring Work Orders",
    oneTimeBill: "One Time Bill",
    recurringBill: "Recurring Bill",
    oneTimeVendorBill: "One Time Vendor Bill",
    recurringVendorBill: "Recurring Vendor Bill",
    recurringVendorBills: "Recurring Vendor Bills",
    oneTimeExpense: "One Time Expense",
    recurringExpense: "Recurring Expense",
    recurringExpenses: "Recurring Expenses",
    numberOfDaysUntilDue: "Number of days until due",
    repeatForever: "Repeat forever",
    forever: "Forever",
    newTransaction: "New Transaction",
    recurringBills: "Recurring Transactions",
    recurringTransactions: "Recurring Transactions",
    emptyDataViewInstructionsTextBillsExpenses: "No Recurring Expenses Or Vendor Bills Found",
    emptyDataViewInstructionsTextTasks: "No Recurring Tasks Or Work Orders Found",
    byX: "By {{x}}",
    byXFromCreation: "By {{x}} {{daysFromCreation}}",
    frequencies: {
      daily: "Daily",
      weekly: "Weekly",
      everyTwoWeeks: "Every 2 Weeks",
      monthly: "Monthly",
      everyTwoMonths: "Every 2 Months",
      quarterly: "Quarterly",
      everySixMonths: "Every 6 Months",
      annually: "Annually"
    },
    helpPanel: {
      description: "Create recurring transactions to save time.",
      createRecurringBill: "How do I create a recurring bill or expense?",
      createRecurringExpense: "How do I create a recurring expense?",
      editDeleteRecurringBill: "How do I edit or delete a recurring bill or expense?"
    }
  },
  incomingPayments: {
    navTitle: "Incoming Payments",
    title: "Online Incoming Payments",
    subtitle: "Efficiently monitor your online deposits in one place.",
    merchantAccountSetup: "Merchant Account Setup",
    merchantAccountApplication: "Merchant Account Application",
    stripeConnectDialog: {
      title: "Merchant Account Application",
      updateTitle: "Update Merchant Information",
      subtitle: "To get started, please complete the Stripe application form.",
      updateSubtitle:
        "In order to update your account information, we will redirect you to our payment processing partner Stripe.",
      merchantAccount: "Merchant Account",
      account: "Account:",
      beforeWeStart: "Before we start:",
      point1:
        "This application must be submitted by an executive, senior manager, or someone else with significant responsibility for controlling and managing your business.",
      point2: "All applications are subject to underwriting and risk team approval."
    },
    summaryTab: {
      tabTitle: "Summary",
      title: "Overview",
      subtitle: "Track your merchant accounts and payment statuses",
      paymentsInProgress: "Payments in Progress",
      filterTitle: "Merchant Account",
      pendingAndProcessingAvailableSoon: {
        title: "Payments Processing",
        subtitle: "Funds that are currently being processed"
      },
      succeededAvailableSoon: {
        title: "Transactions Available to Pay Out Soon",
        subtitle: "Funds that have been successfully collected"
      },
      recentlyCompleted: {
        title: "Recently Deposited",
        amountOfDays: "(7 Days)",
        subtitle: "Funds that have already been deposited in your bank account"
      },
      paymentsStatus: {
        transactionsPendingReview: "Transactions pending risk review",
        estimatedPayoutOn: "Estimated payout on",
        depositExpectedToArriveBy: "Deposit expected to arrive by",
        deposited: "Deposited"
      },
      paymentsChip: {
        pendingReview: "Pending Review",
        processing: "Processing",
        succeeded: "Succeeded",
        disputed: "Disputed",
        failed: "Failed",
        refunded: "Refunded",
        paid: "Paid",
        inTransit: "In Transit"
      },
      numberOfPayments: "({{numberOfPayments}} Payments)",
      viewAllTransactions: "View All Transactions",
      total: "Total"
    },
    emptyState: {
      titleLeftSide: "Set Up a Merchant Account to Accept Online Payments",
      subtitleLeftSide: "Apply for a merchant account to receive online payments from your tenants",
      action: "Set Up Your Payment Account",
      titleRightSide: "Receive online payments from tenants - Apply for Merchant Account",
      subtitleRightSide: "DoorLoop partners with Plaid to connect your bank.",
      point1:
        "Apply for a merchant account - click Apply Now for the bank account on which you want to apply for a Merchant Account.",
      point2: "Get started with Stripe - Follow the steps and enter your contact info.",
      point3: "Add your bank to receive payouts - add the bank you want to receive your payouts.",
      point4: "Application waiting for review - wait for the merchant team to review your application and approve it.",
      noTransactions: "No transactions found",
      noDeposits: "No deposits found"
    },
    transactionsTab: {
      title: "Transactions",
      availableOnSucceededHintText: "Expected to arrive on",
      availableOnDepositedHintText: "Deposited on",
      listHeader: {
        paymentDate: "Payment Details",
        amount: "Amount",
        type: "Type",
        status: "Status",
        name: "Name",
        estArrival: "Est. Arrival"
      },
      noDateTooltipTitle: "The date of availability will be published soon",
      availableOnExplanationTooltip:
        "Date of expected deposit to your bank account.\n" + "Green indicates your payment has been deposited."
    },
    arrivalDate: "Arrival Date",
    usageAndLimitsInfoTab: {
      tabTitle: "Usage & Limits Info",
      title: "Merchant Accounts Monthly Usage",
      subtitle: "You can receive up to <achLimit/> total, which includes up to <cardLimit/> in card payments.",
      listTitle: "Accounts Limits Information",
      popoverItems: {
        updateInformation: "Update Information",
        requestToUpdateLimits: "Request to Update Limits"
      },
      emptyListText: "Please navigate to the <Link>Setup page</Link> to configure your DoorLoop merchant accounts.",
      requestLimitIncrease: "Request limit increase",
      amountUsed: "<total/> of <used/> Used"
    },
    depositsTab: {
      title: "Deposits",
      listHeader: {
        bank: "Bank",
        amount: "Amount",
        transactions: "Transactions",
        status: "Status",
        expectedArrivalDate: "Expected Arrival Date"
      }
    },
    merchantAccountSetupTab: {
      title: "Account",
      subtitle:
        "Setup your DoorLoop merchant accounts to securely accept tenants' online payments and get paid as fast as 3 business days.",
      setupStep: "Setup Step",
      setupComplete: "Setup Complete"
    }
  },
  outgoingPayments: {
    navTitle: "Outgoing Payments",
    title: "Online Outgoing Payments",
    paymentAccountSetup: "Payment Account Setup",
    outgoingPayments: "Outgoing Payments",
    emailRecommended: "Email (Recommended)",
    mailCheck: "Mail Check",
    directDeposit: "Direct Deposit",
    paymentSendingMethod: "Payment Sending Method",
    enableOutgoingPaymentsForThisTenant: "Enable send payments for this tenant",
    enableOutgoingPaymentsForThisVendor: "Enable send payments for this vendor",
    enableOutgoingPaymentsForThisOwner: "Enable send payments for this owner",
    emailInfoDescription: "An email will be sent to the {{personType}}, asking them how they prefer to get paid.",
    emailInfoDescription2:
      "This is the preferred payment method, as it requires the user to authenticate their bank account, and gives them control if they ever need to make any changes etc.",
    theyCanChooseBetween: "They can choose between:",
    directDepositSummary: "Have the funds deposited directly to their bank account.",
    printAndDeposit: "Print and Deposit",
    printAndDepositDesc: "Print the payment as a check and then deposit it through their bank’s mobile app.",
    emailSettings: "Email Settings",
    billingEmail: "Billing Email",
    askThePayeeForAPIN: "Ask the payee for a PIN before accepting this payment",
    PINCode: "PIN Code",
    hintWillBeVisible: "Hint (will be visible to payee)",
    lastUpdatedByOnFrom: "Last updated by {{updatedBy}} on {{updatedOn}} from IP: {{updatedFrom}}",
    mailCheckInfoDescription: "A check will be printed and mailed on your behalf to the address below",
    directDepositInfoDescription: "You can manually enter the routing and account number for direct deposit.",
    directDepositInfoDescription2:
      "The preferred payment sending method is email as it would allow the recipient to input and verify this information.",
    accountSettings: "Account Settings",
    helpHint: "Help Hint:",
    enableOutgoingPayments: "Enable send Payments",
    connectToCheckbookDesc1: "Connect DoorLoop to Checkbook.",
    connectToCheckbookDesc2:
      "Follow the steps below to connect DoorLoop to Checkbook and start sending electronic payments to your owners, vendors and tenants directly from DoorLoop.",
    connectToCheckbookDesc3: "DoorLoop partnered with Checkbook.io, an industry leader for send payments.",
    connectToCheckbook: "Connect to Checkbook",
    learnMoreAboutCheckbook: "Learn more about Checkbook",
    sendingLimits: "Sending Limits",
    enableOutgoingPaymentsWithCheckbookIO: "Enable send payments with Checkbook.io",
    mapAccounts: "Map Accounts",
    mapAccountsDesc: "Please select which Checkbook account to use for each one of your DoorLoop bank accounts",
    selectDoorLoopAccount: "Select DoorLoop Account",
    createNewCheckbookAccount: "Create new Checkbook account",
    didYouKnow: "Did you know?",
    outgoingPaymentsInfoDidYouKnow: "You can setup user roles to control which users have access to send payments.",
    duplicateAccountMappingErrorMessage:
      'Can\'t map two Checkbook.io accounts to the same DoorLoop account ("{{account1}}" and "{{account2}}")',
    checkbookApiSecret: "API Secret",
    getCheckbookAPIkeys: "Get Checkbook API Keys",
    getCheckbookAPIkeysDescription: "Copy and paste your Checkbook API keys in the fields below",
    createCheckbookAccount: "Create Account ",
    createCheckbookAccountDescription: "Create your Checkbook account and link it to DoorLoop",
    checkbookApiKey: "API Key",
    searchPayments: "Search payments",
    viaPhysicalCheck: "Via Physical Check",
    viaDirectDeposit: "Via Direct Deposit",
    addressSettings: "Address Settings",
    missingPaymentInfo: "Missing Payment Info",
    recipient: "Recipient",
    paid: "Paid",
    sent: "Sent",
    inProcess: "In Process",
    unpaid: "Unpaid",
    void: "Void",
    expired: "Expired",
    printed: "Printed",
    mailed: "Mailed",
    failed: "Failed",
    refunded: "Refunded",
    send: "Send",
    resend: "Resend",
    incoming: "Incoming:",
    outgoing: "Outgoing:",
    ownerOutgoingPaymentInfo: "Owner Send Payment Info",
    tenantOutgoingPaymentInfo: "Tenant Send Payment Info",
    vendorOutgoingPaymentInfo: "Vendor Send Payment Info",
    outgoingPaymentsSettingsDescription: "Set up and configure Checkbook.io integration",
    areYouSureYouWantToSendThisPayment: "Are you sure you want to send this payment?",
    paymentHasBeenSent: "Payment has been Sent",
    voidConfirmationText: "Are you sure you want to void this payment?",
    voidPaymentLoadingText: "Voiding payment...",
    addInfo: "Add Info",
    unverifiedAccount: "Unverified Account",
    ensureBankVerified: "Ensure your bank account is verified for payment. Complete the process on Checkbook.io",
    unnamedBankAccount: "Unnamed Bank Account",
    paymentReadyToSend: "Payment Ready to Send",
    paymentReadyToSendDesc: "This transactions is ready to send. You can still make changes to this transaction.",
    sendPayment: "Send Payment",
    digitalPaymentSent: "Digital Payment Sent",
    digitalPaymentSentDesc:
      "If you make changes to this transaction, the digital payment will not be affected. Changes will only take place inside DoorLoop. If you want to update the send payment please void the payment first, and then resend it.",
    physicalPaymentProcessing: "Physical Payment Processing",
    physicalPaymentProcessingDesc:
      "This transaction is linked to a send ePay and cannot be altered unless voided first.",
    digitalPaymentVoided: "Digital Payment Voided",
    digitalPaymentVoidedDesc:
      "This transaction is linked to a send ePay that has been canceled. You can make changes and retry.",
    physicalPaymentFailed: "Physical Payment Failed",
    physicalPaymentFailedDesc: "This transaction is linked to a send ePay that has been canceled.",
    resendPayment: "Resend Payment",
    pleaseSetUpPayeesOutgoingPayments: "Please set up payee's send payments",
    voided: "Voided",
    digitalPaymentPaid: "Digital Payment Paid",
    physicalPaymentMailed: "Physical Payment Mailed",
    paymentPaidDesc:
      "This transaction is linked to a send ePay that has been paid. Changes to this transaction will not be reflected on the actual payment.",
    digitalPaymentExpired: "Digital Payment Expired",
    physicalPaymentExpired: "Physical Payment Expired",
    paymentExpiredDesc: "This transaction is linked to a send ePay that has expired. You can make changes and retry.",
    digitalPaymentRefunded: "Digital Payment Refunded",
    physicalPaymentRefunded: "Physical Payment Refunded",
    paymentRefundedDesc: "This transaction is linked to a send ePay that was refunded. You can make changes and retry.",
    digitalPaymentFailed: "Digital Payment Failed",
    paymentFailedDesc:
      "This transaction is linked to a send ePay that has failed. You can make changes and retry again.",
    modifyLimit: "Modify Limit",
    modifyLimitDesc:
      "Checkbook.io limits transactions to $2,000 per day and $15,000 per month. If necessary, please submit a request to increase your limits.",
    findOutHowToIncreaseYouLimits: "Find out how to increase you limits.",
    submitARequest: "Submit a request",
    emptyState: {
      titleLeftSide: "Send Payments and Pay Your Bills",
      subtitleLeftSide:
        "Send payments to your owners, vendors, and even refund tenants with eCheck/ACH or printed checks through Checkbook.io.",
      action: "Start Your Payment Account Setup",
      titleRightSide: "Ensures Your Bookkeeping Matches What Happened In Real Life",
      subtitleRightSide: "Reconciliation will guard you against duplicate, incorrect and missing transactions.",
      point1: "Start by choosing the DoorLoop account you want to reconcile.",
      point2: "Enter the bank or credit card statement ending balance amount and date.",
      point3: "Compare your transactions in DoorLoop to your statement, checking them as you go."
    }
  },
  apiKeys: {
    apiKeysDialogExplain: "You can generate API keys below that can be used to connect to DoorLoop’s API."
  },
  fromDASHto: "{{from}} - {{to}}",
  createdAtDate: "{{createdAt}}",
  updatedAtDate: "{{updatedAt}}",
  approveByOwner: "Approve By Owner",
  propertyAccountBalance: "Property Account Balance",
  propertyAvailableFounds: "Property Available Funds",
  vendorPayment: "Vendor Payment",
  lineItems: "Line Items",
  paymentWarningTitle: "Payments have been applied to this bill",
  paymentWarningDescription:
    "In order to keep your property financials accurate, some items on this bill cannot be modified. If you wish to make changes, please delete the payments first.",
  saveAndNew: "Save & New",
  revenueAccount: "Revenue Account",
  specifyFeesForApplication: "Specify fees for this application",
  bankReconciliation: {
    title: "Bank Reconciliation",
    reconciled: "Reconciled",
    notReconciled: "Not Reconciled",
    newBankReconciliation: "New Bank Reconciliation",
    bankReconciliationEmptyDataViewInstructionsText: "No Bank Reconciliation Found",
    reconcileAccount: "Reconcile an Account",
    statementInformation: "Statement Information",
    reconcileAccountDescription: "Select the account you would like to reconcile",
    reconciledAt: "Reconciled At",
    helpPanel: {
      learnMoreAboutReconciliation: "Learn more about Reconciliation",
      description: "",
      preformReconciliation: "How do I perform bank reconciliation?",
      fixProblems: "How do I fix problems with reconciliation?",
      undo: "How do I undo a reconciliation?"
    }
  },
  noOpeningBalance: "This account does not have a beginning balance",
  noOpeningBalanceDescription: "Beginning balance is required before creating reconciliation",
  addBeginningBalance: "Add Beginning Balance",
  statementBeginningBalance: "Statement Beginning Balance",
  lastStatementEndingDate: "Last Statement Ending Date",
  statementEndingBalance: "Statement Ending Balance",
  statementEndingDate: "Statement Ending Date",
  endingBalance: "Ending Balance",
  beginningBalance: "Beginning Balance",
  clearedBalance: "Cleared Balance",
  saveForLater: "Save for Later",
  finishReconciliation: "Finish Reconciliation",
  resumeReconciliationNotificationTitle: "This account already has a reconciliation in progress",
  resumeReconciliationNotificationDescription: "Click on “Next” to continue reconciling",
  createdByUser: "By {{userName}}",
  undoReconciliation: "Undo Reconciliation",
  reconciliationReport: "Reconciliation Report",
  numberOfChecksAndPayments: "Checks and Payments Cleared ({{paymentsClearedNum}})",
  numberOfDepositsAndCredits: "Deposits and Other Credits Cleared ({{depositsClearedNum}})",
  bankStatementEndingBalance: "Bank Statement Ending Balance",
  unclearedTransactionsAsOf: "Uncleared transactions as of",
  registerBalanceAsOf: "Register balance as of",
  changesNotIncluded: "Any changes made to transactions after this date are not included in this report",
  ChecksAndPaymentsCleared: "Checks and Payments Cleared ({{}})",
  periodEnding: "Period Ending",
  reconciledOn: "Reconciled On",
  reconciledBy: "Reconciled By",
  deleteReconciliationConfirmationText: "Are you sure you want to delete the reconciliation?",
  clearedTransactions: "Cleared Transactions",
  resumeReconciliation: "Resume Reconciliation",
  transactionReconciled: "This transaction has already been reconciled.",
  transactionReconciledDescription: "Some changes to this transaction will not be allowed.",
  viewReconciliation: "View Reconciliation",
  unclearedTransactions: "Uncleared Transactions",
  takeTour: "Take a tour",
  watchVideo: "Watch a video",
  watchExample: "Watch an example",
  merchantAccount: {
    merchantAccount: "Merchant Account",
    helpPanel: {
      description: "Collect rent automatically by credit card, debit card, or ACH.",
      application: "How to apply for online rent payments",
      onlinePayments: "Learn more about setting up to receive online payments",
      receivePayments: "How to receive online payments",
      howLong: "How long does it take for online payments to reach my bank account?",
      dialogTitle: "Merchant Account Application"
    }
  },
  bankConnect: {
    title: "BankConnect",
    helpPanel: {
      description: "Automatically import transactions from your bank account into DoorLoop.",
      establishConnect: "Establishing your BankConnect",
      reconciliationDifference: "What is the difference between bank reconciliation and connecting to Plaid?",
      plaidCorrection: "How do I correct problems with Plaid?",
      dialogTitle: "BankConnect via Plaid"
    },
    errors: {
      loginRequired: "Please re-authenticate your bank connection",
      refreshTransactionsError: "Failed to refresh transactions",
      unableToSync:
        "We are unable to sync the account due to a Plaid connection error. To fix the problem, please click on"
    }
  },
  transactionsScreen: {
    title: "Transactions",
    doorLoopRecords: "DoorLoop Records",
    bankRecords: "Bank Records",
    bankTransactions: "Bank Transactions",
    importTransactions: "Import Transactions",
    bankAccountName: "Bank Account Name",
    reconciliation: "Reconciliation",
    reconciliationReport: "Reconciliation Report",
    reconciliationReportDescription: "Ensure your bank statement transactions match your DoorLoop transactions.",
    reconciliationHelpBox: {
      title: "Steps To Reconcile",
      step1: "Start by choosing the DoorLoop account you want to reconcile.",
      step2: "Enter the bank or credit card statement ending balance amount and date.",
      step3: "Compare your bank statement with the transactions in DoorLoop and check them as you go.",
      success: {
        title: "The transactions have been matched",
        subtitle: "The account has been reconciled"
      },
      error: {
        title: "Balance is off",
        subtitle: "DoorLoop’s transactions are missing",
        point1: "Any transactions that are missing should be added.",
        point2: "Ask yourself: Is it associated with another DoorLoop account?"
      }
    },
    reconciliationEmptyState: {
      titleLeftSide: "Reconcile Your DoorLoop Records with Bank and Credit Card Statements",
      titleRightSide: "Ensures Your Bookkeeping Matches What Happened In Real Life",
      subtitleLeftSide: "Keep your bookkeeping and reports up to date",
      subtitleRightSide: "Reconciliation will guard you against duplicate, incorrect and missing transactions.",
      point1: "Start by choosing the DoorLoop account you want to reconcile.",
      point2: "Enter the bank or credit card statement ending balance amount and date.",
      point3: "Compare your transactions in DoorLoop to your statement, checking them as you go.",
      action: "New Reconciliations for New Period"
    },
    bankConnectEmptyState: {
      titleLeftSide: "Import your bank transactions to DoorLoop for a seamless financial experience",
      titleRightSide: "Import Your Transactions Securely",
      subtitleLeftSide:
        "Pull, categorize, and reconcile your transactions in seconds by connecting your bank account or credit card to DoorLoop through Plaid.",
      subtitleRightSide: "DoorLoop partners with Plaid to connect your bank.",
      point1: "Securely connect your bank or credit card in seconds, and say goodbye to manual transaction entry.",
      point2: "Link multiple bank accounts and reconcile effortlessly.",
      point3:
        'To see if your bank supports automatic transaction, Check Plaid\'s <0>{{listOfInstitutions}}</0>. If "Transactions" is listed under "Supported Plaid products" for your bank, you can import transactions automatically.',
      listOfInstitutions: "list of institutions",
      action: "Import My Transactions"
    },
    helpPanel: {
      description: "Match your DoorLoop records with your bank records - link your accounts and reconcile effortlessly",
      howToImportTransactions: "How to import transactions",
      bankTransactionsVsReconciliation: "Bank Transactions vs Reconciliation",
      commonReconciliationMistakes: "Common reconciliation mistakes"
    }
  },
  banking: {
    helpPanel: {
      description: "The command center for managing your bank accounts",
      createAccount: "Creating bank accounts",
      propertyConnect: "How do I connect my bank accounts to specific properties?",
      dialogTitle: "Banking Tab"
    }
  },
  managementFees: {
    helpPanel: {
      description: "Set up and collect management fees for the properties you manage.",
      createBills: "Create Management Fee Bills",
      howToRedo: "How do I redo management fee bills if I make a mistake?",
      dialogTitle: "Automatic Management Fees"
    }
  },
  progressBar: "{{progress}}% Complete",
  welcomeDialog: {
    dialogTitle: "Welcome to your Portal 🎉",
    dialogDescription:
      "Your personal tenant portal is an all-in-one destination for checking your balance, making payments, submitting requests, viewing important announcements, and purchasing renters insurance.",
    dialogLetsStart: "Let’s start by completing the steps below:",
    dismissDialog: "Don't Show Again",
    autoPay: "AutoPay",
    rentersInsurance: "Renters Insurance",
    getQuote: "Get a quote or provide a proof of insurance"
  },
  lemonadePromoDialog: {
    dialogTitle: "Get renters insurance powered by Lemonade",
    dialogDescription: "America's top-rated renters insurance provider.",
    getAQuoteNow: "Get A Quote Now",
    iAlreadyHaveInsurance: "I already have insurance",
    promo1: {
      title: "Rated 4.9/5 stars",
      subTitle: "Most loved insurance based on reviews on the App Store, Google Play, and more."
    },
    promo2: {
      title: "Super fast sign up",
      subTitle: "No paperwork. No fine print. Sign up in seconds."
    },
    promo3: {
      title: "Claims paid at record speed",
      subTitle: "21st century coverage that pays claims in seconds."
    },
    promo4: {
      title: "Rates starting from just $5/month",
      subTitle: "Not a typo. Super low rates for super great coverage."
    }
  },
  noActivePayments: "No Active Payments",
  noActivePaymentsDescription: "Send payments to your owners and vendors, and issue refunds to your tenants.",
  setupOutgoingPayments: "Setup Send Payments",
  noPaymentRecords: "No Payment Records",
  paymentInfoPlacement: "All payments information will be shown here when recorded",
  getAQuote: "Get a Quote",
  surePromoDialog: {
    dialogTitle: "Get renters insurance powered by Sure",
    dialogDescription: "Comprehensive and affordable coverage",
    getAQuoteNow: "Get A Quote Now",
    iAlreadyHaveInsurance: "I already have insurance",
    promo1: {
      title: "Protects Your Belongings",
      subTitle: "Coverage for all your items (phone, furniture, etc) to the limits you customize"
    },
    promo2: {
      title: "Loss of Use",
      subTitle: "Covered living expenses if your place becomes uninhabitable"
    },
    promo3: {
      title: "Medical Expenses",
      subTitle: "If someone is accidentally hurt on your property, those medical costs are covered"
    },
    promo4: {
      title: "Affordable Pricing",
      subTitle: "Starting at $8/month, making it a no-brainer to cover you from the unexpected"
    }
  },
  stateSelectionDialog: {
    dialogTitle: "Select your current state of residence",
    selectAState: "Select a state",
    iLiveOutsideUs: "I live outside of the US"
  },
  copyToClipboard: "Copy to clipboard",
  insuranceExpiresInDays: "Policy expires in {{days}} days",
  rentalApplicationPreview: "Rental Application Preview",
  cancelPaymentDialog: {
    title: "Cancel Payment",
    areYouSure: "Are you sure you want to attempt to cancel this payment?",
    description: "Payment cancellation depends on the payment processor and may not always succeed.",
    warning: "You will need to make another payment or you may be charged late fees."
  },
  getDoorloopAppBanner: {
    title: "Get the DoorLoop app",
    description:
      "Access DoorLoop with one convenient app. Manage your account, access your tools, and receive important notifications.",
    getApp: "Get the app",
    continueToSite: "Continue to mobile site"
  },
  confirmUnderstand: "I Understand",
  sendPayments: "Send Payments",
  needHelp: "Need Help?",
  tryAgain: "Try Again",
  connected: "Connected",
  notConnected: "Not Connected",
  bankNotConnected: "Bank not connected",
  sendPayment: "Send Payment",
  acceptPayments: "Accept Payments",
  enable: "Enable",
  awaitingReports: "Awaiting reports",
  remindersWillGoOutAutomatically: "Reminders will go out automatically",
  onlinePaymentsAccountsList: {
    identityVerificationFailed: "Identity Verification Failed",
    identityVerificationNeeded: "Identity verification needed",
    apply: "Apply to start receiving and sending online payments",
    applicationWaitingForReview: "In Review...",
    reviewingApplication: "Reviewing application...",
    accountNotApproved: "Application denied",
    contactSupport: "Please contact customer support",
    inactiveMessage: "Please complete the application for accepting payments first before being able to send payments.",
    inactivePermissionMessage:
      "Your user role doesn't have permission to send payments. Please contact your system admin for access.",
    acceptPaymentStatus: "Accept Payment Status",
    receivePaymentStatus: "Receive Payment Status",
    sendPaymentStatus: "Send Payment Status",
    creditCardUnsupported: "We do not support credit cards for accepting rental revenue."
  },
  bookKeeping: "Bookkeeping",
  receivePayments: "Receive Payments",
  unsupported: "Unsupported",
  updated: "Updated",
  dueInXDays: "Due in {{days}} days",
  expiredXDaysAgo: "Expired {{days}} days ago",
  setAsDefault: "Set as Default",
  bookkeeping: "Bookkeeping",
  sendInviteNow: "Send Invite Now",
  partialPaymentsNotAllowedDescription:
    "The payment amount cannot be edited. For more information, please contact your property manager.",
  frequency: "Frequency",
  paymentFrequency: "Payment Frequency",
  yourBalanceDueIs: "Your balance due is",
  pleaseContactYourPropertyManager:
    "Please contact your property manager for further information on how to make a payment",
  onlinePaymentsUnavailable: "Online payments are currently unavailable",
  amount: "Amount",
  howWouldYouLikeToPay: "How would you like to pay?",
  unavailable: "Unavailable",
  paymentSent: "Payment Sent",
  paymentScheduled: "Payment Scheduled",
  eCheckWarning:
    "It may take up to <bold>72 hours</bold> for payments to be processed. Please ensure that your account maintains sufficient funds.",
  inPersonOther: "In Person / Other",
  paymentMethodUnavailableMessage: "Payment method unavailable. Contact your property manager for more details.",
  paymentMethodOverLimitMessage: "You cannot pay this amount using this method because it exceeds the limit.",
  paymentMethodInvalidSelectionMessage: "Please select a valid payment method.",
  insufficientFundsMessage: "Insufficient funds will result in a $40 returned payment fee.",
  payNow: "Pay Now",
  newPayment: "New Payment",
  activateNow: "Activate Now",
  or: "or",
  active: "Active",
  inactive: "Inactive",
  manualBankDialogTitle: "Your account details are being verified",
  acceptAndClose: "Accept and close",
  changePaymentMethod: "Change Payment Method",
  paymentUnsuccessfulDialogTitle: "Payment Unsuccessful",
  paymentUnsuccessfulDialogDescription:
    "Your payment could not be processed due to an internal error. Please reach out to your Property Manager for assistance in completing this transaction.",
  paymentMethodAdded: "Payment method has been successfully added!",
  paymentMethodAddedInstructions:
    "To complete your rent payment, head back to the DoorLoop mobile app by clicking below",
  backToMobileApp: "Back to Mobile App",
  recommended: "Recommended",
  sec: "sec",
  updateInfo: "Update Info",
  appliedCredit: "Applied Credit",
  bankAccountVerificationInProgressVerify:
    "Bank account verification is in progress. A micro-deposit will be made to your account by {{date}}. Once deposited, please click verify your account below.",
  processing: "Processing...",
  verifyYourAccount: "Verify Your Account",
  accountBeingVerifiedTitle: "Your account is being verified",
  accountBeingVerifiedDescription:
    'Expect to receive a micro-deposit labeled "ACCTVERIFY" made to your account within 1-2 business days and an email with additional instructions to verify your bank account.\n\n Please note that once your account is verified, you must log back into the DoorLoop portal to complete the payment process.',
  bankAccountVerificationInProgress: "Bank account verification is in progress",
  pleaseCompleteRequiredFields: "Please complete all required fields",
  makeSureToFillAllFields:
    'Make sure you fill out all required fields and then click "Next" to complete this process successfully.',
  ePayStatus: {
    processing: "Processing",
    succeeded: "Succeeded",
    disputed: "Disputed",
    failed: "Failed",
    pendingReview: "Pending Review",
    refunded: "Refunded",
    inTransit: "In Transit",
    paid: "Paid"
  },
  complete: "Complete",
  completed: "Completed",
  experienceTitle: "Rate your level of accounting experience from 1-10",
  purposeTitle: "What is the main reason you joined DoorLoop?",
  estimateNumberOfTenantsTitle: "How many tenants do you currently have?",
  goalsAndObjectives: "Goals and objectives",
  estimatedNumberOfTenants: "Estimated number of tenants",
  rateYourExperience: "Rate your experience",
  whatManagementSoftware: "What did you use to manage your properties before joining DoorLoop",
  previousToolOrSoftware: "Previous tool or software",
  reasonForSelectingOther: "Reason for selecting Other",
  balancesSummary: {
    title: "Balances Summary",
    overview: "Overview",
    filterTitle: "Merchant Account",
    pendingAndProcessingAvailableSoon: {
      title: "Payments Processing",
      subTitle: "Funds that are currently being processed."
    },
    succeededAvailableSoon: {
      title: "Transactions Available to Pay Out Soon",
      subTitle: "Funds that have been successfully collected"
    },
    recentlyCompleted: {
      title: "Recently Deposited",
      amountOfDays: "(7 Days)",
      subTitle: "Funds that have already been deposited in your bank account"
    },
    paymentsStatus: {
      transactionsPendingReview: "Transactions pending risk review",
      estimatedPayoutOn: "Estimated payout on",
      depositExpectedToArriveBy: "Deposit expected to arrive by",
      deposited: "Deposited"
    },
    paymentsChip: {
      pendingReview: "Pending Review",
      processing: "Processing",
      succeeded: "Succeeded",
      disputed: "Disputed",
      failed: "Failed",
      refunded: "Refunded",
      paid: "Paid",
      inTransit: "In Transit"
    },
    numberOfPayments: "({{numberOfPayments}} Payments)",
    viewAll: "View All",
    total: "Total"
  },
  transactionsTab: {
    title: "Transactions",
    availableOnSucceededHintText: "Expected to arrive on",
    availableOnDepositedHintText: "Deposited on",
    listHeader: {
      paymentDate: "Payment Details",
      amount: "Amount",
      type: "Type",
      status: "Status",
      name: "Name",
      estArrival: "Est. Arrival"
    },
    noDateTooltipTitle: "The date of availability will be published soon",
    availableOnExplanationTooltip:
      "Date of expected deposit to your bank account.\n" + "Green indicates your payment has been deposited."
  },
  arrivalDate: "Arrival Date",
  usageAndLimitsInfoTab: {
    tabTitle: "Usage & Limits Info",
    title: "Merchant Accounts Monthly Usage",
    subtitle: "You can receive up to <achLimit/> total, which includes up to <cardLimit/> in card payments.",
    listTitle: "Accounts Limits Information",
    popoverItems: {
      updateInformation: "Update Information",
      requestToUpdateLimits: "Request to Update Limits"
    },
    emptyListText: "Please navigate to the <Link>Setup page</Link> to configure your DoorLoop merchant accounts.",
    requestLimitIncrease: "Request limit increase",
    amountUsed: "<total/> of <used/> Used"
  },
  depositsTab: {
    title: "Deposits",
    listHeader: {
      bank: "Bank",
      amount: "Amount",
      transactions: "Transactions",
      status: "Status",
      expectedArrivalDate: "Expected Arrival Date"
    }
  },
  TBD: "TBD",
  noDate: "No Date",
  receivePaymentsHelpPanelDescription:
    "Set up your Doorloop merchant accounts to securely send and receive electronic payments",
  receivePaymentsHelpPanelArticleTopic:
    "Check merchant account balances, transactions, deposits, and more with the received payments page",
  onlinePaymentsSetup: "Online Payments Setup",
  setupDescription: "Set up your Doorloop merchant accounts to securely send and receive electronic payments",
  setup: "Setup",
  tenantPortalFeaturesOnlinePayments: {
    cannotBeTurnedOffTitle: "Unable to disable online payments",
    cannotBeTurnedOffDescriptionCompany:
      "Online payments cannot be disabled on the general tenant portal settings because some of your tenants currently have AutoPays set.\n\nTo disable online payments for a specific lease, please disable online payments on the lease level settings for the tenant portal.",
    cannotBeTurnedOffDescriptionProperty:
      "Online payments cannot be disabled on the property tenant portal settings because some of your tenants currently have AutoPays set.\n\nTo disable online payments for a specific lease, please disable online payments on the lease level settings for the tenant portal.",
    lease: {
      cancelWarning:
        "This lease has an active auto-payment set up. Please be aware that if you disable online payments in the tenant portal, all ongoing auto payments will be canceled.",
      confirmationCancelTitle: "Are you sure you want to cancel online payments for this lease?",
      confirmationCancelDescription:
        "This lease currently has an active auto-pay schedule set up. If you disable online payments for this lease, the auto-pay schedule will be canceled."
    },
    defaultSettingsCancelWarning:
      "Please note, that you have currently disabled online payments in your default settings. If you opt for the default settings for this lease, any automatic payments you have set up will be canceled."
  },
  chooseStatus: "Choose Status",
  cancelAll: "Cancel All",
  rentOverdueSettingTitle: "Send recurring notifications to remind the tenant about overdue rent",
  daysAfterRentIsDue: "Days after rent is due",
  howOftenShouldWeSendReminders: "How often should we send this?",
  whenShouldWeSendThisNotification: "When should we send this notification?",
  oneDayBeforeLateFeeChargedSettingTitle: "Send a reminder one day before late fees will be charged",
  oneDayBeforeLateFeeChargedSettingDescription:
    "Send a notification reminder to the tenant via email or push notification before late fees are charged",
  mobileDownloadVersion: {
    mandatoryTitle: "App Update Required",
    mandatoryText:
      "Your current version of the DoorLoop app has expired! Enjoy all the latest updates and feature enhancements by updating your app now.",
    recommendedTitle: "App Update Recommended",
    recommendedText:
      "New recommended DoorLoop app version is out now! Enjoy all the latest updates and feature enhancements by updating your app now."
  },
  fillInYourRole: "Fill in your role",
  whatIsYourRole: "What is your role?",
  iAmA: "I am a...",
  whatIsYourMainPropertyType: "What is your main property type?",
  whenDoYouPlanOnUsingTheSoftware: "When do you plan on using the software?",
  doYouManagePropertiesInTheUSOrCanada: "Do you manage properties in the US or Canada?",
  fillInYourGoals: "Fill in your goals",
  rentalApplicationListing: {
    rentalApplications: "Rental Applications",
    chooseUnitToApply: "Choose the unit you would like to apply for"
  },
  chooseRentalApplicationDialog: {
    chooseApplication: "Choose Application",
    weNoticed: "We noticed you have {{numOfApplications}} applications for this unit. Choose one or start a new one.",
    applicationCreatedAt: "Created at: {{date}}",
    applicationSubmittedAt: "Submitted at: {{date}}",
    startNewApplication: "Start New Application"
  },
  rentalApplicationListStatusTooltip: {
    inProgressStep: "In Progress {{step}}",
    notSent: "Not Sent",
    applicationSent: "Application Sent",
    sendingFailed: "Sending Failed",
    notSubmitted: "Not Submitted",
    submitted: "Submitted",
    requestReports: "Request Reports",
    pendingReports: "Pending Reports",
    inProgress: "In Progress",
    identityUnverified: "Identity Unverified",
    expired: "Expired",
    reportsFailed: "Reports Failed",
    reportsAvailable: "Reports Available",
    noDecision: "No Decision",
    decisionApproved: "Decision Approved",
    decisionIgnored: "Decision Ignored",
    decisionRejected: "Decision Rejected"
  },
  notSubmitted: "Not Submitted",
  applicationNotSubmitted: "Application not submitted",
  rentalApplicationsOverview: {
    joinHelpText: "You can search for another applicant's email\nfor easy co-signer presentation and navigation.",
    selectApplications: "Select Applications",
    applicantTypes: {
      tenant: "I am a Tenant",
      tenantWithCoApplicants: "Tenant with co-applicants",
      tenantWithCoSigner: "Tenant with co-signer",
      signer: "Applying as co-signer",
      coApplicant: "Applying as a co-applicant",
      tenantWithCoApplicantsAndCoSigners: "Tenant with co-applicants and co-signers"
    },
    rentalApplicationInfo: "Rental Application Info",
    quickActions: {
      requestReportsTooltip: "Application not submitted. Reports available after application received",
      sendApplication: "Send Application",
      resendApplication: "Resend Application",
      requestReports: "Request Reports",
      viewReports: "View Reports",
      sendReminders: "Send Reminders"
    },
    tiles: {
      sendApplication: "Send Application",
      applicantsInfo: "Applicants Info",
      requestReport: "Request Reports",
      applicationDecision: "Application Decision",
      sendReminders: "Send Reminders",
      lastSentAt: "Last Sent At {{date}}",
      resendApplication: "Resend Application",
      resendApplicationTooltip:
        "The applicant's information is either\n" +
        " incorrect or missing. Please resend the application\n" +
        " so they can add or fix any information.",
      sentAt: "Sent At {{date}}",
      notSent: "Not Sent",
      unavailable: "Unavailable",
      submitted: "Submitted",
      pendingApplication: "Pending Application",
      pendingVerification: "Pending Verification",
      failedToRequestReports: "Failed to Request Reports",
      reportsExpired: "Reports Expired",
      identityVerified: "Identity Verified",
      reportsAvailable: "Reports Available",
      viewReports: "View Reports",
      notRequested: "Not Requested",
      unableToVerifyIdentity: "Unable to Verify identity",
      requestReportSuccessesToast: "Successfully requested.",
      requestExpired: "Request Expired",
      sentByLink: "Sent By Link"
    }
  },
  rentalApplicationAuth: {
    title: "Let’s Create your Application",
    postChargeTitle: "Verify Your Identity",
    buttonTxt: "Go to the Application",
    postChargeButtonTxt: "Verify and Continue",
    emailView: {
      description: "Fill out your rental application online",
      postChargeDescription: "Complete this step to securely proceed to the payment page for tenant screening",
      enterYourEmail: "Enter your Email"
    },
    totpView: {
      description: "Enter the code we sent over email to {{email}}",
      resendCode: "Resend code",
      sec: "sec"
    },
    termsAndConditions: "By using DoorLoop you are agreeing to our",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    and: "and"
  },
  contactInfo: "Portal Login Info",
  primaryEmail: "Primary Email",
  mobilePhone: "Mobile Phone",
  financials: "Financials",
  transactions: "Transactions",
  reports: "Reports",
  tabPermissions: "Tab Permissions",
  overview: "Overview",
  contributions: "Contributions",
  makeContributions: "Make Contributions",
  features: "Features",
  ownerPortal: {
    helloUser: "Hello {{fullName}},",
    welcomeBack: "Welcome Back!",
    transactionsScreenTitle: "Transactions",
    transactionsScreenSubtitle: "View the history of your total contribution and distribution transactions here.",
    transactionsEmptyDataViewTitle: "There are no draws or contributions to display",
    transactionsEmptyDataViewSubtitle: "You haven’t conducted any transactions",
    requestsEmptyDataViewTitle: "There are no requests to display",
    requestsEmptyDataViewSubtitle: "You didn't submit any requests",
    requestsScreenTitle: "Your Requests",
    requestsScreenSubtitle: "Create and view all requests here, including updates and which have been completed.",
    reportsScreenTitle: "Reports"
  },
  transactionTypes: {
    ownerDistribution: "Owner Distribution",
    ownerContribution: "Owner Contribution"
  },
  completePropertyDialog: {
    yourPropertyIsReady: "Your Property is ready",
    hereAreTheRecommendedNextSteps: "Here are the recommended next steps:",
    setUpARentalApplication: "Set up a rental application",
    addPhotosAndAmenities: "Add photos and amenities",
    addNewLease: "Add new lease"
  },
  authorizeToUseInformation:
    "You authorize DoorLoop to use the provided information per the <0>{{termsValue}}</0> & <1>{{privacyValue}}</1> and to verify account ownership and balances.",
  handleSendPaymentText:
    "To handle payment limit increases or any other error please refer to the <0>{{ArticleValue}}</0> and complete the process on <1>{{CheckbookIoValue}}</1>",
  rentOverdue: "Rent Overdue",
  hoa: {
    board: "Board",
    position: "Position",
    newBoardMember: "New Board Member",
    newBoardAnnouncement: "New Board Announcement",
    boardMemberEmptyDataViewInstructionsText: "No Board Members Found",
    boardMembership: "Board Membership",
    termStart: "Term Start",
    termEnd: "Term End",
    fillBoardMemberDetails: "Fill in board member details",
    boardMemberName: "Board Member Name",
    boardMember: "Board Member",
    editBoardMember: "Edit Board Member",
    fillInBoardMemberDetails: "Fill board member details",
    removeTerm: "Remove Term",
    removeTermDialogSubtitle: "Are you sure you want to remove this term?",
    unitAddressIsTheSame: "Unit address is the same as property address",
    associations: "Associations",
    replaceOwnerWarning: "Note: There is already an owner associated with this unit:",
    replaceOwnerWarningSubtitle: "If you want to replace the current owner, you will need to ",
    confirmReplaceOwner: "I confirm to replace current association owner",
    confirmReplaceOwnerError: "Please confirm you would like to replace the current unit owner",
    endHomeownerAccount: "end their homeowner account",
    homeownerAccountOutstandingBalance: "Please note this homeowner account has an outstanding balance of ${{balance}}",
    homeownerAccountRemainingCredit: "Please note this homeowner account has a remaining credit of ${{credit}}"
  },
  rentalApplicationValidationFailedMsg: "Failed to send due to missing/wrong field - {{fieldName}}",
  copyPropertyLink: "Copy Property Link",
  copyUnitLink: "Copy Unit Link",
  copyCompanyLink: "Copy Company Link",
  copyProspectLink: "Copy Prospect Link",
  deleteRelatedFiles: `
  This will also delete any attached files.`,
  preview: "Preview",
  filePreview: "File Preview",
  rentalApplicationSettingErrorMsg:
    "There was an issue with the rental application settings. Please contact the property manager.",
  article: "article",
  checkbookIo: "Checkbook.io",
  fileNotSupported: "The file is not supported for preview",
  portalInfoError: "Please enter an email address or a mobile phone number",
  youveGotUnsavedChanges: "Hold on! You've got unsaved changes. Confirm closing this form?",
  billingEmailError: "Please enter a different email address",
  tenantScreeningReportAvailable: "Tenant Screening Reports Available",
  descriptionPayment: "Help mitigate the risk of expensive evictions and property damage by screening your applicants.",
  descriptionScreening:
    "Criminal record, summarized credit risk, and eviction history reports offer all the details you need to quickly identify the best tenants.",
  poweredBy: "Powered by",
  untitledListing: "Untitled Listing",
  availableUnits: "Select Your Preferred Unit",
  selectYourPreferredProperty: "Select Your Preferred Property",
  stillDecidingOnAUnit: "Still deciding on a unit? Apply for the entire property or join the waiting list",
  prospectCanApplyWithoutSelectingASpecificUnit: "Prospects can apply without selecting a specific unit.",
  applyForThisProperty: "Apply For This Property",
  here: "here.",
  securePayments: "Secure Payments with SSL Encryption",
  atLeastOneTenantNeedsToBeAssociatedWithTheLease: "At least one tenant needs to be associated with the lease",
  openUnitForApplicationOngoingMessage: "Opening application listing",
  closeUnitForApplicationOngoingMessage: "Closing application listing",
  removeAndClose: "Remove and Close",
  youAreSkipTenantScreening: "You are About to Skip Tenant Screening",
  descriptionScreeningAlert:
    "This prospect hasn't been fully screened. Skipping this step can lead to unpaid rent, property damage, and costly evictions.\n\nConducting a full screening helps ensure successful tenancies.",
  screenProspect: "Screen prospect",
  skipAndApprove: "Skip and Approve",
  paymentMethodInvalid: "Please refill the payment details by creating a new payment method below",
  discardUnsavedConfirmationDialog: {
    title: "Are you sure you want to leave this process?",
    description:
      "By closing this window, any information you entered will be lost. We'd love to hear why you're leaving this step",
    cancel: "Cancel",
    ok: "Ok",
    discardChanges: "Discard Changes",
    tenantPaymentWizard: {
      description: "Leaving this page will discard all the changes you have made."
    },
    survey: {
      option1: "I need help with payment setup",
      option2: "I'm not ready to make a payment yet",
      option3: "The platform fees are too high",
      option4: "I'm concerned about payment security",
      option5: "I encountered a technical issue",
      other: "Other",
      otherDesc: "Write your description here"
    }
  },
  discardChanges: "Discard Changes",
  checksStatus: "Checks status",
  platformFeeCalculation: "Fee: 3.25% (minimum of $3.50)",
  unknownContact: "Unknown Contact",
  yesterday: "Yesterday",
  justNow: "Just Now",
  minutesAgo: "{{minutes}} minutes ago",
  markAsRead: "Mark as Read",
  markAsUnread: "Mark as Unread",
  archive: "Archive",
  addContact: "Add Contact",
  typeAMessage: "⏎ to send, ⇧ + ⏎ for new line",
  typeAMessageMobile: "Type a message",
  noConversationSelected: "No conversation selected",
  noConversationSelectedDescription: "You can view your conversations in the sidebar",
  startAConversation: "Start a conversation",
  contactInfoIsMissing: "Update Contact Information",
  contactInfoIsMissingDescription:
    "Ensure a valid phone number is included to enable SMS. If none is provided, add a contact number",
  updateContactDetails: "Update Contact Details",
  viaSmsMessage: "Via SMS Message",
  failedToSendMessage: "Failed to send message",
  conversationArchivedSuccessfully: "Conversation was archived successfully to All Messages",
  conversationUnArchivedSuccessfully: "Conversation will now also appear in My Inbox",
  moveToInbox: "Move to Inbox",
  communicationCenter: "Communication Center",
  noChats: "No Chats",
  textMessaging: "Text Messaging",
  sendWelcomeSmsDescription: "Send a welcome message to your tenants",
  insufficientCredits: "Insufficient Credit",
  insufficientCreditsMessage:
    "You're out of messaging credits (0/{{creditsPurchased}}).\nAdd {{chargeQuantity}} credits for ${{chargeAmount}} to stay connected.",
  addXCreditsForX: "Add {{chargeQuantity}} for ${{chargeAmount}}",
  insufficientSmsCredits: "Insufficient SMS Credits ",
  hasBeenCreated: "{{task}} has been created",
  taskStatus: "Status: {{status}}",
  sendWelcomeSms: "Send Welcome SMS",
  sendWelcomeSmsInEditDialog:
    "Once you click “Save”, they will receive an SMS with the property manager's contact information.",
  sendWelcomeSmsInEditDialogSent:
    "A SMS was sent to the {{personTypeLabel}} with the property manager's contact information",
  welcomeSmsSent: "Welcome SMS Sent",
  unsubscribed: "Unsubscribed",
  smsNumber: "SMS Number",
  email: "Email",
  address: "Address",
  contactLinkedSuccessfully: "Contact linked successfully",
  expenseRecorded: "Expense Recorded",
  completeEpay: "Complete Epay",
  numberOfChecks: "Checks",
  numberOfEpays: "E-payments",
  billsRecorded: "Bill Payment Recorded",
  enterBankManually: "You can manually add your bank details by scrolling to the bottom of this page.",
  insurance: "Insurance",
  payments: "Payments",
  more: "More",
  helpCenter: "Help center",
  switchLease: "Switch Lease",
  tenantPortal: {
    helpPanel: {
      paymentWizard: {
        description: "Select your preferred payment method to securely settle outstanding balances and invoices",
        articles: {
          paymentInPortal: "Payment In Portal",
          instantBankVerification: "Instant Bank Verification",
          manualBankVerification: "Manual Bank Verification"
        }
      }
    }
  },
  merchantAccountIsRequired: "Merchant account is required",
  merchantAccountIsRequiredDescription:
    "To set up an SMS number, it is required to have at least one merchant account associated to an EIN",
  insertEin: "Insert EIN",
  requestType: "Request Type",
  maintenanceType: "Maintenance Type",
  requestStatus: "Request Status",
  creationDate: "Creation Date",
  requestDescription: "Request Description",
  notStarted: "Not Started",
  inProgress: "In Progress",
  archived: "Archived",
  notApplicable: "Not Applicable",
  requestNameAndDescription: "Request Name & Description",
  attachment: "Attachment",
  yourRequests: "Your Requests",
  form: {
    fields: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email"
    }
  },
  autoComplete: {
    creatable: {
      unlabeledOption: "Unlabeled Option",
      failedToCreate: "Failed to create a new {{value}}. Please try again."
    },
    placeholder: "Type to filter",
    noOptions: "No options",
    loading: "Loading...",
    showMoreResults: "Show More Results",
    endOfResults: "End of Results",
    selectedGroup: "Selected",
    option: {
      phonePlaceholder: "Missing Phone Number",
      emailPlaceholder: "Missing Email Address"
    }
  },
  savingEllipsis: "Saving...",
  insuranceProviderLemonade: "Lemonade Policy",
  insuranceProviderSure: "Sure Policy",
  orPayUsing: "or pay using",
  orPayUsingCreditCard: "Or Pay Using Credit Card / Debit Card",
  areaCodeErrorTitle: "Area Code Not Supported",
  areaCodeErrorSubtitle:
    "The area code you selected isn’t currently available, but no worries! We’ve got you covered with two options:",
  areaCodeErrorInstructions: "Here’s what you can do next:",
  areaCodeErrorOptionFastest: "Fastest Option",
  areaCodeErrorOptionFastestDescription: "Select a different area code and get set up instantly.",
  areaCodeErrorOptionRequest: "Request Initial Area Code",
  areaCodeErrorOptionRequestDescription:
    'Request Initial Area Code: Want your initial area code? Click "Request Number," and we will secure it with our carrier within 2 business days. You will be notified when it’s ready.',
  areaCodeErrorNeedHelp: "Need help?",
  areaCodeErrorSendMessage: "Send us a message.",
  suggestedCode: "Suggested Code",
  selectYourAreaCode: "Area Code",
  areaCodeSubtitle: "Enter your area code for your new virtual number or use the one suggested below",
  ofUsAndCanadaAreaCodes: "of US and Canada area codes.",
  seeFullList: "See full list",
  deleteRequestConfirmation:
    "Are you sure you want to delete this task? Please note that this action will also remove it from all the associated entities it is shared with.",
  createRequest: "Request Number",
  missingEmail: "Missing Email",
  from: "from",
  to: "to",
  entryNotes: "Entry Notes",
  exportConversation: "Export Conversation",
  marketingLeadTerms: "By submitting this form you agree to DoorLoop's",
  renewNow: "Renew Now",
  addImages: "Add images to help validate and support your maintenance request"
};
