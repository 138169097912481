"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertySettingsDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const lateFeesPolicy_dto_1 = require("./shared/lateFeePolicy/lateFeesPolicy.dto");
const tenantPortalSettings_dto_1 = require("./shared/tenantPortal/tenantPortalSettings.dto");
const managementFeesPropertySettings_dto_1 = require("../settings/shared/managementFees/managementFeesPropertySettings.dto");
const mailingTemplatesSettings_dto_1 = require("./shared/mailingTemplatesSettings.dto");
const paymentAllocationsSettings_dto_1 = require("./shared/paymentAllocationsSettings.dto");
const paymentInstructionsSettings_dto_1 = require("./shared/paymentInstructionsSettings.dto");
const propertyDefaultAccountsSettings_dto_1 = require("./shared/propertyDefaultAccountsSettings.dto");
const propertyReserveSettings_dto_1 = require("./shared/propertyReserveSettings.dto");
const recurringTransactionsSettings_dto_1 = require("./shared/recurringTransactionsSettings.dto");
const rentersInsuranceSettings_dto_1 = require("./shared/rentersInsuranceSettings.dto");
const rentRemindersSettings_dto_1 = require("./shared/rentRemindersSettings.dto");
const requestsSettings_dto_1 = require("./shared/requestsSettings.dto");
const convenienceFeesPolicy_dto_1 = require("./shared/convenienceFeesPolicy.dto");
const returnedPaymentFeesSettings_dto_1 = require("./shared/returnedPaymentFeesSettings.dto");
class PropertySettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PropertySettingsDto = PropertySettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenantPortalSettings_dto_1.TenantPortalSettingsDto),
    __metadata("design:type", tenantPortalSettings_dto_1.TenantPortalSettingsDto)
], PropertySettingsDto.prototype, "tenantPortal", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => lateFeesPolicy_dto_1.LateFeesPolicyDto),
    __metadata("design:type", lateFeesPolicy_dto_1.LateFeesPolicyDto)
], PropertySettingsDto.prototype, "lateFeesPolicy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentersInsuranceSettings_dto_1.RentersInsuranceSettingsDto),
    __metadata("design:type", rentersInsuranceSettings_dto_1.RentersInsuranceSettingsDto)
], PropertySettingsDto.prototype, "rentersInsurance", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)({ each: true }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => paymentAllocationsSettings_dto_1.PaymentAllocationsSettingsDto),
    __metadata("design:type", paymentAllocationsSettings_dto_1.PaymentAllocationsSettingsDto)
], PropertySettingsDto.prototype, "paymentAllocations", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => recurringTransactionsSettings_dto_1.RecurringTransactionsSettingsDto),
    __metadata("design:type", recurringTransactionsSettings_dto_1.RecurringTransactionsSettingsDto)
], PropertySettingsDto.prototype, "recurringTransactions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => propertyDefaultAccountsSettings_dto_1.PropertyDefaultAccountsSettingsDto),
    __metadata("design:type", propertyDefaultAccountsSettings_dto_1.PropertyDefaultAccountsSettingsDto)
], PropertySettingsDto.prototype, "defaultAccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => propertyReserveSettings_dto_1.PropertyReserveSettingsDto),
    __metadata("design:type", propertyReserveSettings_dto_1.PropertyReserveSettingsDto)
], PropertySettingsDto.prototype, "propertyReserve", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => managementFeesPropertySettings_dto_1.ManagementFeesPropertySettingsDto),
    __metadata("design:type", managementFeesPropertySettings_dto_1.ManagementFeesPropertySettingsDto)
], PropertySettingsDto.prototype, "managementFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => mailingTemplatesSettings_dto_1.MailingTemplatesSettingsDto),
    __metadata("design:type", mailingTemplatesSettings_dto_1.MailingTemplatesSettingsDto)
], PropertySettingsDto.prototype, "mailingTemplates", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => paymentInstructionsSettings_dto_1.PaymentInstructionsSettingsDto),
    __metadata("design:type", paymentInstructionsSettings_dto_1.PaymentInstructionsSettingsDto)
], PropertySettingsDto.prototype, "paymentInstructions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto),
    __metadata("design:type", convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto)
], PropertySettingsDto.prototype, "convenienceFeesPolicy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto),
    __metadata("design:type", returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto)
], PropertySettingsDto.prototype, "returnedPaymentFeesSettings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentRemindersSettings_dto_1.RentRemindersSettingsDto),
    __metadata("design:type", rentRemindersSettings_dto_1.RentRemindersSettingsDto)
], PropertySettingsDto.prototype, "rentReminders", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => requestsSettings_dto_1.RequestsSettingsDto),
    __metadata("design:type", requestsSettings_dto_1.RequestsSettingsDto)
], PropertySettingsDto.prototype, "tenantRequests", void 0);
