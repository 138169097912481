"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidEmailErrorCodes = exports.EmailVerificationResult = void 0;
var EmailVerificationResult;
(function (EmailVerificationResult) {
    EmailVerificationResult["VALID"] = "valid";
    EmailVerificationResult["INVALID"] = "invalid";
    EmailVerificationResult["DISPOSABLE"] = "disposable";
    EmailVerificationResult["CATCH_ALL"] = "catchall";
    EmailVerificationResult["UNKNOWN"] = "unknown";
    EmailVerificationResult["FAIL"] = "fail";
})(EmailVerificationResult || (exports.EmailVerificationResult = EmailVerificationResult = {}));
exports.InvalidEmailErrorCodes = {
    [EmailVerificationResult.INVALID]: "The email address entered could not be verified. Please check for typos or try another email.",
    [EmailVerificationResult.DISPOSABLE]: "DoorLoop does not accept disposable email addresses. Please enter a valid email.",
    [EmailVerificationResult.UNKNOWN]: "The email entered could not be verified. Please check your spelling or try another email address."
};
