"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vendors1099ReportItem = void 0;
const taxForms_1 = require("../taxForms");
class Vendors1099ReportItem extends taxForms_1.TaxForm1099Dto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.Vendors1099ReportItem = Vendors1099ReportItem;
