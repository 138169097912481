import React, { useState } from "react";
import { View } from "DLUI/view";
import IconButton from "@material-ui/core/IconButton";
import type { FillColors } from "DLUI/icon";
import { Icon as DLUI_Icon } from "DLUI/icon";
import { ShakeEffectView } from "DLUI/animatableView";
import { CircularProgress } from "@material-ui/core";
import type { SVGIconComponent } from "assets/icons/types";
import type { ViewComponentProps } from "DLUI/view/view";
import { TextTooltip } from "DLUI/popover/textTooltip";
import type { TextTooltipProps } from "DLUI/popover/textTooltip";

export interface IconButtonProps extends Omit<ViewComponentProps, "onClick"> {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ariaLabel?: string;
  Icon: SVGIconComponent;
  width?: number;
  height?: number;
  size?: number;
  pathColor?: FillColors;
  circleFill?: FillColors;
  rectFill?: FillColors;
  loadingInProgress?: boolean;
  removePadding?: boolean;
  disabled?: boolean;
  dataCy?: string;
  disableRipple?: boolean;
  textTooltipProps?: Partial<TextTooltipProps>;
}

const DLUI_IconButton: React.FC<IconButtonProps> = ({
  onClick,
  ariaLabel,
  Icon,
  width,
  height,
  size,
  pathColor,
  circleFill,
  rectFill,
  loadingInProgress,
  disabled,
  removePadding,
  style: styleOverride,
  paddingLeft = 12,
  paddingRight = 12,
  paddingBottom = 12,
  paddingTop = 12,
  dataCy,
  disableRipple,
  textTooltipProps,
  ...viewProps
}) => {
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const _onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled || loadingInProgress) {
      runShakeEffect();
    } else if (onClick) {
      onClick(event);
    }
  };

  const styleObj = removePadding
    ? {
        padding: 0
      }
    : {
        paddingRight,
        paddingLeft,
        paddingBottom,
        paddingTop
      };

  const iconButton = (
    <IconButton
      onClick={_onClick}
      aria-label={ariaLabel || "DLUI_IconButton"}
      style={{
        ...styleObj,
        ...styleOverride
      }}
      disableRipple={disableRipple}
      data-cy={dataCy}
    >
      {loadingInProgress ? (
        <CircularProgress
          style={{
            width: 20,
            height: 20,
            color: "#182C4C"
          }}
        />
      ) : (
        <DLUI_Icon
          pathColor={pathColor}
          width={width}
          height={height}
          size={size}
          Source={Icon}
          circleFill={circleFill}
          rectFill={rectFill}
        />
      )}
    </IconButton>
  );

  return (
    <View width={"auto"} {...viewProps}>
      <ShakeEffectView showEffect={showErrorShakeEffect}>
        {ariaLabel ? (
          <TextTooltip value={ariaLabel} show={Boolean(ariaLabel)} useButton={false} {...textTooltipProps}>
            {iconButton}
          </TextTooltip>
        ) : (
          iconButton
        )}
      </ShakeEffectView>
    </View>
  );
};

export default DLUI_IconButton;
