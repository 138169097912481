import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Routes } from "components/appRouter";
import Screen from "DLUI/screen";
import { View } from "DLUI/view";
import { useLocation } from "react-router-dom";
import MarketingViewSeenOn from "screens/demo/marketingViewSeenOn";
import styles from "./styles";
import "./styles.css";
import { AppLayouts } from "../../../../contexts/appLayoutContext";
import clsx from "clsx";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  children: React.ReactNode;
  documentTitle?: string;
  hideMarketingView?: boolean;
  gap?: number;
}

export const SignupFrameMaxHeight = 700;
export const SignupFrameMaxWidth = 520;

export const ScheduleFrameMaxHeight = 700;
const ScheduleFrameMaxWidth = 1100;

const DemoSignupLayout = ({ children, documentTitle, hideMarketingView, gap }: ComponentProps) => {
  const classes = styles();
  const location = useLocation<any>();
  const { isTabletOrMobile } = useResponsiveHelper();
  const [frameMaxWidth, setFrameMaxWidth] = useState(SignupFrameMaxWidth);
  const [FrameMaxHeight, setFrameMaxHeight] = useState(SignupFrameMaxHeight);

  useEffect(() => {
    if (isTabletOrMobile) {
      setFrameMaxWidth(window.innerWidth);
      setFrameMaxHeight(window.innerHeight);
    } else if (location.pathname === Routes.DEMO_SCHEDULE) {
      setTimeout(() => {
        setFrameMaxWidth(ScheduleFrameMaxWidth);
        setFrameMaxHeight(ScheduleFrameMaxHeight);
      }, 300);
    }
  }, []);

  const renderMarketingView = () => {
    if (location.pathname.indexOf(Routes.DEMO_SCHEDULE) > -1 || hideMarketingView) {
      return null;
    }
    return <MarketingViewSeenOn />;
  };

  const renderLayout = () =>
    isTabletOrMobile ? (
      <View height={window.innerHeight} alignItems={"center"} overflow={"scroll"} width={"100%"}>
        <View width={"100%"} alignItems={"center"} maxWidth={SignupFrameMaxWidth} flex={1}>
          {children}
          {renderMarketingView()}
        </View>
      </View>
    ) : (
      <View height={"100%"} overflow={"hidden"} className={classes.signup_backgroundImage}>
        <Grid justify={"center"} alignItems={"center"} style={{ height: "100%" }} container>
          <View
            overflow={"scroll"}
            gap={gap ?? 40}
            paddingBottom={40}
            backgroundColor={"white"}
            noWrap
            borderRadius={20}
            maxHeight={FrameMaxHeight < window.innerHeight ? FrameMaxHeight : window.innerHeight - 40}
            width={frameMaxWidth}
            className={clsx([classes.demoSignupLayout, "slide-in-right"])}
          >
            {children}
            {renderMarketingView()}
          </View>
        </Grid>
      </View>
    );

  return (
    <Screen
      hideLeftNavBar
      screenLayout={AppLayouts.FullWidth}
      documentTitle={documentTitle}
      removeScreenPaddings
      screenContentFullHeight
      screenContentFullHeightMinimal
    >
      {renderLayout()}
    </Screen>
  );
};

export default DemoSignupLayout;
