import type { DoorLoopLeadDto } from "@doorloop/dto";
import _ from "lodash";
import type { DLMCCookie } from "./types";

const defaultField = "Other/Missing/Null";

const sourceScores = {
  [defaultField]: 2,
  ig: 1,
  fb: 2,
  capterra: 2,
  GetApp: 3,
  "SoftwareAdvice.com": 3,
  bing: 6,
  blog: 6,
  "google-ads": 9,
  null: 9,
  direct: 20
};

const timelineScores = {
  [defaultField]: 10,
  "6 Months +": 5,
  "3-6 Months": 10,
  "1-3 Months": 15,
  ASAP: 20
};

const propertyTypeScores = {
  [defaultField]: 6,
  "Short Term Rentals": 0,
  Storage: 0,
  Commercial: 4,
  "Community Associations": 6,
  "Affordable Housing": 6,
  Residential: 7,
  Both: 10
};

const propertyManagerTypeScores = {
  [defaultField]: 6,
  Landlord: 4,
  "Property Owner": 7,
  "Property Manager": 10
};

const getUnitsScore = (units: number): number => {
  if (units <= 5) return 10;
  if (units > 5 && units <= 10) return 20;
  if (units > 10 && units <= 50) return 40;
  if (units > 50 && units <= 100) return 20;
  if (units > 100) return 5;
  return 10; // dead case?
};

const getKeyValue = (haystack: Record<string, number>, key?: string) =>
  haystack[key || "$$$$"] || haystack[defaultField];

export const calculateLeadScore = (dto: DoorLoopLeadDto, cookieData: DLMCCookie) =>
  _.sum([
    getKeyValue(sourceScores, cookieData?.utmSource),
    getKeyValue(timelineScores, dto.whenPlanToUseSoftware),
    getKeyValue(propertyTypeScores, dto.mainPropertyType),
    getKeyValue(propertyManagerTypeScores, dto.selectedJobTitle),
    getUnitsScore(Number(dto.numberOfUnits || 0))
  ]);
