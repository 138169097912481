import Popover from "@material-ui/core/Popover";
import { DotsIcon } from "assets/icons";
import type { FillColors } from "DLUI/icon";
import { Icon as DLUI_Icon } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import makeStyles from "./styles";
import type { PopoverItem } from "./types";
import { usePermission } from "screens/settings/userRoles/usePermission";
import _ from "lodash";
import type { SVGIconComponent } from "assets/icons/types";
import TextEllipsis from "DLUI/text/textEllipsis";
import { useTranslation } from "react-i18next";
import { DataCy } from "@doorloop/dto";

export interface PopoverProps {
  marginLeft?: number;
  marginRight?: number;
  Icon?: SVGIconComponent;
  topSectionItems: readonly PopoverItem[];
  bottomSectionItems?: PopoverItem[];
  buttonWrapperClass?: string;
  iconSize?: number;
  width?: number;
  iconPathColor?: FillColors;
  marginTop?: number;
  anchorPositionTop?: number;
  shouldShow?: boolean;
  onStateChange?: (nextState: "open" | "close") => void;
  hideDotsIcon?: boolean;
  disableHover?: boolean;
  stopPropagation?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  backgroundColor?: ViewBackgroundColor;
  onPopoverItemSelection?: (item: PopoverItem) => void;
  fontSize?: number;
  component?: React.ReactElement | null;
  iconContainerSize?: number;
  borderRadius?: number;
  dataCy?: string;
}

const DefaultIconSize = 15;

const DLUI_Popover: React.FC<PopoverProps> = ({
  marginLeft,
  marginRight,
  Icon,
  topSectionItems,
  bottomSectionItems,
  width,
  iconPathColor,
  marginTop,
  borderRadius,
  anchorPositionTop,
  shouldShow,
  onStateChange,
  hideDotsIcon,
  stopPropagation,
  backgroundColor,
  iconSize,
  onPopoverItemSelection,
  disableHover,
  onClick,
  fontSize,
  component = null,
  iconContainerSize,
  dataCy = DataCy.DLUI.editPopover
}) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const { hasClearance } = usePermission();
  const [open, setOpen] = useState<boolean>(shouldShow || false);
  const permittedTopSections = topSectionItems.filter(
    ({ clearance, active = true }) => hasClearance(clearance) && active
  );
  const permittedBottomSections =
    bottomSectionItems?.filter(({ clearance, active = true }) => hasClearance(clearance) && active) || [];
  const hasTopSections = !_.isEmpty(permittedTopSections);
  const hasBottomSections = !_.isEmpty(permittedBottomSections);
  const lastItemIndex = _.size(permittedTopSections) + _.size(permittedBottomSections) - 1;

  useEffect(() => {
    if (shouldShow !== undefined) {
      setOpen(shouldShow);
    }
  }, [shouldShow]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (onStateChange) {
      onStateChange("open");
    } else {
      setOpen(true);
    }

    onClick?.(event);
  };

  const _handleClose = (event) => {
    event.stopPropagation();

    if (onStateChange) {
      onStateChange("close");
    } else {
      setOpen(false);
    }
  };

  const renderSeparator = () => <SeparationLine height={1} marginTop={5} marginBottom={5} width={"80%"} />;

  const renderPopoverContent = () => {
    const topSection = permittedTopSections.map((currentElement, index) => {
      const _onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (onStateChange) {
          onStateChange("close");
        } else {
          setOpen(false);
        }
        setTimeout(() => {
          if (currentElement.onClick) {
            currentElement.onClick();
            onPopoverItemSelection && onPopoverItemSelection(currentElement);
          }
        }, 300);
      };
      const CurrentLineIcon = currentElement.Icon;
      return (
        <Fragment key={"POI" + index}>
          {currentElement.groupLabel && (
            <Text
              value={currentElement.groupLabel}
              fontSize={12}
              color={"gray"}
              alignSelf={"flex-start"}
              marginLeft={20}
              marginTop={4}
            />
          )}
          <div
            className={classes.lineWrapper}
            onClick={_onClick}
            key={"PO" + index}
            style={{
              marginBottom: currentElement.marginBottom,
              padding: currentElement.verticalPadding ? `${currentElement.verticalPadding}px 5px` : `0px 5px`,
              color: currentElement.colorEnum || currentElement.textColor || "black",
              ...currentElement.style
            }}
            data-cy={currentElement.dataCy}
          >
            {CurrentLineIcon ? (
              <DLUI_Icon
                Source={CurrentLineIcon}
                width={iconSize || 20}
                height={iconSize || 20}
                marginLeft={10}
                pathColor={currentElement.iconPathColor || "black"}
              />
            ) : null}
            <View flex={1} flexDirection={"row"}>
              <TextEllipsis
                color={currentElement.textColor || "black"}
                fontSize={fontSize || 16}
                paddingRight={10}
                marginLeft={10}
                text={t(currentElement.title)}
                lines={2}
                lineBreak={"auto"}
              />
            </View>
          </div>
          {currentElement.showSeparator && index !== lastItemIndex ? renderSeparator() : null}
        </Fragment>
      );
    });
    let bottomSection: any[] = [];
    if (hasBottomSections) {
      bottomSection = permittedBottomSections.map((currentElement, index) => {
        const CurrentLineIcon = currentElement.Icon;
        const _onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          if (onStateChange) {
            onStateChange("close");
          } else {
            setOpen(false);
          }
          setTimeout(() => {
            if (currentElement.onClick) {
              currentElement.onClick();
              onPopoverItemSelection && onPopoverItemSelection(currentElement);
            }
          }, 300);
        };
        return (
          <div
            className={classes.lineWrapper}
            onClick={_onClick}
            key={"PO" + index}
            style={currentElement.style}
            data-cy={currentElement.dataCy}
          >
            {CurrentLineIcon ? <CurrentLineIcon className={classes.lineIcon} /> : null}

            <Text
              color={currentElement.textColor || "error"}
              fontSize={16}
              value={currentElement.title}
              paddingRight={20}
            />
          </div>
        );
      });
    }

    return (
      <View paddingTop={10} alignItems={"center"} paddingBottom={10} noWrap>
        {topSection}
        {hasTopSections && hasBottomSections ? renderSeparator() : null}
        {bottomSection}
      </View>
    );
  };

  const hoverColor = useMemo(() => {
    let _hoverColor: ViewBackgroundColor = "secondary-gray-light";
    switch (backgroundColor) {
      case "blue": {
        _hoverColor = "blue-light";
      }
    }
    return disableHover ? undefined : _hoverColor;
  }, []);

  if (!Icon && hideDotsIcon) {
    return (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        marginRight={marginRight}
        marginLeft={marginLeft}
        marginTop={marginTop || 0}
        noWrap
      >
        <div style={{ marginTop: anchorPositionTop || 20 }} ref={anchorRef} />

        <Popover
          classes={{ paper: classes.popoverContainer }}
          open={open}
          anchorEl={anchorRef.current}
          onClose={_handleClose}
          anchorPosition={{ top: 20, left: 0 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <View width={width || undefined} noWrap>
            {renderPopoverContent()}
          </View>
        </Popover>
      </View>
    );
  }

  const containerSize = iconContainerSize || (iconSize ? iconSize + 20 : 40);

  const isVariantDefault = !component;

  const popoverBody = (
    <>
      {isVariantDefault && (
        <DLUI_Icon
          pathColor={Icon ? iconPathColor || "gray" : "white"}
          Source={Icon ? Icon : DotsIcon}
          width={iconSize || DefaultIconSize}
          height={iconSize || DefaultIconSize}
        />
      )}
      <div style={{ marginTop: isVariantDefault ? anchorPositionTop || 10 : anchorPositionTop || 0 }} ref={anchorRef} />
      <Popover
        classes={{ paper: classes.popoverContainer }}
        open={open}
        anchorEl={anchorRef.current}
        onClose={_handleClose}
        anchorPosition={{ top: 20, left: 0 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <View width={width || undefined} noWrap>
          {renderPopoverContent()}
        </View>
      </Popover>
    </>
  );

  const componentVariantMap = {
    default: (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        marginRight={marginRight}
        marginLeft={marginLeft}
        marginTop={marginTop || 0}
        width={containerSize}
        height={containerSize}
        onClick={handleClick}
        cursor={"pointer"}
        hoverColor={hoverColor}
        borderRadius={4}
        backgroundColor={backgroundColor}
        dataCy={dataCy}
      >
        {popoverBody}
      </View>
    ),
    override: (
      <div onClick={handleClick} data-cy={dataCy} style={{ marginTop, marginRight, marginLeft, cursor: "pointer" }}>
        {component}
        {popoverBody}
      </div>
    )
  };

  return hasTopSections || hasBottomSections ? componentVariantMap[component ? "override" : "default"] : null;
};

export default DLUI_Popover;
