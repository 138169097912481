import { createTheme, responsiveFontSizes } from "@material-ui/core";

const doorLoopShadow = `
  0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const DLTheme = createTheme({
  typography: {
    fontFamily: 'Muli, Lato, "Helvetica", Arial, sans-serif'
  },
  palette: {
    primary: {
      main: "#4d39e9"
    },
    secondary: {
      main: "#1b2032"
    },
    blue: {
      light: "#1665D8",
      main: "#2F3E83",
      dark: "#161646"
    },
    white: {
      main: "#fff",
      dark: "#EAEBF2",
      semi: "#ffffff80"
    },
    screenBackground: {
      main: "#F5F6FA",
      light: "#ECEEF5"
    },
    darkGreen: {
      main: "#01cc74",
      dark: "#61BE8B"
    },
    gray: {
      main: "#515F78",
      light: "#7A8699",
      dark: "#ADB3CE"
    },
    pink: {
      main: "#FF4998"
    },
    purple: {
      main: "#393875",
      light: "#6D77A8"
    },
    error: {
      main: "#ED555E",
      light: "#FF5555"
    },
    black: {
      main: "#182C4C"
    },
    orange: {
      main: "#F29A16"
    },
    yellow: {
      main: "#F2C94C",
      light: "#FEFBF2",
      dark: "#C2A13D"
    }
  },
  newPalette: {
    text: {
      primary: "#182C4C",
      secondary: "rgba(24, 44, 76, 0.6)",
      disabled: "rgba(24, 44, 76, 0.4)",
      white: "#FFFFFF"
    },
    background: {
      neutral1: "#ECEEF5",
      neutral2: "#F5F6FA",
      paper: "#FFFFFF"
    },
    primary: {
      main: "#FF4998",
      dark: "#CC3A7A",
      light: "#FDDEEB",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(255, 73, 152, 0.05)",
        selected: "rgba(255, 73, 152, 0.15)",
        focus: "rgba(255, 73, 152, 0.2)",
        focusVisible: "rgba(255, 73, 152, 0.3)",
        outlinedBorder: "rgba(255, 73, 152, 0.6)"
      }
    },
    secondary: {
      main: "#1665D8",
      dark: "#0F4697",
      light: "#E3F2FF",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(22, 101, 216, 0.04)",
        selected: "rgba(22, 101, 216, 0.15)",
        focus: "rgba(22, 101, 216, 0.2)",
        focusVisible: "rgba(22, 101, 216, 0.3)",
        outlinedBorder: "rgba(22, 101, 216, 0.6)"
      }
    },
    tertiary: {
      main: "#2F3E83",
      dark: "#202B5B",
      light: "#E8EBF4",
      contrastText: "#FFFFFF"
    },
    neutral: {
      main: "#E3E6ED",
      dark: "#A9B9D6",
      contrastText: "#182C4C"
    },
    error: {
      main: "#E44258",
      dark: "#9F2E3D",
      light: "#FCE5EA",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(255, 73, 152, 0.05)",
        selected: "rgba(228, 66, 88, 0.15)",
        focus: "rgba(228, 66, 88, 0.2)",
        focusVisible: "rgba(228, 66, 88, 0.3)",
        outlinedBorder: "rgba(228, 66, 88, 0.6)"
      }
    },
    warning: {
      light: "#FEF8E8",
      main: "#F2C94C",
      dark: "#C2A13D",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(242, 201, 76, 0.04)",
        selected: "rgba(242, 201, 76, 0.15)",
        focus: "rgba(242, 201, 76, 0.2)",
        focusVisible: "rgba(242, 201, 76, 0.2)",
        outlinedBorder: "rgba(242, 201, 76, 0.6)"
      }
    },
    info: {
      light: "#E3F2FF",
      main: "#1665D8",
      dark: "#0F4697",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(22, 101, 216, 0.04)",
        selected: "rgba(22, 101, 216, 0.15)",
        focus: "rgba(22, 101, 216, 0.2)",
        focusVisible: "rgba(22, 101, 216, 0.3)",
        outlinedBorder: "rgba(22, 101, 216, 0.6)"
      }
    },
    success: {
      main: "#01CC74",
      dark: "#01A35D",
      light: "#EBFAE6",
      contrastText: "#FFFFFF",
      states: {
        hover: "rgba(1, 204, 116, 0.04)",
        selected: "rgba(1, 204, 116, 0.15)",
        focus: "rgba(1, 204, 116, 0.2)",
        focusVisible: "rgba(1, 204, 116, 0.3)",
        outlinedBorder: "rgba(1, 204, 116, 0.6)"
      }
    },
    purple: {
      main: "#5820C0",
      "50": "#F3EEFC",
      "100": "#E3D8F8",
      "500": "#743BDE",
      "600": "#5820C0"
    },
    other: {
      divider: "rgba(24, 44, 76, 0.1)",
      overlay: "rgba(81, 95, 120, 0.85)"
    },
    action: {
      active: "#182C4C",
      disabled: "rgba(24, 44, 76, 0.3)",
      focus: "rgba(24, 44, 76, 0.2)",
      disabledBackground: "rgba(24, 44, 76, 0.12)",
      selected: "rgba(24, 44, 76, 0.1)",
      hover: "rgba(24, 44, 76, 0.05)"
    },
    input: {
      filled: {
        enabledFill: "rgba(24, 44, 76, 0.06)",
        hoverFill: "rgba(24, 44, 76, 0.09)"
      },
      outlined: {
        enabledBorder: "rgba(24, 44, 76, 0.23)",
        hoverBorder: "#182C4C",
        disabledBorder: "rgba(24, 44, 76, 0.1)"
      }
    }
  },
  props: {
    MuiMenu: { elevation: 1 },
    MuiPopover: { elevation: 1 },
    MuiPaper: { elevation: 1 }
  },
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);`
      }
    },
    MuiTooltip: {
      tooltip: {
        "&.MuiTooltip-tooltip": {
          filter: "none",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
          fontSize: 12,
          borderRadius: 2,
          padding: "12px 10px"
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: doorLoopShadow
      },
      root: {
        "&.MuiAlert-root": {
          boxSizing: "border-box",
          borderRadius: 0,
          // Restore default width when child of `flex-box`.
          // See https://stackoverflow.com/a/36080606/4106263
          alignSelf: "stretch",
          "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            "&:has(.MuiButtonGroup-root)": {
              paddingTop: 0,
              paddingBottom: 0
            }
          },
          "& .MuiAlert-icon": {
            alignSelf: "center"
          }
        },
        "&.MuiAlert-standardWarning": {
          fontSize: 16,
          backgroundColor: "rgba(242, 201, 76, 1)",
          color: "rgba(102, 87, 4, 1)"
        }
      }
    }
  }
});

export type Theme = typeof DLTheme;

export default responsiveFontSizes(DLTheme);
