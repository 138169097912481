enum Routes {
  HOME = "/home",
  NEW = "/new",
  AUTH = "/auth",
  WELCOME = "/welcome",
  CALENDAR = "/calendar",
  CALENDAR_OCCUPANCY_OVERVIEW = "/calendar/occupancy-overview",
  CALENDAR_GLOBAL_NEW = "/calendar/global-new",
  CALENDAR_OCCUPANCY_OVERVIEW_GLOBAL_NEW = "/calendar/occupancy-overview/global-new",

  ONBOARDING = "/onboarding",
  ONBOARDING_GLOBAL_NEW = "/onboarding/global-new",
  ONBOARDING_EDIT_GENERAL_SETTINGS = "/onboarding/general-settings",
  ONBOARDING_EDIT_SETTINGS = "/onboarding/edit",

  WELCOME_WIZARD_STEP1 = "/welcome/wizard/step1",
  WELCOME_WIZARD_STEP2 = "/welcome/wizard/step2",
  WELCOME_WIZARD_STEP3 = "/welcome/wizard/step3",
  WELCOME_WIZARD_STEP4 = "/welcome/wizard/step4",
  LOGIN = "/auth/login",
  TENANTS_LOGIN = "/auth/login",
  OWNERS_LOGIN = "/auth/login",
  PHONE_VERIFICATION = "/auth/phone-verification",
  FORGOT_PASSWORD = "/auth/forgot-password",
  FORGOT_PASSWORD_SENT = "/auth/forgot-password/sent",
  FORGOT_PASSWORD_CONFIRM = "/auth/forgot-password/confirm/:loginId/:token",
  SIGNUP_EMAIL = "/auth/signup",
  SIGNUP = "/auth/signup/",
  ACCEPT_INVITE_TOKEN = "/auth/accept-invite/:type/:id/:token",
  ACCEPT_INVITE = "/auth/accept-invite",
  ACCEPT_INVITE_NEW_LOGIN = "/auth/accept-invite/:type/:id/:token/new-login",
  ACCEPT_INVITE_EXISTING_LOGIN = "/auth/accept-invite/:type/:id/:token/existing-login",
  SELECT_ACCOUNT = "/auth/select-account",
  SIGNUP_THANKS = "/auth/signup/thanks",
  SIGNUP_CONFIRM = "/auth/signup/confirm/:loginId/:token",

  PROPERTIES = "/properties",
  PROPERTIES_CONFIRM = "/properties/confirm",
  PROPERTIES_GLOBAL_NEW = "/properties/global-new",
  NEW_PROPERTY = "/properties/new",
  NEW_PROPERTY_GLOBAL_NEW = "/properties/new/global-new",
  PROPERTY_DETAILS = "/properties/:propertyId",
  PROPERTY_DETAILS_GLOBAL_NEW = "/properties/:propertyId/:tabId/global-new",
  PROPERTY_DETAILS_EDIT_UNIT = "/properties/:propertyId/:tabName/edit/:unitId",
  PROPERTY_DETAILS_EDIT_RENTAL_APPLICATION = "/properties/:propertyId/rental-applications/edit/rental-application/:applicationId",
  PROPERTY_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "/properties/:propertyId/rental-applications/edit/decision-status/:applicationId",

  PROPERTY_DETAILS_EDIT_INTERNAL_TASK = "/properties/:propertyId/:tabName/internal-task/edit/:taskId",
  PROPERTY_DETAILS_EDIT_OWNER_REQUEST = "/properties/:propertyId/:tabName/owner-request/edit/:taskId",
  PROPERTY_DETAILS_EDIT_WORK_ORDER = "/properties/:propertyId/:tabName/work-order/edit/:taskId",
  PROPERTY_DETAILS_EDIT_TENANT = "/properties/:propertyId/tenants/edit/:tenantId",
  PROPERTY_DETAILS_EDIT_TENANT_REQUEST = "/properties/:propertyId/:tabName/tenant-request/edit/:taskId",
  PROPERTY_DETAILS_EDIT_VIEW_SIGNATURE_REQUEST = "/properties/:propertyId/signature-requests/view/:signatureRequestId",

  PROPERTY_DETAILS_EDIT_BOARD_MEMBER = "/properties/:propertyId/board/board-membership/edit/:boardMemberId",
  PROPERTY_DETAILS_REMOVE_BOARD_TERM = "/properties/:propertyId/board/board-membership/remove-term/:boardMemberId",

  RECURRING = "/recurring",
  RECURRING_BILLS_AND_EXPENSES = "/recurring/bills-and-expenses",
  RECURRING_BILLS_AND_EXPENSES_EDIT_EXPENSE = "/recurring/bills-and-expenses/edit-recurring-expense/:transactionId",
  RECURRING_BILLS_AND_EXPENSES_EDIT_BILL = "/recurring/bills-and-expenses/edit-recurring-bill/:transactionId",
  RECURRING_BILLS_AND_EXPENSES_EDIT_GENERAL_ENTRY = "/recurring/bills-and-expenses/recurring-general-entry/edit/:transactionId",
  RECURRING_TASKS = "/recurring/tasks",
  EDIT_RECURRING_TASK = "/recurring/tasks/edit-recurring-task/:taskId",
  EDIT_RECURRING_WORK_ORDER = "/recurring/tasks/edit-recurring-work-order/:taskId",
  TASKS = "/tasks",
  TASK_DETAILS = "/tasks/:taskId/:tabName",
  TASK_DETAILS_NEW_INTERNAL_TASK = "/tasks/:parentTaskId/subtasks/internal-task/new",
  TASK_DETAILS_EDIT_INTERNAL_TASK = "/tasks/:parentTaskId/subtasks/internal-task/edit/:taskId",
  TASK_DETAILS_NEW_WORK_ORDER = "/tasks/:parentTaskId/subtasks/work-order/new",
  TASK_DETAILS_OVERVIEW_NEW_WORK_ORDER = "/tasks/:parentTaskId/overview/work-order/new",
  TASK_DETAILS_EDIT_WORK_ORDER = "/tasks/:parentTaskId/subtasks/work-order/edit/:taskId",
  TASKS_GLOBAL_NEW = "/tasks/global-new",

  MY_TASKS = "/tasks/my-tasks",
  TENANT_REQUESTS = "/tasks/tenant-requests",

  OWNER_REQUESTS = "/tasks/owner-requests",
  OWNER_REQUESTS_EDIT_OWNER_REQUEST = "/tasks/owner-requests/owner-request/edit/:taskId",

  UNASSIGNED_TASKS = "/tasks/unassigned-tasks",
  INCOMPLETE_TASKS = "/tasks/incomplete-tasks",

  TASKS_NEW_INTERNAL_TASK = "/tasks/internal-task/new",
  TASKS_NEW_OWNER_REQUEST = "/tasks/owner-request/new",
  TASKS_NEW_TENANT_REQUEST = "/tasks/tenant-request/new",
  TASKS_EDIT_TENANT_REQUEST = "/tasks/tenant-request/edit/:taskId",

  TASKS_NEW_WORK_ORDER = "/tasks/work-order/new",

  TASKS_EDIT_INTERNAL_TASK = "/tasks/internal-task/edit/:taskId",
  TASKS_EDIT_OWNER_REQUEST = "/tasks/owner-request/edit/:taskId",
  TENANT_REQUESTS_EDIT_TENANT_REQUEST = "/tasks/tenant-requests/tenant-request/edit/:taskId",
  TASKS_EDIT_WORK_ORDER = "/tasks/work-order/edit/:taskId",

  WORK_ORDERS = "/tasks/work-orders",
  WORK_ORDERS_EDIT_WORK_ORDER = "/tasks/work-orders/work-order/edit/:taskId",
  WORK_ORDERS_RECURRING_EDIT_WORK_ORDER = "/tasks/work-orders/recurring-work-order/edit/:taskId",

  MY_TASKS_EDIT_OWNER_REQUEST = "/tasks/my-tasks/owner-request/edit/:taskId",
  MY_TASKS_EDIT_INTERNAL_TASK = "/tasks/my-tasks/internal-task/edit/:taskId",

  MY_TASKS_EDIT_RECURRING_TASK = "/tasks/my-tasks/recurring-task/edit/:taskId",

  MY_TASKS_EDIT_TENANT_REQUEST = "/tasks/my-tasks/tenant-request/edit/:taskId",
  MY_TASKS_EDIT_WORK_ORDER = "/tasks/my-tasks/work-order/edit/:taskId",

  UNASSIGNED_TASKS_EDIT_OWNER_REQUEST = "/tasks/unassigned-tasks/owner-request/edit/:taskId",
  UNASSIGNED_TASKS_EDIT_INTERNAL_TASK = "/tasks/unassigned-tasks/internal-task/edit/:taskId",
  UNASSIGNED_TASKS_EDIT_TENANT_REQUEST = "/tasks/unassigned-tasks/tenant-request/edit/:taskId",
  UNASSIGNED_TASKS_EDIT_WORK_ORDER = "/tasks/unassigned-tasks/work-order/edit/:taskId",

  INCOMPLETE_TASKS_EDIT_OWNER_REQUEST = "/tasks/incomplete-tasks/owner-request/edit/:taskId",
  INCOMPLETE_TASKS_EDIT_INTERNAL_TASK = "/tasks/incomplete-tasks/internal-task/edit/:taskId",
  INCOMPLETE_TASKS_EDIT_TENANT_REQUEST = "/tasks/incomplete-tasks/tenant-request/edit/:taskId",
  INCOMPLETE_TASKS_EDIT_WORK_ORDER = "/tasks/incomplete-tasks/work-order/edit/:taskId",

  SUBSCRIPTION = "/subscription",
  SUBSCRIPTION_DETAILS = "/subscription/details",
  SUBSCRIPTION_CHECKOUT = "/subscription/checkout",
  SUBSCRIPTION_CHECKOUT_EDIT_PAYMENT_METHOD = "/subscription/checkout/payment-method/edit",
  SUBSCRIPTION_DETAILS_EDIT_PAYMENT_METHOD = "/subscription/details/payment-method/edit",

  LEASES = "/leases",

  ACTIVE_LEASES = "/leases/active-leases",
  INACTIVE_LEASES = "/leases/inactive-leases",
  ACTIVE_LEASES_GLOBAL_NEW = "/leases/active-leases/global-new",
  ACTIVE_LEASES_DETAILS = "/leases/active-leases/:leaseId/:tabId",
  ACTIVE_LEASES_DETAILS_GLOBAL_NEW = "/leases/active-leases/:leaseId/:tabId/global-new",
  ACTIVE_LEASES_DETAILS_EDIT_TENANT = "/leases/active-leases/:leaseId/tenants/edit/:tenantId",
  ACTIVE_LEASES_DETAILS_VIEW_SIGNATURE_REQUEST = "/leases/active-leases/:leaseId/signature-requests/view/:signatureRequestId",
  ACTIVE_LEASES_DETAILS_BANK_DEPOSIT = "/leases/active-leases/:leaseId/transactions/bank-deposit/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_NEW_TENANT = "/leases/active-leases/:leaseId/:tabId/tenants/new",
  ACTIVE_LEASES_DETAILS_NEW_CHARGE = "/leases/active-leases/:leaseId/:tabId/charge/new",
  ACTIVE_LEASES_DETAILS_EDIT_CHARGE = "/leases/active-leases/:leaseId/:tabId/charge/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_NEW_CREDIT = "/leases/active-leases/:leaseId/:tabId/credit/new",
  ACTIVE_LEASES_DETAILS_EDIT_CREDIT = "/leases/active-leases/:leaseId/:tabId/credit/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_RECURRING_CHARGE = "/leases/active-leases/:leaseId/:tabId/recurring-charge/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_APPLIED_CREDIT = "/leases/active-leases/:leaseId/:tabId/applied-credit/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_RECURRING_CREDIT = "/leases/active-leases/:leaseId/:tabId/recurring-credit/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_REFUND = "/leases/active-leases/:leaseId/:tabId/refund/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_RECURRING_PAYMENT = "/leases/active-leases/:leaseId/:tabId/recurring-payment/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_RECURRING_RENT = "/leases/active-leases/:leaseId/:tabId/recurring-rent/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_PAYMENT = "/leases/active-leases/:leaseId/:tabId/payment/edit/:transactionId",
  ACTIVE_LEASES_DETAILS_EDIT_INTERNAL_TASK = "/leases/active-leases/:leaseId/tasks/internal-task/edit/:taskId",
  ACTIVE_LEASES_DETAILS_EDIT_OWNER_REQUEST = "/leases/active-leases/:leaseId/tasks/owner-request/edit/:taskId",
  ACTIVE_LEASES_DETAILS_EDIT_TENANT_REQUEST = "/leases/active-leases/:leaseId/tasks/tenant-request/edit/:taskId",
  ACTIVE_LEASES_DETAILS_EDIT_WORK_ORDER = "/leases/active-leases/:leaseId/tasks/work-order/edit/:taskId",
  ACTIVE_LEASES_DETAILS_EDIT_EVICTION_STATUS = "/leases/active-leases/:leaseId/:tabId/edit/eviction-status",

  LEASE_RENEWALS = "/leases/renewals",
  RENEW_LEASE = "/leases/renew/:leaseId",
  LEASE_RENEWALS_EDIT_OFFER = "/leases/renewals/offer/:offerId",
  END_LEASE = "/leases/end-lease/:leaseId/:tabId",

  RENTAL_APPLICATIONS = "/rental-applications",
  RENTAL_APPLICATIONS_GLOBAL_NEW = "/rental-applications/global-new",
  EDIT_RENTAL_APPLICATION = "/rental-applications/edit/rental-application/:applicationId",
  UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "/rental-applications/edit/decision-status/:applicationId",
  RENTAL_APPLICATION_DETAILS = "/rental-applications/:applicationId/:tabId",

  RENTAL_APPLICATION_DETAILS_EDIT_RENTAL_APPLICATION = "/rental-applications/:applicationId/:tabId/edit/rental-application",
  RENTAL_APPLICATION_DETAILS_EDIT_INTERNAL_TASK = "/rental-applications/:applicationId/:tabId/internal-task/edit/:taskId",

  RENTAL_APPLICATION_DETAILS_UPDATE_DECISION_STATUS = "/rental-applications/:applicationId/:tabId/edit/decision-status",

  DRAFT_LEASES = "/leases/draft-leases",
  DRAFT_LEASES_GLOBAL_NEW = "/leases/draft-leases/global-new",
  DRAFT_LEASE_DETAILS = "/leases/draft-leases/:leaseId/:tabId",
  DRAFT_LEASE_DETAILS_EDIT_TENANT = "/leases/draft-leases/:leaseId/tenants/edit/:tenantId",
  DRAFT_LEASE_DETAILS_NEW_TENANT = "/leases/draft-leases/:leaseId/:tabId/tenants/new",
  DRAFT_LEASE_DETAILS_VIEW_SIGNATURE_REQUEST = "/leases/draft-leases/:leaseId/signature-requests/view/:signatureRequestId",
  DRAFT_LEASE_DETAILS_EDIT_INTERNAL_TASK = "/leases/draft-leases/:leaseId/tasks/internal-task/edit/:taskId",
  DRAFT_LEASE_DETAILS_GLOBAL_NEW = "/leases/draft-leases/:leaseId/:tabId/global-new",

  NEW_DRAFT_LEASE = "/leases/draft-leases/new-lease",
  NEW_DRAFT_LEASE_GLOBAL_NEW = "/leases/draft-leases/new-lease/global-new",
  EDIT_DRAFT_LEASE = "/leases/draft-leases/edit-lease/:draftLeaseId",
  EDIT_DRAFT_LEASE_GLOBAL_NEW = "/leases/draft-leases/edit-lease/:draftLeaseId/global-new",

  EDIT_OWNER = "/owners/edit/:ownerId",
  EDIT_TENANT = "/tenants/edit/:tenantId",
  EDIT_VENDOR = "/vendors/edit/:vendorId",

  OUTGOING_PAYMENTS = "/outgoing-payments",
  OUTGOING_PAYMENTS_EDIT_OWNER = "/outgoing-payments/owners/edit/:ownerId",
  OUTGOING_PAYMENTS_EDIT_TENANT = "/outgoing-payments/tenants/edit/:tenantId",
  OUTGOING_PAYMENTS_EDIT_VENDOR = "/outgoing-payments/vendors/edit/:vendorId",
  OUTGOING_PAYMENTS_EDIT_EXPENSE = "/outgoing-payments/expense/edit/:transactionId",
  OUTGOING_PAYMENTS_EDIT_REFUND = "/outgoing-payments/refund/edit/:transactionId",
  OUTGOING_PAYMENTS_EDIT_BILL_PAYMENT = "/outgoing-payments/bill-payment/edit/:transactionId",

  INCOMING_PAYMENTS = "/incoming-payments",
  INCOMING_PAYMENTS_GLOBAL_NEW = "/incoming-payments/global-new",
  INCOMING_PAYMENTS_ACCOUNTS_VERIFY_IDENTITY = "/incoming-payments/merchant-account-setup/verify-identity/:merchantAccountId",
  INCOMING_PAYMENTS_STRIPE_MERCHANT_ACCOUNT = "/incoming-payments/merchant-account-setup/stripe-merchant-account/:merchantAccountId",
  INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_EIN = "/incoming-payments/merchant-account-setup/stripe-required-ein/:merchantAccountId",
  INCOMING_PAYMENTS_ACCOUNTS_UPDATE_INFO = "/incoming-payments/merchant-account-setup/stripe-update-merchant-account/:merchantAccountId",
  INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_BANK_ACCOUNT = "/incoming-payments/merchant-account-setup/stripe-required-bank-account/:merchantAccountId",
  INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_OTHER = "/incoming-payments/merchant-account-setup/stripe-required-other/:merchantAccountId",
  INCOMING_PAYMENTS_NEW_ACCOUNT = "/incoming-payments/chart-of-accounts/new",
  INCOMING_PAYMENTS_NEW_MERCHANT_ACCOUNT = "/incoming-payments/new-merchant-account/:accountId",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_BALANCE_SUMMARY = "/incoming-payments/summary",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_SETUP = "/incoming-payments/merchant-account-setup",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_GENERAL_INFO = "/incoming-payments/general-info",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS = "/incoming-payments/transactions",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_DEPOSITS = "/incoming-payments/deposits",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_DEPOSITS_EDIT_BANK_DEPOSIT = "/incoming-payments/deposits/bank-deposit/edit/:transactionId",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS_EDIT_PAYMENTS = "/incoming-payments/transactions/payment/edit/:transactionId",
  INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS_EDIT_BANK_DEPOSIT = "/incoming-payments/transactions/bank-deposit/edit/:transactionId",
  INCOMING_PAYMENTS_OUTGOING_PAYMENTS_SETTINGS = "/incoming-payments/outgoing-payments-settings",

  VENDORS = "/vendors",
  VENDORS_GLOBAL_NEW = "/vendors/global-new",
  VENDOR_DETAILS_GLOBAL_NEW = "/vendors/:vendorId/:tabName/global-new",
  VENDOR_DETAILS = "/vendors/:vendorId/:tabName",
  VENDOR_DETAILS_EDIT_BILL = "/vendors/:vendorId/transactions/bill/edit/:transactionId",
  VENDOR_DETAILS_EDIT_BILL_PAYMENT = "/vendors/:vendorId/transactions/bill-payment/edit/:transactionId",
  VENDOR_DETAILS_EDIT_CREDIT = "/vendors/:vendorId/transactions/vendor-credit/edit/:transactionId",
  VENDOR_DETAILS_EDIT_EXPENSE = "/vendors/:vendorId/transactions/expense/edit/:transactionId",
  VENDOR_DETAILS_EDIT = "/vendors/:vendorId/:tabId/edit/:sectionId",
  VENDOR_DETAILS_EDIT_WORK_ORDER = "/vendors/:vendorId/tasks/work-order/edit/:taskId",
  VENDOR_DETAILS_EDIT_INTERNAL_TASK = "/vendors/:vendorId/tasks/internal-task/edit/:taskId",

  USERS = "/users",
  USERS_GLOBAL_NEW = "/users/global-new",
  EDIT_USER = "/settings/company-users/edit/:userId",

  TENANTS = "/tenants",
  TENANTS_GLOBAL_NEW = "/tenants/global-new",
  TENANT_DETAILS = "/tenants/:tenantId/:tabName",
  TENANT_DETAILS_GLOBAL_NEW = "/tenants/:tenantId/:tabName/global-new",
  TENANT_DETAILS_EDIT = "/tenants/:tenantId/:tabId/edit/:sectionId",
  TENANT_DETAILS_COMMUNICATION = "/tenants/:tenantId/:tabId/view/:communicationId",
  TENANT_DETAILS_EDIT_TENANT_REQUEST = "/tenants/:tenantId/tasks/tenant-request/edit/:taskId",
  TENANT_DETAILS_EDIT_INTERNAL_TASK = "/tenants/:tenantId/tasks/internal-task/edit/:taskId",
  TENANT_DETAILS_EDIT_RENTAL_APPLICATION = "/tenants/:tenantId/rental-applications/edit/rental-application/:applicationId",
  TENANT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "/tenants/:tenantId/rental-applications/edit/decision-status/:applicationId",
  NEW_TENANT = "/tenants/new",

  LISTINGS = "/listings",

  SCREENINGS = "/screenings",
  MAINTENANCE = "/maintenance",

  OWNERS = "/owners",
  OWNERS_GLOBAL_NEW = "/owners/global-new",
  OWNERS_DISTRIBUTIONS = "/owners/distributions",
  OWNER_DETAILS = "/owners/:ownerId/:tabName",
  OWNER_DETAILS_EDIT = "/owners/:ownerId/:tabId/edit/:sectionId",
  OWNER_DETAILS_GLOBAL_NEW = "/owners/:ownerId/:tabName/global-new",
  OWNER_TRANSACTIONS_EDIT_BANK_DEPOSIT = "/owners/:ownerId/:tabId/bank-deposit/edit/:transactionId",
  OWNER_DETAILS_EDIT_CONTRIBUTION = "/owners/:ownerId/:tabId/owner-contribution/edit/:transactionId",
  OWNER_DETAILS_EDIT_EXPENSE = "/owners/:ownerId/:tabId/expense/edit/:transactionId",
  OWNER_DETAILS_EDIT_OWNER_REQUEST = "/owners/:ownerId/tasks/owner-request/edit/:taskId",
  OWNER_DETAILS_EDIT_INTERNAL_TASK = "/owners/:ownerId/tasks/internal-task/edit/:taskId",

  PROSPECTS = "/prospects",
  PROSPECTS_GLOBAL_NEW = "/prospects/global-new",
  PROSPECT_DETAILS_GLOBAL_NEW = "/prospects/:prospectId/:tabName/global-new",
  PROSPECT_DETAILS = "/prospects/:prospectId/:tabName",
  PROSPECT_DETAILS_EDIT = "/prospects/:prospectId/:tabName/edit/:sectionId",
  PROSPECT_DETAILS_EDIT_RENTAL_APPLICATION = "/prospects/:prospectId/rental-applications/edit/rental-application/:applicationId",
  PROSPECT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "/prospects/:prospectId/rental-applications/edit/decision-status/:applicationId",

  EDIT_PROSPECT = "/prospects/edit/:prospectId",
  EDIT_PROSPECT_RENTAL_APPLICATION = "/rental-applications/:applicationId/prospects/edit/:prospectId",
  EDIT_PROSPECT_TENANT_RENTAL_APPLICATION = "/rental-applications/:applicationId/tenants/edit/:prospectId",
  EDIT_PROPERTY_RENTAL_APPLICATION = "/rental-applications/:applicationId/:propertyId/edit/property",

  ACCOUNTING = "/accounting",

  REPORTS = "/reports",
  FILES = "/files",
  FILES_CONFIRM_DIALOG = "/files/confirm",
  FILES_FILE_PREVIEW = "/files/file-preview",
  NOTES = "/notes",
  NOTES_CONFIRM_DIALOG = "/notes/confirm",
  NOTES_EDIT = "/notes/edit/:noteId",
  FILES_EDIT = "/files/edit/:fileId",
  FILES_GLOBAL_NEW = "/files/global-new",
  NOTES_GLOBAL_NEW = "/notes/global-new",

  BANKING = "/banking",
  PRINT_CHECKS = "/banking/print-checks",
  PRINT_CHECKS_GLOBAL = "/banking/print-checks/global-new",
  PRINT_CHECKS_NEW_EXPENSE = "/banking/print-checks/expense/new",
  PRINT_CHECKS_PREVIEW = "/banking/print-checks/preview",
  PRINT_CHECKS_PREVIEW_GLOBAL = "/banking/print-checks/preview/global-new",
  PRINT_CHECKS_REMOVE_CHECK = "/banking/print-checks/remove-check",
  PRINT_CHECKS_PRINT_REVIEW_PRINTING = "/banking/print-checks/preview/review-printing",
  PRINT_CHECKS_EDIT_EXPENSE = "/banking/print-checks/expense/edit/:transactionId",
  PRINT_CHECKS_EDIT_REFUND = "/banking/print-checks/refund/edit/:transactionId",
  PRINT_CHECKS_BILL_PAYMENT = "/banking/print-checks/bill-payment/edit/:transactionId",

  PROPERTY_BANK_ACCOUNTS_REPORT = "/reports/property-bank-accounts",
  PROPERTY_BANK_ACCOUNTS_REPORT_EDIT_BANK_ACCOUNT = "/reports/property-bank-accounts/chart-of-accounts/edit/:accountId",
  TRANSACTION_DETAILS_REPORT = "/reports/transaction-details-report",
  DEPOSIT_DETAILS_REPORT = "/reports/deposit-details-report",
  PROFIT_AND_LOSS_REPORT = "/reports/profit-and-loss",
  PROFIT_AND_LOSS_DETAIL_REPORT = "/reports/profit-and-loss-detail",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_PAYMENT_EDIT_APPLIED_CREDIT = "/reports/profit-and-loss-detail/applied-credit/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_PAYMENT = "/reports/profit-and-loss-detail/payment/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_BANK_DEPOSIT = "/reports/profit-and-loss-detail/bank-deposit/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_LEASE_CHARGE = "/reports/profit-and-loss-detail/charge/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_LEASE_CREDIT = "/reports/profit-and-loss-detail/credit/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_EXPENSE = "/reports/profit-and-loss-detail/expense/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_REFUND = "/reports/profit-and-loss-detail/refund/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_BILL_PAYMENT = "/reports/profit-and-loss-detail/bill-payment/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_GENERAL_ENTRY = "/reports/profit-and-loss-detail/general-entry/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/profit-and-loss-detail/owner-contribution/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_VENDOR_CREDIT = "/reports/profit-and-loss-detail/vendor-credit/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_BANK_TRANSFER = "/reports/profit-and-loss-detail/bank-transfer/edit/:transactionId",
  PROFIT_AND_LOSS_DETAIL_REPORT_EDIT_BILL = "/reports/profit-and-loss-detail/bill/edit/:transactionId",
  DEPOSIT_DETAIL_REPORT = "/reports/deposit-detail",
  DEPOSIT_DETAIL_REPORT_EDIT_BANK_DEPOSIT = "/reports/deposit-detail/bank-deposit/edit/:transactionId",
  OWNERS_1099_REPORT = "/reports/owners-1099",
  VENDORS_1099_REPORT = "/reports/vendors-1099",
  BALANCE_SHEET_REPORT = "/reports/balance-sheet",
  BALANCE_SHEET_DETAIL_REPORT = "/reports/balance-sheet-detail",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_PAYMENT_EDIT_APPLIED_CREDIT = "/reports/balance-sheet-detail/applied-credit/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_PAYMENT = "/reports/balance-sheet-detail/payment/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_BANK_DEPOSIT = "/reports/balance-sheet-detail/bank-deposit/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_LEASE_CHARGE = "/reports/balance-sheet-detail/charge/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_LEASE_CREDIT = "/reports/balance-sheet-detail/credit/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_EXPENSE = "/reports/balance-sheet-detail/expense/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_REFUND = "/reports/balance-sheet-detail/refund/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_BILL_PAYMENT = "/reports/balance-sheet-detail/bill-payment/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_GENERAL_ENTRY = "/reports/balance-sheet-detail/general-entry/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/balance-sheet-detail/owner-contribution/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_VENDOR_CREDIT = "/reports/balance-sheet-detail/vendor-credit/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_BANK_TRANSFER = "/reports/balance-sheet-detail/bank-transfer/edit/:transactionId",
  BALANCE_SHEET_DETAIL_REPORT_EDIT_BILL = "/reports/balance-sheet-detail/bill/edit/:transactionId",
  CASH_FLOW_STATEMENT_REPORT = "/reports/cash-flow-statement",
  RENT_ROLL_REPORT = "/reports/leasing/rent-roll",
  PROPERTY_RESERVE_FUNDS_REPORT = "/reports/property/reserve-funds",
  PROPERTY_RESERVE_FUNDS_REPORT_EDIT_SETTINGS = "/reports/property/reserve-funds/edit/reserve-funds/:propertyId",
  REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS = "/reports/rent-roll-summary",
  REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY = "/reports/rent-roll-summary-by-property",
  TENANTS_REPORT = "/reports/tenants",
  TENANT_VEHICLES_REPORT = "/reports/tenant/vehicles",
  TENANTS_PETS_REPORT = "/reports/tenants-pets",
  RENTAL_APPLICATIONS_REPORT = "/reports/rental-applications",
  PROSPECTS_REPORT = "/reports/prospects",
  LEASE_INSURANCE_SUMMARY_REPORT = "/reports/leasing/insurance/summary-report",
  LEASE_INSURANCE_DETAILS_REPORT_EDIT_INSURANCE_POLICY = "/reports/leasing/insurance/details-report/insurance-policy/edit/:policyId",
  LEASE_LATE_FEES_REPORT = "/reports/leasing/late-fees",
  MANAGEMENT_FEES_REPORT = "/reports/management-fees",
  LEASE_ACCOUNT_STATEMENTS_REPORT = "/reports/leasing/account-statements",
  LEASE_INSURANCE_DETAILS_REPORT = "/reports/leasing/insurance/details-report",
  LEASING_DRAFT_LEASES_REPORT = "/reports/leasing/draft-leases",
  LEASING_LEASES_REPORT = "/reports/leasing/leases",
  VENDORS_REPORT = "/reports/vendors/directory",
  VENDORS_LEDGER_REPORT = "/reports/vendors/ledger",
  VENDORS_LEDGER_REPORT_EDIT_BILL_PAYMENT = "/reports/vendors/ledger/bill-payment/edit/:transactionId",
  VENDORS_LEDGER_REPORT_EDIT_BILL = "/reports/vendors/ledger/bill/edit/:transactionId",
  VENDORS_LEDGER_REPORT_EDIT_CREDIT = "/reports/vendors/ledger/vendor-credit/edit/:transactionId",
  VENDORS_LEDGER_REPORT_EDIT_EXPENSE = "/reports/vendors/ledger/expense/edit/:transactionId",
  WORK_ORDERS_REPORT = "/reports/work-orders",
  WORK_ORDERS_REPORT_EDIT_USER = "/reports/work-orders/users/edit/:userId",
  TASKS_REPORT = "/reports/tasks",
  TRANSACTIONS_LIST_REPORT = "/reports/transactions-list-report",
  TRANSACTIONS_LIST_EDITABLE_REPORT = "/reports/transactions-list-editable-report",
  BILLS_AND_EXPENSES = "/reports/bill-and-expenses",
  CHARGES_AND_PAYMENT = "/reports/charges-and-payment",
  TRIAL_BALANCE_REPORT = "/reports/trial-balance",
  OWNERS_REPORT = "/reports/owners",
  PROPERTY_OWNERSHIP_REPORT = "/reports/property-ownership",
  BANK_BALANCE_BY_PROPERTY = "/reports/bank-balance-by-property",

  ACCOUNTS_RECEIVABLE_AGING_DETAIL = "/reports/accounts-receivable/aging-detail",

  ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT = "/reports/accounts-receivable/aging-detail-by-account",

  ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_LEASE_CHARGE = "/reports/accounts-receivable/aging-detail-by-account/charge/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_PAYMENT = "/reports/accounts-receivable/aging-detail-by-account/payment/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_LEASE_CREDIT = "/reports/accounts-receivable/aging-detail-by-account/credit/edit/:transactionId",

  ACCOUNTS_PAYABLE_AGING_DETAIL = "/reports/accounts-payable/aging-detail",
  ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT = "/reports/accounts-payable/aging-detail-by-account",

  ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_VENDOR_CREDIT = "/reports/accounts-payable/aging-detail-by-account/vendor-credit/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_BILL_PAYMENT = "/reports/accounts-payable/aging-detail-by-account/bill-payment/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_BILL = "/reports/accounts-payable/aging-detail-by-account/bill/edit/:transactionId",

  ACCOUNTS_RECEIVABLE_AGING_SUMMARY = "/reports/accounts-receivable/aging-summary",
  ACCOUNTS_PAYABLE_AGING_SUMMARY = "/reports/accounts-payable/aging-summary",

  RECURRING_TRANSACTIONS_REPORT = "/reports/recurring-transactions",
  RECURRING_TASKS_REPORT = "/reports/recurring-tasks",
  RECURRING_WORK_ORDERS_REPORT = "/reports/recurring-work-orders",
  RECURRING_EXPENSES_REPORT = "/reports/recurring-expenses",
  RECURRING_VENDOR_BILLS_REPORT = "/reports/recurring-vendor-bills",
  RECURRING_VENDOR_BILLS_REPORT_EDIT_BILL = "/reports/recurring-vendor-bills/edit-recurring-bill/:transactionId",
  RECURRING_TRANSACTIONS_DETAIL_REPORT = "/reports/recurring-transactions-detail",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_GLOBAL_NEW = "/reports/recurring-transactions-detail/global-new",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_RENT = "/reports/recurring-transactions-detail/recurring-rent/edit/:transactionId",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_CHARGE = "/reports/recurring-transactions-detail/recurring-charge/edit/:transactionId",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_CREDIT = "/reports/recurring-transactions-detail/recurring-credit/edit/:transactionId",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_BILL = "/reports/recurring-transactions-detail/edit-recurring-bill/:transactionId",
  RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_EXPENSE = "/reports/recurring-transactions-detail/edit-recurring-expense/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_RENT = "/reports/recurring-transactions/recurring-rent/edit/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_GENERAL_ENTRY = "/reports/recurring-transactions/recurring-general-entry/edit/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_CHARGE = "/reports/recurring-transactions/recurring-charge/edit/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_CREDIT = "/reports/recurring-transactions/recurring-credit/edit/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_PAYMENT = "/reports/recurring-transactions/recurring-payment/edit/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_EXPENSE = "/reports/recurring-transactions/edit-recurring-expense/:transactionId",
  RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_BILL = "/reports/recurring-transactions/edit-recurring-bill/:transactionId",
  RECURRING_TASKS_REPORT_EDIT_TASK = "/reports/recurring-tasks/edit-recurring-task/:taskId",
  RECURRING_TASKS_REPORT_EDIT_WORK_ORDER = "/reports/recurring-tasks/edit-recurring-work-order/:taskId",
  RECURRING_WORK_ORDERS_REPORT_EDIT_WORK_ORDER = "/reports/recurring-work-orders/edit-recurring-work-order/:taskId",
  RECURRING_EXPENSES_REPORT_EDIT_EXPENSE = "/reports/recurring-expenses/edit-recurring-expense/:transactionId",

  TRANSACTIONS_REPORT_EDIT_PAYMENT = "/reports/transaction-details-report/payment/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_BANK_DEPOSIT = "/reports/transaction-details-report/bank-deposit/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_LEASE_CHARGE = "/reports/transaction-details-report/charge/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_LEASE_CREDIT = "/reports/transaction-details-report/credit/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_EXPENSE = "/reports/transaction-details-report/expense/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_REFUND = "/reports/transaction-details-report/refund/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_BILL_PAYMENT = "/reports/transaction-details-report/bill-payment/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_GENERAL_ENTRY = "/reports/transaction-details-report/general-entry/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/transaction-details-report/owner-contribution/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_VENDOR_CREDIT = "/reports/transaction-details-report/vendor-credit/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_BANK_TRANSFER = "/reports/transaction-details-report/bank-transfer/edit/:transactionId",
  TRANSACTIONS_REPORT_EDIT_BILL = "/reports/transaction-details-report/bill/edit/:transactionId",

  TRANSACTIONS_LIST_REPORT_EDIT_APPLIED_CREDIT = "/reports/transactions-list-report/applied-credit/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_PAYMENT = "/reports/transactions-list-report/payment/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_BANK_DEPOSIT = "/reports/transactions-list-report/bank-deposit/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_LEASE_CHARGE = "/reports/transactions-list-report/charge/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_LEASE_CREDIT = "/reports/transactions-list-report/credit/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_EXPENSE = "/reports/transactions-list-report/expense/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_REFUND = "/reports/transactions-list-report/refund/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_BILL_PAYMENT = "/reports/transactions-list-report/bill-payment/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_GENERAL_ENTRY = "/reports/transactions-list-report/general-entry/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/transactions-list-report/owner-contribution/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_VENDOR_CREDIT = "/reports/transactions-list-report/vendor-credit/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_BANK_TRANSFER = "/reports/transactions-list-report/bank-transfer/edit/:transactionId",
  TRANSACTIONS_LIST_REPORT_EDIT_BILL = "/reports/transactions-list-report/bill/edit/:transactionId",

  ACCOUNT_REGISTER_REPORT = "/reports/account-register-report",
  ACCOUNT_REGISTER_REPORT_EDIT_PAYMENT = "/reports/account-register-report/payment/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_BANK_DEPOSIT = "/reports/account-register-report/bank-deposit/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_LEASE_CHARGE = "/reports/account-register-report/charge/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_LEASE_CREDIT = "/reports/account-register-report/credit/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_EXPENSE = "/reports/account-register-report/expense/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_REFUND = "/reports/account-register-report/refund/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_BILL_PAYMENT = "/reports/account-register-report/bill-payment/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_GENERAL_ENTRY = "/reports/account-register-report/general-entry/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/account-register-report/owner-contribution/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_VENDOR_CREDIT = "/reports/account-register-report/vendor-credit/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_BANK_TRANSFER = "/reports/account-register-report/bank-transfer/edit/:transactionId",
  ACCOUNT_REGISTER_REPORT_EDIT_BILL = "/reports/account-register-report/bill/edit/:transactionId",

  OWNER_STATEMENT_REPORT = "/reports/owner-statement",
  OWNER_STATEMENT_REPORT_EDIT_PAYMENT = "/reports/owner-statement/payment/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_BANK_DEPOSIT = "/reports/owner-statement/bank-deposit/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_LEASE_CHARGE = "/reports/owner-statement/charge/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_LEASE_CREDIT = "/reports/owner-statement/credit/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_EXPENSE = "/reports/owner-statement/expense/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_REFUND = "/reports/owner-statement/refund/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_BILL_PAYMENT = "/reports/owner-statement/bill-payment/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_GENERAL_ENTRY = "/reports/owner-statement/general-entry/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/owner-statement/owner-contribution/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_VENDOR_CREDIT = "/reports/owner-statement/vendor-credit/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_BANK_TRANSFER = "/reports/owner-statement/bank-transfer/edit/:transactionId",
  OWNER_STATEMENT_REPORT_EDIT_BILL = "/reports/owner-statement/bill/edit/:transactionId",

  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_PAYMENT = "/reports/accounts-payable/aging-detail/payment/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_BANK_DEPOSIT = "/reports/accounts-payable/aging-detail/bank-deposit/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_LEASE_CHARGE = "/reports/accounts-payable/aging-detail/charge/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_LEASE_CREDIT = "/reports/accounts-payable/aging-detail/credit/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_EXPENSE = "/reports/accounts-payable/aging-detail/expense/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_REFUND = "/reports/accounts-payable/aging-detail/refund/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_BILL_PAYMENT = "/reports/accounts-payable/aging-detail/bill-payment/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_GENERAL_ENTRY = "/reports/accounts-payable/aging-detail/general-entry/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/accounts-payable/aging-detail/owner-contribution/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_VENDOR_CREDIT = "/reports/accounts-payable/aging-detail/vendor-credit/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_BANK_TRANSFER = "/reports/accounts-payable/aging-detail/bank-transfer/edit/:transactionId",
  ACCOUNTS_PAYABLE_AGING_DETAIL_REPORT_EDIT_BILL = "/reports/accounts-payable/aging-detail/bill/edit/:transactionId",

  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_PAYMENT = "/reports/accounts-receivable/aging-detail/payment/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_BANK_DEPOSIT = "/reports/accounts-receivable/aging-detail/bank-deposit/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_LEASE_CHARGE = "/reports/accounts-receivable/aging-detail/charge/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_LEASE_CREDIT = "/reports/accounts-receivable/aging-detail/credit/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_EXPENSE = "/reports/accounts-receivable/aging-detail/expense/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_REFUND = "/reports/accounts-receivable/aging-detail/refund/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_BILL_PAYMENT = "/reports/accounts-receivable/aging-detail/bill-payment/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_GENERAL_ENTRY = "/reports/accounts-receivable/aging-detail/general-entry/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_OWNER_CONTRIBUTION = "/reports/accounts-receivable/aging-detail/owner-contribution/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_VENDOR_CREDIT = "/reports/accounts-receivable/aging-detail/vendor-credit/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_BANK_TRANSFER = "/reports/accounts-receivable/aging-detail/bank-transfer/edit/:transactionId",
  ACCOUNTS_RECEIVABLE_AGING_DETAIL_REPORT_EDIT_BILL = "/reports/accounts-receivable/aging-detail/bill/edit/:transactionId",

  UNITS = "/units",
  UNITS_CONFIRM = "/units/confirm",
  UNITS_GLOBAL_NEW = "/units/global-new",
  UNIT_DETAILS = "/units/:unitId",
  UNIT_DETAILS_GLOBAL_NEW = "/units/:unitId/global-new",
  EDIT_UNIT = "/units/edit/:unitId",
  NEW_UNIT = "/units/new",
  UNIT_DETAILS_EDIT = "/units/:unitId/:tabId/edit",
  UNIT_DETAILS_EDIT_DIALOG_TAB = "/units/:unitId/:tabId/edit/:dialogTabName",
  UNIT_DETAILS_EDIT_TENANT = "/units/:unitId/:tabId/edit/:tenantId",
  UNIT_DETAILS_EDIT_RENTAL_APPLICATION = "/units/:unitId/rental-applications/edit/rental-application/:applicationId",
  UNIT_DETAILS_VIEW_SIGNATURE_REQUEST = "/units/:unitId/signature-requests/view/:signatureRequestId",
  UNIT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "/units/:unitId/rental-applications/edit/decision-status/:applicationId",

  UNIT_DETAILS_EDIT_OWNER_REQUEST = "/units/:unitId/:tabId/owner-request/edit/:taskId",
  UNIT_DETAILS_EDIT_INTERNAL_TASK = "/units/:unitId/:tabId/internal-task/edit/:taskId",
  UNIT_DETAILS_EDIT_TENANT_REQUEST = "/units/:unitId/:tabId/tenant-request/edit/:taskId",
  UNIT_DETAILS_EDIT_WORK_ORDER = "/units/:unitId/:tabId/work-order/edit/:taskId",

  BILLS = "/bills",
  OPEN_BILLS = "/bills/open-bills",
  OPEN_BILLS_EDIT_BILL = "/bills/open-bills/bill/edit/:transactionId",
  PAY_BILLS = "/bills/pay-bills",

  OPEN_BILLS_ACCOUNTS_PAYABLE_AGING_SUMMARY = "/open-bills/accounts-payable/aging-summary",

  INCOME_ACCOUNTS_RECEIVABLE_AGING_SUMMARY = "/income/unpaid-rent/accounts-receivable/aging-summary",

  BOOKKEEPING = "/bookkeeping",
  BOOKKEEPING_TRANSACTIONS = "/bookkeeping/transactions",
  BOOKKEEPING_BANK_ACCOUNTS_EDIT_ACCOUNT = "/bookkeeping/transactions/chart-of-accounts/edit/:accountId",
  BOOKKEEPING_TRANSACTIONS_OVERVIEW = "/bookkeeping/transactions/overview",
  BOOKKEEPING_TRANSACTIONS_OVERVIEW_NEW_ACCOUNT = "/bookkeeping/transactions/overview/chart-of-accounts/new",
  BOOKKEEPING_TRANSACTIONS_OVERVIEW_EDIT = "/bookkeeping/transactions/overview/chart-of-accounts/edit/:accountId",
  BOOKKEEPING_CONNECT_NEW_ACCOUNT = "/bookkeeping/transactions/chart-of-accounts/new",
  BOOKKEEPING_TRANSACTIONS_CONNECT_ACCOUNT = "/bookkeeping/transactions/overview/:accountId/connect",
  BOOKKEEPING_BANK_TRANSACTIONS_CONFIRM_DIALOG = "/bookkeeping/transactions/confirm",
  BOOKKEEPING_BANK_CONNECT_GLOBAL_NEW = "/bookkeeping/transactions/global-new",
  BOOKKEEPING_CONNECT_EDIT_DEPOSIT = "/bookkeeping/transactions/deposit/edit/:accountId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_REPAIR_CONNECTION = "/bookkeeping/transactions/:accountId/repair-connection",
  BOOKKEEPING_BANK_REPAIR_CONNECTION_BANK_TRANSACTIONS = "/bookkeeping/transactions/bank-transactions/:accountId/repair-connection",
  BOOKKEEPING_BANK_ACCOUNTS_DISCONNECT_ACCOUNT = "/bookkeeping/transactions/overview/:accountId/bank-disconnect",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS = "/bookkeeping/transactions/:accountId/bank-transactions",
  BOOKKEEPING_INIT_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS = "/bookkeeping/transactions/bank-transactions",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_GLOBAL_NEW = "/bookkeeping/transactions/:accountId/bank-transactions/global-new",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_DEPOSIT = "/bookkeeping/transactions/bank-transactions/bank-deposit/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_EXPENSE = "/bookkeeping/transactions/bank-transactions/expense/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BANK_TRANSFER = "/bookkeeping/transactions/bank-transactions/bank-transfer/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BILL = "/bookkeeping/transactions/bank-transactions/bill/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BILL_PAYMENT = "/bookkeeping/transactions/bank-transactions/bill-payment/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_GENERAL_ENTRY = "/bookkeeping/transactions/bank-transactions/general-entry/edit/:transactionId",
  BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_REFUND = "/bookkeeping/transactions/bank-transactions/refund/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION = "/bookkeeping/transactions/reconciliation",
  BOOKKEEPING_NEW_BANK_RECONCILIATION = "/bookkeeping/transactions/reconciliation/new",
  BOOKKEEPING_NEW_BANK_RECONCILIATION_GLOBAL_NEW = "/bookkeeping/transactions/reconciliation/new/global-new",
  BOOKKEEPING_RECONCILIATION_REPORT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId",
  BOOKKEEPING_RECONCILIATION_REPORT_GLOBAL_NEW = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/global-new",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_DEPOSIT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/bank-deposit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_CHARGE = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/charge/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_EXPENSE = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/expense/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_PAYMENT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/payment/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_CREDIT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/credit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_REFUND = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/refund/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_BILL_PAYMENT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/bill-payment/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_GENERAL_ENTRY = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/general-entry/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_OWNER_CONTRIBUTION = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/owner-contribution/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_CREDIT = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/vendor-credit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_TRANSFER = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/bank-transfer/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_BILL = "/bookkeeping/transactions/reconciliation/report/:reconciliationId/bill/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_DEPOSIT = "/bookkeeping/transactions/reconciliation/new/bank-deposit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_CHARGE = "/bookkeeping/transactions/reconciliation/new/charge/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_EXPENSE = "/bookkeeping/transactions/reconciliation/new/expense/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_PAYMENT = "/bookkeeping/transactions/reconciliation/new/payment/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_CREDIT = "/bookkeeping/transactions/reconciliation/new/credit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_REFUND = "/bookkeeping/transactions/reconciliation/new/refund/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_BILL_PAYMENT = "/bookkeeping/transactions/reconciliation/new/bill-payment/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_GENERAL_ENTRY = "/bookkeeping/transactions/reconciliation/new/general-entry/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_OWNER_CONTRIBUTION = "/bookkeeping/transactions/reconciliation/new/owner-contribution/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_CREDIT = "/bookkeeping/transactions/reconciliation/new/vendor-credit/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_TRANSFER = "/bookkeeping/transactions/reconciliation/new/bank-transfer/edit/:transactionId",
  BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_BILL = "/bookkeeping/transactions/reconciliation/new/bill/edit/:transactionId",
  BOOKKEEPING_BUDGETS_LIST = "/bookkeeping/budgets",
  BOOKKEEPING_BUDGET_NEW = "/bookkeeping/budgets/new",
  BOOKKEEPING_BUDGET_EDIT = "/bookkeeping/budgets/edit/:id",

  BUDGET_VS_ACTUAL_REPORT = "/reports/budget-vs-actual",
  OWNER_PORTAL_BUDGET_VS_ACTUAL_REPORT = "/owner-portal/reports/accounting/budget-vs-actual",

  ACCOUNTS = "/accounts",
  CHART_OF_ACCOUNTS = "/accounts/chart-of-accounts",

  EDIT_CHART_OF_ACCOUNTS = "/accounts/chart-of-accounts/edit/:accountId",
  ACCOUNTS_EDIT_BANK_ACCOUNT = "/accounts/chart-of-accounts/bank-deposit/edit/:transactionId",

  USER_ROLES = "/user-roles",
  NEW_USER_ROLE = "/user-roles/new",
  EDIT_USER_ROLE = "/user-roles/edit/:roleId",

  SETTINGS = "/settings",
  SETTINGS_GENERAL_SETTINGS = "/settings/general-settings",
  SETTINGS_COMPANY_USERS = "/settings/company-users",

  EDIT_TASK_CATEGORIES = "/settings/general-settings/edit/task-categories",
  EDIT_NOTE_CATEGORIES = "/settings/general-settings/edit/note-categories",
  EDIT_FILE_CATEGORIES = "/settings/general-settings/edit/file-categories",
  EDIT_VENDOR_CATEGORIES = "/settings/general-settings/edit/vendor-categories",
  EDIT_LEAD_SOURCE = "/settings/general-settings/edit/lead-source",
  EDIT_PORTFOLIOS = "/settings/general-settings/edit/portfolios",
  EDIT_COMPANY_LATE_FEES = "/settings/general-settings/edit/late-fees",

  TENANT_PORTAL_BASE_URL = "/tenant-portal",
  TENANT_PORTAL_HOME = "/tenant-portal/:leaseId/home",
  TENANT_PORTAL_PAYMENTS = "/tenant-portal/:leaseId/payments",
  TENANT_PORTAL_PAYMENTS_EDIT_RECURRING_PAYMENT = "/tenant-portal/:leaseId/payments/recurring-payment/edit/:transactionId",
  TENANT_PORTAL_INSURANCE = "/tenant-portal/:leaseId/insurance",
  TENANT_PORTAL_INSURANCE_CONFIRM = "/tenant-portal/:leaseId/insurance/confirm",
  TENANT_PORTAL_NEW_INSURANCE_POLICY = "/tenant-portal/:leaseId/insurance/new-insurance-policy",
  EDIT_TENANT_PORTAL_INSURANCE = "/tenant-portal/:leaseId/insurance/edit/insurance-policy/:policyId",
  TENANT_PORTAL_REQUESTS = "/tenant-portal/:leaseId/requests",
  TENANT_PORTAL_REQUESTS_NEW = "/tenant-portal/:leaseId/requests/new-request",
  TENANT_PORTAL_EDIT_REQUEST = "/tenant-portal/:leaseId/requests/edit/:requestId",
  TENANT_PORTAL_REQUESTS_EDIT_LOGIN_AND_PASSWORD = "/tenant-portal/:leaseId/requests/edit/login-and-password",
  TENANT_PORTAL_MY_LEASE_HOME = "/tenant-portal/:leaseId/home/my-lease",
  TENANT_PORTAL_MY_LEASE_PAYMENTS = "/tenant-portal/:leaseId/payments/my-lease",
  TENANT_PORTAL_MY_LEASE_INSURANCE = "/tenant-portal/:leaseId/insurance/my-lease",
  TENANT_PORTAL_MY_LEASE_REQUESTS = "/tenant-portal/:leaseId/requests/my-lease",
  TENANT_PORTAL_MY_LEASE_ANNOUNCEMENTS = "/tenant-portal/:leaseId/announcements/my-lease",
  TENANT_PORTAL_RENTAL_APPLICATION = "/tenant-portal/rental-application",
  TENANT_PORTAL_RENTAL_APPLICATION_POST_SUBMISSION_PAYMENT = "/tenant-portal/rental-application/post-submission-payment",
  TENANT_PORTAL_RENTAL_APPLICATION_APPLICANT_SCREENING_SHARE = "/tenant-portal/rental-application/reports/:token",
  TENANT_PORTAL_ANNOUNCEMENTS = "/tenant-portal/:leaseId/announcements",
  TENANT_PORTAL_ANNOUNCEMENT_DETAILS = "/tenant-portal/:leaseId/announcement-details/:announcementId",
  TENANT_PORTAL_NEW_PAYMENT_WIZARD = "/tenant-portal/:leaseId/payment-wizard/new",
  TENANT_PORTAL_NEW_RECURRING_PAYMENT_WIZARD = "/tenant-portal/:leaseId/payment-wizard/new/recurring",
  TENANT_PORTAL_EDIT_PAYMENT_WIZARD = "/tenant-portal/:leaseId/payment-wizard/edit/recurring/:transactionId",
  TENANT_PORTAL_RENTAL_APPLICATION_AUTH = "/tenant-portal/rental-application/auth",
  TENANT_PORTAL_FINANCIALS = "/tenant-portal/:leaseId/financials",
  TENANT_PORTAL_FINANCIALS_CONTACT_INFO = "/tenant-portal/:leaseId/financials/contact-info",
  TENANT_PORTAL_FINANCIALS_MY_LEASE = "/tenant-portal/:leaseId/financials/my-lease",
  TENANT_PORTAL_ACCOUNTS_RECEIVABLE_AGING_SUMMARY = "/tenant-portal/:leaseId/financials/accounts-receivable/aging-summary",
  TENANT_PORTAL_PROFIT_AND_LOSS_REPORT = "/tenant-portal/:leaseId/financials/profit-and-loss",
  TENANT_PORTAL_BALANCE_SHEET_REPORT = "/tenant-portal/:leaseId/financials/balance-sheet",
  TENANT_PORTAL_CASH_FLOW_STATEMENT_REPORT = "/tenant-portal/:leaseId/financials/cash-flow-statement",
  TENANT_PORTAL_LISTING_RENTAL_APPLICATION = "/tenant-portal/rental-applications/listing",

  OWNER_PORTAL_BASE_URL = "/owner-portal",
  OWNER_PORTAL_HOME = "/owner-portal/home",
  OWNER_PORTAL_TRANSACTIONS = "/owner-portal/transactions",
  OWNER_PORTAL_REQUESTS = "/owner-portal/requests",
  OWNER_PORTAL_REQUESTS_DELETE_CONFIRM = "/owner-portal/requests/confirm",
  OWNER_PORTAL_REQUESTS_NEW = "/owner-portal/requests/owner-request/new",
  OWNER_PORTAL_REQUESTS_VIEW = "/owner-portal/requests/owner-request/edit/:requestId",
  OWNER_PORTAL_REQUESTS_EDIT_LOGIN_AND_PASSWORD = "/owner-portal/requests/edit/login-and-password",
  OWNER_PORTAL_REPORTS = "/owner-portal/reports",
  OWNER_PORTAL_REPORTS_CONTACT_INFO = "/owner-portal/reports/contact-info",
  OWNER_PORTAL_REPORTS_EDIT_LOGIN_AND_PASSWORD = "/owner-portal/reports/edit/login-and-password",
  OWNER_PORTAL_TRANSACTION_DETAILS_REPORT = "/owner-portal/reports/transaction-details-report",
  OWNER_PORTAL_BANK_BALANCE_BY_PROPERTY = "/owner-portal/reports/bank-balance-by-property",
  OWNER_PORTAL_PROFIT_AND_LOSS_REPORT = "/owner-portal/reports/profit-and-loss",
  OWNER_PORTAL_BALANCE_SHEET_REPORT = "/owner-portal/reports/balance-sheet",
  OWNER_PORTAL_CASH_FLOW_STATEMENT_REPORT = "/owner-portal/reports/cash-flow-statement",
  OWNER_PORTAL_OWNER_STATEMENT_REPORT = "/owner-portal/reports/owner-statement",
  OWNER_PORTAL_PROPERTY_RESERVE_FUNDS_REPORT = "/owner-portal/reports/property/reserve-funds",
  OWNER_PORTAL_RENT_ROLL_REPORT = "/owner-portal/reports/leasing/rent-roll",
  OWNER_PORTAL_REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS = "/owner-portal/reports/rent-roll-summary",
  OWNER_PORTAL_REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY = "/owner-portal/reports/rent-roll-summary-by-property",
  OWNER_PORTAL_PROPERTY_OWNERSHIP_REPORT = "/owner-portal/reports/property-ownership",
  OWNER_PORTAL_OWNERS_1099_REPORT = "/owner-portal/reports/owners-1099",
  OWNER_PORTAL_PROSPECTS_REPORT = "/owner-portal/reports/prospects",
  OWNER_PORTAL_RENTAL_APPLICATIONS_REPORT = "/owner-portal/reports/rental-applications",
  OWNER_PORTAL_TASKS_REPORT = "/owner-portal/reports/tasks",
  OWNER_PORTAL_TENANTS_REPORT = "/owner-portal/reports/tenants",
  OWNER_PORTAL_TENANT_VEHICLES_REPORT = "/owner-portal/reports/tenant/vehicles",
  OWNER_PORTAL_TENANTS_PETS_REPORT = "/owner-portal/reports/tenants-pets",
  OWNER_PORTAL_ACCOUNTS_PAYABLE_AGING_DETAIL = "/owner-portal/reports/accounts-payable/aging-detail",
  OWNER_PORTAL_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT = "/owner-portal/reports/accounts-payable/aging-detail-by-account",
  OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_DETAIL = "/owner-portal/reports/accounts-receivable/aging-detail",
  OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT = "/owner-portal/reports/accounts-receivable/aging-detail-by-account",
  OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_SUMMARY = "/owner-portal/reports/accounts-receivable/aging-summary",
  OWNER_PORTAL_RECURRING_TRANSACTIONS_DETAIL_REPORT = "/owner-portal/reports/recurring-transactions-detail",

  DEMO = "/demo",
  DEMO_SIGNUP = "/demo/signup",
  DEMO_ADDITIONAL_INFO = "/demo/additional-info",
  DEMO_SCHEDULE = "/demo/schedule",

  ICON_BROWSER = "/icon-browser",

  ANNOUNCEMENTS = "/announcements",
  ANNOUNCEMENTS_GLOBAL_NEW = "/announcements/global-new",
  ANNOUNCEMENTS_NEW = "/announcements/new",
  ANNOUNCEMENTS_DETAILS = "/announcements/:announcementId/:tabName",
  ANNOUNCEMENTS_DETAILS_GLOBAL_NEW = "/announcements/:announcementId/:tabName/global-new",
  ANNOUNCEMENTS_DETAILS_EDIT = "/announcements/:announcementId/:tabName/edit",
  ANNOUNCEMENTS_DETAILS_DELETE = "/announcements/:announcementId/overview/confirm",
  ANNOUNCEMENTS_DETAILS_VIEW_COMMUNICATION = "/announcements/:announcementId/:tabName/view/:communicationId",
  ANNOUNCEMENTS_CONFIRM = "/announcements/confirm",
  COMMUNICATIONS = "/communications",
  SIGNATURE_REQUESTS = "/signature-requests",
  SIGNATURE_TEMPLATES = "/signature-templates",
  EDIT_SIGNATURE_TEMPLATE = "/signature-templates/edit/:signatureTemplateId",
  SIGNATURE_REQUESTS_VIEW = "/signature-requests/view/:signatureRequestId",
  NEW_SIGNATURE_REQUESTS = "/signature-requests/new",
  NEW_SIGNATURE_TEMPLATE = "/signature-templates/new",
  COMMUNICATIONS_CONFIRM = "/communications/confirm",
  COMMUNICATIONS_VIEW = "/communications/view/:communicationId",
  MANAGEMENT_FEES = "/management-fees",
  MANAGEMENT_FEES_GLOBAL_NEW = "/management-fees/global-new",
  OAUTH_REDIRECT = "/oauth-redirect",

  COMMUNICATIONS_CENTER = "/communications-center",
  COMMUNICATIONS_CENTER_SETUP_SMS = "/communications-center/setup-sms",
  COMMUNICATIONS_CENTER_INBOX = "/communications-center/inbox",
  COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION = "/communications-center/inbox/:conversationId",
  COMMUNICATIONS_CENTER_ALL_MESSAGES = "/communications-center/all-messages",
  COMMUNICATIONS_CENTER_ALL_MESSAGES_VIEW_CONVERSATION = "/communications-center/all-messages/:conversationId",
  COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES = "/communications-center/unassigned-messages",
  COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION = "/communications-center/unassigned-messages/:conversationId",
  CONVERSATION_TASK = "/conversation-task"
}

export default Routes;

export const makeEditRoute = (id: string) => `/edit/${id}`;
