import React, { useMemo } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import type { SelectProps } from "@material-ui/core/Select";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { View } from "DLUI/view";
import type { ViewBackgroundColor, ViewComponentProps } from "DLUI/view/view";
import { fieldSizesMap, useStylesTextInput } from "DLUI/form/textField/textInput";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { useUniqueId } from "hooks/useUniqueId";

import ViewOnlyInput from "../../viewOnlyInput/viewOnlyInput";

import "./styles.css";
import { FieldSizes } from "DLUI/form/textField/types";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import type { MenuProps } from "@material-ui/core";

interface OptionValid {
  value: string;
  label: string;
}

export interface SelectInputProps extends Omit<SelectProps, "error"> {
  size?: number | string;
  marginLeft?: number;
  marginTop?: number;
  label?: string;
  uniqueKey: string;
  required?: boolean;
  flexValue?: number;
  selectionEnum?: Record<string, string>;
  customEnumOrder?: string[];
  onTransform?: (values: OptionValid[]) => OptionValid[];
  value: string;
  translationKey?: string;
  isError?: boolean;
  errorText?: string;
  paddingRight?: number;
  jsonFile?: any[];
  jsonkeys?: string[];
  jsonValue?: string;
  viewOnly?: boolean;
  fullWidthViewOnly?: boolean;
  defaultValue?: string;
  paddingLeft?: number;
  enableEPay?: boolean;
  viewOnlyBackgroundColor?: ViewBackgroundColor;
  viewOnlyMarginLeft?: number;
  variant?: "filled" | "outlined";
  dataCy?: string;
  disabled?: boolean;
  viewOnlyExtraValue?: string;
  useEnumValue?: boolean;
  MenuProps?: Partial<MenuProps>;
  IconComponent?: React.FC;
  className?: string;
  viewProps?: ViewComponentProps;
}

const SelectInput: React.FC<SelectInputProps> = ({
  size,
  marginLeft = 0,
  marginTop = 0,
  label: propsLabel = "",
  uniqueKey,
  required,
  selectionEnum,
  customEnumOrder,
  onTransform,
  name,
  value,
  onChange,
  onBlur,
  errorText,
  isError,
  style = {},
  translationKey,
  paddingRight,
  paddingLeft,
  jsonFile,
  jsonkeys,
  jsonValue,
  viewOnly,
  fullWidthViewOnly,
  viewOnlyBackgroundColor = "transparent",
  viewOnlyMarginLeft,
  variant = "outlined",
  dataCy,
  disabled = false,
  viewOnlyExtraValue,
  useEnumValue,
  MenuProps,
  IconComponent,
  className,
  viewProps
}: SelectInputProps) => {
  const { isMobile } = useResponsiveHelper();

  const classes = useStylesTextInput();
  const deviceClassName = clsx(["DLUI_Select", isMobile ? "MOBILE" : "WEB"]);

  const _fieldSize: FieldSizes = useMemo(() => (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB), [isMobile]);

  const { t } = useTranslation();
  const isReadOnlyContext = useReadOnlyContext();
  const translatedLabel = useMemo(() => t(propsLabel), [t, propsLabel]);

  /**
   * There is a bug in material when adding required attribute,
   * they are not adding asterisk to the span element from Select component.
   * So we are adding here asterisk manually to only Select component label.
   * Also in case the label is empty string we turn it to undefined because material renders an empty space on the ui.
   */
  const selectTranslatedLabel = translatedLabel ? (required ? `${translatedLabel} *` : translatedLabel) : undefined;

  const labelId = useUniqueId();

  const widthWithoutPadding = size?.toString() || "100%";
  const width = paddingRight ? `calc(${widthWithoutPadding} - ${paddingRight}px)` : widthWithoutPadding;

  const styleSelect = useMemo(() => {
    return {
      marginLeft,
      width,
      ...fieldSizesMap[_fieldSize].InputProps.style
    };
  }, [width, marginLeft, _fieldSize]);

  const selectItems: OptionValid[] = useMemo(() => {
    if (selectionEnum) {
      const items = (customEnumOrder || Object.keys(selectionEnum)).map<OptionValid>((itemKey: string) => {
        const value = useEnumValue || customEnumOrder ? selectionEnum[itemKey] : itemKey;
        const valueEnum = selectionEnum[itemKey];
        const label = translationKey
          ? t(`common.enums.${translationKey}.${useEnumValue ? itemKey : valueEnum}`)
          : valueEnum;

        return { value, label };
      });

      return onTransform ? onTransform(items) : items;
    }

    if (jsonFile && jsonValue) {
      return jsonFile.map((currentItem) => {
        const label = jsonkeys?.reduce((displayName, key, keyIndex) => {
          const val = currentItem[key];
          if (!keyIndex) {
            return val;
          }
          // TODO: Move language logic elsewhere
          if (keyIndex === 1 && key === "language") {
            return `${displayName} (${val})`;
          }
          return `${displayName} ${val}`;
        }, "");

        return {
          label: label || currentItem[jsonValue],
          value: currentItem[jsonValue]
        };
      });
    }

    return [];
  }, [selectionEnum]);

  if (viewOnly || isReadOnlyContext) {
    return (
      <ViewOnlyInput
        value={
          (translationKey && selectionEnum ? t(`common.enums.${translationKey}.${selectionEnum[value]}`) : value) +
          (viewOnlyExtraValue || "")
        }
        label={translatedLabel}
        backgroundColor={viewOnlyBackgroundColor}
        marginTop={marginTop}
        fullWidth={fullWidthViewOnly}
        marginLeft={viewOnlyMarginLeft}
      />
    );
  }

  return (
    <View marginTop={marginTop} paddingLeft={paddingLeft} paddingRight={paddingRight} {...viewProps}>
      <FormControl
        className={clsx([deviceClassName, classes.root, className])}
        variant={variant}
        style={{ width: "100%", ...style }}
        required={required}
        error={isError || Boolean(errorText)}
      >
        {translatedLabel && (
          <InputLabel
            variant={variant}
            id={labelId}
            className={clsx([deviceClassName, classes.label])}
            margin={isMobile ? undefined : "dense"}
          >
            {translatedLabel}
          </InputLabel>
        )}
        <Select
          IconComponent={IconComponent}
          MenuProps={MenuProps}
          labelId={labelId}
          label={selectTranslatedLabel}
          id={"Select" + uniqueKey}
          variant={variant}
          style={styleSelect}
          margin={isMobile ? undefined : "dense"}
          classes={{ select: deviceClassName }}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          error={isError || Boolean(errorText)}
          data-cy={dataCy || name}
          disabled={disabled}
        >
          {selectItems?.map((item) =>
            item ? (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ) : null
          )}
        </Select>
        {errorText ? <FormHelperText>{errorText}</FormHelperText> : null}
      </FormControl>
    </View>
  );
};

export default SelectInput;
