const Leases = {
  Screen: {
    MoveIn: "leases.screen.moveIn",
    ExpiringSoon: "leases.screen.expiringSoon",
    Lease: "leases.screen.lease",
    EmptydataViewInstructionsText: "leases.screen.emptydataViewInstructionsText",
    EmptydataAddNew: "leases.screen.emptydataAddNew",
    SearchPaneltitle: "leases.screen.searchPaneltitle",
    SearchPlaceHolder: "leases.screen.searchPlaceHolder",
    DeleteConfirmationText: "leases.screen.deleteConfirmationText",
    DeleteLoadingText: "leases.screen.deleteLoadingText",
    DeleteSuccessText: "leases.screen.deleteSuccessText",
    AddNewLease: "leases.screen.addNewLease",
    ViewRentalApplication: "leases.screen.viewRentalApplication",
    CreateLease: "leases.screen.createLease",
    MakeActive: "leases.screen.makeActive",
    CancelLease: "leases.screen.cancelLease",
    CancelLeaseLoadingText: "leases.screen.cancelLeaseLoadingText",
    CancelLeaseSuccessText: "leases.screen.cancelLeaseSuccessText",
    ActivateLeaseLoadingText: "leases.screen.activateLeaseLoadingText",
    ActivateLeaseSuccessText: "leases.screen.activateLeaseSuccessText",
    LeaseType: "leases.screen.leaseType",
    Termduring: "leases.screen.termduring",
    TermStarts: "leases.screen.termStarts",
    NewLease: "leases.screen.newLease",
    DraftLease: "leases.screen.draftLease",
    ExistingLease: "leases.screen.existingLease",
    LeaseRenewal: "leases.screen.leaseRenewal",
    RenewalLease: "leases.screen.renewalLease",
    NoLeaseIdFoundError: "leases.screen.noLeaseIdFoundError",
    ManageLease: "leases.screen.manageLease",
    HelpPanel: {
      DraftLease: {
        Description: "leases.screen.helpPanel.draftLease.description",
        CreateLease: "leases.screen.helpPanel.draftLease.createLease",
        EditLease: "leases.screen.helpPanel.draftLease.editLease",
        SignLease: "leases.screen.helpPanel.draftLease.signLease"
      },
      Renewals: {
        Description: "leases.screen.helpPanel.renewals.description",
        RenewLease: "leases.screen.helpPanel.renewals.renewLease",
        EndLease: "leases.screen.helpPanel.renewals.endLease",
        EndOfTerm: "leases.screen.helpPanel.renewals.endOfTerm"
      },
      RentalApplications: {
        Description: "leases.screen.helpPanel.rentalApplications.description",
        SetUp: "leases.screen.helpPanel.rentalApplications.setUp",
        HowToSend: "leases.screen.helpPanel.rentalApplications.howToSend",
        ReviewApplications: "leases.screen.helpPanel.rentalApplications.reviewApplications",
        DialogTitle: "leases.screen.helpPanel.rentalApplications.dialogTitle"
      }
    }
  },
  InsurancePolicy: {
    NewInsurancePolicy: "leases.insurancePolicy.newInsurancePolicy",
    SelectLease: "leases.insurancePolicy.selectLease",
    InsurancePolicy: "leases.insurancePolicy.insurancePolicy",
    InsuranceDetails: "leases.insurancePolicy.insuranceDetails",
    PolicyOwner: "leases.insurancePolicy.policyOwner",
    PolicyNumber: "leases.insurancePolicy.policyNumber",
    EffectiveDate: "leases.insurancePolicy.effectiveDate",
    ExpirationDate: "leases.insurancePolicy.expirationDate",
    ProviderName: "leases.insurancePolicy.providerName",
    LiabilityCoverage: "leases.insurancePolicy.liabilityCoverage",
    InsuranceProvided: "leases.insurancePolicy.status.insuranceProvided",
    InsuranceRequired: "leases.insurancePolicy.insuranceRequired",
    InsuranceNotRequired: "leases.insurancePolicy.insuranceNotRequired",
    DeletePolicyConfirm: "leases.insurancePolicy.deletePolicyConfirm",
    Status: {
      Provided: "leases.insurancePolicy.status.provided",
      Expired: "leases.insurancePolicy.status.expired",
      Future: "leases.insurancePolicy.status.future",
      AddPolicy: "leases.insurancePolicy.status.addPolicy"
    }
  },
  EditLease: {
    SelectUnitsAddRemoveFromXLease: "leases.editLease.selectUnitsAddRemoveFromXLease"
  },
  NewLease: {
    IfNewLeaseDatesCorrectHelperText: "leases.newLease.ifNewLeaseDatesCorrectHelperText",
    NavigationHelperTitle: "leases.newLease.navigationHelperTitle",
    LearnMoreNewLease: "leases.newLease.learnMoreNewLease",
    HelpPanel: {
      Subtitle: "leases.newLease.helpPanel.subtitle",
      NewLease: "leases.newLease.helpPanel.newLease",
      AddPreviousLeases: "leases.newLease.helpPanel.addPreviousLeases",
      DialogTitle: "leases.newLease.helpPanel.dialogTitle"
    },
    PleaseNote: "leases.newLease.pleaseNote",
    MultipleUnitsLeaseTotalsInfo: "leases.newLease.multipleUnitsLeaseTotalsInfo",
    UnitEditConfirmations: {
      ConfirmAddRemove: "leases.newLease.unitEditConfirmations.confirmAddRemove",
      ConfirmAdd: "leases.newLease.unitEditConfirmations.confirmAdd",
      ConfirmRemove: "leases.newLease.unitEditConfirmations.confirmRemove"
    },
    ConflictErrorMessage: "leases.newLease.conflictErrorMessage",
    MultipleConflictsErrorMessage: "leases.newLease.multipleConflictsErrorMessage",
    NewLeaseConflictErrorMessage: "leases.newLease.newLeaseConflictErrorMessage",
    ScreenTitle: "leases.newLease.screenTitle",
    LeaseTerm: "leases.newLease.leaseTerm",
    WhichProperty: "leases.newLease.whichProperty",
    WhichUnit: "leases.newLease.whichUnit",
    Unit: "leases.newLease.unit",
    OptionalUnit: "leases.newLease.optionalUnit",
    ChooseUnit: "leases.newLease.chooseUnit",
    ChooseProperty: "leases.newLease.chooseProperty",
    LeaseTermsTitle: "leases.newLease.leaseTermsTitle",
    Fixed: "leases.newLease.fixed",
    Atwill: "leases.newLease.atwill",
    Rollover: "leases.newLease.rollover",
    TotalBalanceDue: "leases.newLease.totalBalanceDue",
    TotalDepositsHeld: "leases.newLease.totalDepositsHeld",
    TotalRecurringRent: "leases.newLease.totalRecurringRent",
    TotalRecurringCharges: "leases.newLease.totalRecurringCharges",
    TotalRecurringCredits: "leases.newLease.totalRecurringCredits",
    TotalRecurringPayments: "leases.newLease.totalRecurringPayments",
    StartDate: "leases.newLease.startDate",
    EndDate: "leases.newLease.endDate",
    SwitchToAtWill: "leases.newLease.switchToAtWill",
    LeaseDates: "leases.newLease.leaseDates",
    LeaseCharges: "leases.newLease.leaseCharges",
    LeaseChargesDescription: "leases.newLease.leaseChargesDescription",
    LeaseOverview: {
      PropertySection: {
        Title: "leases.newLease.leaseOverview.propertySection.title",
        Subtitle: "leases.newLease.leaseOverview.propertySection.subtitle",
        PropertyPlaceholder: "leases.newLease.leaseOverview.propertySection.propertyPlaceholder",
        UnitsPlaceholder: "leases.newLease.leaseOverview.propertySection.unitsPlaceholder"
      },
      TermsSection: {
        Title: "leases.newLease.leaseOverview.termsSection.title",
        Subtitle: "leases.newLease.leaseOverview.termsSection.subtitle",
        ImageBoxes: {
          Fixed: "leases.newLease.leaseOverview.termsSection.imageBoxes.fixed",
          Monthly: "leases.newLease.leaseOverview.termsSection.imageBoxes.monthly"
        },
        Dates: {
          StartDate: "leases.newLease.leaseOverview.termsSection.dates.startDate",
          EndDate: "leases.newLease.leaseOverview.termsSection.dates.endDate"
        },
        Rollover: "leases.newLease.leaseOverview.termsSection.rollover"
      }
    },
    LeaseTenants: {
      LeaseTenantsTitle: "leases.newLease.leaseTenants.leaseTenantsTitle",
      SelectTenants: "leases.newLease.leaseTenants.selectTenants",
      AddTenant: "leases.newLease.leaseTenants.addTenant",
      CreateNewTenant: "leases.newLease.leaseTenants.createNewTenant",
      SelectExistingTenant: "leases.newLease.leaseTenants.selectExistingTenant",
      AddNewTenantToLease: "leases.newLease.leaseTenants.addNewTenantToLease",
      ResidentPortalAccess: "leases.newLease.leaseTenants.residentPortalAccess",
      ResidentPortalAccessDescription: "leases.newLease.leaseTenants.residentPortalAccessDescription",
      RequireRentersInsurance: "leases.newLease.leaseTenants.requireRentersInsurance",
      RequireRentersInsuranceDescription: "leases.newLease.leaseTenants.requireRentersInsuranceDescription",
      LearnMoreResidentPortal: "leases.newLease.leaseTenants.learnMoreResidentPortal",
      LearnMoreRentersInsurance: "leases.newLease.leaseTenants.learnMoreRentersInsurance",
      ChooseFromList: "leases.newLease.leaseTenants.chooseFromList"
    },
    LeaseRent: {
      OccurrencesRemaining: "leases.newLease.leaseRent.occurrencesRemaining",
      Occurrences: "leases.newLease.leaseRent.occurrences",
      NextOccurrence: "leases.newLease.leaseRent.nextOccurrence",
      LastOccurrence: "leases.newLease.leaseRent.lastOccurrence",
      RentCharges: "leases.newLease.leaseRent.rentCharges",
      RentChargesDescription: "leases.newLease.leaseRent.rentChargesDescription",
      Charges: "leases.newLease.leaseRent.charges",
      Charge: "leases.newLease.leaseRent.charge",
      FirstRentDate: "leases.newLease.leaseRent.firstRentDate",
      FirstRentPlaceholder: "leases.newLease.leaseRent.firstRentPlaceholder",
      RentFrequency: "leases.newLease.leaseRent.rentFrequency",
      RentChargesItemDescription: "leases.newLease.leaseRent.rentChargesItemDescription",
      AddFutureRent: "leases.newLease.leaseRent.addFutureRent",
      ScheduleRentIncrease: "leases.newLease.leaseRent.scheduleRentIncrease",
      Amount: "leases.newLease.leaseRent.amount",
      WhicAccount: "leases.newLease.leaseRent.whicAccount",
      WhichRentAccount: "leases.newLease.leaseRent.whichRentAccount",
      Description: "leases.newLease.leaseRent.description",
      AddAnotherCharge: "leases.newLease.leaseRent.addAnotherCharge",
      AddAnotherChargeOneTime: "leases.newLease.leaseRent.addAnotherChargeOneTime",
      AddAnotherRentSchedule: "leases.newLease.leaseRent.addAnotherRentSchedule",
      OtherCharges: "leases.newLease.leaseRent.otherCharges",
      OtherChargesDescription: "leases.newLease.leaseRent.otherChargesDescription",
      RecurringCharges: "leases.newLease.leaseRent.recurringCharges",
      RecurringCredit: "leases.newLease.leaseRent.recurringCredit",
      RecurringPayment: "leases.newLease.leaseRent.recurringPayment",
      RecurringCreditDescriptionHeader: "leases.newLease.leaseRent.recurringCreditDescriptionHeader",
      AddAnotherSecurityDepositCharge: "leases.newLease.leaseRent.addAnotherSecurityDepositCharge",
      AddSecurityDepositCharge: "leases.newLease.leaseRent.addSecurityDepositCharge",
      AddAnotherRecurringCharge: "leases.newLease.leaseRent.addAnotherRecurringCharge",
      AddAnotherRecurringCredit: "leases.newLease.leaseRent.addAnotherRecurringCredit",
      AddAnotherOneTimeCharge: "leases.newLease.leaseRent.addAnotherOneTimeCharge",
      AddAnotherOneTimeCredit: "leases.newLease.leaseRent.addAnotherOneTimeCredit",
      AddRecurringCharge: "leases.newLease.leaseRent.addRecurringCharge",
      AddRecurringCredit: "leases.newLease.leaseRent.addRecurringCredit",
      AddOneTimeCharge: "leases.newLease.leaseRent.addOneTimeCharge",
      AddOneTimeCredit: "leases.newLease.leaseRent.addOneTimeCredit",
      OneTimeCharges: "leases.newLease.leaseRent.oneTimeCharges",
      OneTimeChargesDescription: "leases.newLease.leaseRent.oneTimeChargesDescription",
      OneTimeCreditsDescription: "leases.newLease.leaseRent.oneTimeCreditsDescription",
      Credits: "leases.newLease.leaseRent.credits",
      Credit: "leases.newLease.leaseRent.credit",
      CreditsDescription: "leases.newLease.leaseRent.creditsDescription",
      DueDate: "leases.newLease.leaseRent.dueDate",
      DateIssued: "leases.newLease.leaseRent.dateIssued",
      SecurityDeposit: "leases.newLease.leaseRent.securityDeposit",
      SecurityDepositDescription: "leases.newLease.leaseRent.securityDepositDescription",
      UntilEndOfLease: "leases.newLease.leaseRent.untilEndOfLease",
      RepeatUntilEndOfLease: "leases.newLease.leaseRent.repeatUntilEndOfLease",
      Frequency: "leases.newLease.leaseRent.frequency",
      LateFees: "leases.newLease.leaseRent.lateFees",
      DefaultPolicy: "leases.newLease.leaseRent.defaultPolicy",
      LateFeesDescription: "leases.newLease.leaseRent.lateFeesDescription",
      UsePropertyLateFeesPolicy: "leases.newLease.leaseRent.usePropertyLateFeesPolicy",
      SpecifyLateFeesPolicy: "leases.newLease.leaseRent.specifyLateFeesPolicy",
      StartChargingLateFee: "leases.newLease.leaseRent.startChargingLateFee",
      DaysAfterRentDue: "leases.newLease.leaseRent.daysAfterRentDue",
      ChargeAmountOf: "leases.newLease.leaseRent.chargeAmountOf",
      ContinueCharging: "leases.newLease.leaseRent.continueCharging",
      UntilRentPaid: "leases.newLease.leaseRent.untilRentPaid",
      FeeIncomeAccount: "leases.newLease.leaseRent.feeIncomeAccount",
      Days: "leases.newLease.leaseRent.days",
      RecurringRent: "leases.newLease.leaseRent.recurringRent",
      OtherRecurringTransactions: "leases.newLease.leaseRent.otherRecurringTransactions",
      NextCharge: "leases.newLease.leaseRent.nextCharge",
      ProrateCharges: {
        Notification: "leases.newLease.leaseRent.prorateCharges.notification",
        ProrateCharges: "leases.newLease.leaseRent.prorateCharges.prorateCharges",
        ProrateDate: "leases.newLease.leaseRent.prorateCharges.prorateDate",
        AddAdditionalCharge: "leases.newLease.leaseRent.prorateCharges.addAdditionalCharge",
        RecalculateCharges: "leases.newLease.leaseRent.prorateCharges.recalculateCharges"
      }
    },
    LeaseDeposits: {
      SecurityDepositTitle: "leases.newLease.leaseDeposits.securityDepositTitle",
      SecurityDepositSubTitle: "leases.newLease.leaseDeposits.securityDepositSubTitle",
      SecurityDepositAmount: "leases.newLease.leaseDeposits.securityDepositAmount",
      SecurityDepositTellUsMore: "leases.newLease.leaseDeposits.securityDepositTellUsMore",
      SecurityDepositDatePlaceholder: "leases.newLease.leaseDeposits.securityDepositDatePlaceholder"
    },
    LeaseLateFees: {
      Title: "leases.newLease.leaseLateFees.title",
      Subtitle: "leases.newLease.leaseLateFees.subtitle",
      PolicySettingsDescription: "leases.newLease.leaseLateFees.policySettingsDescription",
      LateFeesActivateOptions: {
        Active: "leases.newLease.leaseLateFees.lateFeesActivateOptions.active",
        Disable: "leases.newLease.leaseLateFees.lateFeesActivateOptions.disable"
      },
      PolicyOptions: {
        PropertyLateFees: "leases.newLease.leaseLateFees.policyOptions.propertyLateFees",
        CustomLateFees: "leases.newLease.leaseLateFees.policyOptions.customLateFees"
      },
      policyType: {
        Disabled: "leases.newLease.leaseLateFees.policyType.disabled",
        Property: "leases.newLease.leaseLateFees.policyType.property",
        Company: "leases.newLease.leaseLateFees.policyType.company"
      },
      NotificationAlert: {
        Title: "leases.newLease.leaseLateFees.notificationAlert.title",
        Description: "leases.newLease.leaseLateFees.notificationAlert.description"
      }
    },
    LeaseStatus: {
      SendESignature: "leases.newLease.leaseStatus.sendESignature",
      Inactive: "leases.newLease.leaseStatus.inactive",
      ESignatureChooseDescription: "leases.newLease.leaseStatus.eSignatureChooseDescription",
      ESignatureSentDescription: "leases.newLease.leaseStatus.eSignatureSentDescription",
      DefineYourLeaseTitle: "leases.newLease.leaseStatus.defineYourLeaseTitle",
      DefineYourLeaseSubtitle: "leases.newLease.leaseStatus.defineYourLeaseSubtitle",
      ScreenTitle: "leases.newLease.leaseStatus.screenTitle",
      TypeOfLease: "leases.newLease.leaseStatus.typeOfLease",
      Active: "leases.newLease.leaseStatus.active",
      Draft: "leases.newLease.leaseStatus.draft",
      NotSent: "leases.newLease.leaseStatus.notSent",
      Sent: "leases.newLease.leaseStatus.sent",
      PartiallySigned: "leases.newLease.leaseStatus.partiallySigned",
      CounterSigned: "leases.newLease.leaseStatus.counterSigned",
      Activated: "leases.newLease.leaseStatus.activated",
      Cancelled: "leases.newLease.leaseStatus.cancelled",
      DraftStatus: "leases.newLease.leaseStatus.draftStatus",
      DraftStatusDescription: "leases.newLease.leaseStatus.draftStatusDescription",
      UploadFileInstructions: "leases.newLease.leaseStatus.uploadFileInstructions",
      ActiveLeaseTooltipDescription: "leases.newLease.leaseStatus.activeLeaseTooltipDescription",
      DraftLeaseTooltipDescription: "leases.newLease.leaseStatus.draftLeaseTooltipDescription",
      CurrentStatus: "leases.newLease.leaseStatus.currentStatus",
      Created: "leases.newLease.leaseStatus.created",
      Signed: "leases.newLease.leaseStatus.signed",
      SaveAsDraft: "leases.newLease.leaseStatus.saveAsDraft",
      NotSigned: "leases.newLease.leaseStatus.notSigned",
      Viewed: "leases.newLease.leaseStatus.viewed",
      Accepted: "leases.newLease.leaseStatus.accepted",
      Rejected: "leases.newLease.leaseStatus.rejected"
    }
  },
  ExistingLease: {
    Subtitle: "leases.existingLease.subtitle",
    RolloverToAtWill: "leases.existingLease.rolloverToAtWill",
    Yes: "leases.existingLease.yes",
    No: "leases.existingLease.no",
    MonthToMonth: "leases.existingLease.monthToMonth",
    RentCharges: "leases.existingLease.rentCharges",
    WillIncrease: "leases.existingLease.willIncrease",
    WhenIncrease: "leases.existingLease.whenIncrease",
    WillBeChargingDeposit: "leases.existingLease.willBeChargingDeposit",
    NewDeposits: "leases.existingLease.newDeposits",
    ExistingDeposits: "leases.existingLease.existingDeposits",
    HowMuchAdditionalDeposit: "leases.existingLease.howMuchAdditionalDeposit",
    CurrentlyHeld: "leases.existingLease.currentlyHeld",
    AdditionalDeposit: "leases.existingLease.additionalDeposit",
    Expiring: "leases.existingLease.expiring",
    LeaseTenants: {
      Notification: {
        Title: "leases.existingLease.leaseTenants.notification.title",
        Description: "leases.existingLease.leaseTenants.notification.description"
      }
    },
    LeaseRent: {
      FirstRentDate: "leases.existingLease.leaseRent.firstRentDate",
      Notification: "leases.existingLease.leaseRent.notification"
    },
    LeaseDeposits: {
      SecurityDepositSubTitle: "leases.existingLease.leaseDeposits.securityDepositSubTitle",
      SecurityDepositAmount: "leases.existingLease.leaseDeposits.securityDepositAmount",
      Notification: "leases.existingLease.leaseDeposits.notification"
    },
    LeaseStatus: {
      OriginalLeaseCopy: "leases.existingLease.leaseStatus.originalLeaseCopy",
      Description: {
        FirstLine: "leases.existingLease.leaseStatus.description.firstLine",
        SecondLine: "leases.existingLease.leaseStatus.description.secondLine"
      },
      SmallDirectionTip: "leases.existingLease.leaseStatus.smallDirectionTip"
    }
  },
  WizardSteps: {
    Overview: "leases.wizardSteps.overview",
    Tenants: "leases.wizardSteps.tenants",
    Rent: "leases.wizardSteps.rent",
    Deposit: "leases.wizardSteps.deposit",
    LateFees: "leases.wizardSteps.lateFees",
    Status: "leases.wizardSteps.status",
    Review: "leases.wizardSteps.review"
  },
  ReviewStepItems: {
    PropertyNameTitle: "leases.reviewStepItems.propertyNameTitle",
    UnitNameTitle: "leases.reviewStepItems.unitNameTitle",
    LeaseTermsTitle: "leases.reviewStepItems.leaseTermsTitle",
    DatesTitle: "leases.reviewStepItems.datesTitle",
    TenantsTitle: "leases.reviewStepItems.tenantsTitle",
    RentChargesTitle: "leases.reviewStepItems.rentChargesTitle",
    DepositTitle: "leases.reviewStepItems.depositTitle",
    LateFeesTitle: "leases.reviewStepItems.lateFeesTitle",
    StepTitle: "leases.reviewStepItems.stepTitle",
    StepSubtitle: "leases.reviewStepItems.stepSubtitle",
    ReviewTitle: "leases.reviewStepItems.reviewTitle",
    FixedTerm: "leases.reviewStepItems.fixedTerm",
    MonthToMonth: "leases.reviewStepItems.monthToMonth"
  },

  DraftLease: {
    Terms: "leases.draftLease.terms",
    MoveIn: "leases.draftLease.moveIn",
    MoveInDate: "leases.draftLease.moveInDate",
    Started: "leases.draftLease.started",
    Starts: "leases.draftLease.starts",
    Days: "leases.draftLease.days",
    DaysAgo: "leases.draftLease.daysAgo",
    MoveTomorrow: "leases.draftLease.moveTomorrow",
    MoveToday: "leases.draftLease.moveToday",
    MoveOut: "leases.draftLease.moveOut",
    Expiring: "leases.draftLease.expiring",
    WithRollover: "leases.draftLease.withRollover",
    MoveOutTomorrow: "leases.draftLease.moveOutTomorrow",
    MoveOutToday: "leases.draftLease.moveOutToday",
    Overdue: "leases.draftLease.overdue",
    Valid: "leases.draftLease.valid",
    Expired: "leases.draftLease.expired",
    ReceivePayment: "leases.draftLease.receivePayment",
    CreateNewReceivePayment: "leases.draftLease.createNewReceivePayment",
    AddCharge: "leases.draftLease.addCharge",
    GiveRefund: "leases.draftLease.giveRefund",
    IssueCredit: "leases.draftLease.issueCredit",
    OutstandingBalance: "leases.draftLease.outstandingBalance",
    UpcomingCharges: "leases.draftLease.upcomingCharges",
    DeleteDraftLeasConfirmationText: "leases.draftLease.deleteDraftLeasConfirmationText",
    DeleteDraftLeaseLoadingText: "leases.draftLease.deleteDraftLeaseLoadingText",
    DeleteDraftLeaseSuccessText: "leases.draftLease.deleteDraftLeaseSuccessText",
    NoRenewalDraftLeaseIdFoundError: "leases.draftLease.noRenewalDraftLeaseIdFoundError"
  },
  FutureCharges: {
    FutureChargeChip: "leases.futureCharges.futureChargeChip",
    UpcomingCharges: "leases.futureCharges.upcomingCharges"
  },
  LeaseDetails: {
    MonthlyRent: "leases.leaseDetails.monthlyRent",
    DepositsHeld: "leases.leaseDetails.depositsHeld",
    Transactions: "leases.leaseDetails.transactions",
    Rent: "leases.leaseDetails.rent",
    Deposits: "leases.leaseDetails.deposits",
    LeaseCharge: "leases.leaseDetails.leaseCharge",
    LeasePayment: "leases.leaseDetails.leasePayment",
    LeaseRefund: "leases.leaseDetails.leaseRefund",
    LeaseCredit: "leases.leaseDetails.leaseCredit",
    VedorBill: "leases.leaseDetails.vedorBill",
    VedorBillPayment: "leases.leaseDetails.vedorBillPayment",
    VendorCredit: "leases.leaseDetails.vendorCredit",
    Expense: "leases.leaseDetails.expense",
    GeneralEntery: "leases.leaseDetails.generalEntery",
    Transfer: "leases.leaseDetails.transfer",
    CurrentBalance: "leases.leaseDetails.currentBalance",
    LastPaymentDate: "leases.leaseDetails.lastPaymentDate",
    ChangeUnitsForLeaseConfirmation: "leases.leaseDetails.changeUnitsForLeaseConfirmation"
  },
  LeaseUnit: {
    LinkedUnits: "leases.leaseUnits.linkedUnits",
    EditUnitsTitle: "leases.leaseUnits.editUnitsTitle",
    LeaseUnits: "leases.leaseUnits.leaseUnits",
    NumberOfLinkedUnits: "leases.leaseUnits.numOfLinkedUnits",
    UnitName: "leases.leaseUnits.unitName",
    UnitSize: "leases.leaseUnits.unitSize",
    EditOwnerButtonText: "leases.leaseUnits.editOwnerButtonText",
    LeaseSubTitle: "leases.leaseUnits.leaseSubTitle",
    DepositHeld: "leases.leaseUnits.depositHeld",
    Units: "leases.leaseUnits.units",
    Property: "leases.leaseUnits.property"
  },
  ListHeader: {
    Lease: "leases.listHeader.lease",
    LeaseNotes: "leases.listHeader.leaseNotes",
    Term: "leases.listHeader.term",
    Status: "leases.listHeader.status",
    Rent: "leases.listHeader.rent",
    RentPerSize: "leases.listHeader.rentPerSize",
    Deposits: "leases.listHeader.deposits",
    Balance: "leases.listHeader.balance",
    RenewalStatus: "leases.listHeader.renewalStatus",
    LastPaymentDate: "leases.listHeader.lastPaymentDate",
    LastPaymentAmount: "leases.listHeader.lastPaymentAmount",
    LastReturnedPaymentDate: "leases.listHeader.lastReturnedPaymentDate",
    LastReturnedPaymentAmount: "leases.listHeader.lastReturnedPaymentAmount",
    NumOfReturnedPayments: "leases.listHeader.numOfReturnedPayments",
    NumOfLateFeeCharges: "leases.listHeader.numOfLateFeeCharges",
    LastLateFeeChargeDate: "leases.listHeader.lastLateFeeChargeDate",
    NextLease: "leases.listHeader.nextLease",
    NextLeaseStart: "leases.listHeader.nextLeaseStart",
    Tenant: "leases.listHeader.tenant",
    Provider: "leases.listHeader.provider",
    Coverage: "leases.listHeader.coverage"
  },
  BulkActionErrors: {
    NoLeaseSelected: "leases.bulkActionErrors.noLeaseSelected",
    NoCustomAllocationSelected: "leases.bulkActionErrors.noCustomAllocationSelected",
    NoUnitsWithValidSizes: "leases.bulkActionErrors.noUnitsWithValidSizes",
    TotalChargeMustBeGreaterThanZero: "leases.bulkActionErrors.totalChargeMustBeGreaterThanZero",
    TotalCreditMustBeGreaterThanZero: "leases.bulkActionErrors.totalCreditMustBeGreaterThanZero",
    NoLeasesFoundForThisProperty: "leases.bulkActionErrors.noLeasesFoundForThisProperty"
  },
  LeaseCredit: {
    ProcessingCreditForLeaseX: "leases.leaseCredit.processingCreditForLeaseX",
    BulkIssueCredits: "leases.leaseCredit.bulkIssueCredits",
    PerUnitCredits: "leases.leaseCredit.perUnitCredits",
    CommonCreditAllocation: "leases.leaseCredit.commonCreditAllocation",
    TotalCredit: "leases.leaseCredit.totalCredit"
  },
  LeaseCharge: {
    CurrentBalanceDue: "leases.leaseCharge.currentBalanceDue",
    Greater: "leases.leaseCharge.greater",
    Less: "leases.leaseCharge.less",
    NoUnitsSelected: "leases.leaseCharge.noUnitsSelected",
    ChargeTotalDeltaConflict: "leases.leaseCharge.chargeTotalDeltaConflict",
    NoPropertySelected: "leases.leaseCharge.noPropertySelected",
    ProcessingChargeForLeaseX: "leases.leaseCharge.processingChargeForLeaseX",
    CommonChargeAllocation: "leases.leaseCharge.commonChargeAllocation",
    Rounding: "leases.leaseCharge.rounding",
    TotalCharge: "leases.leaseCharge.totalCharge",
    BulkPostCharges: "leases.leaseCharge.bulkPostCharges",
    PerUnitCharges: "leases.leaseCharge.perUnitCharges",
    OneTimeCharge: "leases.leaseCharge.oneTimeCharge",
    CreateNewOneTimeCharge: "leases.leaseCharge.createNewOneTimeCharge",
    OneTimeCredit: "leases.leaseCharge.oneTimeCredit",
    CreateNewOneTimeCredit: "leases.leaseCharge.createNewOneTimeCredit",
    OneTimePayment: "leases.leaseCharge.oneTimePayment",
    OneTimeRefund: "leases.leaseCharge.oneTimeRefund",
    CreateNewOneTimeRefund: "leases.leaseCharge.createNewOneTimeRefund",
    RecurringCharge: "leases.leaseCharge.recurringCharge",
    SelectLease: "leases.leaseCharge.selectLease",
    SelectProperty: "leases.leaseCharge.selectProperty",
    Reference: "leases.leaseCharge.reference",
    ReferenceAuto: "leases.leaseCharge.referenceAuto",
    Description: "leases.leaseCharge.description",
    AddLineItem: "leases.leaseCharge.addLineItem",
    BalanceDue: "leases.leaseCharge.balanceDue",
    YourOutstandingBalance: "leases.leaseCharge.yourOutstandingBalance",
    TotalRefund: "leases.leaseCharge.totalRefund",
    TotalCredit: "leases.leaseCharge.totalCredit",
    TotalPayment: "leases.leaseCharge.totalPayment",
    TotalAmount: "leases.leaseCharge.totalAmount",
    AddNote: "leases.leaseCharge.addNote",
    AddMemo: "leases.leaseCharge.addMemo",
    EmptyChargeValidationMessage: "leases.leaseCharge.emptyChargeValidationMessage",
    PressToSelectLease: "leases.leaseCharge.pressToSelectLease",
    LearnMoreOneTimePayment: "leases.leaseCharge.learnMoreOneTimePayment",
    DescriptionOneTimeRefund: "leases.leaseCharge.descriptionOneTimeRefund",
    DepositAmount: "leases.leaseCharge.depositAmount",
    DepositAccount: "leases.leaseCharge.depositAccount",
    IssueCreditHelpPanel: {
      Description: "leases.leaseCharge.issueCreditHelpPanel.description",
      Create: "leases.leaseCharge.issueCreditHelpPanel.create",
      EditDelete: "leases.leaseCharge.issueCreditHelpPanel.editDelete",
      DialogTitle: "leases.leaseCharge.issueCreditHelpPanel.dialogTitle"
    },
    OneTimePaymentHelpPanel: {
      Description: "leases.leaseCharge.oneTimePaymentHelpPanel.description",
      Record: "leases.leaseCharge.oneTimePaymentHelpPanel.record",
      EditDelete: "leases.leaseCharge.oneTimePaymentHelpPanel.editDelete",
      DialogTitle: "leases.leaseCharge.oneTimePaymentHelpPanel.dialogTitle"
    },
    OneTimeChargeHelpPanel: {
      Description: "leases.leaseCharge.oneTimeChargeHelpPanel.description",
      Create: "leases.leaseCharge.oneTimeChargeHelpPanel.create",
      EditDelete: "leases.leaseCharge.oneTimeChargeHelpPanel.editDelete",
      DialogTitle: "leases.leaseCharge.oneTimeChargeHelpPanel.dialogTitle"
    },
    RefundHelpPanel: {
      Description: "leases.leaseCharge.refundHelpPanel.description",
      GiveRefund: "leases.leaseCharge.refundHelpPanel.giveRefund",
      EditDeleteRefund: "leases.leaseCharge.refundHelpPanel.editDeleteRefund",
      DialogTitle: "leases.leaseCharge.refundHelpPanel.dialogTitle"
    }
  },
  RenewLease: {
    NewTerms: "leases.renewLease.newTerms",
    NewTermsValidation: "leases.renewLease.newTermsValidation",
    TabInstructions: "leases.renewLease.tabInstructions",
    RentChargesInstructions: "leases.renewLease.rentChargesInstructions",
    OtherChargesInstructions: "leases.renewLease.otherChargesInstructions",
    DepositsInstructions: "leases.renewLease.depositsInstructions",
    CreateLease: "leases.renewLease.createLease",
    CreateLeaseSuccessText: "leases.renewLease.createLeaseSuccessText",
    statusValidation: "leases.renewLease.statusValidation",
    WizardSteps: {
      Overview: "leases.renewLease.wizardSteps.overview",
      ChargesAndCredits: "leases.renewLease.wizardSteps.chargesAndCredits",
      Deposits: "leases.renewLease.wizardSteps.deposits"
    }
  },
  LeaseRenewals: {
    TermEnds: "leases.leaseRenewals.termEnds",
    LeasesExpiring: "leases.leaseRenewals.leasesExpiring",
    NotRenewing: "leases.leaseRenewals.notRenewing",
    Started: "leases.leaseRenewals.started",
    NotStarted: "leases.leaseRenewals.notStarted",
    OfferPending: "leases.leaseRenewals.offerPending",
    DraftPending: "leases.leaseRenewals.draftPending",
    NextSteps: "leases.leaseRenewals.nextSteps",
    CreateOffer: "leases.leaseRenewals.createOffer",
    RenewLease: "leases.leaseRenewals.renewLease",
    EndLease: "leases.leaseRenewals.endLease",
    ActivateLease: "leases.leaseRenewals.activateLease",
    NewRenewalOffer: "leases.leaseRenewals.newRenewalOffer",
    CurrentTerms: "leases.leaseRenewals.currentTerms",
    PoposedNewTerms: "leases.leaseRenewals.proposedNewTerms",
    ProposedNewTermsDescription: "leases.leaseRenewals.proposedNewTermsDescription",
    AddAnotherOffer: "leases.leaseRenewals.addAnotherOffer",
    TermOffer: "leases.leaseRenewals.termOffer",
    ProposedRent: "leases.leaseRenewals.proposedRent",
    ProposedTerms: "leases.leaseRenewals.proposedTerms",
    NotifyTenantsDescription: "leases.leaseRenewals.notifyTenantsDescription",
    ViewLease: "leases.leaseRenewals.viewLease",
    EditLease: "leases.leaseRenewals.editLease",
    ViewOffer: "leases.leaseRenewals.viewOffer",
    LoadingOfferData: "leases.leaseRenewals.loadingOfferData",
    LoadingOfferDataErrorText: "leases.leaseRenewals.loadingOfferDataErrorText",
    LoadingOfferDataSuccessText: "leases.leaseRenewals.loadingOfferDataSuccessText",
    CreatingOffer: "leases.leaseRenewals.creatingOffer",
    OfferStatus: "leases.leaseRenewals.offerStatus",
    NonRenewalNotice: "leases.leaseRenewals.nonRenewalNotice",
    NoticeDate: "leases.leaseRenewals.noticeDate",
    NoticeNotes: "leases.leaseRenewals.noticeNotes",
    ViewDraft: "leases.leaseRenewals.viewDraft",
    EditDraft: "leases.leaseRenewals.editDraft"
  },
  EndLease: {
    HelpPanel: {
      Subtitle: "leases.endLease.helpPanel.subtitle",
      EndLease: "leases.endLease.helpPanel.endLease",
      EndedLeaseEdit: "leases.endLease.helpPanel.endedLeaseEdit",
      EndedLeaseDeposit: "leases.endLease.helpPanel.endedLeaseDeposit",
      VideoEndLease: "leases.endLease.helpPanel.videoEndLease"
    },
    EndingLeaseTitle: "leases.endLease.endingLeaseTitle",
    EndingLeaseDescription: "leases.endLease.endingLeaseDescription",
    LeaseBalance: "leases.endLease.leaseBalance",
    OwedToYou: "leases.endLease.owedToYou",
    RefundTenant: "leases.endLease.refundTenant",
    ZeroBalanceNotificationLabel: "leases.endLease.zeroBalanceNotificationLabel",
    LeaseHasSecurityDepositAndNoOB: "leases.endLease.leaseHasSecurityDepositAndNoOB",
    LeaseHasOBAndNoSecurityDeposit: "leases.endLease.leaseHasOBAndNoSecurityDeposit",
    RefundTitle: "leases.endLease.refundTitle",
    RefundDescription: "leases.endLease.refundDescription",
    ActionsLabelOneAction: "leases.endLease.actionsLabelOneAction",
    ActionsLabelExactlyOneAction: "leases.endLease.actionsLabelExactlyOneAction",
    ActionsLabelTwoActions: "leases.endLease.actionsLabelTwoActions",
    ActionsLabelOneOrMoreActions: "leases.endLease.actionsLabelOneOrMoreActions",
    DeleteLeaseAnywayDescription: "leases.endLease.deleteLeaseAnywayDescription",
    DeleteLeaseAnywayDescriptionSecondary: "leases.endLease.deleteLeaseAnywayDescriptionSecondary",
    EndLeaseAnyway: "leases.endLease.endLeaseAnyway",
    MoneyOwedToYou: "leases.endLease.moneyOwedToYou",
    MoneyOwedToTenant: "leases.endLease.moneyOwedToTenant",
    RefundGreaterThanDepositDescription: "leases.endLease.refundGreaterThanDepositDescription",
    HasOBAndDepositButDepositGreater: "leases.endLease.hasOBAndDepositButDepositGreater",
    HasOBAndDepositButDepositLessThan: "leases.endLease.hasOBAndDepositButDepositLessThan",
    IssueCreditToZeroOutDescription: "leases.endLease.issueCreditToZeroOutDescription",
    IssueCreditToReduceAmountOwedTitle: "leases.endLease.issueCreditToReduceAmountOwedTitle",
    IssueCreditToReduceAmountOwedDescription: "leases.endLease.issueCreditToReduceAmountOwedDescription",
    PostChargeToZeroOutDescription: "leases.endLease.postChargeToZeroOutDescription",
    PostChargeToIncreaseDepositBalanceDescriptionTitle:
      "leases.endLease.postChargeToIncreaseDepositBalanceDescriptionTitle",
    PostChargeToIncreaseDepositBalanceDescription: "leases.endLease.postChargeToIncreaseDepositBalanceDescription",
    PostChargeToReduceAmountOwedToTenantTitle: "leases.endLease.postChargeToReduceAmountOwedToTenantTitle",
    PostChargeToReduceAmountOwedToTenantDescription: "leases.endLease.postChargeToReduceAmountOwedToTenantDescription",
    PostChargeToReduceCreditAndDepositTitle: "leases.endLease.postChargeToReduceCreditAndDepositTitle",
    PostChargeToReduceCreditAndDepositDescription: "leases.endLease.postChargeToReduceCreditAndDepositDescription",
    ReceivePaymentToZeroOutTitle: "leases.endLease.receivePaymentToZeroOutTitle",
    ReceivePaymentToZeroOutDescription: "leases.endLease.receivePaymentToZeroOutDescription",
    AddACreditToReduceAmountOwedTitle: "leases.endLease.addACreditToReduceAmountOwedTitle",
    AddACreditToReduceAmountOwedDescription: "leases.endLease.addACreditToReduceAmountOwedDescription",
    WithholdDepositToApplyToLeaseTitle: "leases.endLease.withholdDepositToApplyToLeaseTitle",
    WithholdDepositToApplyToLease: "leases.endLease.withholdDepositToApplyToLease",
    WithholdDepositToPartiallyCoverAmountOwnedTitle: "leases.endLease.withholdDepositToPartiallyCoverAmountOwnedTitle",
    WithholdDepositToPartiallyCoverAmountOwnedDescription:
      "leases.endLease.withholdDepositToPartiallyCoverAmountOwnedDescription",
    LeaseHasOutstandingCredit: "leases.endLease.leaseHasOutstandingCredit",
    LeaseHasOutstandingCreditWithSecurityDeposit: "leases.endLease.leaseHasOutstandingCreditWithSecurityDeposit",
    LeaseOutstandingCredit: "leases.endLease.leaseOutstandingCredit",
    RefundTheAmountOwedTheTenantTitle: "leases.endLease.refundTheAmountOwedTheTenantTitle",
    RefundTheAmountOwedTheTenant: "leases.endLease.refundTheAmountOwedTheTenant",
    RefundTheCreditAndDepositToTenantTitle: "leases.endLease.refundTheCreditAndDepositToTenantTitle",
    RefundTheCreditAndDepositToTenant: "leases.endLease.refundTheCreditAndDepositToTenant",

    WizardSteps: {
      Overview: "leases.endLease.wizardSteps.overview",
      ChargesAndCredits: "leases.endLease.wizardSteps.chargesAndCredits",
      Deposits: "leases.endLease.wizardSteps.deposits",
      Refunds: "leases.endLease.wizardSteps.refunds"
    },
    CurrentLeaseTerms: "leases.endLease.currentLeaseTerms",
    MoveOutDate: "leases.endLease.moveOutDate",
    MoveOutDateDescription: "leases.endLease.moveOutDateDescription",
    NoticeDate: "leases.endLease.noticeDate",
    MoveOutChargesAndCredits: "leases.endLease.moveOutChargesAndCredits",
    MoveOutChargesAndCreditsDescription: "leases.endLease.moveOutChargesAndCreditsDescription",
    MoveOutChargesAndCreditsAction: "leases.endLease.moveOutChargesAndCreditsAction",
    RefundDueToTenant: "leases.endLease.refundDueToTenant",
    LoadingLeaseData: "leases.endLease.loadingLeaseData",
    LeaseDataLoaded: "leases.endLease.leaseDataLoaded",
    AddMoveOutCharge: "leases.endLease.addMoveOutCharge",
    AddMoveOutCredit: "leases.endLease.addMoveOutCredit",
    InsufficientOutstandingBalance: "leases.endLease.insufficientOutstandingBalance",
    RefundDepositTabDescription: "leases.endLease.refundDepositTabDescription",
    WithholdDepositTabDescription: "leases.endLease.withholdDepositTabDescription",
    WithholdDepositValidationMessage: "leases.endLease.withholdDepositValidationMessage",
    RefundBalance: "leases.endLease.refundBalance",
    RefundBalanceDescription: "leases.endLease.refundBalanceDescription",
    RefundsValidationMessage: "leases.endLease.refundsValidationMessage",
    EndingLease: "leases.endLease.endingLease",
    LeaseEnded: "leases.endLease.leaseEnded"
  },
  LeaseTransaction: {
    Dialog: {
      TitleText: "leases.leaseTransaction.dialog.titleText",
      LoadingText: "leases.leaseTransaction.dialog.loadingText",
      ReverseConfirmationText: "leases.leaseTransaction.dialog.reverseConfirmationText",
      ReversedPaymentSuccessText: "leases.leaseTransaction.dialog.reversedPaymentSuccessText",
      ExplainerText: "leases.leaseTransaction.dialog.explainerText",
      OriginalPayment: "leases.leaseTransaction.dialog.originalPayment",
      ReturnedPayment: "leases.leaseTransaction.dialog.returnedPayment",
      ReturnDate: "leases.leaseTransaction.dialog.returnDate",
      BenefitsTitle: "leases.leaseTransaction.dialog.benefitsTitle",
      Benefits1: "leases.leaseTransaction.dialog.benefits1",
      Benefits2: "leases.leaseTransaction.dialog.benefits2",
      shouldChargeReturnedPaymentFees: "leases.leaseTransaction.dialog.shouldChargeReturnedPaymentFees",
      InputAmountLabel: "leases.leaseTransaction.dialog.inputAmountLabel",
      InputAccountLabel: "leases.leaseTransaction.dialog.inputAccountLabel"
    },
    ReceivedFrom: "leases.leaseTransaction.receivedFrom",
    TransactionType: "leases.leaseTransaction.transactionType",
    NoTransactionsFound: "leases.leaseTransaction.noTransactionsFound",
    ReconcileWithoutTransactions: "leases.leaseTransaction.reconcileWithoutTransactions",
    ReconciliationReportForZeroTransactions: "leases.leaseTransaction.reconciliationReportForZeroTransactions",
    ReconciliationReportForZeroTransactionsDescription:
      "leases.leaseTransaction.reconciliationReportForZeroTransactionsDescription",
    CreateReconciliation: "leases.leaseTransaction.createReconciliation",

    NoDepositsFound: "leases.leaseTransaction.noDepositsFound",
    Charge: {
      SwitchToBulkPostCharges: "leases.leaseTransaction.charge.switchToBulkPostCharges",
      SwitchToPostCharge: "leases.leaseTransaction.charge.switchToPostCharge",
      CreatedText: "leases.leaseTransaction.charge.CreatedText",
      LoadingData: "leases.leaseTransaction.charge.loadingData",
      LoadingDataSuccess: "leases.leaseTransaction.charge.loadingDataSuccess",
      DeleteConfirmationText: "leases.leaseTransaction.charge.deleteConfirmationText",
      DeleteChargeLoadingText: "leases.leaseTransaction.charge.deleteChargeLoadingText",
      DeleteChargeSuccessText: "leases.leaseTransaction.charge.deleteChargeSuccessText",
      Creating: "leases.leaseTransaction.charge.creating",
      Updating: "leases.leaseTransaction.charge.updating",
      Updated: "leases.leaseTransaction.charge.updated"
    },
    Credit: {
      SwitchToBulkIssueCredits: "leases.leaseTransaction.credit.switchToBulkIssueCredits",
      SwitchToIssueCredit: "leases.leaseTransaction.credit.switchToIssueCredit",
      CreatedText: "leases.leaseTransaction.credit.createdText",
      LoadingData: "leases.leaseTransaction.credit.loadingData",
      LoadingDataSuccess: "leases.leaseTransaction.credit.loadingDataSuccess",
      DeleteConfirmationText: "leases.leaseTransaction.credit.deleteConfirmationText",
      DeleteChargeLoadingText: "leases.leaseTransaction.credit.deleteChargeLoadingText",
      DeleteChargeSuccessText: "leases.leaseTransaction.credit.deleteChargeSuccessText",
      Creating: "leases.leaseTransaction.credit.creating",
      Updating: "leases.leaseTransaction.credit.updating",
      Updated: "leases.leaseTransaction.credit.updated"
    },
    Deposit: {
      ChargeDeposit: "leases.leaseTransaction.deposit.chargeDeposit",
      CreatedText: "leases.leaseTransaction.deposit.createdText",
      LoadingData: "leases.leaseTransaction.deposit.loadingData",
      LoadingDataSuccess: "leases.leaseTransaction.deposit.loadingDataSuccess",
      DeleteConfirmationText: "leases.leaseTransaction.deposit.deleteConfirmationText",
      DeleteChargeLoadingText: "leases.leaseTransaction.deposit.deleteChargeLoadingText",
      DeleteChargeSuccessText: "leases.leaseTransaction.deposit.deleteChargeSuccessText",
      Creating: "leases.leaseTransaction.deposit.creating",
      Updating: "leases.leaseTransaction.deposit.updating",
      Updated: "leases.leaseTransaction.deposit.updated",
      AmountHeld: "leases.leaseTransaction.deposit.amountHeld",
      DepositAccount: "leases.leaseTransaction.deposit.depositAccount",
      AmountToWithhold: "leases.leaseTransaction.deposit.amountToWithhold",
      AmountToRefund: "leases.leaseTransaction.deposit.amountToRefund",
      LeaseOutstandingBalance: "leases.leaseTransaction.deposit.leaseOutstandingBalance",
      TotalDepositsWithheld: "leases.leaseTransaction.deposit.totalDepositsWithheld",
      RemainingDeposits: "leases.leaseTransaction.deposit.remainingDeposits",
      NoDepositHeld: "leases.leaseTransaction.deposit.noDepositHeld",
      DepositsRefunded: "leases.leaseTransaction.deposit.depositsRefunded"
    },
    Payment: {
      SwitchToBulkReceivePayments: "leases.leaseTransaction.payment.switchToBulkReceivePayments",
      SwitchToSinglePaymentReceive: "leases.leaseTransaction.payment.switchToSinglePaymentReceive",
      BulkReceivePayments: "leases.leaseTransaction.payment.bulkReceivePayments",
      ReceivedFrom: "leases.leaseTransaction.payment.receivedFrom",
      DepositAccount: "leases.leaseTransaction.payment.depositAccount",
      PaymentAmount: "leases.leaseTransaction.payment.paymentAmount",
      AmountReceived: "leases.leaseTransaction.payment.amountReceived",
      PaymentMethod: "leases.leaseTransaction.payment.paymentMethod",
      CheckNumber: "leases.leaseTransaction.payment.checkNumber",
      CheckNumberAbbreviated: "leases.leaseTransaction.payment.checkNumberAbbreviated",
      AutomaticPaymentAllocation: "leases.leaseTransaction.payment.automaticPaymentAllocation",
      AutomaticDepositCreation: "leases.leaseTransaction.payment.automaticDepositCreation",
      SendPaymentReceipts: "leases.leaseTransaction.payment.sendPaymentReceipts",
      OutstandingTransactions: "leases.leaseTransaction.payment.outstandingTransactions",
      LoadingOutstandingTransactions: "leases.leaseTransaction.payment.loadingOutstandingTransactions",
      Charge: "leases.leaseTransaction.payment.charge",
      NoOutstandingTransactions: "leases.leaseTransaction.payment.noOutstandingTransactions",
      DeleteConfirmationText: "leases.leaseTransaction.payment.deleteConfirmationText",
      DeletePaymentLoadingText: "leases.leaseTransaction.payment.deletePaymentLoadingText",
      DeletePaymentSuccessText: "leases.leaseTransaction.payment.deletePaymentSuccessText",
      AmountToApply: "leases.leaseTransaction.payment.amountToApply",
      AmountToCredit: "leases.leaseTransaction.payment.amountToCredit",
      CreatedText: "leases.leaseTransaction.payment.createdText",
      LoadingData: "leases.leaseTransaction.payment.loadingData",
      InvalidAmountReceived: "leases.leaseTransaction.payment.invalidAmountReceived",
      LoadingDataSuccess: "leases.leaseTransaction.payment.loadingDataSuccess",
      Creating: "leases.leaseTransaction.payment.creating",
      Updating: "leases.leaseTransaction.payment.updating",
      Updated: "leases.leaseTransaction.payment.updated",
      AppliedCreditHelpPanel: {
        Description: "leases.leaseTransaction.payment.appliedCreditHelpPanel.description"
      }
    },
    Refund: {
      PayToTenant: "leases.leaseTransaction.refund.payToTenant",
      PayFromAccount: "leases.leaseTransaction.refund.payFromAccount",
      AddToPrintQueue: "leases.leaseTransaction.refund.addToPrintQueue",
      LeaseRefund: "leases.leaseTransaction.refund.leaseRefund",
      OverpaymentRefund: "leases.leaseTransaction.refund.overpaymentRefund",
      RefundDeposit: "leases.leaseTransaction.refund.refundDeposit",
      WithholdDeposit: "leases.leaseTransaction.refund.withholdDeposit",
      CreateNewWithholdDeposit: "leases.leaseTransaction.refund.createNewWithholdDeposit",
      Accountbalance: "leases.leaseTransaction.refund.accountbalance",
      DeleteRefundLoadingText: "leases.leaseTransaction.refund.deleteRefundLoadingText",
      DeleteRefundSuccessText: "leases.leaseTransaction.refund.deleteRefundSuccessText",
      DeleteConfirmationText: "leases.leaseTransaction.refund.deleteConfirmationText"
    },
    Rent: {
      CreatedText: "leases.leaseTransaction.rent.createdText",
      LoadingData: "leases.leaseTransaction.rent.loadingData",
      LoadingDataSuccess: "leases.leaseTransaction.rent.loadingDataSuccess",
      Creating: "leases.leaseTransaction.rent.creating",
      Updating: "leases.leaseTransaction.rent.updating",
      Updated: "leases.leaseTransaction.rent.updated",
      DeleteRentLoadingText: "leases.leaseTransaction.rent.deleteRentLoadingText",
      DeleteRentSuccessText: "leases.leaseTransaction.rent.deleteRentSuccessText",
      DeleteConfirmationText: "leases.leaseTransaction.rent.deleteConfirmationText"
    },
    ReversedPayment: {
      DeleteReversedPaymentLoadingText: "leases.leaseTransaction.reversedPayment.deleteReversedPaymentLoadingText",
      DeleteReversedPaymentSuccessText: "leases.leaseTransaction.reversedPayment.deleteReversedPaymentSuccessText",
      DeleteConfirmationText: "leases.leaseTransaction.reversedPayment.deleteConfirmationText"
    }
  },
  Settings: {
    LeaseSettings: "leases.settings.leaseSettings",
    LeaseTerm: "leases.settings.leaseTerm",
    TermsDescriptionTitle: "leases.settings.termsDescriptionTitle",
    TermsDescriptionSubTitle: "leases.settings.termsDescriptionSubTitle",
    LateFees: "leases.settings.lateFees",
    LateFeesDescription: "leases.settings.lateFeesDescription",
    SpecifyLateFeesForLease: "leases.settings.specifyLateFeesForLease",
    ResidentPortal: "leases.settings.residentPortal",
    SpecifyResidentPortalForLease: "leases.settings.specifyResidentPortalForLease",
    RentersInsurance: "leases.settings.rentersInsurance",
    SpecifyRentersInsurance: "leases.settings.specifyRentersInsurance",
    RentersInsuranceDescription: "leases.settings.rentersInsuranceDescription",
    PaymentsAllocation: "leases.settings.paymentsAllocation",
    SpecifyPaymentAllocation: "leases.settings.specifyPaymentAllocation",
    LateFeesDescriptionTitle: "leases.settings.lateFeesDescriptionTitle",
    PaymentsDescriptionTitle: "leases.settings.paymentsDescriptionTitle",
    EvictionStatus: "leases.settings.evictionStatus",
    EvictionStatusDescription: "leases.settings.evictionStatusDescription",
    LeaseInEviction: "leases.settings.leaseInEviction",
    EvictionDialogDescription: "leases.settings.evictionDialogDescription",
    EvictionRecommend: "leases.settings.evictionRecommend",
    EvictionNotificationDisableOnlinePayments: "leases.settings.evictionNotificationDisableOnlinePayments",
    EvictionNotificationStopRecurringPayments: "leases.settings.evictionNotificationStopRecurringPayments",
    NameAndNotes: "leases.settings.nameAndNotes",
    NameAndNotesDialogDescription: "leases.settings.nameAndNotesDialogDescription",
    LeaseName: "leases.settings.leaseName",
    StartDate: "leases.settings.startDate",
    EndDate: "leases.settings.endDate",
    Unit: "leases.settings.unit",
    Address: "leases.settings.address",
    RentCharges: "leases.settings.rentCharges"
  }
};
export default Leases;
