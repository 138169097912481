import React from "react";
import type { FormatNumberOptions } from "react-intl";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import type { LocaleData } from "store/auth/actions";
import type { IRootState } from "../store";
import AppLocale from "../locale/locale";
import type { TranslationOverride } from "../locale/types";

//@ts-ignore
if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/locale-data/en"); // Add locale data for de
}

interface ComponentProps {
  children: React.ReactNode;
}

const ExtractNumber = 1000.22;

export const getLocaleData = (
  currency: string,
  locale: string,
  formatNumberToParts: (value: number | bigint, opts?: FormatNumberOptions | undefined) => Intl.NumberFormatPart[]
) => {
  const localeData: LocaleData = {
    thousandSeparator: ",",
    decimalSeparator: ".",
    currencySymbol: "$",
    percentageSymbol: "%",
    currency: "USD",
    locale: "en-US",
    dateFormat: "MM/DD/YYYY",
    currencySymbolPosition: "prefix",
    percentSymbolPosition: "prefix"
  };
  // format number into currency parts
  let formatParts = formatNumberToParts(ExtractNumber, {
    style: "currency",
    currency
  });

  const currencySymbol = formatParts.find((x) => x.type === "currency");
  localeData.currencySymbol = currencySymbol ? currencySymbol.value : localeData.currencySymbol;

  localeData.currencySymbolPosition = formatParts[0].type === "currency" ? "prefix" : "suffix";

  localeData.currency = currency;
  localeData.locale = locale;

  // format number into number parts
  formatParts = formatNumberToParts(ExtractNumber);

  const currencyGroup = formatParts.find((x: any) => x.type === "group");

  localeData.thousandSeparator = currencyGroup ? currencyGroup.value : "";

  const currencyDecimal = formatParts.find((x) => x.type === "decimal");
  localeData.decimalSeparator = currencyDecimal ? currencyDecimal.value : "";

  // format number into percent parts
  formatParts = formatNumberToParts(ExtractNumber, {
    style: "percent"
  });

  // get the percent symbol
  const percentSymbol = formatParts.find((x) => x.type === "percentSign");
  localeData.percentageSymbol = percentSymbol ? percentSymbol.value : localeData.percentageSymbol;

  localeData.percentSymbolPosition = formatParts[0].type === "percentSign" ? "prefix" : "suffix";

  const date = new Date(2020, 10, 21);

  let dateString = date.toLocaleDateString(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });

  dateString = dateString.replace("2020", "YYYY").replace("21", "DD").replace("11", "MM");
  localeData.dateFormat = dateString;

  return localeData;
};

const DLUI_IntlProvider: React.FC<ComponentProps> = ({ children }: ComponentProps) => {
  let locale = "en-US";
  const overrides: TranslationOverride[] = [];
  const { localeData } = useSelector((state: IRootState) => state.auth);

  AppLocale.getInstance(overrides);

  if (localeData && localeData.locale) {
    locale = localeData.locale;
  }

  return (
    <IntlProvider timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} key={locale} locale={locale}>
      {children}
    </IntlProvider>
  );
};

export { DLUI_IntlProvider as IntlProvider };
