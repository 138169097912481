"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const settingsAccess_enum_1 = require("../userRoles/settingsAccess.enum");
class GlobalSettingsDto {
}
exports.GlobalSettingsDto = GlobalSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "companyInformation", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "regionAndCurrency", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "defaultAccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "doorloopSubscription", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "paymentAllocations", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "tenantPortal", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "ownerPortal", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "rentReminders", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "paymentInstructions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "tenantRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "ownerRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "lateFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "convenienceFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "returnedPaymentFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "rentalApplications", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "quickbooks", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "listings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "managementFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "prospectsSSNAndDLFullInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(settingsAccess_enum_1.SettingsAccessEnum),
    __metadata("design:type", String)
], GlobalSettingsDto.prototype, "communicationCenter", void 0);
