"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiErrorUtils = void 0;
const apiError_1 = require("../apiError");
class ApiErrorUtils {
    isApiError(obj) {
        return obj instanceof apiError_1.ApiError;
    }
}
exports.apiErrorUtils = new ApiErrorUtils();
