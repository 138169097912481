"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllAccountClasses = exports.AccountClass = void 0;
var AccountClass;
(function (AccountClass) {
    AccountClass["ASSET"] = "ASSET";
    AccountClass["LIABILITY"] = "LIABILITY";
    AccountClass["EQUITY"] = "EQUITY";
    AccountClass["REVENUE"] = "REVENUE";
    AccountClass["EXPENSE"] = "EXPENSE";
})(AccountClass || (exports.AccountClass = AccountClass = {}));
exports.AllAccountClasses = Object.values(AccountClass);
