export default {
  Screen: {
    Title: "communicationsCenter.screen.title",
    MyInboxTitle: "communicationsCenter.screen.myInboxTitle",
    AllMessagesTitle: "communicationsCenter.screen.allMessagesTitle",
    UnAssignedTitle: "communicationsCenter.screen.unAssignedTitle",
    HelpPanel: {
      Description: "communicationsCenter.screen.helpPanel.description"
    }
  },
  FilterPanel: {
    InboxFilters: "communicationsCenter.filterPanel.inboxFilters"
  },
  SetupSmsWizard: {
    Screen: {
      Title: "communicationsCenter.setupSmsWizard.screen.title",
      Subtitle: "communicationsCenter.setupSmsWizard.screen.subTitle",
      SuccessTitle: "communicationsCenter.setupSmsWizard.screen.successTitle",
      SuccessSubtitle: "communicationsCenter.setupSmsWizard.screen.successSubtitle",
      HelpPanel: {
        Description: "communicationsCenter.setupSmsWizard.screen.helpPanel.description"
      }
    },
    SetupSmsDescription: {
      BulletDescriptions: {
        InstantUpdates: "communicationsCenter.setupSmsWizard.setupSmsDescription.bulletDescriptions.instantUpdates",
        EffortlessChat: "communicationsCenter.setupSmsWizard.setupSmsDescription.bulletDescriptions.effortlessChat",
        QuickAssistance: "communicationsCenter.setupSmsWizard.setupSmsDescription.bulletDescriptions.quickAssistance"
      },
      ServiceOnlySupported: "communicationsCenter.setupSmsWizard.setupSmsDescription.serviceOnlySupported"
    },
    BusinessDetailsStep: {
      BusinessDetails: "communicationsCenter.setupSmsWizard.businessDetailsStep.businessDetails",
      FillInYourBusinessDetails: "communicationsCenter.setupSmsWizard.businessDetailsStep.fillInYourBusinessDetails",
      EinTitle: "communicationsCenter.setupSmsWizard.businessDetailsStep.einTitle",
      WebsiteTitle: "communicationsCenter.setupSmsWizard.businessDetailsStep.websiteTitle"
    }
  },
  Errors: {
    FailedRetrieveAvailablePhoneNumbers: "communicationsCenter.errors.failedRetrieveAvailablePhoneNumbers",
    ContactNotEmpty: "communicationsCenter.errors.contactNotEmpty",
    FailedActivateNumber: "communicationsCenter.errors.failedActivateNumber",
    FailedToFindNumbers: "communicationsCenter.errors.failedToFindNumbers"
  },
  PhoneNumberPendingTitle: "communicationsCenter.phoneNumberPendingTitle",
  PhoneNumberPendingSubtitle: "communicationsCenter.phoneNumberPendingSubtitle",
  ContactUs: "communicationsCenter.contactUs",
  FailedMessageTitle: "communicationsCenter.failedMessageTitle",
  FailedMessageSubtitle: "communicationsCenter.failedMessageSubtitle",
  SetupSmsTitle: "communicationsCenter.setupSmsTitle",
  SetupSmsSubtitle: "communicationsCenter.setupSmsSubtitle",
  SetupSms: "communicationsCenter.setupSms",
  Read: "communicationsCenter.read",
  UnRead: "communicationsCenter.unread",
  InvalidContact: "communicationsCenter.invalidContact",
  SearchForRecipients: "communicationsCenter.searchForRecipients",
  NoRecipientsFound: "communicationsCenter.noRecipientsFound",
  Searching: "communicationsCenter.searching",
  ServiceNotAvailableInRegionTitle: "communicationsCenter.serviceNotAvailableInRegionTitle",
  ServiceNotAvailableInRegionDescription: "communicationsCenter.serviceNotAvailableInRegionDescription",
  ChangeYourRegion: "communicationsCenter.changeYourRegion",
  YourRequestReceived: "communicationsCenter.yourRequestReceived",
  CreditPackageRenewedSuccessfully: "communicationsCenter.creditPackageRenewedSuccessfully"
};
