import type { DoorLoopLeadDto } from "@doorloop/dto";
import {
  createValidator,
  DoorLoopBaseLeadDto,
  DoorLoopLeadSources,
  SegmentEventTypes,
  ServerRoutes
} from "@doorloop/dto";
import { AuthLogoIcon, LogoWhiteIcon } from "assets/icons";
import ShakeEffectView from "DLUI/animatableView/shakeEffectView";
import ValidationIndicator from "DLUI/form/validationIndicator/validationIndicator";
import TextField from "DLUI/form/textField/textField";

import { Icon } from "DLUI/icon/icon";
import SeparationLine from "DLUI/separatorView/separationLine";
import Text from "DLUI/text/text";
import View from "DLUI/view/view";
import type { FormikProps } from "formik";
import { FastField, Form, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import qs from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DemoSignupLayout from "screens/authScreen/layout/demoSignupLayout";
import { analyticsService } from "services/analyticsService";
import { PrivacyPolicyUrl, TermsOfServiceUrl } from "utils/sharedVariables";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import DLButton from "DLUI/button/dlButton/dlButton";
import { DLButtonSizesEnum } from "DLUI/button/dlButton/enums";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { history } from "@/store/history";
import { Routes } from "@/components/appRouter";
import { axiosInstance } from "./axiosInstance";

let formikGlobalRef: FormikProps<any> | null = null;

const AdditionalInfoFieldName = "additionalInfo";

declare global {
  interface Window {
    grecaptcha?: {
      getResponse: () => string;
      execute: () => void;
      reset: () => void;
    };
    recaptchaCallback?: any;
    onloadCallback?: any;
  }
}

const validateForm = createValidator(DoorLoopBaseLeadDto);

const axiosPost = async <T = unknown,>({ url, data }) => {
  const res = await axios.post<T>(url, data);

  return res;
};

const createDemoBaseLead = async (values: DoorLoopLeadDto, recaptchaToken: string) =>
  await axiosInstance.post(ServerRoutes.AUTH_CREATE_DEMO_BASE_LEAD, {
    ...values,
    "g-recaptcha-response": recaptchaToken
  });

const SignUp = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>();
  const { isMobile } = useResponsiveHelper();

  const email = useMemo(() => {
    const queryString = qs.parse(window.location.search);
    return queryString["email"] as string | undefined;
  }, []);

  const showAdditionalInfo = useMemo(() => {
    const queryString = qs.parse(window.location.search);
    const retValue = queryString !== null && (queryString[AdditionalInfoFieldName] as string) === "true";
    return retValue;
  }, []);

  const [hideForm, setHideForm] = useState<boolean>(email !== undefined);

  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);

  useEffect(() => {
    if (recaptchaToken && formikGlobalRef !== null) {
      handleSubmit(formikGlobalRef.values);
    }
  }, [recaptchaToken]);

  useEffect(() => {
    // patch to force the show form in case the user dismiss the recaptcha
    setTimeout(() => {
      setHideForm(false);
    }, 3000);
  }, []);

  const isValidForm = async (formikRef: FormikProps<DoorLoopBaseLeadDto>) => {
    formikRef.setFieldTouched("email");
    const errors = (await formikRef.validateForm()) as any;
    const _errors = _.isEmpty(errors);
    if (!_errors) {
      runShakeEffect();
      setErrorMessage(undefined);
    }
    return _errors;
  };

  window.recaptchaCallback = (token) => {
    if (formikGlobalRef) {
      setRecaptchaToken(token);
    }
  };

  window.onloadCallback = (error) => {
    const queryString = qs.parse(window.location.search);
    let email: string | undefined;
    if (queryString["email"]) {
      email = queryString["email"] as string;
    }
    if (email && !recaptchaToken) {
      const response = window.grecaptcha?.getResponse();
      window.grecaptcha?.execute();
    }
  };

  const didPressScheduleDemo = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (recaptchaToken) {
          await handleSubmit(formikGlobalRef.values);
        } else if (window.grecaptcha) {
          setHideForm(false);
          window.grecaptcha.execute();
        }
      }
    }
  };

  const navigateToNextStep = (leadInfo: DoorLoopBaseLeadDto, recaptchaToken?: string) => {
    if (showAdditionalInfo) {
      history.push({
        pathname: Routes.DEMO_ADDITIONAL_INFO,
        search: location.search,
        state: {
          leadInfo,
          recaptchaToken
        }
      });
    } else {
      history.push({
        pathname: Routes.DEMO_SCHEDULE,
        search: location.search,
        state: { allowClose: false }
      });
    }
  };

  const handleSubmit = async (values: DoorLoopBaseLeadDto) => {
    if (loadingInProgress) {
      runShakeEffect();
      return;
    }
    analyticsService.track(SegmentEventTypes.LEAD_SUBMITTED_FORM, {
      email: values.email,
      category: "Leads",
      label: "Demo"
    });
    if (recaptchaToken) {
      analyticsService.track(SegmentEventTypes.LEAD_PASSED_RECAPTCHA, {
        email: values.email,
        category: "Leads",
        label: "Demo"
      });
      setLoadingInProgress(true);
      const results = await createDemoBaseLead(values, recaptchaToken).catch((e) => {
        setErrorMessage(e);
        setLoadingInProgress(false);
        runShakeEffect();
      });
      if (results && results.status && results.data) {
        setErrorMessage(undefined);

        analyticsService.identifyLead(values.email, undefined, undefined, undefined, results.data.sfLeadId);

        analyticsService.track(SegmentEventTypes.LEAD_CREATED, {
          email: values.email,
          category: "Leads",
          label: "Demo",
          value: 135
        });

        navigateToNextStep(
          {
            ...values,
            ...results.data
          },
          recaptchaToken
        );
      } else {
        analyticsService.track(SegmentEventTypes.LEAD_EMAIL_INVALID, {
          email: values.email,
          category: "Leads",
          label: "Demo"
        });
        setErrorMessage(t(AppStrings.Common.GeneralError));
        setRecaptchaToken(undefined);

        window.grecaptcha?.reset?.();
      }
    } else {
      analyticsService.track(SegmentEventTypes.LEAD_FAILED_RECAPTCHA, {
        email: values.email,
        category: "Leads",
        label: "Demo"
      });
      setErrorMessage(t(AppStrings.Auth.RecaptchaFailed));
      runShakeEffect();
    }
    setLoadingInProgress(false);
    setHideForm(false);
  };

  const initFormvalues = useMemo(() => {
    const signupDto = new DoorLoopBaseLeadDto();
    const queryString = qs.parse(window.location.search);
    if (queryString["email"]) {
      signupDto.email = queryString["email"] as string;
    }
    if (queryString["phone"]) {
      signupDto.phone = queryString["phone"] as string;
    }
    if (queryString["name"]) {
      signupDto.name = queryString["name"] as string;
    }
    if (queryString["companyName"]) {
      signupDto.companyName = queryString["companyName"] as string;
    }
    if (queryString["numberOfUnits"]) {
      signupDto.numberOfUnits = Number(queryString["numberOfUnits"]);
      if (!signupDto.numberOfUnits) {
        signupDto.numberOfUnits = undefined;
      }
    }
    const eventUrl = queryString["eventUrl"] || "";
    switch (eventUrl) {
      case "call": {
        signupDto.leadSource = DoorLoopLeadSources.SCHEDULE_CALL;
        break;
      }
      default: {
        signupDto.leadSource = DoorLoopLeadSources.SCHEDULE_DEMO;
        break;
      }
    }

    return signupDto;
  }, []);

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const didChangeInput = (nextValue: string) => {
    setErrorMessage(undefined);
  };

  const didPressTermsOfServiceUrl = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    window.open(TermsOfServiceUrl, "_blank");
  };

  const didPressPrivacyPolicy = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    window.open(PrivacyPolicyUrl, "_blank");
  };

  return (
    <DemoSignupLayout>
      <Helmet>
        <script async src="https://www.google.com/recaptcha/api.js?onload=onloadCallback"></script>
      </Helmet>
      <View noWrap justifyContent={"center"} alignItems={"center"}>
        <div
          className="g-recaptcha"
          data-sitekey="6LdBWMcZAAAAAKxUIf6ZAShPaISQaNDWFSXnwqVY"
          data-callback="recaptchaCallback"
          data-onload={"onloadCallback"}
          data-expired-callback={"recaptchaError"}
          data-size="invisible"
          style={{ position: "absolute", left: 0, top: 330, display: "block" }}
        ></div>
        <Formik initialValues={initFormvalues} validate={validateForm} onSubmit={didPressScheduleDemo}>
          {(formik) => {
            formikGlobalRef = formik;
            if (hideForm) {
              return (
                <View marginTop={220} alignItems={"center"} justifyContent={"center"}>
                  <LoadingAnimation />
                </View>
              );
            }

            return (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <View alignItems={"center"}>
                  <View alignItems={"center"}>
                    <Icon Source={isMobile ? LogoWhiteIcon : AuthLogoIcon} width={200} marginTop={30} />
                    <SeparationLine width={"100%"} height={1} marginTop={20} />
                  </View>

                  <View justifyContent={"center"} alignItems={"center"}>
                    <Text
                      fontSize={30}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      value={AppStrings.Common.DoorloopDemo}
                      marginTop={isMobile ? 20 : 40}
                      color={isMobile ? "white" : "black"}
                    />
                    <Text
                      fontSize={20}
                      value={AppStrings.Common.DoorloopDemoDescription}
                      marginTop={10}
                      color={isMobile ? "white" : "gray"}
                      align={"center"}
                    />
                    <View width={50} height={5} backgroundColor={"pink"} marginTop={20} />
                    <View maxWidth={390}>
                      <ValidationIndicator
                        shouldShow={errorMessage !== undefined}
                        displayText={errorMessage || ""}
                        justifyContent={"center"}
                        fullWidth
                        themeColor={isMobile ? "white" : "red"}
                      />
                    </View>

                    <View alignItems={"center"} maxWidth={345}>
                      <FastField
                        component={TextField}
                        label={t(AppStrings.Auth.EmailAddress)}
                        name={"email"}
                        marginTop={20}
                        required
                        TextFieldType={"email"}
                        onChange={didChangeInput}
                        variant={isMobile ? "filled" : "outlined"}
                        autoComplete={"on"}
                      />
                    </View>

                    <ShakeEffectView showEffect={showErrorShakeEffect}>
                      <View alignItems={"center"} marginTop={20}>
                        <DLButton
                          onClick={didPressScheduleDemo}
                          actionText={AppStrings.Navigation.Continue}
                          size={DLButtonSizesEnum.LARGE}
                          isLoading={loadingInProgress}
                          style={{ minWidth: 345 }}
                        />
                      </View>
                    </ShakeEffectView>
                  </View>
                </View>
              </Form>
            );
          }}
        </Formik>
      </View>
    </DemoSignupLayout>
  );
};

export default SignUp;
