import DemoAdditionalInfo from "screens/demo/additionalInfo";
import AppStrings from "locale/keys";
import Routes from "./routes";
import { Route, Switch } from "react-router";
import DemoSignUp from "screens/demo/signup";
import DemoSchedule from "screens/demo/schedule";

const DemoRouter = () => (
  <Switch>
    <Route exact path={Routes.DEMO_SIGNUP} render={() => <DemoSignUp />} />
    <Route
      exact
      path={Routes.DEMO_SCHEDULE}
      render={() => <DemoSchedule documentTitle={AppStrings.Common.ScheduleDemo} />}
    />
    <Route exact path={Routes.DEMO_ADDITIONAL_INFO} render={() => <DemoAdditionalInfo />} />
  </Switch>
);

export default DemoRouter;
