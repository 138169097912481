import { useCallback, useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const SmallDesktopMaxWidth = 1440;
const TabletOrMobileScreenMaxWidth = 960;
const MobileScreenMaxWidth = 767;

const DEBUG_PAGE_SIZE = false;

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl";

export const TopMargin = 10;
export const MobileScreenContainerDefaultPadding = 15;
export const ScreenContainerDefaultPadding = 20;
export const VerticalInputPadding = 10;
export const HorizontalInputPadding = 20;
export const FlexGap = 16;

export const getDeviceType = () => {
  if (isIOS) {
    return "ios";
  }
  if (isAndroid) {
    return "android";
  }
  return "desktop";
};

const getViewType = () => {
  const isTabletOrMobile = checkIsTabletOrMobile();
  const isMobile = checkIsMobile();

  if (!isMobile && !isTabletOrMobile) {
    return "Desktop";
  }
  if (!isMobile && isTabletOrMobile) {
    return "Tablet";
  }
  return "Mobile";
};

const checkIsTabletOrMobile = () => window.innerWidth <= TabletOrMobileScreenMaxWidth;

const checkIsOnlyTablet = () =>
  window.innerWidth <= TabletOrMobileScreenMaxWidth && window.innerWidth > MobileScreenMaxWidth;

const checkIsPortrait = () => window.matchMedia("(orientation: portrait)").matches;

const getScreenSize = (): ScreenSize => {
  if (window.innerWidth <= 600) {
    return "xs";
  }
  if (window.innerWidth < 960) {
    return "sm";
  }
  if (window.innerWidth < 1280) {
    return "md";
  }
  if (window.innerWidth < 1920) {
    return "lg";
  }
  return "xl";
};

const isSize = (size: ScreenSize) => size === getScreenSize();
const isScreenSizeIn = (sizes: ScreenSize[]) => sizes.includes(getScreenSize());

const checkIsSmallDesktop = () =>
  window.innerWidth <= SmallDesktopMaxWidth && window.innerWidth >= TabletOrMobileScreenMaxWidth;

const checkIsMobile = () => window.innerWidth < MobileScreenMaxWidth;

const getScreenContainerPadding = (isTabletOrMobileState: boolean) =>
  isTabletOrMobileState ? MobileScreenContainerDefaultPadding : ScreenContainerDefaultPadding;

const calculateResponsiveState = () => {
  const isTabletOrMobile = checkIsTabletOrMobile();
  const isMobile = checkIsMobile();

  const isPortrait = checkIsPortrait();
  const isSmallDesktop = checkIsSmallDesktop();
  const isOnlyTablet = checkIsOnlyTablet();

  const isOnlyMobile = isMobile && !isOnlyTablet;
  const isDesktop = !isTabletOrMobile;
  const isOnlyDesktop = !isOnlyMobile && !isOnlyTablet;
  const isOnlyTabletAndPortrait = isOnlyTablet && isPortrait;

  const screenContainerPadding = getScreenContainerPadding(isTabletOrMobile);
  const currentSize = getScreenSize();

  const verticalInputPadding = isMobile ? 0 : VerticalInputPadding;
  const inputRightPadding = isMobile ? 0 : HorizontalInputPadding;

  return {
    isTabletOrMobile,
    isOnlyMobile,
    isOnlyTablet,
    isOnlyTabletAndPortrait,
    isOnlyDesktop,
    isSmallDesktop,
    isMobile,
    isDesktop,
    isPortrait,
    inputRightPadding,
    verticalInputPadding,
    screenContainerPadding,
    size: currentSize
  };
};

const isNativeAppByType = (type: "android" | "ios"): boolean => {
  const platformsTypes = {
    ios: navigator.userAgent.includes("DL_webView"),
    android: navigator.userAgent.includes("wv")
  };

  return platformsTypes[type];
};

const staticState = {
  isSize,
  isScreenSizeIn,
  isNativeAppByType,
  marginTopDefault: TopMargin,
  flexGap: FlexGap
};

export const useResponsive = () => {
  const [calculatedState, setCalculatedState] = useState(calculateResponsiveState());

  const updateSize = useCallback(() => {
    if (isIOS || isAndroid) {
      return;
    }

    setCalculatedState(calculateResponsiveState());

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (DEBUG_PAGE_SIZE) {
      const viewType = getViewType();
      document.title = `Size: ${getScreenSize().toUpperCase()} | View Type: ${viewType}`;
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (DEBUG_PAGE_SIZE) {
      updateSize();
    }
  }, [updateSize]);

  return { ...staticState, ...calculatedState };
};

export type UseResponsiveDataReturn = ReturnType<typeof useResponsive>;

export const getInitialState = (): UseResponsiveDataReturn => {
  const calculatedState = calculateResponsiveState();
  return { ...staticState, ...calculatedState };
};

export const DeviceType = getDeviceType();

// https://getbootstrap.com/docs/5.0/layout/breakpoints/
export enum BreakpointDown {
  MD = MobileScreenMaxWidth - 0.02
}

export const MediaBreakpointDown = {
  MD: `@media (max-width: ${BreakpointDown.MD}px)`
} as const;

// https://gist.github.com/paulirish/5d52fb081b3570c81e3a
export function isBreakpointDown(breakpoint: BreakpointDown) {
  return window.innerWidth <= breakpoint;
}
