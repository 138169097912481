import React from "react";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import Text from "DLUI/text";
import { AlertDangerIcon } from "assets/icons";
import type { PopoverItem } from "DLUI/popover";
import { SelectionPopover } from "DLUI/popover";
import type { ReplaceObjectInterface } from "DLUI/text/text";
import { AnimatePresence, motion } from "framer-motion";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  maxWidth?: number;
  displayText?: string | React.ReactNode;
  displayString?: string;
  justifyContent?: "center" | "flex-start";
  shouldShow: boolean;
  marginTop?: string | number;
  selectionPopoverItems?: PopoverItem[];
  wrapperWidth?: string;
  fullWidth?: boolean;
  underline?: "dashed" | "dotted" | "solid" | undefined;
  themeColor?: "white" | "red";
  onClick?: () => void;
  replaceObject?: ReplaceObjectInterface;
  dataCy?: string;
}

const ValidationIndicator: React.FC<ComponentProps> = ({
  maxWidth,
  displayText,
  displayString,
  justifyContent,
  shouldShow,
  marginTop,
  selectionPopoverItems,
  wrapperWidth,
  fullWidth,
  underline,
  themeColor,
  onClick,
  replaceObject,
  dataCy
}: ComponentProps) => {
  const classes = makeStyles();

  const componentContainerClass =
    themeColor && themeColor === "white" ? classes.whiteThemeComponentContainer : classes.componentContainer;

  return (
    <AnimatePresence>
      {shouldShow && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <View
            maxWidth={maxWidth}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={marginTop ?? 20}
            onClick={onClick}
            dataCy={DataCy.dialog.validationIndicator}
          >
            <SelectionPopover width={170} sectionItems={selectionPopoverItems || []}>
              <div
                style={{
                  width: wrapperWidth || "100%",
                  justifyContent: justifyContent ? justifyContent : "center",
                  boxSizing: "border-box"
                }}
                className={componentContainerClass}
              >
                <AlertDangerIcon className={classes.errorSignIcon} width={25} />
                <View paddingLeft={10} autoWidth maxWidth={"calc(100% - 45px)"}>
                  <Text
                    fullWidth={fullWidth || justifyContent === "center"}
                    color={"error"}
                    align={"left"}
                    data-cy={dataCy}
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={"17px"}
                    underLine={underline}
                    replaceObject={replaceObject}
                    value={displayString}
                  >
                    {displayText}
                  </Text>
                </View>
              </div>
            </SelectionPopover>
          </View>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ValidationIndicator;
