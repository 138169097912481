"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationSaveForLaterResponse = exports.RentalApplicationSaveForLaterDto = exports.RentalApplicationClientDto = exports.RentalApplicationDto = exports.RentalApplicationBaseDto = exports.RentalApplicationInProgressDto = void 0;
const common_1 = require("../common");
const dependant_dto_1 = require("../common/dependant.dto");
const ePayReceivableInfo_dto_1 = require("../common/ePayReceivableInfo.dto");
const pet_dto_1 = require("../common/pet.dto");
const vehicle_dto_1 = require("../common/vehicle.dto");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const rentalApplicationAboutInfo_dto_1 = require("./rentalApplicationAboutInfo.dto");
const rentalApplicationAdditionalQuestion_dto_1 = require("./rentalApplicationAdditionalQuestion.dto");
const rentalApplicationApplicantType_enum_1 = require("./rentalApplicationApplicantType.enum");
const rentalApplicationCoApplicants_dto_1 = require("./rentalApplicationCoApplicants.dto");
const rentalApplicationEmploymentAndIncomeInfo_dto_1 = require("./rentalApplicationEmploymentAndIncomeInfo.dto");
const rentalApplicationFeeInfo_dto_1 = require("./rentalApplicationFeeInfo.dto");
const rentalApplicationResidentialHistory_dto_1 = require("./rentalApplicationResidentialHistory.dto");
const files_1 = require("../files");
const rentalApplicationTermsAndConditions_dto_1 = require("./rentalApplicationTermsAndConditions.dto");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const rentalApplicationProspectScreeningShare_dto_1 = require("./rentalApplicationProspectScreeningShare.dto");
const rentalApplicationReportsRestrictions_dto_1 = require("./rentalApplicationReportsRestrictions.dto");
class RentalApplicationInProgressDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationInProgressDto = RentalApplicationInProgressDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationInProgressDto.prototype, "lastStepName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], RentalApplicationInProgressDto.prototype, "lastStepNumber", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], RentalApplicationInProgressDto.prototype, "totalSteps", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationInProgressDto.prototype, "isInProgress", void 0);
class RentalApplicationBaseDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RentalApplicationBaseDto = RentalApplicationBaseDto;
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationAboutInfo_dto_1.RentalApplicationAboutInfoDto),
    __metadata("design:type", rentalApplicationAboutInfo_dto_1.RentalApplicationAboutInfoDto)
], RentalApplicationBaseDto.prototype, "aboutInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", RentalApplicationInProgressDto)
], RentalApplicationBaseDto.prototype, "inProgress", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RentalApplicationBaseDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RentalApplicationBaseDto.prototype, "unit", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], RentalApplicationBaseDto.prototype, "initialLandingInApplication", void 0);
class RentalApplicationDto extends RentalApplicationBaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RentalApplicationDto = RentalApplicationDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(rentalApplicationApplicantType_enum_1.RentalApplicationApplicantType),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "applicantType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(rentalApplicationApplicantType_enum_1.RentalApplicationCoApplicantType),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "coApplicantType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "assignedToUser", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(24),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "applicationGroupId", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "expectedMoveInDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => dependant_dto_1.DependantDto),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "dependants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => pet_dto_1.PetDto),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "pets", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => files_1.FileDto),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "files", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => vehicle_dto_1.VehicleDto),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "vehicles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => common_1.EmergencyContactDto),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "emergencyContacts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationEmploymentAndIncomeInfo_dto_1.RentalApplicationEmploymentAndIncomeInfoDto),
    __metadata("design:type", rentalApplicationEmploymentAndIncomeInfo_dto_1.RentalApplicationEmploymentAndIncomeInfoDto)
], RentalApplicationDto.prototype, "employmentAndIncomeInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => rentalApplicationResidentialHistory_dto_1.RentalApplicationResidentialHistoryDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "residentialHistoryInfo", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => rentalApplicationAdditionalQuestion_dto_1.RentalApplicationAdditionalQuestionDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "additionalQuestions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => rentalApplicationCoApplicants_dto_1.RentalApplicationCoApplicantsDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], RentalApplicationDto.prototype, "coApplicants", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => { var _a; return (_a = x.feeInfo) === null || _a === void 0 ? void 0 : _a.requireOnlinePayment; }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => ePayReceivableInfo_dto_1.EPayReceivableInfoDto),
    __metadata("design:type", ePayReceivableInfo_dto_1.EPayReceivableInfoDto)
], RentalApplicationDto.prototype, "applicationFeeEPayInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFeeInfo_dto_1.RentalApplicationFeeInfoDto),
    __metadata("design:type", rentalApplicationFeeInfo_dto_1.RentalApplicationFeeInfoDto)
], RentalApplicationDto.prototype, "fees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationTermsAndConditions_dto_1.RentalApplicationTermsAndConditionsDto),
    __metadata("design:type", rentalApplicationTermsAndConditions_dto_1.RentalApplicationTermsAndConditionsDto)
], RentalApplicationDto.prototype, "landlordTermsAndConditions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationDto.prototype, "coApplicantsGroupId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", rentalApplicationProspectScreeningShare_dto_1.RentalApplicationProspectScreeningShareDto)
], RentalApplicationDto.prototype, "prospectScreeningShare", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", rentalApplicationReportsRestrictions_dto_1.RentalApplicationReportsRestrictionsDto)
], RentalApplicationDto.prototype, "reportsRestrictions", void 0);
class RentalApplicationClientDto extends RentalApplicationDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.RentalApplicationClientDto = RentalApplicationClientDto;
class RentalApplicationSaveForLaterDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationSaveForLaterDto = RentalApplicationSaveForLaterDto;
__decorate([
    (0, class_transformer_1.Type)(() => RentalApplicationBaseDto),
    __metadata("design:type", RentalApplicationBaseDto)
], RentalApplicationSaveForLaterDto.prototype, "dto", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], RentalApplicationSaveForLaterDto.prototype, "shouldSendEmail", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationSaveForLaterDto.prototype, "propertyName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationSaveForLaterDto.prototype, "unitName", void 0);
class RentalApplicationSaveForLaterResponse {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationSaveForLaterResponse = RentalApplicationSaveForLaterResponse;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RentalApplicationSaveForLaterResponse.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], RentalApplicationSaveForLaterResponse.prototype, "isAlreadySubmitted", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RentalApplicationSaveForLaterResponse.prototype, "message", void 0);
