"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreenWidgets = void 0;
var ScreenWidgets;
(function (ScreenWidgets) {
    ScreenWidgets["BALANCE_DUE"] = "balanceDue";
    ScreenWidgets["MISSING_CONTACT_INFO"] = "missingContactInfo";
    ScreenWidgets["NOT_SIGNED_TO_THE_PORTAL"] = "notSignedToThePortal";
    ScreenWidgets["INVALID_EMAILS"] = "invalidEmails";
})(ScreenWidgets || (exports.ScreenWidgets = ScreenWidgets = {}));
