"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionsListReportGroupBy = void 0;
var TransactionsListReportGroupBy;
(function (TransactionsListReportGroupBy) {
    TransactionsListReportGroupBy["DATE"] = "date";
    TransactionsListReportGroupBy["TYPE"] = "type";
    TransactionsListReportGroupBy["NAME"] = "name";
    TransactionsListReportGroupBy["ACCOUNT"] = "account";
    TransactionsListReportGroupBy["PROPERTIES"] = "properties";
    TransactionsListReportGroupBy["PAYMENT_METHOD"] = "paymentMethod";
    TransactionsListReportGroupBy["DEPOSIT_STATUS"] = "depositStatus";
})(TransactionsListReportGroupBy || (exports.TransactionsListReportGroupBy = TransactionsListReportGroupBy = {}));
