import AppRouter from "./components/appRouter/appRouter";
import { ThemeProvider } from "@material-ui/styles";
import DLTheme from "styles/defaultTheme";
import StoreProvider from "./utils/storeProvider";
import { IntlProvider } from "./utils/intlProvider";
import { ResponsiveProvider } from "./contexts/responsiveContext";
import { HelmetProvider } from "react-helmet-async";

export function App() {
  return (
    <HelmetProvider>
      <StoreProvider>
        <IntlProvider>
          <ResponsiveProvider>
            <ThemeProvider theme={DLTheme}>
              <AppRouter />
            </ThemeProvider>
          </ResponsiveProvider>
        </IntlProvider>
      </StoreProvider>
    </HelmetProvider>
  );
}
