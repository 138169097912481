"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnlinePaymentFeesSettingsDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const convenienceFeesPolicy_dto_1 = require("./convenienceFeesPolicy.dto");
const returnedPaymentFeesSettings_dto_1 = require("./returnedPaymentFeesSettings.dto");
const lateFeePolicy_1 = require("./lateFeePolicy");
class OnlinePaymentFeesSettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.OnlinePaymentFeesSettingsDto = OnlinePaymentFeesSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => lateFeePolicy_1.LateFeesSettingsDto),
    __metadata("design:type", lateFeePolicy_1.LateFeesSettingsDto)
], OnlinePaymentFeesSettingsDto.prototype, "lateFeesSettings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto),
    __metadata("design:type", convenienceFeesPolicy_dto_1.ConvenienceFeesPolicyDto)
], OnlinePaymentFeesSettingsDto.prototype, "convenienceFeesPolicy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto),
    __metadata("design:type", returnedPaymentFeesSettings_dto_1.ReturnedPaymentFeesSettingsDto)
], OnlinePaymentFeesSettingsDto.prototype, "returnedPaymentFeesSettings", void 0);
