import type { SubscriptionLoginResponseDto } from "@doorloop/dto";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import type { BreadCrumbsText } from "DLUI/screenTitle";
import { BreadCrumbs } from "DLUI/screenTitle";
import { View } from "DLUI/view";
import type { CSSProperties, FC } from "react";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { analyticsService } from "services/analyticsService";
import Routes from "../../appRouter/routes";
import Scroller from "./scroller/scroller";
import makeStyles from "./styles";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import moment from "moment";
import type { SVGIconComponent } from "assets/icons/types";
import type { PopoverItem } from "DLUI/popover";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import "./styles.css";
import type { FormikProps } from "formik";
import useIntercomBubble from "hooks/useIntercomBubble";
import Text from "DLUI/text";
import { useAtom, useSetAtom } from "jotai";
import { isPrintingAtom } from "utils/atoms";
import { FullScreenLoading } from "DLUI/loading/fullScreenLoading";
import { AppLayouts, useNavigationContext } from "../../../contexts/appLayoutContext";
import { useEffectAsync } from "../../../hooks/useEffectAsync";
import type { IRootState } from "store/index";
import { SeparationLine } from "DLUI/separatorView";
import type { ScreenSize } from "../../../contexts/utils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { desktopTopNavigationBarBackgroundColorAtom } from "components/layouts/layoutAtom";
import { useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

export interface ScreenProps {
  documentTitle?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  titleText?: BreadCrumbsText | BreadCrumbsText[];
  subTitleText?: BreadCrumbsText | BreadCrumbsText[];
  ActionPanel?: React.FC<any>;
  onScrollRef?: (scroller: Scroller) => void;
  hideLeftNavBar?: boolean;
  renderBackgroundLayer?: () => React.ReactNode;
  renderFrontLayer?: (formikRef?: FormikProps<any>) => React.ReactNode;
  searchPanelPlaceHolderText?: string;
  searchPanelFilterOptions?: any[];
  initialFilterSelection?: {};
  SearchPanelIcon?: SVGIconComponent;
  didChangeFilterOptions?: (filterObj: Record<string, any>) => void;
  filterTriggers?: Record<string, any>;
  detailsScreenInstance?: boolean;
  detailsScreenInstanceShowRenderDialogProvider?: boolean;
  requireAuth?: boolean;
  requestInProgress?: boolean;
  showHeaderShadow?: boolean;
  onDialogRefreshEvent?: () => void;
  actionButtons?: PopoverItem[];
  excludedScreenSizes?: ScreenSize[];
  excludedDevices?: any[];
  removeScreenPaddings?: boolean;
  formikRef?: FormikProps<any>;
  helpPanel?: any;
  hideSeparator?: boolean;
  screenContentFullHeight?: boolean;
  screenContentFullHeightMinimal?: boolean;
  fullWidth?: boolean;
  hideSearchBar?: boolean;
  screenLayout?: AppLayouts;
  excludeDevicesView?: FC<any>;
  scrollWrapperStyle?: CSSProperties;
  screenContainerStyle?: CSSProperties;
  ignoreUserSubscriptionCheck?: boolean;
  showBackButton?: boolean;
  hideSearchPanel?: boolean;
  validateFormErrors?: any;
}

const isPushRegistered = false;
const Screen = ({
  documentTitle,
  backgroundColor,
  showBackButton,
  titleText,
  subTitleText,
  children,
  ActionPanel,
  onScrollRef,
  renderBackgroundLayer,
  renderFrontLayer,
  searchPanelPlaceHolderText,
  searchPanelFilterOptions,
  initialFilterSelection,
  SearchPanelIcon,
  didChangeFilterOptions,
  detailsScreenInstance,
  detailsScreenInstanceShowRenderDialogProvider,
  requireAuth,
  requestInProgress,
  onDialogRefreshEvent,
  actionButtons,
  excludedScreenSizes,
  excludedDevices,
  removeScreenPaddings,
  formikRef,
  helpPanel,
  hideSeparator = true,
  screenContentFullHeight = false,
  screenContentFullHeightMinimal,
  fullWidth = false,
  screenLayout = AppLayouts.Sidebar,
  hideSearchBar,
  filterTriggers,
  screenContainerStyle,
  scrollWrapperStyle,
  excludeDevicesView,
  ignoreUserSubscriptionCheck = false,
  hideSearchPanel,
  validateFormErrors
}: ScreenProps) => {
  const { t } = useTranslation();
  const { setCurrentLayout } = useNavigationContext();
  const classes = makeStyles();
  const { isMobile, isTabletOrMobile, screenContainerPadding } = useResponsiveHelper();
  const { formatNumberToParts } = useIntl();
  const { hideIntercomBubble } = useIntercomBubble();
  const [isPrinting] = useAtom(isPrintingAtom);
  const setDesktopTopNavigationBarBackgroundColor = useSetAtom(desktopTopNavigationBarBackgroundColorAtom);
  const dispatch = useDispatch();
  const screenScroller = useRef(null);
  const [authInProgress, setAuthInProgress] = useState<boolean>(Boolean(requireAuth));
  const location = detailsScreenInstance ? window.location : useLocation<any>();
  const appTheme = useTheme();
  const _screenPadding = detailsScreenInstance || removeScreenPaddings ? 0 : screenContainerPadding;
  const currentLoginResponse = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const handleUserSubscription = (subscription?: SubscriptionLoginResponseDto) => {
    const arrivedFromSubscriptionRoute = location.pathname.includes("subscription");

    const hasSubscription = subscription?.status !== undefined && subscription?.status !== "canceled";

    const trialDate = moment(subscription?.trialEndsAt);

    const today = moment();

    const trialEnded = today.isAfter(trialDate);

    const paymentIssueWithSubscription =
      hasSubscription && subscription?.status !== "active" && subscription?.status !== "trialing";

    if (!arrivedFromSubscriptionRoute) {
      if (!hasSubscription && trialEnded) {
      } else if (paymentIssueWithSubscription) {
      }
    }
  };

  const handleScrollRef = () => {
    if (screenScroller !== null && screenScroller.current !== null) {
      if (onScrollRef) {
        setTimeout(() => {
          //@ts-ignore
          onScrollRef(new Scroller(screenScroller.current));
        }, 1000);
      }
    }
  };

  useEffectAsync(async () => {
    handleScrollRef();

    analyticsService.page();
    setDesktopTopNavigationBarBackgroundColor(backgroundColor || appTheme.palette.screenBackground.main);
  }, []);

  useEffect(() => {
    handleScrollRef();

    if (!authInProgress) {
      setCurrentLayout(screenLayout);
    }
  }, [authInProgress]);

  useEffect(() => {
    isMobile && hideIntercomBubble();
  }, [isMobile]);

  const renderScreenTitle = () => {
    const marginBottom = isTabletOrMobile ? 10 : hideSeparator ? 0 : 20;

    if (!titleText && !subTitleText) {
      return null;
    }

    return (
      <View
        justifyContent={"center"}
        marginBottom={marginBottom}
        minHeight={60}
        className={"breadcrumbs-container"}
        overflow={"hidden"}
        noWrap
      >
        <BreadCrumbs
          ActionPanel={ActionPanel}
          helpPanel={helpPanel}
          subTitleText={subTitleText}
          titleText={titleText}
          screenPadding={_screenPadding}
          showBackButton={showBackButton}
        />
        {!hideSeparator && <SeparationLine width={"calc(100% - 40px)"} marginLeft={20} marginTop={10} height={1} />}
      </View>
    );
  };

  const _renderBackgroundLayer = () => {
    if (renderBackgroundLayer) {
      return renderBackgroundLayer();
    }
    return null;
  };

  const _renderFrontLayer = () => renderFrontLayer && renderFrontLayer(formikRef);

  const _didChangeFilterOptions = () => {};

  const renderSearchPanel = () => {
    if (!searchPanelFilterOptions || !searchPanelPlaceHolderText) {
      return null;
    }

    return <div></div>;
  };

  const renderPrintingLoading = () =>
    isPrinting && (
      <FullScreenLoading>
        <Text value={AppStrings.Common.PrintPreparingMessage} />
      </FullScreenLoading>
    );

  const content = (
    <Fragment>
      {renderPrintingLoading()}
      <div className={clsx(["backgroundLayer", classes.screenLayer, classes.backgroundLayer])}>
        {_renderBackgroundLayer()}
      </div>
      <div
        className={clsx([
          classes.screenLayer,
          classes.contentLayer,
          isMobile && !screenContentFullHeight ? classes.contentLayerMobile : "",
          "contentLayer"
        ])}
      >
        <div
          ref={screenScroller}
          id={detailsScreenInstance ? "" : "screenScroll"}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            overflowX: isMobile ? "hidden" : undefined,
            ...scrollWrapperStyle
          }}
          className={clsx([
            "screen-component",
            detailsScreenInstance ? "detailsScreenContainer" : classes.overflowScroll
          ])}
        >
          {renderScreenTitle()}
          <View
            style={screenContainerStyle}
            fullWidth={fullWidth}
            paddingRight={_screenPadding}
            paddingLeft={_screenPadding}
            paddingTop={_screenPadding}
            height={screenContentFullHeight ? "100%" : undefined}
            className={"screen-container"}
            noWrap
          >
            {!hideSearchPanel && renderSearchPanel()}
            {SearchPanelIcon ? (
              <Hidden mdDown>
                <div className={classes.searchPanelIcon}>
                  <SearchPanelIcon />
                </div>
              </Hidden>
            ) : null}
            {children}
          </View>
        </div>
      </div>
      <div className={clsx([classes.screenLayer, classes.frontLayer])}>{_renderFrontLayer()}</div>
    </Fragment>
  );

  const renderScreenContent = () => {
    if (authInProgress) {
      return (
        <div
          style={{ height: `calc(100vh - 50px)` }}
          className={clsx([classes.screenContainer, classes.loadingIndicatorContainer])}
        >
          <LoadingAnimation />
        </div>
      );
    }

    if (detailsScreenInstance && !detailsScreenInstanceShowRenderDialogProvider) {
      return content;
    }

    return content;
  };

  const shouldRemoveOverflowHiddenRole = useMemo(
    () => location.pathname.indexOf(Routes.REPORTS) !== -1 || location.pathname.indexOf(Routes.PRINT_CHECKS) !== -1,
    [location.pathname]
  );

  const finalDocumentTitle = documentTitle ? `${t(AppStrings.Common.DocumentTitle)}${t(documentTitle)}` : undefined;

  return (
    <div style={{ height: "100%", width: "100%" }} className={clsx([classes.screenContainer, "DLUI_screenContainer"])}>
      {finalDocumentTitle && (
        <Helmet>
          <title>{finalDocumentTitle}</title>
        </Helmet>
      )}
      <div className={classes.screenContent}>
        <div
          className={clsx(shouldRemoveOverflowHiddenRole ? "" : "pageContainer", classes.pageContainer)}
          style={{ backgroundColor }}
        >
          <div
            className={clsx([
              screenContentFullHeightMinimal ? classes.screenContentContainerFullHeight : classes.screenContentContainer
            ])}
            style={{ height: isTabletOrMobile ? "100%" : undefined }}
          >
            {renderScreenContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
