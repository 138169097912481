import View from "DLUI/view/view";
import { useEffect, useMemo, useRef, useState } from "react";
import DemoSignupLayout from "screens/authScreen/layout/demoSignupLayout";
import Text from "DLUI/text/text";
import AppStrings from "locale/keys";
import { ShakeEffectView } from "DLUI/animatableView";
import { Grid } from "@material-ui/core";
import type { FormikProps } from "formik";
import { FastField, FormikContext, useFormik } from "formik";
import ValidationIndicator from "DLUI/form/validationIndicator/validationIndicator";
import TextField from "DLUI/form/textField/textField";
import Select from "DLUI/form/select/selectField";

import { useTranslation } from "react-i18next";
import {
  createValidator,
  DoorLoopLeadDto,
  isntNorthAmerica,
  JobTitlesMarketing,
  MainPropertyTypeEnum,
  OtherJobTitles,
  SegmentEventTypes,
  ServerRoutes,
  WhenPlanToUseSoftware,
  YesNoEnum
} from "@doorloop/dto";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import qs from "query-string";
import { analyticsService } from "services/analyticsService";
import { history } from "store/history";
import { useInjectClickCaseScript } from "./clickCeaseScript";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useFormikTools } from "hooks/useFormikTools";
import ViewSlider from "react-view-slider";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { useEffectAsync } from "hooks/useEffectAsync";
import { WizardStepsIndicator } from "DLUI/wizard/wizardStepsIndicator";
import type { DLMCCookie } from "screens/demo/types";
import { PrivacyPolicyUrl } from "utils/sharedVariables";
import { Link } from "DLUI/link";
import { MobileScreenContainerDefaultPadding } from "contexts/utils";
import { QueryParams } from "utils/queryParams";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { demoFormUtils } from "screens/demo/demo.utils";
import { Routes } from "@/components/appRouter";
import { axiosInstance } from "./axiosInstance";

interface IPDetails {
  country: string;
  ip: string;
}

const FORM_INNER_WIDTH = 360;

const customPropertyTypeEnumOrder = [
  "RESIDENTIAL",
  "COMMERCIAL",
  "RESIDENTIAL_COMMERCIAL",
  "COMMUNITY_ASSOCIATIONS",
  "AFFORDABLE_HOUSING",
  "STORAGE",
  "SHORT_TERM_RENTALS",
  "OTHER"
];

const getIPDetails = async (): Promise<IPDetails> => {
  const resp = await fetch("https://ipapi.co/json/");
  const { country_name: country, ip } = await resp.json();
  return {
    country,
    ip
  };
};

const getIsUsingVPN = async (ipAddress: string) => {
  const proxyResponse = await fetch(
    `https://proxycheck.io/v2/${ipAddress}?key=public-4w01sq-47853m-z7mi31&risk=1&vpn=1`,
    { method: "GET" }
  );
  const proxyData = await proxyResponse.json();
  return Boolean(proxyData.ip && proxyData[proxyData.ip].proxy === "yes");
};

const parseObj = (str?: string) => {
  try {
    return JSON.parse(str || "null");
  } catch (e) {
    return null;
  }
};

interface ComponentProps {
  documentTitle?: string;
}

const decodeURIComponentIteratively = (str: string, maxTries = 4): string => {
  // some cookies may be encoded multiple times
  // parse it in a loop until it doesn't change anymore
  for (let i = 0; i < maxTries; i++) {
    const decoded = decodeURIComponent(str);
    if (decoded === str) {
      // there was no change, we reached the final result
      return decoded;
    }
    str = decoded;
  }

  // if we reached here, we couldn't properly decode the cookie
  return str;
};

const getDLMCCookie = (): Record<string, string> => {
  const str = decodeURIComponent(document.cookie);
  if (!str?.trim()) return {};
  if (!str.includes("=")) return {};
  return str
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      // edge case - v?.[1] might not have a value - use optional access ?. to avoid crash
      acc[decodeURIComponentIteratively(v[0].trim())] = decodeURIComponentIteratively(v?.[1]?.trim());
      return acc;
    }, {});
};

const createOrUpdateDemoLead = async (data: DoorLoopLeadDto) =>
  await axiosInstance.post(ServerRoutes.AUTH_POST_CREATE_OR_UPDATE_DEMO_LEAD, data);

export default function DemoAdditionalInfo() {
  const DLMCCookieObject = parseObj(getDLMCCookie()?.dlmc) as DLMCCookie;
  const { isMobile } = useResponsiveHelper();
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const [arrivedWithInvalidEmail, setArrivedWithInvalidEmail] = useState(false);
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const location = useLocation<any>();
  const [currentActiveView, setCurrentActiveView] = useState(0);
  const IPDetailsRef = useRef<IPDetails | null>(null);
  const hadEmailInQueryString = useMemo(() => {
    const { email } = qs.parse(window.location.search);
    return Boolean(email);
  }, []);
  const { startSessionRecordingWithoutFeatureFlag } = useAnalyticsService();

  const initFormValues = useMemo(() => {
    if (location.state?.leadInfo) {
      return location.state?.leadInfo;
    }
    const signupDto = new DoorLoopLeadDto();
    const queryString = qs.parse(window.location.search);
    signupDto.email ||= queryString.email as string;
    return signupDto;
  }, []);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: createValidator(DoorLoopLeadDto),
    onSubmit: _.noop
  });

  useFormikTools(formik);

  useEffectAsync(async () => {
    formik.setFieldValue(
      "leadsource",
      QueryParams.get("leadsource") === "Scheduled Call" ? "Scheduled Call" : "Scheduled Demo"
    );
    const res = await getIPDetails();
    const isUsingVPN = await getIsUsingVPN(res.ip);
    IPDetailsRef.current = res;
    formik.setFieldValue("ipAddress", res.ip);
    formik.setFieldValue("country", res.country);
    formik.setFieldValue("isUsingVPN", isUsingVPN);
    const emailValidationResult = await demoFormUtils.hubspot.createEmailOnlyLead(
      {
        ...formik.values,
        ipAddress: res.ip,
        country: res.country,
        isUsingVPN
      },
      DLMCCookieObject
    );
    if (emailValidationResult) {
      formik.setFieldError("email", "Please enter a valid email");
      formik.setFieldTouched("email", true, false);
      setArrivedWithInvalidEmail(true);
    }
    demoFormUtils.track("visited_demo_form_page");
  }, []);

  useEffect(() => {
    analyticsService.page();
  }, []);

  useInjectClickCaseScript();

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const isFirstStepValid = async () => {
    formik.setFieldTouched("email");
    formik.setFieldTouched("name");
    formik.setFieldTouched("phone");
    formik.setFieldTouched("numberOfUnits");

    const [errors, { isValid }] = await Promise.all([
      formik.validateForm(),
      demoFormUtils.validateEmail(formik.values.email)
    ]);

    if (!isValid) {
      formik.setFieldError("email", "Please enter a valid email");
      formik.setFieldTouched("email", true, false);
    }
    return isValid && _.isEmpty(_.intersection(_.keys(errors), ["email", "name", "phone", "numberOfUnits"]));
  };

  const isSecondStepValid = async () => {
    formik.setFieldTouched("selectedJobTitle");
    formik.setFieldTouched("jobTitle");
    formik.setFieldTouched("mainPropertyType");
    formik.setFieldTouched("whenPlanToUseSoftware");
    const errors = await formik.validateForm();
    return _.isEmpty(
      _.intersection(_.keys(errors), ["selectedJobTitle", "jobTitle", "mainPropertyType", "whenPlanToUseSoftware"])
    );
  };

  const handleSubmit = async (values: DoorLoopLeadDto) => {
    if (loadingInProgress) {
      runShakeEffect();
      return;
    }
    setLoadingInProgress(true);

    try {
      await createOrUpdateDemoLead(values);
    } catch (e) {}

    analyticsService.track(SegmentEventTypes.LEAD_SUBMITTED_PERSONAL_INFO_FORM, {
      category: "Leads",
      label: "Demo",
      value: 165,
      ...values
    });
    analyticsService.identifyLead(values.email, values.name, values.phone, values.numberOfUnits, values.sfLeadId);
    setErrorMessage(undefined);
    const queryString = qs.parse(location.search);
    queryString.email = values.email || "";
    queryString.name = values.name || "";
    queryString.phone = values.phone || "";
    queryString.numberOfUnits = values.numberOfUnits?.toString() || "";

    const { result_url, result_type } = await demoFormUtils.getCalendlyURL(values, DLMCCookieObject);
    if (result_type === "arbitrary_url") {
      window.location.href = result_url;
    } else {
      history.push({
        pathname: Routes.DEMO_SCHEDULE,
        search: qs.stringify({
          result_url,
          name: values.name,
          phone: values.phone,
          email: values.email
        }),
        state: { allowClose: false }
      });
    }
  };

  const didPressScheduleDemo = async (formik: FormikProps<DoorLoopLeadDto>) => {
    if (currentActiveView === 0) {
      if (await isFirstStepValid()) {
        demoFormUtils.track("lead_filled_out_first_demo_form_section");
        demoFormUtils.hubspot.reportToHubSpot(formik.values, DLMCCookieObject, "c4fb7737-968f-4f13-bff4-ac22ab0d7ab4");
        setCurrentActiveView(1);
        demoFormUtils.track("second_demo_form_step_loaded");
      } else {
        runShakeEffect();
      }
    } else if (await isSecondStepValid()) {
      demoFormUtils.track("lead_submitted_personal_info_form");
      demoFormUtils.hubspot.reportToHubSpot(formik.values, DLMCCookieObject, "c8c49158-4926-475d-bfb0-185fbb34076f");
      await handleSubmit(formik.values);
    } else {
      runShakeEffect();
    }
  };

  const makeBlurTracking = (fieldName: string) => (fieldValue: string) => {
    demoFormUtils.track(
      `user_filled_out_${fieldName}`,
      {
        fieldName,
        timestamp: new Date().toISOString()
      },
      {
        [fieldName]: fieldValue
      }
    );
  };

  const makeDidBlur = (fieldName: string) => (nextValue: string) => {
    formik.setFieldTouched(fieldName);
    formik.validateField(fieldName);
    if (nextValue) {
      const queryString = qs.parse(window.location.search);
      queryString[fieldName] = nextValue;
      history.replace({
        search: qs.stringify(queryString)
      });
    }
  };

  const viewsArray = [
    () => (
      <View flexDirection={"row"} marginTop={20} gap={20}>
        {(!hadEmailInQueryString || arrivedWithInvalidEmail) && (
          <Grid item xs={12}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.Email)}
              name={"email"}
              required
              autoComplete={"on"}
              onBlur={makeBlurTracking("email")}
              onBlurUnconditionally={makeDidBlur("email")}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FastField
            component={TextField}
            label={t(AppStrings.Common.FullName)}
            name={"name"}
            required
            autoComplete={"on"}
            onBlur={makeBlurTracking("full_name")}
            onBlurUnconditionally={makeDidBlur("name")}
          />
        </Grid>
        <Grid item xs={12}>
          <FastField
            component={TextField}
            label={t(AppStrings.Tenants.NewTenant.Phone)}
            name={"phone"}
            required
            autoComplete={"on"}
            onBlur={makeBlurTracking("phone")}
            onBlurUnconditionally={makeDidBlur("phone")}
            formatType={"plain-number"}
            maxNumber={100_000_000_000_000_000}
          />
        </Grid>
        <Grid item xs={12}>
          <FastField
            component={TextField}
            label={t(AppStrings.Auth.WizardStep2NumberOfUnits)}
            name={"numberOfUnits"}
            required
            formatType={"number"}
            onBlur={makeBlurTracking("expected_number_of_units__c")}
            onBlurUnconditionally={makeDidBlur("numberOfUnits")}
            maxNumber={999_999}
          />
        </Grid>
      </View>
    ),
    () => (
      <View
        flexDirection={"row"}
        marginTop={20}
        gap={20}
        style={{ display: currentActiveView !== 1 ? "none" : "flex" }}
      >
        <Grid item xs={12}>
          <FastFieldSafe
            component={Select}
            name={"selectedJobTitle"}
            onChange={makeBlurTracking("user_selected_occupation")}
            label={AppStrings.Common.WhatIsYourRole}
            selectionEnum={JobTitlesMarketing}
            translationKey={"jobTitlesMarketing"}
            uniqueKey={"rolesSelector"}
            useEnumValue
            displayEmpty
            required
          />
        </Grid>
        {formik.values.selectedJobTitle === JobTitlesMarketing.OTHER && (
          <Grid item xs={12}>
            <FastFieldSafe
              component={Select}
              name={"jobTitle"}
              onChange={makeBlurTracking("are_you_a_tenant__attorney__or_accountant_")}
              label={AppStrings.Common.IAmA}
              selectionEnum={OtherJobTitles}
              translationKey={"otherJobTitles"}
              uniqueKey={"otherTitlesSelector"}
              useEnumValue
              displayEmpty
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FastFieldSafe
            component={Select}
            name={"mainPropertyType"}
            onChange={makeBlurTracking("primary_portfolio__type")}
            label={t(AppStrings.Common.WhatIsYourMainPropertyType)}
            selectionEnum={MainPropertyTypeEnum}
            customEnumOrder={customPropertyTypeEnumOrder}
            translationKey={"mainPropertyTypes"}
            uniqueKey={"mainPropertyTypeSelector"}
            useEnumValue
            displayEmpty
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FastFieldSafe
            component={Select}
            name={"whenPlanToUseSoftware"}
            onChange={makeBlurTracking("customer_reported_timeline__c")}
            label={t(AppStrings.Common.WhenDoYouPlanOnUsingTheSoftware)}
            selectionEnum={WhenPlanToUseSoftware}
            translationKey={"whenPlanToUseSoftware"}
            uniqueKey={"whenPlanToUseSoftwareSelector"}
            useEnumValue
            displayEmpty
            required
          />
        </Grid>
        {isntNorthAmerica(formik.values.country) && (
          <Grid item xs={12}>
            <FastFieldSafe
              component={Select}
              name={"doYouManagePropertiesInTheUSOrCanada"}
              onChange={makeBlurTracking("manage_properties_us_canada")}
              label={t(AppStrings.Common.DoYouManagePropertiesInTheUSOrCanada)}
              selectionEnum={YesNoEnum}
              translationKey={"yesNoEnum"}
              uniqueKey={"doYouManagePropertiesInTheUSOrCanadaSelector"}
              useEnumValue
              displayEmpty
              required
            />
          </Grid>
        )}
      </View>
    )
  ];

  return (
    <DemoSignupLayout
      hideMarketingView
      documentTitle={AppStrings.Common.ScheduleDemoAdditionalinfoDocumentTitle}
      gap={0}
    >
      <FormikContext.Provider value={formik}>
        <View paddingLeft={isMobile ? 0 : 40} paddingRight={isMobile ? 0 : 40}>
          <View
            paddingLeft={isMobile ? 10 : 40}
            paddingRight={isMobile ? 10 : 40}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              fontSize={30}
              fontWeight={900}
              textTransform={"uppercase"}
              value={AppStrings.Common.DoorloopDemo}
              marginTop={20}
            />
            <Text
              fontSize={20}
              value={AppStrings.Common.DoorloopDemoAdditionalInfoDescription}
              marginTop={10}
              align={"center"}
            />
            <ViewSlider
              keepViewsMounted
              renderView={({ index }) => viewsArray[index]?.()}
              numViews={2}
              activeView={currentActiveView}
              innerViewWrapper={{
                display: "flex",
                height: "100%",
                width: "100%"
              }}
            />
            <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
              <View backgroundColor={"error-red"} alignItems={"center"} marginTop={20}>
                <DLButton
                  onClick={async () => await didPressScheduleDemo(formik)}
                  actionText={AppStrings.Navigation.Continue}
                  size={DLButtonSizesEnum.LARGE}
                  isLoading={loadingInProgress}
                  style={{ width: "100%" }}
                />
              </View>
            </ShakeEffectView>
            <View
              flexDirection={"row"}
              marginTop={20}
              paddingLeft={MobileScreenContainerDefaultPadding}
              paddingRight={MobileScreenContainerDefaultPadding}
              justifyContent={"center"}
            >
              <Text fontSize={12} value={AppStrings.Common.MarketingLeadTerms} />
              <Link hrefUrl={PrivacyPolicyUrl} underline={"always"} hoverColor={"blue"} type={"blank"}>
                <Text marginLeft={3} fontSize={12}>
                  {t(AppStrings.Common.PrivacyPolicy)}
                </Text>
              </Link>
            </View>
            <ValidationIndicator
              shouldShow={errorMessage !== undefined}
              displayText={errorMessage || ""}
              wrapperWidth={FORM_INNER_WIDTH + "px"}
            />
          </View>
        </View>
        <View fullWidth alignItems={"center"} marginTop={28}>
          <WizardStepsIndicator currentStep={currentActiveView} numOfSteps={3} />
        </View>
      </FormikContext.Provider>
    </DemoSignupLayout>
  );
}
