import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Header from "./AppBars/appBarContainer";
import Footer from "./footer";

interface IAppLayoutProps extends RouteComponentProps {
  children: any;
}

const AppLayout: React.FC<IAppLayoutProps> = (props: IAppLayoutProps) => (
  <>
    <Header />
    <Grid container xs={12}>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
    <Footer />
  </>
);

export default withRouter(AppLayout);
