const Tenants = {
  Screen: {
    ContactInfo: "tenants.screen.contactInfo",
    TenantRequestChooseLeaseAssociatedWithMultipleUnits:
      "tenants.screen.tenantRequestChooseLeaseAssociatedWithMultipleUnits",
    SendingInvite: "tenants.screen.sendingInvite",
    SendingReminder: "tenants.screen.sendingReminder",
    Tenant: "tenants.screen.tenant",
    TenantPortal: "tenants.screen.tenantPortal",
    DeleteConfirmationText: "tenants.screen.deleteConfirmationText",
    DeleteLoadingText: "tenants.screen.deleteLoadingText",
    DeleteSuccessText: "tenants.screen.deleteSuccessText",
    SearchPaneltitle: "tenants.screen.searchPaneltitle",
    SearchPlaceHolder: "tenants.screen.searchPlaceHolder",
    EmptydataViewInstructionsText: "tenants.screen.emptydataViewInstructionsText",
    EmptydataAddNew: "tenants.screen.emptydataAddNew",
    AddNewTenant: "tenants.screen.addNewTenant",
    EditTenant: "tenants.screen.editTenant",
    ViewProfile: "tenants.screen.viewProfile",
    LoginToPortal: "tenants.screen.loginToPortal",
    ResidentPortalEnabled: "tenants.screen.residentPortalEnabled",
    ResidentPortalNotEnabled: "tenants.screen.residentPortalNotEnabled",
    MoveInTenant: "tenants.screen.moveInTenant",
    MoveOutTenant: "tenants.screen.moveOutTenant",
    PortalEnabled: "tenants.screen.portalEnabled",
    PortalDisabled: "tenants.screen.portalDisabled",
    Portal: "tenants.screen.portal",
    HelpPanel: {
      AddToNewLease: "tenants.screen.helpPanel.addToNewLease",
      AddToExistingLease: "tenants.screen.helpPanel.addToExistingLease",
      FindTenants: "tenants.screen.helpPanel.findTenants",
      Overview: "tenants.screen.helpPanel.overview",
      Description: "tenants.screen.helpPanel.description",
      DialogTitle: "tenants.screen.helpPanel.dialogTitle"
    }
  },
  MoveTenants: {
    WhoIsMovingIn: "tenants.moveTenants.whoIsMovingIn",
    WhoIsMovingOut: "tenants.moveTenants.whoIsMovingOut",
    SelectTenant: "tenants.moveTenants.selectTenant",
    InviteToPortal: "tenants.moveTenants.inviteToPortal",
    MoveInCreateErrorText: "tenants.moveTenants.moveInCreateErrorText",
    MoveOutCreateErrorText: "tenants.moveTenants.moveOutCreateErrorText",
    MoveInCreateSuccessText: "tenants.moveTenants.moveInCreateSuccessText",
    MoveOutCreateSuccessText: "tenants.moveTenants.moveOutCreateSuccessText",
    MoveOutDate: "tenants.moveTenants.moveOutDate",
    MoveInDate: "tenants.moveTenants.moveInDate",
    NoticeDate: "tenants.moveTenants.noticeDate",
    HelpPanel: {
      In: {
        Description: "tenants.moveTenants.helpPanel.in.description",
        Move: "tenants.moveTenants.helpPanel.in.move",
        DialogTitle: "tenants.moveTenants.helpPanel.in.dialogTitle"
      },
      Out: {
        Description: "tenants.moveTenants.helpPanel.out.description",
        Move: "tenants.moveTenants.helpPanel.out.move",
        DialogTitle: "tenants.moveTenants.helpPanel.out.dialogTitle"
      }
    }
  },
  NewTenant: {
    FirstName: "tenants.newTenant.firstName",
    LastName: "tenants.newTenant.lastName",
    MiddleNameInitials: "tenants.newTenant.middleNameInitials",
    MiddleName: "tenants.newTenant.middleName",
    Company: "tenants.newTenant.company",
    JobTitle: "tenants.newTenant.jobTitle",
    Notes: "tenants.newTenant.notes",
    ContactInfo: "tenants.newTenant.contactInfo",
    ContactDetails: "tenants.newTenant.contactDetails",
    Phone: "tenants.newTenant.phone",
    MobilePhone: "tenants.newTenant.mobilePhone",
    Email: "tenants.newTenant.email",
    PrimaryEmail: "tenants.newTenant.primaryEmail",
    Address: "tenants.newTenant.address",
    AddAnother: "tenants.newTenant.addAnother",
    AlternateAddress: "tenants.newTenant.alternateAddress",
    PrimaryAddress: "tenants.newTenant.primaryAddress",
    EmergencyContact: "tenants.newTenant.emergencyContact",
    EmergencyContactInformation: "tenants.newTenant.emergencyContactInformation",
    EmergencyContactName: "tenants.newTenant.emergencyContactName",
    EmergencyContactRelationship: "tenants.newTenant.emergencyContactRelationship",
    Contact: "tenants.newTenant.contact",
    LoadingText: "tenants.newTenant.loadingText",
    ErrorText: "tenants.newTenant.errorText",
    SuccessText: "tenants.newTenant.successText",
    CreateNewTenant: "tenants.newTenant.createNewTenant",
    UploadPhoto: "tenants.newTenant.uploadPhoto",
    ChooseFromList: "tenants.newTenant.chooseFromList",
    PersonalInfo: "tenants.newTenant.personalInfo",
    NoAddresses: "tenants.newTenant.noAddresses",
    NoContactInfo: "tenants.newTenant.noContactInfo",
    NoEmergencyContacts: "tenants.newTenant.noEmergencyContacts",
    NoNotes: "tenants.newTenant.noNotes",
    NoContacts: "tenants.newTenant.noContacts",
    ViewContacts: "tenants.newTenant.viewContacts",
    DateOfBirth: "tenants.newTenant.dateOfBirth",
    DeleteConfirmationText: "tenants.newTenant.deleteConfirmationText",
    DeleteLoadingText: "tenants.newTenant.deleteLoadingText",
    DeleteProspectSuccessText: "tenants.newTenant.deleteProspectSuccessText",
    TenantLoginEmailPhoneDescription: "tenants.newTenant.tenantLoginEmailPhoneDescription",
    Pets: "tenants.newTenant.pets",
    Vehicles: "tenants.newTenant.vehicles",
    Dependents: "tenants.newTenant.dependents"
  },
  FilterValues: {
    Current: "tenants.filterValues.current",
    Past: "tenants.filterValues.past",
    Future: "tenants.filterValues.future",
    Canceled: "tenants.filterValues.canceled",
    Draft: "tenants.filterValues.draft",
    Invited: "tenants.filterValues.invited",
    Active: "tenants.filterValues.active",
    NotInvited: "tenants.filterValues.notInvited"
  },
  Status: {
    Current: "tenants.status.current",
    Past: "tenants.status.past",
    Future: "tenants.status.future",
    Draft: "tenants.status.draft",
    Active: "tenants.status.active",
    Inactive: "tenants.status.inactive"
  }
};

export default Tenants;
