import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

const SCROLL_OFFSET = 100;

const defaultOuterContainerId = "screenScroll";

export default class Scroller {
  private container: HTMLDivElement | null = null;
  private outerContainerId: string | null = null;

  private scrollTopTimeoutId: number;
  private scrollBottomTimeoutId: number;

  constructor(container: HTMLDivElement, outerContainerId = defaultOuterContainerId) {
    this.container = container;
    this.outerContainerId = outerContainerId;
  }

  destroy() {
    this.container = null;
    this.outerContainerId = null;

    window.clearTimeout(this.scrollTopTimeoutId);
    window.clearTimeout(this.scrollBottomTimeoutId);
  }

  scrollTop = (topPosition: number, delay = 0, scrollOffset?: number) => {
    this.scrollTopTimeoutId = window.setTimeout(() => {
      this.container?.scroll({
        top: topPosition - (scrollOffset || SCROLL_OFFSET),
        behavior: "smooth"
      });
    }, delay);
  };

  scrollBottom = (delay = 0) => {
    window.setTimeout(() => {
      this.outerContainerId &&
        this.container?.scrollTo({
          left: 0,
          top: document.getElementById(this.outerContainerId)?.scrollHeight,
          behavior: "smooth"
        });
    }, delay);
  };
}
