import React, { useMemo } from "react";
import "./styles.css";
import clsx from "clsx";
import type { SVGIconComponent } from "assets/icons/types";

export type FillColors =
  | "black"
  | "blue"
  | "gray"
  | "white"
  | "light-gray"
  | "lighter-gray"
  | "lightest-gray"
  | "dark-green"
  | "error"
  | "warning"
  | "pink"
  | "light-blue"
  | "light-blue-secondary"
  | "yellow"
  | "secondary-yellow"
  | "secondary-red"
  | "green"
  | "red"
  | "current-color"
  | "noFill";

export interface IconProps {
  Source: SVGIconComponent;
  width?: number | string;
  height?: number | string;
  size?: number | string;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  circleFill?: FillColors;
  rectFill?: FillColors;
  circleStroke?: FillColors;
  pathColor?: FillColors;
  className?: string;
  dataCy?: string;
}

export const Icon: React.FC<IconProps> = ({
  Source,
  width = "auto",
  height = "auto",
  size,
  marginLeft = 0,
  marginRight = 0,
  marginTop = 0,
  circleFill,
  pathColor,
  className,
  circleStroke,
  rectFill,
  dataCy
}) => {
  const styleObj = {
    height: size || height,
    width: size || width,
    marginLeft,
    marginRight,
    marginTop,
    dataCy
  };

  const rectFillClass = useMemo(() => {
    switch (rectFill) {
      case "light-gray":
        return "recLightGray";
      case "lighter-gray":
        return "recGrayLighter";
      case "lightest-gray":
        return "recGrayLightest";
      case "black":
        return "recLightBlack";
      case "gray":
        return "recGray";
      case "white":
        return "recLightWhite";
      case "dark-green":
        return "recLightDarkGreen";
      case "pink":
        return "recLightPink";
      case "blue":
        return "recBlue";
      case "light-blue":
        return "recLightBlue";
      case "error":
        return "recLightError";
      case "warning":
        return "recWarning";
      case "yellow":
        return "recYellow";
      case "current-color":
        return "currentColor";
      default:
        return "";
    }
  }, [rectFill]);

  const circleFillClass = useMemo(() => {
    switch (circleFill) {
      case "black":
        return "circleBlack";
      case "gray":
        return "circleGray";
      case "light-gray":
        return "circleLightGray";
      case "lighter-gray":
        return "circleGrayLighter";
      case "lightest-gray":
        return "circleGrayLightest";
      case "white":
        return "circleWhite";
      case "dark-green":
        return "circleDarkGreen";
      case "pink":
        return "circlePink";
      case "blue":
        return "circleBlue";
      case "light-blue":
        return "circleLightBlue";
      case "error":
        return "circleError";
      case "warning":
        return "circleWarning";
      case "current-color":
        return "currentColor";
      default:
        return "";
    }
  }, [circleFill]);

  const pathFillClass = useMemo(() => {
    switch (pathColor) {
      case "black":
        return "pathBlack";
      case "gray":
        return "pathGray";
      case "light-gray":
        return "pathLightGray";
      case "lighter-gray":
        return "pathGrayLighter";
      case "lightest-gray":
        return "pathGrayLightest";
      case "white":
        return "pathWhite";
      case "dark-green":
        return "pathDarkGreen";
      case "error":
        return "pathError";
      case "blue":
        return "pathBlue";
      case "green":
        return "pathGreen";
      case "pink":
        return "pathPink";
      case "light-blue":
        return "pathLightBlue";
      case "light-blue-secondary":
        return "pathLightBlueSecondary";
      case "yellow":
        return "pathYellow";
      case "red":
        return "pathRed";
      case "secondary-yellow":
        return "pathSecondaryYellow";
      case "secondary-red":
        return "pathSecondaryRed";
      case "current-color":
        return "currentColor";
      default:
        return "";
    }
  }, [pathColor]);

  const circleStrokeClass = useMemo(() => {
    switch (circleStroke) {
      case "dark-green":
        return "circleStrokeDarkGreen";
      case "error":
        return "circleStrokeError";
      case "current-color":
        return "currentColor";
      default:
        return "";
    }
  }, [circleStroke]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      className={clsx(["DLUI_Icon", circleFillClass, pathFillClass, circleStrokeClass, rectFillClass])}
      data-cy={dataCy}
    >
      <Source className={className} style={styleObj} />
    </div>
  );
};
