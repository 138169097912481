import React, { useEffect, useMemo } from "react";
import qs from "query-string";
import { InlineWidget } from "react-calendly";
import DemoSignupLayout, { ScheduleFrameMaxHeight } from "screens/authScreen/layout/demoSignupLayout";
import { IconButton } from "DLUI/form";
import { AuthLogoIcon, CloseCircleIcon } from "../../../assets";
import { useLocation } from "react-router";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { QueryParams } from "utils/queryParams";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { analyticsService } from "services/analyticsService";
import { demoFormUtils } from "screens/demo/demo.utils";

interface ComponentProps {
  documentTitle?: string;
}

const isCalendlyEvent = (e) => e.data.event && e.data.event.indexOf("calendly") === 0;

const getCalendlyURLFromParams = (): string | null => {
  const resultUrl = QueryParams.get("result_url");
  return resultUrl ? decodeURIComponent(resultUrl) : null;
};

const Schedule = ({ documentTitle }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const { state } = useLocation<{ allowClose?: boolean }>();
  const { isMobile } = useResponsiveHelper();
  const { startSessionRecordingWithoutFeatureFlag } = useAnalyticsService();

  useEffect(() => {
    analyticsService.page();
  }, []);

  useEffect(() => {
    const onCalendlyMessage = function (e) {
      if (isCalendlyEvent(e)) {
        demoFormUtils.track(e.data.event);
        if (e.data?.event === "calendly.event_scheduled") {
          demoFormUtils.track("lead_scheduled_demo");
          document.location.href = "https://www.doorloop.com/thanks-confirmed" + document.location.search;
        }
      }
    };
    window.addEventListener("message", onCalendlyMessage);
    return () => window.removeEventListener("message", onCalendlyMessage);
  }, []);

  const { email, phone, name } = useMemo(() => {
    const queryString = qs.parse(window.location.search);
    let phone = queryString.phone || "";
    if (phone !== "" && phone.indexOf("1") !== 0) {
      phone = "1" + phone;
    }
    return {
      email: queryString.email as string,
      phone: phone as string,
      name: queryString.name as string
    };
  }, []);

  const dialogFrameHeight = useMemo(() => {
    if (isTabletOrMobile) {
      return window.innerHeight;
    }

    let frameHeight = ScheduleFrameMaxHeight;
    if (isTabletOrMobile || window.innerHeight < ScheduleFrameMaxHeight) {
      frameHeight = window.innerHeight;
    }
    return frameHeight;
  }, [window.location.pathname]);

  const calendlyURL = getCalendlyURLFromParams();

  return (
    <DemoSignupLayout documentTitle={documentTitle} gap={0}>
      {state?.allowClose && (
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10
          }}
        >
          <IconButton Icon={CloseCircleIcon} onClick={() => history.back()} />
        </div>
      )}
      <View noWrap alignItems={"center"} marginTop={16} marginBottom={16}>
        {!isMobile && <Icon Source={AuthLogoIcon} width={200} />}
        {/*<Text value={AppStrings.Common.DoorloopDemoLetsScheduleYourPersonalizedDemo} marginTop={16} />*/}
      </View>
      {calendlyURL && (
        <InlineWidget
          url={calendlyURL}
          prefill={{
            email,
            name,
            customAnswers: {
              a1: phone
            }
          }}
          styles={{
            width: "100%",
            margin: "0px",
            padding: "0px",
            height: dialogFrameHeight
          }}
        />
      )}
    </DemoSignupLayout>
  );
};

export default Schedule;
